import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import Client from '../../../../entities/client';
import {Observable} from 'rxjs';
import {API_URL} from '@app/core/constants';

@Injectable()
export class FormClientInfoService {
    constructor(private http: HttpClient) {
    }

    addClient(client: Client): Observable<any> {
        client.setTehInfo();
        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const options = {headers: headers};

        return this.http.post(`${API_URL}/customers`, client, options);
    }

    getCarById(clientId: number, carId: number): Observable<any> {
        return this.http.get(`${API_URL}/customers/${clientId}/cars/${carId}`);
    }

    addCar(clientId: number, car: any): Observable<any> {
        return this.http.post(`${API_URL}/customers/${clientId}/cars/add`, {...car});
    }

    editCar(clientId: number, carId, car: any): Observable<any> {
        return this.http.put(`${API_URL}/customers/${clientId}/cars/${carId}/edit`, {...car});
    }

    removeCar(clientId: number, carId: number): Observable<any> {
        return this.http.delete(`${API_URL}/customers/${clientId}/cars/${carId}/remove`);
    }
}
