import {AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {API_URL} from '@app/core/constants';
import {UIMangerService} from '@services/ui-manger.service';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';

@Component({
    selector: 'app-list-of-expenses',
    templateUrl: './list-of-expenses.component.html',
    styleUrls: ['./list-of-expenses.component.scss']
})
export class ListOfExpensesComponent implements OnInit, AfterViewInit {
    @ViewChild(MatPaginator) paginator: MatPaginator;

    displayedColumns: string[];
    dataSource: MatTableDataSource<any[]>;
    resultsLength = 0;

    constructor(
        private cdRef: ChangeDetectorRef,
        private http: HttpClient,
        private ui: UIMangerService
    ) {
        this.displayedColumns = ['user', 'sum', 'payment_purpose', 'money_taken_from', 'payment_type', 'created_at', /*'actions'*/];
        this.dataSource = new MatTableDataSource<any>([]);
    }

    ngOnInit(): void {
        this.http.get(`${API_URL}/company-expenses`).subscribe((response: any) => {
            this.resultsLength = response.data.total;
            this.dataSource.data = response.data.data;
        });
    }

    ngAfterViewInit(): void {
        // mast last line
        this.cdRef.detectChanges();

        this.paginator.page
            .pipe(
                switchMap(() => {
                    this.ui.getLoader().show();
                    return this.getPage(this.paginator.pageIndex + 1);
                }),
                map((result: any) => {
                    this.ui.getLoader().hide();
                    this.resultsLength = result.data.total;
                    return result.data.data;
                }),
                catchError(() => {
                    this.ui.getLoader().hide();
                    return of([]);
                })
            )
            .subscribe(data => {
                this.dataSource.data = data;
            });
    }

    private getPage(page: number) {
        return this.http.get(`${API_URL}/company-expenses?page=${page}`);
    }
}
