<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xs-12">

                <div class="card">
                    <div class="card-header card-header-tabs" data-background-color="red">
                        <div class="nav-tabs-navigation">
                            <div class="nav-tabs-wrapper">
                                <ul class="nav nav-tabs" data-tabs="tabs">
                                    <li *ngFor="let parentCat of serviceCategoryList; let idx = index;"
                                        [class]="idx === 0 ? 'active': ''">
                                        <a data-toggle="tab" href="#tab-pane-content-{{parentCat.id}}">
                                            {{parentCat.name}}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="card-content">
                        <div class="tab-content">
                            <div *ngFor="let parentCat of serviceCategoryList; let idx = index;"
                                 class="tab-pane" [class.active]="idx === 0"
                                 id="tab-pane-content-{{parentCat.id}}">
                                <div>
                                    <!--<mat-form-field>-->
                                    <!--<input matInput (keyup)="applyFilter($event.target.value)" placeholder="Фильтр">-->
                                    <!--</mat-form-field>-->
                                </div>

                                <div *ngFor="let category of parentCat.children">
                                    <div *ngIf="category.services.length">
                                        <h3>{{category.name}}</h3>
                                        <app-price-table [tableData]="category.services"></app-price-table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
