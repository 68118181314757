import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ServiceSaleComponent} from '@app/states/boxes/components/box-view/components/service-sale/service-sale.component';
import {UIMangerService} from '@services/ui-manger.service';
import {
    ChangePriceModalData,
    ChangePriceModalResult
} from '@app/states/boxes/components/box-view/components/change-price-modal/change-price-modal';
import {ChangePriceModalComponent} from '@app/states/boxes/components/box-view/components/change-price-modal/change-price-modal.component';
import {ModalEmployeesListComponent} from '@shared/components/modal-employees-list/modal-employees-list.component';

@Component({
    selector: 'app-added-services',
    templateUrl: './added-services.component.html',
    styleUrls: ['./added-services.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddedServicesComponent implements OnInit, OnChanges {
    @Input() showError: boolean;
    @Input() services: any[] = [];

    @Output() onUpdate: EventEmitter<any> = new EventEmitter<any>();
    @Output() onRemove: EventEmitter<any> = new EventEmitter<any>();

    serviceTotalCost: number = 0;
    serviceSaleTotalCost: number = 0;

    constructor(private ui: UIMangerService) {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.recalculateServiceTotalCost();
        this.recalculateServiceSaleTotalCost();
    }

    /**
     * Откывает модальное окно с формой для установки скидки
     * Вызывается по событию
     * @param service
     */
    public setSaleForServiceItem(service: any) {
        this.ui.open(ServiceSaleComponent, {
            serviceName: service.name,
            servicePrice: service.price
        })
            .subscribe(result => {
                if (result) {
                    this.onUpdate.emit({
                        ...service,
                        salePrice: result.toPrice,
                        discountFixed: result.fixed,
                        discountPercent: result.percent,
                        cause: result.cause,
                    });

                    // this.recalculateServiceTotalCost();
                    // this.recalculateServiceSaleTotalCost();
                }
            });
    }

    /**
     * Откывает модальное окно с формой для изменения базовой
     * Вызывается по событию
     * @param service
     */
    async changeBasePrice(service: any) {
        const result = await this.ui.openAsPromise<ChangePriceModalData, ChangePriceModalResult>(ChangePriceModalComponent, {
            serviceName: service.name,
            serviceBasePrice: service.price,
            servicePrice: service.price,
        });

        if (result.success) {
            this.onUpdate.emit({
                ...service,
                price: result.price,
                base_price: service.price,
            });

            this.ui.showSnackBar(`Базовая цена услуги "${service.name}" изменена`);
        }
    }

    async changeEmployeeForService(service: any) {
        const result = await this.ui.openAsPromise(ModalEmployeesListComponent, {
            employees: service.responsibles
        });

        if (result === undefined) {
            return;
        }

        this.onUpdate.emit({
            ...service,
            employees: result
        });
    }

    private recalculateServiceTotalCost(): void {
        let cost = 0;

        this.services.forEach((value: any) => {
            if (value.price !== undefined && value.price !== null && value.active) {
                cost += value.price;
            }
        });

        this.serviceTotalCost = cost;
    }

    private recalculateServiceSaleTotalCost(): void {
        let cost = 0;

        this.services.forEach((value: any) => {
            if (value.price !== undefined && value.price !== null && value.active) {
                if (value.salePrice !== undefined && value.salePrice !== null && value.active) {
                    cost += value.salePrice;
                } else {
                    cost += value.price;
                }
            }
        });

        this.serviceSaleTotalCost = cost !== this.serviceTotalCost ? cost : 0;
    }
}
