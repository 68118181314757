<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xs-12">
                <div class="card">
                    <div class="card-content">
                        <mat-list>
                            <mat-list-item *ngFor="let cash of cashList; trackBy: tackFn" class="custom-hover">
                                <div matLine>
                                    <span class="fix-ico-cover">
                                    <i class="fa"
                                       [ngClass]="{

                                        'fa-money': cash.type == 'cash',
                                        'fa-credit-card-alt': cash.type == 'card',
                                        'fa-university': cash.type == 'legal'
                                       }"
                                       aria-hidden="true"
                                    ></i>
                                    </span>
                                    {{cash.name}}&nbsp; - &nbsp;<strong>{{cash.balance || 0}}₽</strong>
                                </div>

                                <button
                                    class="fixed-min-width-auto"
                                    (click)="cashCollection(cash)"
                                    *ngIf="cash.balance > 0"
                                    mat-raised-button color="accent"
                                    title="Инкасация">Инкасация
                                </button>
                            </mat-list-item>
                        </mat-list>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
