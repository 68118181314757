import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {IAddServiceSale, ICustomerSharesChange} from '../customer-shares.component';

@Component({
    selector: 'app-share-group',
    templateUrl: './share-group.component.html',
    styleUrls: ['./share-group.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShareGroupComponent implements OnInit, OnChanges {
    @Input() group: any;
    @Input() currentOrderServices: any[];
    @Output() addSale: EventEmitter<ICustomerSharesChange>;
    @Output() addServiceSale: EventEmitter<IAddServiceSale>;

    public orderHasSaleService: boolean;
    public disableButton: boolean;

    constructor() {
        this.addSale = new EventEmitter<ICustomerSharesChange>();
        this.addServiceSale = new EventEmitter<IAddServiceSale>();
        this.currentOrderServices = this.currentOrderServices || [];
        this.orderHasSaleService = false;
        this.disableButton = false;
    }

    ngOnInit() {
        this.checkOrderService(this.currentOrderServices, this.group.result.service_id);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.currentOrderServices.firstChange) {
            this.checkOrderService(changes.currentOrderServices.currentValue, this.group.result.service_id);
        }
    }

    public onAddSale(): void {
        this.addSale.emit({
            serviceId: this.group.result.service_id,
            nFreeId: this.group.result.n_free_id
        });
        this.disableButton = true;
    }

    public onAddServiceSale(): void {
        this.addServiceSale.emit({
            nFreeId: this.group.result.n_free_id,
            serviceId: this.group.result.service_id,
            gradeId: this.group.result.grade_id,
            price: this.group.result.price,
        });
        this.disableButton = true;
    }

    private checkOrderService(orderServices: any[], saleServiceId: any): void {
        this.orderHasSaleService = orderServices.some((item: any) => item.id === saleServiceId && item.active);
    }
}
