import {Injectable} from '@angular/core';
import {StorageInterface} from './storage.interface';
import {Todo} from '../components/todo/todo';
import {Observable} from 'rxjs';

@Injectable()
export class StorageService implements StorageInterface {

    constructor() {
    }

    getItem(): Observable<Todo[]> {
        return undefined;
    }

    addItem(data: Todo): void {
    }

    updateItem(data: Todo): void {
    }

    removeItem(data: Todo): void {
    }

    clear(): void {
    }

}
