<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xs-12">
                <div class="card">
                    <div class="card-header card-header-icon" data-background-color="rose">
                        <i class="material-icons">phone</i>
                    </div>

                    <div class="card-content">
                        <h4 class="card-title">
                            Детализация по сотруднику <span *ngIf="!isLoading">{{employee.fio}}</span>
                        </h4>

                        <div class="order-table-wrap">
                            <div class="spinner-wrap" *ngIf="isLoading">
                                <mat-spinner></mat-spinner>
                            </div>

                            <div *ngIf="!isLoading">
                                <table class="table table-striped table-hover">
                                    <tr>
                                        <td>Позиция</td>
                                        <td>{{position.name}}</td>
                                    </tr>
                                    <tr>
                                        <td>Процент от заказа</td>
                                        <td>{{employee.sum_percent}}%</td>
                                    </tr>
                                </table>

                                <br>
                                <table class="table table-striped table-hover">
                                    <tr>
                                        <td>Смена</td>
                                        <td>{{session.id}}</td>
                                    </tr>
                                    <tr>
                                        <td>Тип</td>
                                        <td>{{session.type_value}}</td>
                                    </tr>
                                    <tr *ngIf="managerInfo.orders_count">
                                        <td>Количество заказов</td>
                                        <td>{{managerInfo.orders_count}} шт.</td>
                                    </tr>
                                    <tr *ngIf="managerInfo.orders_total_sum">
                                        <td>Сумма заказов</td>
                                        <td>{{managerInfo.orders_total_sum}} руб.</td>
                                    </tr>
                                    <tr>
                                        <td>Открыл</td>
                                        <td>{{session.user_open.name}}</td>
                                    </tr>
                                    <tr>
                                        <td>Закрыл</td>
                                        <td>
                                            {{session.user_close?.name}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Администратор</td>
                                        <td>{{session.administrator.fio}}</td>
                                    </tr>
                                    <tr>
                                        <td>Кассир</td>
                                        <td>{{session.cashier.fio}}</td>
                                    </tr>
                                </table>

                                <br>
                                <table class="table" *ngIf="employee.salary">
                                    <tr class="alert alert-info">
                                        <td>Итого {{position.name}} заработал</td>
                                        <td class="text-right"><strong>{{employee.salary}} руб.</strong></td>
                                    </tr>
                                </table>

                                <br>
                                <table class="table table-striped table-hover" *ngIf="ordersInfo.items.length">
                                    <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Заработок за заказ</th>
                                        <th>Услуги, которые выполнял</th>
                                        <th>Другие услуги в заказе</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let order of ordersInfo.items">
                                        <td>{{order.order_id}}</td>
                                        <td>{{order.sum}}</td>
                                        <td>
                                            <div *ngFor="let service of order.services">
                                                <ul>
                                                    <li>Услуга: {{service.name}}</li>
                                                    <li>Базовая стоимость: {{service.base_price}} ₽</li>
                                                    <li>Базовая установленная стоимость: {{service.price}} ₽</li>
                                                    <li>Стоимость для клиента (%): {{service.sale_price}} ₽</li>
                                                    <li>Кол. сотрудников в услуге: {{service.employee_count}}</li>
                                                </ul>
                                            </div>
                                        </td>
                                        <td>
                                            <div *ngFor="let service of order.other_services">
                                                <ul>
                                                    <li>Услуга: {{service.service.name}}</li>
                                                    <li>Стоимость: {{service.sale_price}}</li>
                                                    <li *ngIf="service.employee_count">
                                                        Кол. сотрудников в услуге: {{service.employee_count}}
                                                    </li>
                                                    <li *ngIf="service.active === 0">
                                                        Услугу отменили
                                                    </li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                    <tfoot>
                                    <tr class="alert alert-info">
                                        <td>Итого заработал</td>
                                        <td></td>
                                        <td></td>
                                        <td class="text-right"><strong>{{ordersInfo.total_salary}} руб.</strong></td>
                                    </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
