import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
    selector: 'app-box-status',
    templateUrl: './box-status.component.html',
    styleUrls: ['./box-status.component.scss'],
})
export class BoxStatusComponent implements OnInit, OnChanges {
    @Input() status: string;
    @Input() label: string;
    @Input() removePadding: boolean = false;

    private matches: any[];
    public activeClass: string;

    constructor() {
        this.matches = [
            {
                status: 'EMPTY',
                className: 'alert-success'
            },
            {
                status: 'IN_PROGRESS',
                className: 'alert-danger'
            }
        ];
    }

    ngOnInit() {
        this.getActiveClass();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.status.currentValue !== changes.status.previousValue) {
            this.getActiveClass();
        }
    }

    private getActiveClass() {
        this.matches.forEach((item: any) => {
            if (this.status === item.status) {
                this.activeClass = item.className;
                return;
            }
        });
    }
}
