import {Component, OnDestroy, OnInit} from '@angular/core';
import {NotificationService} from '@services/notification.service';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {
    public notificationData: any[] = [];
    public openList: boolean;
    private subscriptions: Subscription;

    constructor(
        private notificationService: NotificationService,
        private router: Router
    ) {
        this.openList = true;
        this.subscriptions = new Subscription();
    }

    ngOnInit(): void {
        this.showList();
        const notification$ = this.notificationService.getDbList().subscribe((response: any[]) => {
            response.forEach((item: any) => {
                this.notificationData.push({
                    id: item.id,
                    link: this.getLinkByType(item.type),
                    message: item.data.message,
                    recordId: item.data.recordId,
                });
            });
        });
        this.subscriptions.add(notification$);

        this.listenNotification();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    markAsRead($event, notification: any) {
        $event.stopImmediatePropagation();
        this.notificationService.markAsRead(notification.id).subscribe((response: any) => {
            if (response.success) {
                this.notificationData = this.notificationData.filter((item: any) => item.id !== notification.id);
                this.hideList();
            }

            if (notification.link && notification.link !== '') {
                this.router.navigate(
                    [notification.link],
                    {queryParams: {id: notification.recordId}}
                );
            }
        });
    }

    private listenNotification() {
        const listener$ = this.notificationService.connect().subscribe((item: any) => {
            this.notificationData.push({...item, link: this.getLinkByType(item.type)});
        });
        this.subscriptions.add(listener$);
    }

    private getLinkByType(type: string): string {
        let link = '';

        switch (type) {
            case 'App\\Notifications\\Record\\RemindAboutRecordNotification':
                link = '/records/current';
                break;
            default:
                link = '';
        }

        return link;
    }

    private showList(): void {
        this.openList = true;
    }

    private hideList(): void {
        this.openList = false;
    }
}
