<div *ngIf="shares.length">
    <mat-card>
        <mat-card-content>
            <h5><strong>Бонусы клиента</strong></h5>

            <div *ngFor="let group of shares">
                <h6>
                    <strong>{{group.action.name}}</strong>
                    <span>
                        Требуется услуг для получуние бессплатной - <strong>{{group.action.n_count}}</strong>
                    </span>
                </h6>

                <h6>
                    <span>Авто: </span>
                    <span *ngIf="group.car.number">{{group.car.number}}</span>
                    <span *ngIf="group.car.make">,&nbsp;{{group.car.make.name}}</span>
                    <span *ngIf="group.car.model">,&nbsp;{{group.car.model.name}}</span>
                    <span *ngIf="group.car.year">,&nbsp;{{group.car.year}}</span>
                </h6>

                <div class="table-responsive">
                    <table class="table">
                        <thead>
                        <tr>
                            <th>Услуга</th>
                            <th class="text-center">Заказывал</th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr *ngFor="let service of group.services">
                            <td class="td--service-name">{{service.service_name}}</td>
                            <td class="text-center">{{service.current_count}}</td>
                        </tr>
                        <tr>
                            <td><strong>Общее число заказов по акционным услугам</strong></td>
                            <td class="text-center"><strong>{{group.result.total_count}}</strong></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <br>
            </div>
        </mat-card-content>
    </mat-card>
</div>
