<h6>
    <strong>{{group.action.name}}</strong>
    <span>
        Требуется услуг для получения бесплатной - <strong>{{group.action.n_count}}</strong>
    </span>
</h6>

<h6>
    <span>Авто: </span>
    <span *ngIf="group.car.number">{{group.car.number}}</span>
    <span *ngIf="group.car.make">,&nbsp;{{group.car.make.name}}</span>
    <span *ngIf="group.car.model">,&nbsp;{{group.car.model.name}}</span>
    <span *ngIf="group.car.year">,&nbsp;{{group.car.year}}</span>
</h6>

<div class="table-responsive">
    <table class="table">
        <thead>
        <tr>
            <th>Услуга</th>
            <th class="text-center">Заказывал</th>
        </tr>
        </thead>

        <tbody>
        <tr *ngFor="let service of group.services">
            <td class="td--service-name">{{service.service_name}}</td>
            <td class="text-center">{{service.current_count}}</td>
        </tr>
        <tr>
            <td><strong>Итого заказов по акциям</strong></td>
            <td class="text-center"><strong>{{group.result.total_count}}</strong></td>
        </tr>
        </tbody>

        <tfoot *ngIf="group.result.can_use">
        <tr>
            <td>
                Доступна скидка по услуге - <strong>{{group.result.service_name}}</strong>
            </td>
            <td class="text-right">
                <button mat-button
                        type="button"
                        [disabled]="disableButton"
                        *ngIf="!orderHasSaleService && group.result.grade_id"
                        (click)="onAddServiceSale()">
                    Добавить услугу к заказу
                </button>

                <button mat-button
                        type="button"
                        [disabled]="disableButton"
                        *ngIf="orderHasSaleService"
                        (click)="onAddSale()">
                    Применить скидку
                </button>
            </td>
        </tr>
        </tfoot>
    </table>
</div>
<br>
