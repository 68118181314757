<div class="card">
    <div class="card-content">
        <h4 class="card-title">Грейды автомобиля</h4>

        <ul class="grades">
            <li class="grades__group-item" *ngFor="let group of gradesGroupsList">
                <div class="grades__group-name">{{group.category.name}}</div>

                <ul class="row grades__list">
                    <li *ngFor="let grade of group.grades" class="col-xs-6 col-sm-4 grades__list-item">
                        <div class="grades__item">
                            <mat-checkbox class="example-margin" [checked]="grade.activeInUI" (change)="updateGrade(grade)">
                                {{grade.name}}
                            </mat-checkbox>
                        </div>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</div>

