import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {API_URL} from '@app/core/constants';

@Component({
    selector: 'app-session-position-employees',
    templateUrl: './session-position-employees.component.html',
    styleUrls: ['./session-position-employees.component.scss']
})
export class SessionPositionEmployeesComponent implements OnInit, OnDestroy {
    session: any;
    position: any;
    photo: any;
    employees: any[];
    private subscriptions: Subscription;

    constructor(
        private http: HttpClient,
        private route: ActivatedRoute,
    ) {
        this.session = null;
        this.position = null;
        this.photo = null;
        this.employees = [];
        this.subscriptions = new Subscription();
    }

    ngOnInit(): void {
        const response$ = this.getEmployees().subscribe((response: any) => {
            this.session = response.session;
            this.position = response.position;
            this.photo = response.photo;
            this.employees = response.employees;

            //this.breadcrumb.updateBreadcrumbLabels({positionName: this.position.name});
        });
        this.subscriptions.add(response$);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private getEmployees(): Observable<any> {
        const {sessionId, positionId} = this.route.snapshot.params;
        return this.http.get(`${API_URL}/salaries/sessions/${sessionId}/positions/${positionId}`);
    }
}
