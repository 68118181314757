<div class="d-flex justify-content-end">
    <button mat-raised-button color="primary"
            [routerLink]="['/clients/profile', clientId, 'car-add']">
        Добавить машину
    </button>
</div>

<div class="table-responsive">
    <mat-table #table [dataSource]="dataSource" class="table">

        <!-- number Column -->
        <ng-container matColumnDef="number">
            <mat-header-cell *matHeaderCellDef>Номер машины</mat-header-cell>
            <mat-cell *matCellDef="let element">
                {{element.number}}
            </mat-cell>
        </ng-container>

        <!-- make Column -->
        <ng-container matColumnDef="make">
            <mat-header-cell *matHeaderCellDef>Марка</mat-header-cell>
            <mat-cell *matCellDef="let element">
                {{element.make?.name || '-'}}
            </mat-cell>
        </ng-container>

        <!-- model Column -->
        <ng-container matColumnDef="model">
            <mat-header-cell *matHeaderCellDef>Модель</mat-header-cell>
            <mat-cell *matCellDef="let element">
                {{element.model?.name || '-'}}
            </mat-cell>
        </ng-container>

        <!-- year Column -->
        <ng-container matColumnDef="year">
            <mat-header-cell *matHeaderCellDef>Год выпуска</mat-header-cell>
            <mat-cell *matCellDef="let element">
                {{element.year || '-'}}
            </mat-cell>
        </ng-container>

        <!-- bonus Column -->
        <ng-container matColumnDef="bonus">
            <mat-header-cell *matHeaderCellDef>Бонус</mat-header-cell>
            <mat-cell *matCellDef="let element">
                {{element.bonus || 0}}
            </mat-cell>
        </ng-container>

        <!-- actions Column -->
        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef>Действия</mat-header-cell>
            <mat-cell *matCellDef="let element" class="nowrap td-actions text-right">
                <button type="button" class="btn btn-success"
                        [routerLink]="['/clients/profile/'+ clientId +'/car/'+ element.id +'/update']">
                    <i class="material-icons">edit</i>
                </button>

                <button type="button" class="btn btn-danger" (click)="removeCar(element)">
                    <i class="material-icons">close</i>
                </button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
</div>
