import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {BoxSimple} from '@app/states/boxes/box-interfaces';
import {UIMangerService} from '@services/ui-manger.service';
import Order from '@app/entities/Order';
import {ConfirmComponent, ConfirmData} from '@shared/components/confirm/confirm.component';

export interface BoxChangeEmit {
    order: any;
    newBox: BoxSimple;
}

@Component({
    selector: '[current-order-row]',
    templateUrl: './current-order-row.component.html',
    styleUrls: ['./current-order-row.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
})
export class CurrentOrderRowComponent implements OnInit {
    @Input() order: any;
    @Input() boxList: BoxSimple[] = [];
    @Output() boxChange: EventEmitter<BoxChangeEmit> = new EventEmitter();
    @Output() completeOrder: EventEmitter<any> = new EventEmitter();
    @Output() pay: EventEmitter<any> = new EventEmitter();
    @Output() cancelOrder: EventEmitter<any> = new EventEmitter();

    ORDER_STATUS_IN_PROGRESS: number = Order.STATUS_IN_PROGRESS;
    startSelectedBoxId: number;
    selectedBoxId: number;

    constructor(private ui: UIMangerService) {
    }

    ngOnInit(): void {
        this.setActiveBox();
    }

    /**
     * Изменяет бокс для заказа
     */
    async onBoxChange() {
        const box = this.boxList.find(boxItem => boxItem.id === +this.selectedBoxId);

        if (box && box.order_id) {
            const result = await this.ui.open<ConfirmComponent, ConfirmData, boolean>(ConfirmComponent, {
                name: 'Бокс занят',
                text: `Вы точно хотите заменить заказ №${box.order_id} в "${box.name}"`,
                successButtonText: 'Заменить',
            }).toPromise();

            if (result === false) {
                this.selectedBoxId = this.startSelectedBoxId;
                return;
            }
        }

        const emptyBox: BoxSimple = {id: 0, name: 'Без бокса / Вывод из бокса', order_id: null};
        this.boxChange.emit({
            order: this.order,
            newBox: box || emptyBox,
        });
    }

    /**
     * Проставляет активный бокс
     */
    private setActiveBox() {
        const box = this.boxList.find(box => {
            return box.id === this.order.box_id && box.order_id === this.order.id;
        });

        if (box !== undefined) {
            this.selectedBoxId = box.id;
        } else {
            this.selectedBoxId = 0;
        }

        this.startSelectedBoxId = this.selectedBoxId;
    }
}
