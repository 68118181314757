import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CashCollectionInputData} from '@app/states/cashbox/components/cash-collection/CashCollectionInputData';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {UIMangerService} from '@services/ui-manger.service';
import {API_URL} from '@app/core/constants';

@Component({
    selector: 'app-cash-collection',
    templateUrl: './cash-collection.component.html',
    styleUrls: ['./cash-collection.component.scss']
})
export class CashCollectionComponent implements OnInit {
    cash: any;
    showLoader: boolean = false;
    form: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<CashCollectionComponent>,
        @Inject(MAT_DIALOG_DATA) public data: CashCollectionInputData,
        private fb: FormBuilder,
        private http: HttpClient,
        private ui: UIMangerService,
    ) {
        this.cash = this.data.cash;
        this.form = this.fb.group({
            sum: [null, [Validators.required, Validators.max(this.cash.balance)]]
        });
    }

    ngOnInit() {
    }

    close(): void {
        this.dialogRef.close({success: false});
    }

    /**
     * Инкасация касы
     */
    success(): void {
        if (this.form.valid) {
            this.showLoader = true;
            this.http.post(`${API_URL}/cashes/collection`, {
                cash_id: this.cash.id,
                sum: this.form.controls.sum.value
            }).subscribe(
                (response: any) => {
                    this.showLoader = false;

                    if (response.success) {
                        this.dialogRef.close({
                            success: true,
                        });
                    }
                },
                (error: HttpErrorResponse) => {
                    this.ui.showSnackBar(error.error.message);
                    this.showLoader = false;
                }
            );
        }
    }
}
