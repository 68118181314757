<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xs-12">
                <div class="card">
                    <div class="card-header card-header-icon" data-background-color="rose">
                        <i class="material-icons">contacts</i>
                    </div>

                    <div class="card-content">
                        <h4 class="card-title">Новый заказ в магазине</h4>

                        <app-added-services
                            [showError]="form.controls['services'].invalid && form.controls['services'].touched"
                            [services]="selectedServices"
                            (onUpdate)="updateItemFromSelectedServices($event)"
                            (onRemove)="removeFromSelected($event)"></app-added-services>

                        <div class="clearfix" *ngIf="services.length">
                            <app-service-list
                                [items]="services"
                                (onSelect)="selectHandler($event)"></app-service-list>
                        </div>

                        <hr>
                        <app-added-services
                            [showError]="form.controls['services'].invalid && form.controls['services'].touched"
                            [services]="selectedServices"
                            (onUpdate)="updateItemFromSelectedServices($event)"
                            (onRemove)="removeFromSelected($event)"></app-added-services>

                        <form novalidate [formGroup]="form" (submit)="submit()">
                            <div class="d-flex justify-content-between">
                                <button type="button" class="btn btn-fill btn-default" (click)="resetForm()">
                                    Сбросить
                                </button>

                                <button type="submit" class="btn btn-fill btn-rose" [disabled]="form.invalid">
                                    Оплатить
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
