<div class="table-responsive">
    <mat-table #table [dataSource]="dataSource">
        <!-- Date Column -->
        <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef>Дата</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.date}} </mat-cell>
        </ng-container>

        <!-- whoCalled Column -->
        <ng-container matColumnDef="whoCalled">
            <mat-header-cell *matHeaderCellDef>Кто звонил</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.whoCalled}} </mat-cell>
        </ng-container>

        <!-- talkTime Column -->
        <ng-container matColumnDef="talkTime">
            <mat-header-cell *matHeaderCellDef>Продолжительность</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.talkTime}} </mat-cell>
        </ng-container>

        <!-- commentOnTheConversation Column -->
        <ng-container matColumnDef="commentOnTheConversation">
            <mat-header-cell *matHeaderCellDef>Комментарий</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.commentOnTheConversation}} </mat-cell>
        </ng-container>

        <!-- resultOfConversation Column -->
        <ng-container matColumnDef="resultOfConversation">
            <mat-header-cell *matHeaderCellDef>Итог</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.resultOfConversation}} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
</div>
