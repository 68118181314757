import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ServicesModule} from '@services/services.module';
import {SharedComponentsModule} from './components/shared.components.module';
import {DirectivesModule} from './directives/directives.module';

@NgModule({
    imports: [
        CommonModule,
        ServicesModule,
        SharedComponentsModule,
        DirectivesModule
    ],
    declarations: [],
    exports: [
        ServicesModule,
        SharedComponentsModule,
        DirectivesModule
    ],
    providers: []
})
export class SharedModule {
}
