<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xs-12">
        <div class="card">
          <div class="card-content">
            <p>
              control works!
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>