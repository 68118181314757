import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {API_URL} from '../constants';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SessionShiftService {
    private currentState: CurrentState;
    readonly currentState$: BehaviorSubject<CurrentState>;

    constructor(
        private http: HttpClient
    ) {
        this.currentState$ = new BehaviorSubject<CurrentState>(null);
    }

    public startSession(data: any): Observable<any> {
        return this.http.post(`${API_URL}/sessions`, data).pipe(
            tap((response: any) => {
                this.currentState = response;
                this.currentState$.next(response);
            }),
        );
    }

    public closeSession(comment: string = ''): Observable<any> {
        return this.http.put(`${API_URL}/sessions/${this.currentState.session.id}`, {comment}).pipe(
            tap((response: any) => {
                this.currentState = response;
                this.currentState$.next(response);
            }),
        );
    }

    public checkSession(): Observable<any> {
        return this.http.get(`${API_URL}/sessions/check`).pipe(
            tap((response: any) => {
                this.currentState = response;
                this.currentState$.next(response);
                // console.log('checkSession', this.currentState);
            }),
        );
    }

    public isEmptyCurrentSession(): boolean {
        if (this.currentState === undefined) {
            return false;
        }

        return this.currentState.shiftIsOpen;
    }

    public isNotEmptyCurrentSession(): boolean {
        return !!this.currentState;
    }

    public getCurrentState(): CurrentState {
        return this.currentState;
    }

    public getCurrentStateSubject(): BehaviorSubject<CurrentState> {
        return this.currentState$;
    }

    public getListOfCashiers(): Observable<any[]> {
        return this.http.get(`${API_URL}/employees/getCashiers`).pipe(
            map((response: any) => response.data)
        );
    }

    public getListOfAdministrators(): Observable<any[]> {
        return this.http.get(`${API_URL}/employees/getAdministrators`).pipe(
            map((response: any) => response.data)
        );
    }
}

export interface CurrentState {
    success: boolean;
    isDayNow: boolean;
    isNightNow: boolean;
    needClosePreviousShift: boolean;
    shiftIsOpen: boolean;
    session: SessionShift;
}

export interface SessionShift {
    id: number;
    user_open_id: number;
    user_closed_id: number | null;
    type: string;
    sum_real: number;
    sum_cash: number;
    sum_card: number;
    is_employee_installed: string;
    created_at: string;
    updated_at: string;
    closed_at: string | null;
    user_open: ShiftUser | null;
    user_close: ShiftUser | null;
}

interface ShiftUser {
    id: number;
    employee_id: number;
    email: string;
    name: string;
}
