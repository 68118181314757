import {ApplicationRef, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {BoxInfoService} from '@services/box-info.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ModalEmployeesListComponent} from '@shared/components/modal-employees-list/modal-employees-list.component';
import {Observable, Subscription} from 'rxjs';
import {Location} from '@angular/common';
import {pluck} from '@app/Helpers/Helper';
import {ServiceSaleComponent} from './components/service-sale/service-sale.component';
import {LoaderService} from '@shared/components/loader/loader.service';
import {OrderPaymentModalComponent} from '@shared/components/order-payment-modal/order-payment-modal.component';
import {IAddServiceSale, ICustomerSharesChange} from './components/customer-shares/customer-shares.component';
import {UIMangerService} from '@services/ui-manger.service';
import {OrderSaleComponent} from '@app/states/boxes/components/box-view/components/order-sale/order-sale.component';
import {OrderSaleResult} from '@app/states/boxes/components/box-view/components/order-sale/order-sale-config';
import {ModalListOfServiceForSelectionComponent} from '@shared/components/order/modal-list-of-service-for-selection/modal-list-of-service-for-selection.component';
import {ModalListInputData} from '@shared/components/order/modal-list-of-service-for-selection/interfases';
import {ChangePriceModalComponent} from '@app/states/boxes/components/box-view/components/change-price-modal/change-price-modal.component';
import {
    ChangePriceModalData,
    ChangePriceModalResult
} from '@app/states/boxes/components/box-view/components/change-price-modal/change-price-modal';
import {PHOTO_URL} from '@app/core/constants';
import {OrderCompleteComponent} from '@shared/components/order/order-complete/order-complete.component';
import {OrderService} from '@app/states/orders/services/order.service';
import {BoxSimple} from '@app/states/boxes/box-interfaces';
import {ConfirmComponent, ConfirmData} from '@shared/components/confirm/confirm.component';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatTooltip} from '@angular/material/tooltip';

@Component({
    selector: 'app-box-view',
    templateUrl: './box-view.component.html',
    styleUrls: ['./box-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    // encapsulation: ViewEncapsulation.None
})
export class BoxViewComponent implements OnInit, OnDestroy {
    box: any;
    order: any = false;
    serviceTotalCost = 0;
    serviceSaleTotalCost = 0;
    averageServiceExecutionTime = 0;
    employeeOfThisOrder: Observable<any[]>;
    photoUrl: string = PHOTO_URL;
    boxList: BoxSimple[] = [];
    selectedBoxId: number | string;
    startSelectedBoxId: number | string;
    private subscription: Subscription;

    constructor(
        private app: ApplicationRef,
        private cd: ChangeDetectorRef,
        private activeRouter: ActivatedRoute,
        private boxService: BoxInfoService,
        private orderService: OrderService,
        private dialog: MatDialog,
        private location: Location,
        private router: Router,
        private loader: LoaderService,
        private snackBar: MatSnackBar,
        private ui: UIMangerService,
    ) {
        this.subscription = new Subscription();
    }

    get responsibles(): object[] {
        return this.box.employeeList;
    }

    ngOnInit() {
        this.activeRouter.params.subscribe((res: any) => {
            this.boxService.getBox(res.id).subscribe((result) => {
                this.box = result.data;
                this.recalculating();
                this.updateEmployeeOfThisOrder();

                // Получаем список боксов. TODO: нужно сделать паралельное выполенение
                this.orderService.getBoxList().subscribe(response => {
                    this.boxList = response;
                    this.setActiveBox();
                });
            });
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    /**
     * Проставляет активный бокс
     */
    private setActiveBox() {
        const box = this.boxList.find(boxItem => {
            return boxItem.id === this.box.order.box_id && boxItem.order_id === this.box.order.id;
        });

        if (box !== undefined) {
            this.selectedBoxId = box.id;
        } else {
            this.selectedBoxId = 0;
        }

        this.startSelectedBoxId = this.selectedBoxId;
    }

    toggleServiceItemStatus(item: any) {
        if (item.active) {
            const service$ = this.boxService.disableServiceItem(this.box.order.id, item.id).subscribe(({success}) => {
                this.toggleServiceItemStatusHandler(item, success);
            });
            this.subscription.add(service$);
        } else {
            const service$ = this.boxService.enableServiceItem(this.box.order.id, item.id).subscribe(({success}) => {
                this.toggleServiceItemStatusHandler(item, success);
            });
            this.subscription.add(service$);
        }
    }

    openAddServiceModal(): void {
        const dialog$ = this.dialog
            .open<ModalListOfServiceForSelectionComponent, ModalListInputData>(ModalListOfServiceForSelectionComponent, {
                width: '95%',
                minHeight: '80%',
                data: {
                    currentServiceList: this.box.order.services.filter((service: any) => service.active),
                    customer: this.box.order.customer,
                    car: this.box.order.car,
                }
            })
            .afterClosed()
            .subscribe(result => {
                if (result !== undefined && result !== false) {
                    this.addServices(result);
                }
            });
        this.subscription.add(dialog$);
    }

    setSaleToOrder(): void {
        const dialog$ = this.ui.open(OrderSaleComponent).subscribe((result: OrderSaleResult) => {
            if (result) {
                this.ui.getLoader().show();
                this.boxService.setOrderDiscount(this.box.order.id, result).subscribe((response: any) => {
                    if (response.success) {
                        this.box.order.services = response.data.services;
                        this.recalculating();
                    } else {
                        this.ui.showSnackBar('Ошибка установки скидки, попробуйте еще раз!');
                    }

                    this.ui.getLoader().hide();
                });
            }
        });
        this.subscription.add(dialog$);
    }

    setSaleForServiceItem(service: any) {
        const dialog$ = this.ui.open(ServiceSaleComponent, {
            serviceName: service.name,
            servicePrice: service.price,
            serviceDiscountPercent: service.discountPercent
        })
            .subscribe(result => {
                if (result) {
                    this.boxService.setSaleForService(this.box.order.id, service.pivot.id, result).subscribe((response: any) => {
                        if (response.success) {
                            service.salePrice = response.data.sale_price;
                            service.discountFixed = response.data.discount_fixed;
                            service.discountPercent = response.data.discount_percent;

                            this.recalculateServiceSaleTotalCost();
                        }
                    });
                }
            });

        this.subscription.add(dialog$);
    }

    /**
     * Переносит заказ в другой бокс или освабождает бокс от заказа
     */
    async openChangeBoxModal() {
        const box = this.boxList.find(boxItem => boxItem.id === +this.selectedBoxId);
        const newBoxId = box ? box.id : 0;

        if (box && box.order_id) {
            const result = await this.ui.open<ConfirmComponent, ConfirmData, boolean>(ConfirmComponent, {
                name: 'Бокс занят',
                text: `Вы точно хотите заменить заказ №${box.order_id} в "${box.name}"`,
                successButtonText: 'Заменить',
            }).toPromise();

            if (result === false) {
                this.selectedBoxId = this.startSelectedBoxId;
                return;
            }
        }

        this.loader.show();
        this.orderService.changeBox(this.box.id, newBoxId, this.box.order.id).subscribe(response => {
            if (response.success) {
                this.loader.hide();

                if (newBoxId > 0) {
                    this.router.navigate(['/boxes/', newBoxId]);
                } else {
                    this.router.navigate(['/orders/list']);
                }
            }
        });
    }

    changeEmployeesInBox(): void {
        const modal$ = this.openChangeEmployeesModal({employees: this.box.employeeList}).subscribe(result => {
            if (result !== undefined) {
                const changeEmployees$ = this.boxService.changeEmployees(this.box.id, pluck('id', result)).subscribe((res: any) => {
                    if (res.success) {
                        this.box.employeeList = result;
                        this.updateEmployeeOfThisOrder();
                    }
                });
                this.subscription.add(changeEmployees$);
            }
        });
        this.subscription.add(modal$);
    }

    async changeBasePrice(service: any) {
        const result = await this.ui.openAsPromise<ChangePriceModalData, ChangePriceModalResult>(ChangePriceModalComponent, {
            serviceName: service.name,
            serviceBasePrice: service.pivot.base_price,
            servicePrice: service.price,
        });

        if (result.success) {
            const response = await this.boxService.changePrice(this.box.order.id, service.pivot.id, {price: result.price}).toPromise();

            if (response.success) {
                service.price = response.data.price;
                service.pivot.price = response.data.price;
                service.salePrice = response.data.sale_price;
                service.discountFixed = response.data.discount_fixed;
                service.discountPercent = response.data.discount_percent;

                this.recalculateServiceSaleTotalCost();
                this.ui.showSnackBar(`Базовая цена услуги "${service.name}" изменена`);
            }
        }

    }

    changeEmployeeForServiceRow(service: any): void {
        const modal$ = this.openChangeEmployeesModal({employees: service.responsibles}).subscribe((result: any) => {
            if (result !== undefined) {
                const response$ = this.boxService
                    .updateServiceResponsibilities(service.pivot.id, this.box.id, pluck('id', result))
                    .subscribe((response: any) => {
                        if (response.success) {
                            service.responsibles = result;
                            this.updateEmployeeOfThisOrder();
                        }
                    });
                this.subscription.add(response$);
            }
        });
        this.subscription.add(modal$);
    }

    /**
     * Завершение заказ
     * @param {MatTooltip | null} tooltip
     */
    confirmOfCompleteOrder(tooltip: MatTooltip | null): void {
        if (!this.box.employeeList.length && tooltip !== null) {
            tooltip.show();
            return;
        }

        const modal$ = this.dialog
            .open(OrderCompleteComponent, {
                width: '60%',
                minHeight: '80%',
                disableClose: true,
            })
            .afterClosed()
            .subscribe(result => {
                if (result && result.success) {
                    this.loader.show();
                    this.boxService.completeOrder(this.box.id, this.box.order.id).subscribe(
                        (response: any) => {
                            this.loader.hide();

                            if (response.success) {
                                if (result.n6carWash) {
                                    this.boxService.resetN6carWash(this.box.order.customer_id)
                                        .subscribe(response => console.info('resetN6carWash', response));
                                }

                                this.router.navigate(['/']);
                            }
                        },
                        (error) => {
                            this.loader.hide();
                        }
                    );
                }
            });
        this.subscription.add(modal$);
    }

    payOrder(): void {
        const modal$ = this.dialog
            .open(OrderPaymentModalComponent, {
                width: '60%',
                minHeight: '80%',
                disableClose: true,
                data: {
                    orderId: this.box.order.id,
                    customerType: this.box.order.customer.type_id,
                    sum: this.serviceSaleTotalCost,
                    cashOperationType: 1 // Нужно сделать константу
                }
            })
            .afterClosed()
            .subscribe(result => {
                if (result && result.success && result.order !== null) {
                    this.box.order = result.order;

                    if (result.order.status === 1 || result.order.status === 2) {
                        this.confirmOfCompleteOrder(null);
                    }
                }
            });
        this.subscription.add(modal$);
    }

    setSaleByShares(share: ICustomerSharesChange): void {
        const service = this.box.order.services.find((service: any) => service.id === share.serviceId);
        const option = {
            fixed: service.pivot.price,
            percent: 100
        };

        this.loader.show();
        const deductResponse$ = this.boxService.deductPointsByShares(
            this.box.order.id,
            this.box.order.customer_id,
            share.nFreeId,
            share.serviceId
        )
            .subscribe(
                (response: any) => {
                    if (response.success) {
                        const saleService$ = this.boxService.setSaleForService(this.box.order.id, service.pivot.id, option).subscribe(
                            (response: any) => {
                                if (response.success) {
                                    this.box.order.services = this.box.order.services.map((item: any) => {
                                        if (item.id === service.id) {
                                            item.salePrice = response.data.sale_price;
                                            item.discountFixed = response.data.discount_fixed;
                                            item.discountPercent = response.data.discount_percent;
                                        }

                                        return {...item};
                                    });

                                    this.recalculateServiceSaleTotalCost();
                                    this.loader.hide();
                                    this.snackBar.open('Скидка установлена', 'Закрыть', {
                                        duration: 2000,
                                        verticalPosition: 'top'
                                    });
                                }
                            },
                            (error) => {
                                this.loader.hide();
                            }
                        );
                        this.subscription.add(saleService$);
                    }
                },
                (error) => {
                    this.loader.hide();
                }
            );
        this.subscription.add(deductResponse$);
    }

    onAddServiceSale(event: IAddServiceSale): void {
        const service = {
            id: event.serviceId,
            active: true,
            price: event.price,
            gradeId: event.gradeId,
        };

        this.addServices([service], () => {
            this.setSaleByShares({nFreeId: event.nFreeId, serviceId: event.serviceId});
        });
    }

    trackByServices(index, item) {
        return item.id;
    }

    private toggleServiceItemStatusHandler(item, success) {
        if (success) {
            this.box.order.services = this.box.order.services.map((service: any) => {
                if (service.pivot.id === item.pivot.id) {
                    service.active = !item.active;
                }

                return {...service};
            });
            this.recalculating();
        }
    }

    private addServices(data: any[], cb: () => void = null): void {
        const prepareResult = data.map(service => {
            service.responsibles = this.responsibles;
            return service;
        });

        const source$ = this.boxService.addNewServiceToOrder(this.box.id, this.box.order.id, prepareResult).subscribe((response) => {
            if (response.success) {
                this.box.order.services = [...this.box.order.services, ...response.data];
                this.recalculating();
                this.updateEmployeeOfThisOrder();

                if (typeof cb === 'function') {
                    cb();
                }
            }
        });

        this.subscription.add(source$);
    }

    private openChangeEmployeesModal(data: any = {}): Observable<any> {
        return this.dialog
            .open(ModalEmployeesListComponent, {
                width: '90%',
                minHeight: '80%',
                data: data
            })
            .afterClosed();
    }

    private recalculating(): void {
        this.recalculateServiceTotalCost();
        this.recalculateServiceSaleTotalCost();
        this.calculateAvgTimeForService();
    }

    private recalculateServiceTotalCost(): void {
        let cost = 0;

        if (this.box.order !== null) {
            this.box.order.services.forEach((item: any) => {
                if (item.price !== undefined && item.price !== null && item.active) {
                    cost += item.price;
                }
            });
        }

        this.serviceTotalCost = cost;
    }

    private recalculateServiceSaleTotalCost(): void {
        let cost = 0;

        if (this.box.order !== null) {
            this.box.order.services.forEach((item: any) => {
                if (item.price !== undefined && item.price !== null && item.active) {
                    if (item.salePrice !== undefined && item.salePrice !== null && item.active) {
                        cost += item.salePrice;
                    } else {
                        cost += item.price;
                    }
                }
            });
        }

        this.serviceSaleTotalCost = cost !== this.serviceTotalCost ? cost : this.serviceTotalCost;
    }

    private calculateAvgTimeForService() {
        if (this.box.order != null && this.box.order.services.length) {
            this.averageServiceExecutionTime = 0;
            this.box.order.services.forEach((item: any) => {
                if (item.active === true) {
                    this.averageServiceExecutionTime += item.agv_time;
                }
            });
        }
    }

    private updateEmployeeOfThisOrder(): void {
        if (this.box !== null && this.box !== undefined) {
            const servicesEmployeesIds = [];
            let set = new Set<any>();

            if ((this.box.order && this.box.order.services && Array.isArray(this.box.order.services))) {
                this.box.order.services.forEach((service: any) => {
                    if (service.responsibles && Array.isArray(service.responsibles)) {
                        service.responsibles.forEach((employee: any) => {
                            set.add({name: 'alex'});
                        });
                    }
                });
            }

            console.log('servicesEmployees', set);
            // this.employeeOfThisOrder = this.boxService.getEmployeeListById(servicesEmployees);
        }
    }
}
