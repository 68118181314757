import {AfterViewInit, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ModalListInputData} from '@shared/components/order/modal-list-of-service-for-selection/interfases';
import {FormControl} from '@angular/forms';
import {Subscription} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {UIMangerService} from '@services/ui-manger.service';
import {API_URL} from '@app/core/constants';
import {ConfirmComponent} from '@shared/components/confirm/confirm.component';
import {SelectGradeComponent} from '@shared/components/order/list-of-service-for-selection/select-grade/select-grade.component';
import {calculateFixedPrice} from '@app/Helpers/service-helper';
import {uuid} from '@app/Helpers/Helper';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-modal-list-of-service-for-selection',
    templateUrl: './modal-list-of-service-for-selection.component.html',
    styleUrls: ['./modal-list-of-service-for-selection.component.scss']
})
export class ModalListOfServiceForSelectionComponent implements OnInit, AfterViewInit, OnDestroy {
    alreadyAddedServices: any[];
    customer: any;
    car: any;
    selectedServices: any[] = [];
    servicesForSearch: any[] = [];
    servicesPopular: any[] = [];
    servicesTabs: any[] = [];
    servicesOther: any[] = [];
    isLoading = true;
    searchTerm = new FormControl();
    private subscriptions: Subscription;

    constructor(
        public dialogRef: MatDialogRef<ModalListOfServiceForSelectionComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ModalListInputData,
        private ui: UIMangerService,
        private http: HttpClient,
    ) {
        this.alreadyAddedServices = data.currentServiceList;
        this.customer = data.customer;
        this.car = data.car;
        this.subscriptions = new Subscription();
    }

    ngOnInit(): void {
        this.http.get(`${API_URL}/serviceList`).subscribe((response: any) => {
            this.isLoading = false;

            this.servicesPopular = response.data.popular;
            this.servicesOther = response.data.all;
            this.servicesTabs = response.data.tabs;
        });

        this.search();
    }

    ngAfterViewInit(): void {
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    cancel(): void {
        this.dialogRef.close(false);
    }

    successClose(): void {
        this.dialogRef.close(this.selectedServices);
    }

    selectServiceHandler(service: any) {
        const exist = this.selectedServices.find((item: any) => item.id === service.id);

        if (!!exist) {
            this.ui.open(ConfirmComponent, {name: 'Добавить услугу еще 1 раз?'}).subscribe(result => {
                if (result) {
                    this.setGradeToTheService({...service});
                }
            });

            return;
        }

        this.setGradeToTheService({...service});
    }

    updateItemFromSelectedServices(service: any): void {
        this.selectedServices = this.selectedServices.map((item: any) => {
            if (service._id === item._id) {
                return service;
            }

            return item;
        });
    }

    removeItemFromSelectedServices(service: any): void {
        this.selectedServices = this.selectedServices.filter((item: any) => {
            return item._id !== service._id;
        });
        this.updateAllUIServicesLists();
    }

    private setGradeToTheService(service: any) {
        const clientGrade = this._getGradeByCategoryId(service.master_service_category_id);

        if (clientGrade !== undefined) {
            this.http.get(`${API_URL}/serviceList/${service.id}/grades/${clientGrade.gradeId}`)
                .subscribe((response: any) => {
                    if (response.success) {
                        this._setGrade(service, {
                            id: response.data.grade.id,
                            name: response.data.grade.name,
                            price: response.data.price,
                        });
                    } else {
                        this.selectedGrade(service);
                    }
                });
        } else {
            this.selectedGrade(service);
        }
    }

    private _getGradeByCategoryId($masterCategoryId: number): any | undefined {
        if (this.car && this.car.model && this.car.model.grades) {
            return this.car.model.grades.find((grade: any) => grade.categoryId === $masterCategoryId);
        }

        return undefined;
    }

    private selectedGrade(service: any): void {
        const dialog$ = this.ui.open(SelectGradeComponent, {serviceId: service.id})
            .subscribe((grade) => {
                if (grade) {
                    this._setGrade(service, grade);

                    if (this.car && this.car.model && this.car.model.id) {
                        this._setGradeToCarModel(this.car.model.id, service.master_service_category_id, grade.id);
                    }
                }
            });
        this.subscriptions.add(dialog$);
    }


    private _setGradeToCarModel(modelId: number, gradeCategoryId: number, gradeId: number) {
        this.http.put(`${API_URL}/cars/model-list/${modelId}`, {gradeCategoryId, gradeId}).subscribe((response: any) => {
            // TODO: так делать нельзя это нужно переделить, нужно пушить
            this.car.model.grades.push({
                categoryId: gradeCategoryId,
                gradeId: gradeId,
            });
        });
    }

    private _setGrade(service: any, grade: any) {
        if (grade) {
            service._id = uuid();
            service.price = grade.price;
            service.gradeName = grade.name;
            service.gradeId = grade.id;

            if (
                this.customer &&
                this.customer.love_service_id !== null &&
                service.can_be_loved &&
                service.id === this.customer.love_service_id
            ) {
                const fixed = calculateFixedPrice(service.price, service.love_percent);
                service.salePrice = service.price - fixed;
                service.discountFixed = fixed;
                service.discountPercent = service.love_percent;
            }

            this.selectedServices = [...this.selectedServices, service];
            this.updateAllUIServicesLists();
        }
    }

    private updateAllUIServicesLists() {
        const selectedIds = this.selectedServices.map((service: any) => service.id);

        this.updateUIServicesLists(this.servicesPopular, selectedIds);
        this.updateUIServicesLists(this.servicesOther, selectedIds);

        this.servicesTabs.forEach((parent: any) => {
            parent.children.forEach((child: any) => {
                this.updateUIServicesLists(child.services, selectedIds);
            });
        });
    }

    /**
     * Проставляет услугам в списке флаг, выбрана или удалена услуга
     * @param {object[]} list
     * @param {number[]} selectedIds
     */
    private updateUIServicesLists(list: any[], selectedIds: number[]) {
        list.forEach((service: any) => {
            if (selectedIds.includes(service.id)) {
                service.isAdded = true;
                return;
            }

            service.isAdded = false;
        });
    }

    /**
     * Поиск по всем услугах
     */
    private search(): void {
        this.searchTerm.valueChanges.subscribe((term: string) => {
            if (!term.length) {
                this.servicesForSearch = [];
                return;
            }

            this.servicesForSearch = this.servicesOther
                .filter((item: any) => {
                    return item.name.toLowerCase().includes(term.toLowerCase());
                })
                .sort((a, b) => {
                    const nameA = a.category.name.toLowerCase();
                    const nameB = b.category.name.toLowerCase();

                    if (nameA < nameB) {
                        return -1;
                    }

                    if (nameA > nameB) {
                        return 1;
                    }

                    return 0;
                });
        });
    }
}
