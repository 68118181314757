import {Directive, ElementRef, HostListener, Renderer2} from '@angular/core';

@Directive({
    selector: '[phonePrefix]'
})
export class PhonePrefixDirective {

    constructor(private el: ElementRef,
                private renderer: Renderer2) {
    }

    @HostListener('focus', ['$event']) onFocus($event) {
        if (!this.el.nativeElement.value.length) {
            this.el.nativeElement.value = '+7';
        }
    }

    @HostListener('blur', ['$event']) onBlur($event) {
        if (this.el.nativeElement.value === '+7') {
            this.el.nativeElement.value = '';
        }
    }
}
