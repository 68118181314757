<form (submit)="submit(fInput.value); $event.preventDefault(); fInput.value = ''">
    <mat-form-field class="full-width">
        <input matInput placeholder="Введите смс сообщения" #fInput
               class="form-control-lg input-lg">
    </mat-form-field>
    <div class="text-right">
        <button type="button" mat-raised-button color="accent" [disabled]="!fInput.value.length"
                (click)="submit(fInput.value); fInput.value = ''">
            Отправить смс
        </button>
    </div>
</form>
