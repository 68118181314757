<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xs-12">
                <div>
                    <a [routerLink]="['/cashbox/add-expense']" class="btn btn-block btn-lg bnt-info">
                        Добавить расход
                    </a>
                </div>
                <div>
                    <a [routerLink]="['/cashbox/list-of-expense']" class="btn btn-block btn-lg bnt-info">
                        Список расходов
                    </a>
                </div>
                <div>
                    <a [routerLink]="['/cashbox/add']" class="btn btn-block btn-lg bnt-info disabled">
                        Внесение наличных
                    </a>
                </div>
                <div>
                    <a [routerLink]="['/cashbox/x-report']" class="btn btn-block btn-lg bnt-success disabled">
                        X отчет
                    </a>
                </div>
                <div>
                    <a [routerLink]="['/cashbox/z-report']" class="btn btn-block btn-lg bnt-danger disabled">
                        Z отчет
                    </a>
                </div>
                <div>
                    <a  [routerLink]="['/cashbox/control']" class="btn btn-block btn-lg bnt-info disabled">
                        Сверка итогов (эквайринг)
                    </a>
                </div>

            </div>
        </div>
    </div>
</div>
