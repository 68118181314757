import {Component, OnDestroy, OnInit} from '@angular/core';
import {SessionShift, SessionShiftService} from '@app/core/services/session-shift.service';
import {AuthBaseService} from '@app/core/services/auth-base.service';
import {Subscription} from 'rxjs';
import {ConfirmAskReasonComponent} from '../../confirm-ask-reason/confirm-ask-reason.component';
import * as moment from 'moment';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-session-need-close',
    templateUrl: './session-need-close.component.html',
    styleUrls: ['./session-need-close.component.scss']
})
export class SessionNeedCloseComponent implements OnInit, OnDestroy {
    public session: SessionShift;
    public user: any;
    private subscriptions: Subscription;

    constructor(
        public dialogRef: MatDialogRef<SessionNeedCloseComponent>,
        private dialog: MatDialog,
        private service: SessionShiftService,
        private authBase: AuthBaseService,
    ) {
        this.subscriptions = new Subscription();
    }

    ngOnInit(): void {
        this.user = this.authBase.getUser();

        if (this.service.isNotEmptyCurrentSession()) {
            this.session = this.service.getCurrentState().session;
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public closeShift() {
        if (moment({hour: 21}).diff(moment(), 'minutes') > 0) {
            const dialog$ = this.dialog
                .open(ConfirmAskReasonComponent, {width: '60%', minHeight: '80%'})
                .afterClosed()
                .subscribe(result => {
                    (result && result.success) && this.closeShiftHandler(result.data);
                });
            this.subscriptions.add(dialog$);
        } else {
            this.closeShiftHandler();
        }
    }

    public continueCurrentSession() {
        this.dialogRef.close({needOpenSession: false});
    }

    private closeShiftHandler(comment: string = '') {
        this.service.closeSession(comment).subscribe((response: any) => {
            if (response.success) {
                this.dialogRef.close({needOpenSession: true});
                return;
            }

            console.error('Ошибка закрытия сессии', response);
        });
    }
}
