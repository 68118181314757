<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xs-12">
                <div class="row">
                    <div class="col-xs-12 col-sm-4"
                         *ngFor="let item of boxList | async; let i = index"
                         [routerLink]="['/boxes', item.id]">

                        <h3>{{ item.name }}</h3>
                        <div class="d-flex justify-content-center">
                            <video muted="muted" autoplay style="max-width: 100%;" height="200" controls>
                                <source [src]="item.video_url"/>
                                Your browser doesn't support HTML5 video tag.
                            </video>
                        </div>

                        <app-box-status [status]="item.currentStatus.status" [label]="item.currentStatus.label"></app-box-status>
                        <br>
                        <div>{{item.description}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
