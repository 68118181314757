import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {API_URL} from '../core/constants';
import {map} from 'rxjs/operators';

@Injectable()
export class ClientFormService {
    constructor(private http: HttpClient) {
    }

    public getInputData() {
        return this.http.get(`${API_URL}/customers/createData`).pipe(
            map((result: any) => {
                result.carNumberTypes = result.carNumberTypes.map(this.prepareCarTypeNumber);
                return result;
            })
        );
    }

    private prepareCarTypeNumber(item: any): any {
        const patternData = item.pattern.split('|');
        item.pattern = [];

        if (patternData[0] !== 'other') {
            item.pattern = patternData.map((s: string) => {
                if (s === 'space') {
                    return ' ';
                } else {
                    return new RegExp(s);
                }
            });
        }

        return item;
    }
}

