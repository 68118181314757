import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-selectable-customer-cars-list',
    templateUrl: './selectable-customer-cars-list.component.html',
    styleUrls: ['./selectable-customer-cars-list.component.scss']
})
export class SelectableCustomerCarsListComponent implements OnInit {
    @Input() client: any;
    @Output() change: EventEmitter<any> = new EventEmitter<any>();

    constructor() {
    }

    get cars(): any[] {
        if (this.client && this.client.hasOwnProperty('cars')) {
            return this.client.cars;
        }

        return [];
    }

    ngOnInit() {
    }

    onChange(car: any) {
        this.change.emit(car);
    }
}
