import {Component} from '@angular/core';
import {LoaderService} from '@shared/components/loader/loader.service';

@Component({
    selector: 'app-layout-manager',
    templateUrl: './manager-layout.component.html'
})
export class ManagerLayoutComponent {
    constructor(public loader: LoaderService) {
    }
}
