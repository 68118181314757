<mat-list>
    <mat-list-item class="col-xs-12 col-sm-6 col-lg-4 service-list__grid-item" *ngFor="let service of items">
        <button mat-button
                type="button"
                class="service-item"
                [title]="service.name"
                [class.is-added]="service.isAdded"
                (click)="select(service)">
            <span class="d-flex justify-content-between">
                <span>
                    <span>
                        {{service.uName}}
                        <span *ngIf="showCategory"> - {{service.category?.name}}</span>
                    </span>

                    <span>
                        <mat-icon *ngIf="client && client.love_service_id == service.id">favorite</mat-icon>
                    </span>
                </span>

                <span *ngIf="service.price">{{service.price}}₽</span>
            </span>
        </button>
    </mat-list-item>
</mat-list>
