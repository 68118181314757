import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {AuthBaseService} from '../core/services/auth-base.service';
import {BROADCAST_URL} from '../core/constants';

declare const Pusher: any;

@Injectable()
export class PusherService {
    public notification: any;
    public boxChannel: any;
    public tiresChannel: any;
    private pusher: any;

    constructor(private auth: AuthBaseService) {
        this.pusher = new Pusher(environment.pusher.key, {
            authEndpoint: BROADCAST_URL,
            cluster: environment.pusher.cluster,
            encrypted: true,
            disableStats: true,
            auth: {
                headers: {
                    'Authorization': auth.getToken()
                }
            }
        });

        this.notification = this.pusher.subscribe(`private-App.User.${this.auth.getUser().id}`);
        this.boxChannel = this.pusher.subscribe('box-events');
        this.tiresChannel = this.pusher.subscribe('tires');
    }
}
