import {Directive, ElementRef} from '@angular/core';

@Directive({
    selector: '[appAutofocus]'
})
export class AppAutofocusDirective {

    constructor(private el: ElementRef) {
    }

    ngAfterViewInit() {
        // TODO: нужно что-бы материал успел инициализироваться
        setTimeout(() => {
            this.el.nativeElement.focus();
        });
    }
}
