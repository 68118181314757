import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {API_URL} from '@app/core/constants';
import {map} from 'rxjs/operators';
import {BoxSimple, BoxSimpleResponse} from '@app/states/boxes/box-interfaces';
import {BooleanResponse} from '@app/interfaces/base-server-responses';
import {OrderCreateResponse} from '@app/states/orders/orders.interfaces';

@Injectable()
export class OrderService {

    constructor(private http: HttpClient) {
    }

    /**
     * Создает заказ.
     */
    create(data: any): Observable<OrderCreateResponse> {
        return this.http.post<OrderCreateResponse>(`${API_URL}/orders`, data);
    }

    getCustomerCars(customerId: number): Observable<any> {
        return this.http
            .get(`${API_URL}/customers/${customerId}/cars`)
            .pipe(
                map((result: any) => result.data)
            );
    }

    /**
     * Получение списка текущих заказов
     */
    getList(): Observable<any> {
        return this.http.get(`${API_URL}/orders`);
    }

    cancelOrder(orderId: number, rejectionReason: string): Observable<any> {
        return this.http.post(`${API_URL}/orders/${orderId}/cancel`, {rejectionReason});
    }

    /**
     * Переност заказа из бокса в бокс, или вывод из бокса
     */
    changeBox(oldBoxId: number, newBoxId: number, orderId: number): Observable<BooleanResponse> {
        return this.http.post<BooleanResponse>(`${API_URL}/orders/${orderId}/changeBox`, {oldBoxId, newBoxId});
    }

    getLastOrders(limit: number = 10) {
        return this.http.get(`${API_URL}/orders/lastOrders?limit=${limit}`).pipe(
            map((result: any) => result.data)
        );
    }

    deleteOrder(orderId: number): Observable<any> {
        return this.http.delete(`${API_URL}/orders/${orderId}`);
    }

    createRequestToDeleteOrder(orderId: number, cause: string): Observable<any> {
        return this.http.post(`${API_URL}/orders/${orderId}/create-request-to-delete`, {
            orderId: orderId,
            cause: cause,
        });
    }

    /**
     * Изменяет тип оплаты в заказе путем переброса цен между sum_cash и sum_card
     * @param {number} orderId
     * @param {object} paymentOptions
     */
    changePaymentType(orderId: number, paymentOptions: any): Observable<any> {
        return this.http.patch(`${API_URL}/orders/${orderId}/change-payment-type`, {...paymentOptions});
    }

    /**
     * Получение простого списка боксов
     */
    getBoxList(): Observable<BoxSimple[]> {
        return this.http.get<BoxSimpleResponse>(`${API_URL}/boxes/list`).pipe(
            map(response => response.data)
        );
    }
}
