import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-send-sms',
    templateUrl: './send-sms.component.html',
    styleUrls: ['./send-sms.component.scss']
})
export class SendSmsComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

    submit(value: any) {

    }

}
