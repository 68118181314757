import {Routes} from '@angular/router';
import {LoginComponent} from './components/login/login.component';
import {AuthLayoutComponent} from '../../layouts/auth-layout/auth-layout.component';

export const routes: Routes = [
    {
        path: 'auth',
        component: AuthLayoutComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'login'
            },
            {
                path: 'login',
                component: LoginComponent,
            }
        ]
    }
];
