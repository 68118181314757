import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {FormControl} from '@angular/forms';
import {debounceTime, map, repeat, switchMap, tap} from 'rxjs/operators';
import {merge, Observable, Subscription} from 'rxjs';
import {ClientsService} from '../../services/clients.service';
import {MIN_NUMBER_FOR_AUTOCOMPLETE, PHONE_MASK} from '@app/core/constants';
import maskInput from 'vanilla-text-mask/dist/vanillaTextMask.js';

@Component({
    selector: 'app-client-list',
    templateUrl: './client-list.component.html',
    styleUrls: ['./client-list.component.scss']
})
export class ClientListComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('inputPhone', {read: ViewContainerRef}) public inputPhone;

    phone = new FormControl('');
    carNumber = new FormControl('');
    //filteredClients$: Observable<any[]>;
    filteredClients: any[];
    isFetch: boolean = false;
    private maskedInputController;
    private subscriptions: Subscription = new Subscription();

    constructor(
        private clientsService: ClientsService,
    ) {
    }

    ngOnInit() {
        const pones$ = this.phone.valueChanges.pipe(
            debounceTime(1000),
            tap(() => {
                this.isFetch = true;
                this.carNumber.patchValue('', {emitEvent: false});
            }),
            map(phone => phone.replace(/\D+/g, '')),
            switchMap(phone => phone.length >= MIN_NUMBER_FOR_AUTOCOMPLETE ? this.clientsService.findClientsByPhone(phone) : []),
        ).subscribe((response: any[]) => {
            this.isFetch = false;
            this.filteredClients = response;
        });
        this.subscriptions.add(pones$);

        const cars$ = this.carNumber.valueChanges.pipe(
            debounceTime(1000),
            tap(() => {
                this.isFetch = true;
                this.phone.patchValue('', {emitEvent: false});
            }),
            switchMap(carNumber => this.clientsService.findClientsByCarNumberLike(carNumber)),
        ).subscribe((response: any[]) => {
            this.isFetch = false;
            this.filteredClients = response;
        });
        this.subscriptions.add(cars$);


        //this.filteredClients$ = merge(pones$, cars$);
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.maskedInputController = maskInput({
                inputElement: this.inputPhone.element.nativeElement,
                mask: PHONE_MASK
            });
        });
    }

    ngOnDestroy() {
        this.maskedInputController.destroy();
    }
}
