<div class="main-content">
    <div class="container-fluid">
        <div class="card">
            <div class="card-header card-header-icon" data-background-color="purple">
                <i class="material-icons">assignment</i>
            </div>
            <div class="card-content">
                <h4 class="card-title">
                    {{isNew ? 'Добавления новой машины' : 'Обновления машины'}}
                </h4>

                <form class="client-form form-horizontal"
                      #domForm
                      [formGroup]="form"
                      novalidate
                      (submit)="submit()">
                    <div class="row">
                        <app-input-car-number
                            [parentForm]="form"
                            [inputType]="'carNumberType'"
                            [inputNumber]="'number'"
                            [needSetDefaults]="false"
                            [typeClasses]="'col-xs-12 col-sm-4'"
                            [numberClasses]="'col-xs-12 col-sm-8'">
                        </app-input-car-number>
                    </div>

                    <div class="row">
                        <div class="col-xs-12 col-sm-4">

                            <!--<mat-form-field>-->
                            <!--<input type="text" placeholder="Марка"-->
                            <!--matInput-->
                            <!--formControlName="make"-->
                            <!--[matAutocomplete]="autoMakes"-->
                            <!--(blur)="getModelsList($event)"-->
                            <!--&gt;-->
                            <!--<mat-autocomplete #autoMakes="matAutocomplete">-->
                            <!--<mat-option *ngFor="let make of makesListOptions"-->
                            <!--[value]="make.name">-->
                            <!--{{make.name}}-->
                            <!--</mat-option>-->
                            <!--</mat-autocomplete>-->
                            <!--</mat-form-field>-->

                            <mat-form-field>
                                <mat-select formControlName="make" placeholder="Марка"
                                            (valueChange)="getModelsList($event)">
                                    <ngx-mat-select-search
                                        [formControl]="makesFilterCtrl"
                                        [placeholderLabel]="'Поиск'"
                                        [noEntriesFoundLabel]="'совпадений не найдено'"
                                    >
                                    </ngx-mat-select-search>
                                    <mat-option *ngFor="let make of makesListOptions | async"
                                                [value]="make.name">
                                        {{make.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="col-xs-12 col-sm-4">
                            <mat-form-field>
                                <mat-select formControlName="model" placeholder="Модель" #modelSelect>
                                    <ngx-mat-select-search
                                        [formControl]="modelsFilterCtrl"
                                        [placeholderLabel]="'Поиск модели'"
                                        [noEntriesFoundLabel]="'модель не найдена'"
                                    >
                                    </ngx-mat-select-search>
                                    <mat-option *ngFor="let model of modelsListOptions | async"
                                                [value]="model.name">
                                        {{model.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="col-xs-12 col-sm-4">
                            <mat-form-field class="client-form__field">
                                <input matInput placeholder="Год выпуска" formControlName="year">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-12">
                            <div class="d-flex justify-content-between">
                                <button mat-raised-button color="warn" (click)="backToProfile()">Отменить
                                </button>
                                <button type="submit" mat-raised-button color="accent">
                                    {{isNew ? 'Добавить' : 'Обновить'}}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div *ngIf="form.controls['model'].value">
            <app-grade-info [modelName]="form.controls['model'].value"></app-grade-info>
        </div>
    </div>
</div>
