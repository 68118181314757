<ng-container [formGroup]="parentForm">
    <div class="autocomplete">
        <mat-form-field class="autocomplete__input">
            <input matInput
                   [placeholder]="placeholder"
                   [formControlName]="controlName"
                   autocomplete="off"
                   upperFirstLetter>
        </mat-form-field>
        <mat-card [hidden]="!showItems" class="autocomplete__list">
            <mat-option *ngFor="let item of data | async" (click)="selectItem(item)">
                {{ item[objectKey] }}
            </mat-option>
        </mat-card>
    </div>
</ng-container>
