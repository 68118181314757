<h2 class="text-center">Смена типа оплаты</h2>

<label>Выбирите новый тип</label>

<mat-radio-group [(ngModel)]="currentType">
    <div *ngFor="let payment of paymentTypeList">
        <mat-radio-button [value]="payment.val">
            {{payment.name}}
        </mat-radio-button>
    </div>
</mat-radio-group>

<div class="d-flex justify-content-around">
    <button mat-raised-button color="warn" (click)="close()">Отменить</button>
    <button mat-raised-button color="accent" (click)="success()">Подтвердить</button>
</div>
