<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xs-12">
                <div class="card">
                    <div class="card-header card-header-icon" data-background-color="rose">
                        <i class="material-icons">phone</i>
                    </div>

                    <div class="card-content">
                        <h4 class="card-title">Зарплаты</h4>

                        <div class="order-table-wrap">
                            <div class="spinner-wrap" *ngIf="false">
                                <mat-spinner *ngIf="false"></mat-spinner>
                            </div>

                            <table class="table table-hover" *ngIf="sessions.length">
                                <thead>
                                <tr>
                                    <th>№ сессии</th>
                                    <th>Дата</th>
                                    <th>Сумма ЗП</th>
                                    <th title="Информация о смене">Информация</th>
                                </tr>
                                </thead>
                                <tbody>
                                <ng-container *ngFor="let session of sessions">
                                    <tr [routerLink]="'/salaries/session/' + session.id">
                                        <td>
                                            {{session.id}}
                                            <span>({{session.type === 'DAY' ? 'День' : 'Ночь'}})</span>
                                        </td>
                                        <td>
                                            <div title="Дата открытия смены">{{session.created_at}}</div>
                                            <div *ngIf="session.closed_at" title="Дата закрытия смены">
                                                {{session.closed_at}}
                                            </div>
                                        </td>
                                        <td>{{session.salary_sum}} руб</td>
                                        <td>
                                            <div>Открыл(а): {{session.user_open.name}}</div>
                                            <div *ngIf="session.user_close">Закрыл(а): {{session.user_close.name}}</div>
                                            <div>Администратор: {{session.administrator.fio}}</div>
                                            <div>Кассир: {{session.cashier.fio}}</div>
                                        </td>
                                    </tr>
                                </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
