<div class="row">
    <div class="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3">
        <form novalidate [formGroup]="form" (submit)="onSubmit()">
            <div class="card card-login card-hidden">
                <div class="card-header text-center" data-background-color="rose">
                    <h4 class="card-title">Форма авторизации</h4>

                    <div class="social-line">
                        <a class="btn btn-just-icon btn-simple">
                            <i class="fa fa-facebook-square"></i>
                        </a>

                        <a class="btn btn-just-icon btn-simple">
                            <i class="fa fa-twitter"></i>
                        </a>

                        <a class="btn btn-just-icon btn-simple">
                            <i class="fa fa-google-plus"></i>
                        </a>
                    </div>
                </div>

                <p class="category text-center">
                    Войти с помощью логина и пароля
                </p>

                <div class="card-content">
                    <div class="input-group">
                        <span class="input-group-addon">
                            <i class="material-icons">email</i>
                        </span>

                        <div class="form-group label-floating">

                        </div>
                        <mat-form-field class="full-width">
                            <mat-label>Email</mat-label>
                            <input matInput type="email" formControlName="email" autofocus>
                        </mat-form-field>
                    </div>

                    <div class="input-group">
                        <span class="input-group-addon">
                            <i class="material-icons">lock_outline</i>
                        </span>

                        <mat-form-field class="full-width">
                            <mat-label>Пароль</mat-label>
                            <input matInput type="password" formControlName="password">
                        </mat-form-field>
                    </div>
                </div>

                <div class="text-center mat-error" *ngIf="showErrorMessage">
                    Не верный логин или пароль!
                    <br><br>
                </div>

                <div class="footer text-center">
                    <button type="submit" mat-raised-button color="accent">Войти</button>
                </div>
            </div>
        </form>
    </div>
</div>
