import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
    ChangePriceModalData,
    ChangePriceModalResult
} from '@app/states/boxes/components/box-view/components/change-price-modal/change-price-modal';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-change-price-modal',
    templateUrl: './change-price-modal.component.html',
    styleUrls: ['./change-price-modal.component.scss']
})
export class ChangePriceModalComponent implements OnInit {
    serviceName: string = '';
    serviceBasePrice: number;
    servicePrice: number;
    form: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<ChangePriceModalComponent, ChangePriceModalResult>,
        @Inject(MAT_DIALOG_DATA) public data: ChangePriceModalData,
        private fb: FormBuilder
    ) {
        this.serviceName = this.data.serviceName || '';
        this.serviceBasePrice = this.data.serviceBasePrice || 0;
        this.servicePrice = this.data.servicePrice || 0;
    }

    ngOnInit() {
        this.form = this.fb.group({
            price: [null, [Validators.required]],
        });
    }

    resetToBasePrice(): void {
        this.form.patchValue({
            price: this.serviceBasePrice,
        });

        this.success();
    }

    close(): void {
        this.dialogRef.close({
            success: false,
        });
    }

    success(): void {
        if (this.form.valid) {
            this.dialogRef.close({
                success: true,
                ...this.form.value
            });
        }
    }
}
