import {Routes} from '@angular/router';
import {ProfileComponent} from './components/profile/profile.component';
import {NewClientComponent} from './components/new-client/new-client.component';
import {ClientListComponent} from './components/client-list/client-list.component';
import {CarFormComponent} from './components/profile/components/car-form/car-form.component';
import {ManagerLayoutComponent} from '../../layouts/manager-layout/manager-layout.component';
import {ManagerGuard} from '../../core/guards/manager.guard';
import {ExpressAddComponent} from './components/express-add/express-add.component';

export const routes: Routes = [
    {
        path: 'clients',
        component: ManagerLayoutComponent,
        data: {
            menu: {
                name: 'Клиенты',
                type: 'sub',
                showInDashboard: true,
                showInSideBar: true,
                fontIcon: 'dashboard',
                imageIcon: '../assets/img/dashboard-menu/new-client.svg'
            }
        },
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'new'
            },
            {
                path: 'new',
                component: NewClientComponent,
                canActivate: [ManagerGuard],
                data: {
                    menu: {
                        name: 'Новый',
                        type: 'link',
                        showInDashboard: false,
                        showInSideBar: true,
                        fontIcon: 'dashboard',
                        imageIcon: ''
                    }
                },
            },
            {
                path: 'express-add',
                component: ExpressAddComponent,
                canActivate: [ManagerGuard],
                data: {
                    menu: {
                        name: 'Быстрое добавления',
                        type: 'link',
                        showInDashboard: false,
                        showInSideBar: true,
                        fontIcon: 'dashboard',
                        imageIcon: ''
                    }
                },
            },
            {
                path: 'list',
                component: ClientListComponent,
                canActivate: [ManagerGuard],
                data: {
                    menu: {
                        name: 'Список',
                        type: 'link',
                        showInDashboard: false,
                        showInSideBar: true,
                        fontIcon: 'dashboard',
                        imageIcon: ''
                    }
                },
            },
            {
                path: 'profile/:id',
                component: ProfileComponent,
                canActivate: [ManagerGuard]
            },
            {
                path: 'profile/:id/car-add',
                component: CarFormComponent,
                canActivate: [ManagerGuard]
            },
            {
                path: 'profile/:id/car/:carId/update',
                component: CarFormComponent,
                canActivate: [ManagerGuard]
            }
        ]
    }
];
