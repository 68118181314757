<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xs-12">
                <div class="card">
                    <div class="card-content">
                        <form (submit)="submit()" [formGroup]="form">
                            <mat-form-field class="full-width">
                                <mat-label>Кто вносит расход</mat-label>
                                <input [value]="user.name" matInput placeholder="Кто вносит расход" readonly>
                            </mat-form-field>

                            <mat-form-field class="full-width">
                                <mat-label>Выбирите кассу</mat-label>
                                <mat-select formControlName="cash" placeholder="Касса">
                                    <mat-option *ngFor="let cash of cashes"
                                                [title]="'Доступно для списания - ' + cash.balance + '₽'"
                                                [value]="cash.id">
                                        {{cash.name}} - {{cash.balance}}₽
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="form.controls.cash.invalid">
                                    Нужно выбрать тип платежа
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class="full-width">
                                <mat-label>Статья расходов</mat-label>
                                <mat-select formControlName="cash_type">
                                    <mat-option *ngFor="let type of expensesTypes" [value]="type.id">
                                        {{type.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field *ngIf="form.get('employee').enabled" class="full-width">
                                <mat-label>Выбирите сотрудника для начисление</mat-label>
                                <mat-select formControlName="employee">
                                    <mat-option *ngFor="let employee of employeeList" [value]="employee.id">
                                        {{employee.name}} {{employee.surname}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field class="full-width">
                                <mat-label>Сумма</mat-label>
                                <input formControlName="sum" matInput placeholder="Сумма" type="number">
                                <mat-error *ngIf="form.controls.sum.hasError('required')">
                                    Нужно заполнить сумму
                                </mat-error>
                                <mat-error *ngIf="form.controls.sum.hasError('pattern')">
                                    Сумму вводить можно только цифрами
                                </mat-error>
                                <mat-error *ngIf="form.controls.sum.hasError('max')">
                                    Сумма расхода не может превышать {{form.controls.sum.errors.max.max}}₽
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class="full-width">
                                <mat-label>Назначения платежа</mat-label>
                                <input formControlName="payment_purpose" matInput placeholder="Назначения платежа">
                            </mat-form-field>

                            <mat-checkbox [formControl]="showDateTimePicker">Указать дату расхода</mat-checkbox>

                            <mat-form-field class="full-width" *ngIf="showDateTimePicker.value">
                                <mat-label>Дата и время</mat-label>
                                <input type="datetime-local" matInput formControlName="datetime"
                                       placeholder="Дата и время">
                            </mat-form-field>

                            <div class="d-flex justify-content-end">
                                <button color="accent" mat-raised-button type="submit">Отправить</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
