import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {AuthBaseService} from '@app/core/services/auth-base.service';
import {LocalHistoryService} from '@services/local-history.service';
import {RevenuePerShiftComponent} from '../components/revenue-per-shift/revenue-per-shift.component';
import {CurrentState, SessionShiftService} from '@app/core/services/session-shift.service';
import {SessionOpenModalComponent} from '@shared/components/shifts/session-open-modal/session-open-modal.component';
import {SessionNeedCloseComponent} from '@shared/components/shifts/session-need-close/session-need-close.component';
import {HttpClient} from '@angular/common/http';
import {UIMangerService} from '@services/ui-manger.service';
import {MatDialog} from '@angular/material/dialog';

declare var $: any;

const misc: any = {
    navbar_menu_visible: 0,
    active_collapse: true,
    disabled_collapse_init: 0,
};

@Component({
    selector: 'app-navbar-cmp',
    templateUrl: 'navbar.component.html',
    styleUrls: ['navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    public isRoot: boolean;
    public user: any;
    private nativeElement: Node;
    private toggleButton: any;
    private sidebarVisible: boolean;
    private sessionState: CurrentState;

    constructor(
        private location: Location,
        private renderer: Renderer2,
        private element: ElementRef,
        private router: Router,
        private authBase: AuthBaseService,
        private localHistory: LocalHistoryService,
        private dialog: MatDialog,
        private sessionShift: SessionShiftService,
        private http: HttpClient,
        private ui: UIMangerService
    ) {
        this.nativeElement = element.nativeElement;
        this.sidebarVisible = false;
    }

    ngOnInit() {
        this.user = this.authBase.getUser();
        this.isRoot = this.localHistory.isRoot;
        this.sessionShift.getCurrentStateSubject().subscribe((result) => {
            this.sessionState = result;
        });

        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];

        if ($('body').hasClass('sidebar-mini')) {
            misc.sidebar_mini_active = true;
        }

        if ($('body').hasClass('hide-sidebar')) {
            misc.hide_sidebar_active = true;
        }

        $('#minimizeSidebar').click(function () {
            if (misc.sidebar_mini_active === true) {
                $('body').removeClass('sidebar-mini');
                misc.sidebar_mini_active = false;

            } else {
                setTimeout(function () {
                    $('body').addClass('sidebar-mini');

                    misc.sidebar_mini_active = true;
                }, 300);
            }

            // we simulate the window Resize so the charts will get updated in realtime.
            const simulateWindowResize = setInterval(function () {
                window.dispatchEvent(new Event('resize'));
            }, 180);

            // we stop the simulation of Window Resize after the animations are completed
            setTimeout(function () {
                clearInterval(simulateWindowResize);
            }, 1000);
        });

        $('#hideSidebar').click(function () {
            if (misc.hide_sidebar_active === true) {
                setTimeout(function () {
                    $('body').removeClass('hide-sidebar');
                    misc.hide_sidebar_active = false;
                }, 300);
                setTimeout(function () {
                    $('.sidebar').removeClass('animation');
                }, 600);
                $('.sidebar').addClass('animation');

            } else {
                setTimeout(function () {
                    $('body').addClass('hide-sidebar');
                    // $('.sidebar').addClass('animation');
                    misc.hide_sidebar_active = true;
                }, 300);
            }

            // we simulate the window Resize so the charts will get updated in realtime.
            const simulateWindowResize = setInterval(function () {
                window.dispatchEvent(new Event('resize'));
            }, 180);

            // we stop the simulation of Window Resize after the animations are completed
            setTimeout(function () {
                clearInterval(simulateWindowResize);
            }, 1000);
        });
    }

    isMobileMenu() {
        if ($(window).width() < 991) {
            return false;
        }
        return true;
    }

    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const body = document.getElementsByTagName('body')[0];
        setTimeout(function () {
            toggleButton.classList.add('toggled');
        }, 500);
        body.classList.add('nav-open');

        this.sidebarVisible = true;
    }

    sidebarClose() {
        const body = document.querySelector('body');
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        body.classList.remove('nav-open');
    }

    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    }

    backUrl(): void {
        this.location.back();
    }

    logout() {
        this.authBase.logout();
    }

    getRevenue() {
        this.dialog.open(RevenuePerShiftComponent, {
            minWidth: '50%',
            minHeight: '50%',
        });
    }

    startShift() {
        this.dialog.open(SessionOpenModalComponent, {disableClose: true});
    }

    closeShift() {
        this.dialog.open(SessionNeedCloseComponent, {disableClose: true}).afterClosed().subscribe((result: any) => {
            if (result.needOpenSession) {
                this.dialog.open(SessionOpenModalComponent, {disableClose: true});
            }
        });
    }

    restartBackendServer() {
        this.ui.loaderShow();
        this.http.get('https://drmauto.ru/api/v1/server/erp-restart.php?ajax=1').subscribe(
            (response: any) => {
                setTimeout(() => {
                    this.ui.loaderHide();
                }, 30000);
            },
            (error: any) => {
                setTimeout(() => {
                    this.ui.loaderHide();
                }, 30000);
            }
        );
    }
}
