import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CustomerService} from '@services/customer.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-customer-shares-info',
    templateUrl: './customer-shares-info.component.html',
    styleUrls: ['./customer-shares-info.component.scss']
})
export class CustomerSharesInfoComponent implements OnInit, OnDestroy {
    @Input() customerId: number;

    shares = [];
    private subscription: Subscription;

    constructor(private customerService: CustomerService) {
        this.subscription = new Subscription();
    }

    ngOnInit(): void {
        const data = this.customerService.getShares(this.customerId).subscribe((response: any) => {
            this.shares = response.data;
        });
        this.subscription.add(data);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
