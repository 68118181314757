<ng-container [formGroup]="parentForm">
    <div [class]="typeClasses">
        <mat-form-field class="full-width">
            <mat-select
                placeholder="Выбирите тип номера"
                [formControlName]="inputType"
                (selectionChange)="onChangeCarTypeNumber($event)">
                <mat-option *ngFor="let type of typeCarNumbers" [value]="type.id">
                    {{ type.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div [class]="numberClasses">
        <mat-form-field class="full-width" [floatLabel]="'always'">
            <mat-label>Номер машины</mat-label>

            <input type="text" matInput *ngIf="!carNumberPattern"
                   autocomplete="off"
                   [placeholder]="carNumberPlaceholder"
                   [formControlName]="inputNumber"
                   [readonly]="!carNumberPattern"
                   [matTooltip]="!carNumberPattern ? 'Заполните - Тип номера' : '' "
                   #inputCarNumber
                   (blur)="clearDataOnBlur()">

            <input type="text" matInput *ngIf="carNumberPattern"
                   autocomplete="off"
                   [placeholder]="carNumberPlaceholder"
                   [formControlName]="inputNumber"
                   [readonly]="!carNumberPattern"
                   #inputCarNumber
                   (blur)="clearDataOnBlur()">

            <mat-error *ngIf="parentForm.controls[inputNumber].hasError('required')">Обязательно к заполнению</mat-error>
        </mat-form-field>
        <app-simple-autocomplete
            *ngIf="showAutoComplete"
            [data]="carNumberList"
            [objectKey]="'number'"
            (selected)="onSelectedCarNumber($event)"
        ></app-simple-autocomplete>
    </div>
</ng-container>
