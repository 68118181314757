import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SessionsComponent} from './pages/sessions/sessions.component';
import {RouterModule} from '@angular/router';
import {routes} from './salaries.routes';
import {MenuModule} from '@services/menu/menu.module';
import {MaterialModule} from '@app/core/modules/material/material.module';
import {SessionPositionsComponent} from './pages/session-positions/session-positions.component';
import {SessionPositionEmployeesComponent} from './pages/session-position-employees/session-position-employees.component';
import {EmployeeDetailComponent} from './pages/employee-detail/employee-detail.component';

@NgModule({
    declarations: [SessionsComponent, SessionPositionsComponent, SessionPositionEmployeesComponent, EmployeeDetailComponent],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MenuModule.forRoot({routes, order: 11}),
        MaterialModule,
    ]
})
export class SalariesModule {
}
