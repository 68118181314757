<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xs-12">
                <div class="card">
                    <!--div class="card-header">
                        <h4 class="card-title">Стандарты обслуживания -
                            <small>Залог успеха</small>
                        </h4>
                    </div-->
                    <div class="card-content">
                        <ul class="nav nav-pills nav-pills-warning">
                            <li class="active">
                                <a data-toggle="tab" href="#pill1" aria-expanded="false">Встреча клиента</a>
                            </li>
                            <li>
                                <a data-toggle="tab" href="#pill2" aria-expanded="false">Входящий звонок</a>
                            </li>
                            <li>
                                <a data-toggle="tab" href="#pill4" aria-expanded="true">Регламенты</a>
                            </li>
                        </ul>
                        <div class="tab-content">
                            <div class="tab-pane active" id="pill1">

                                <h3>Встреча клиентов при непосредственном обращении</h3>
                                <ol>
                                    <li>
                                        Быть всегда максимально улыбчивыми и приветливыми.
                                    </li>
                                    <li>
                                        Всегда, когда клиенты заходят в автомойку вставать из-за стойки, поздороваться, выйти на встречу клиенту на 1-2 шага от стойки ресепшн, узнать цель посещения автомойки, указать, где можно оставить верхнюю одежду (помочь при неоходимости);
                                    </li>
                                    <li>
                                        Используемые фразы:
                                        <br><br>
                                        <blockquote>
                                        <ol>
                                            <li>
                                                Приветствие: Добрый день, вечер, доброе утро, здравствуйте
                                            </li>
                                            <li>
                                                Выяснение цели посещения: «Вы…. по записи…., первый раз у нас в автомойке?»
                                            </li>
                                            <li>
                                                Обслуживание: «Покажите, где можно оставить верхнюю одежду/куртку, пальто и т.д…»
                                            </li>
                                            <li>
                                                Запрещено спрашивать «… по какому вопросу, чем Вы можем Вам помочь и т.д.»
                                            </li>
                                        </ol>
                                        </blockquote>
                                    </li>
                                    <li>
                                        Предложить чай/кофе
                                    </li>
                                    <li>
                                        Ознакомить клиента с ценами на услуги, сидками (либо системой скидок) и акциями, проходящими в автомойке в данный период.
                                    </li>
                                    <li>
                                        Обязательно озвучить наличие детского уголка, если клиент с ребенком.
                                    </li>
                                    <li>
                                        Спросить клиента не мешает ли ему кондиционер, выключить или включить, настроить температуру.
                                    </li>
                                    <li>
                                        Проводить клиента в комнату ожидания и предложить кофе, чай, снеки.
                                    </li>
                                </ol>
                                <h4>
                                    Алгоритм поведения с клиентами:
                                </h4>
                                <ul class="timeline timeline-simple">
                                    <li class="timeline-inverted">
                                        <div class="timeline-badge info">
                                            <i class="material-icons">mood</i>
                                        </div>
                                        <div class="timeline-panel">
                                            <div class="timeline-heading">
                                                <span class="label label-info">Приветствие</span>
                                            </div>
                                            <div class="timeline-body">
                                                <p>
                                                    При появлении клиента, отложить текущие дела и переключить свое внимание на клиента
                                                </p>
                                            </div>

                                            <ol>
                                                <li>Установить визуальный контакт с клиентом</li>
                                                <li>Улыбнуться клиенту</li>
                                                <li>
                                                    Поприветствовать клиента
                                                    <blockquote>
                                                        Добрый день, слушаю Вас! (Здравствуйте, чем я могу вам помочь?)
                                                    </blockquote>
                                                </li>
                                                <li>
                                                    Уточнить цель визита
                                                    <blockquote>
                                                        Позвольте я Вам помогу…
                                                    </blockquote>
                                                </li>
                                                <li>
                                                    Предложить клиенту чай, кофе, воду и т.п.
                                                    <blockquote>
                                                        Давайте я Вам сделаю чай? кофе и т.п
                                                    </blockquote>
                                                </li>
                                                <li>
                                                    Извиниться перед клиентом за необходимость ожидания в очереди или за время, потраченное на различные оргвопросы
                                                </li>
                                                <li>
                                                    Извините, что пришлось подождать. (Спасибо за ожидание!)
                                                </li>
                                            </ol>
                                        </div>
                                    </li>
                                    <li class="timeline-inverted">
                                        <div class="timeline-badge info">
                                            <i class="material-icons">mood</i>
                                        </div>
                                        <div class="timeline-panel">
                                            <div class="timeline-heading">
                                                <span class="label label-info">Если заняты</span>
                                            </div>
                                            <div class="timeline-body">
                                                <p>
                                                    При отсутствии возможности отвлечься от текущих дел, например - *приход клиента, в момент общения администратора по телефону с другим клиентом
                                                </p>
                                                <p>
                                                    Дать клиенту понять, что вы его заметили (улыбка и кивок) и сообщить вошедшему клиенту, что сейчас ему уделят внимание:
                                                </p>
                                                <blockquote>
                                                    Прошу прощения, буквально пару минут…
                                                </blockquote>
                                                <p>
                                                    При отсутствии возможности отвлечься от текущих дел:
                                                    <br>
                                                    Например - *приход клиента, в момент общения администратора по телефону с другим клиентом
                                                </p>
                                                <p>
                                                    Перед выполнением операции проинформировать клиента о том, сколько времени займет ее выполнение, если длительность операции составляет более 5 минут
                                                </p>
                                                <p>
                                                    Сейчас мне нужно будет внести Ваши данные в компьютер (проверить информацию…, уточнить данные… и т.д.), это займет не более 5 минут.
                                                </p>
                                                <p>
                                                    ОБЯЗАТЕЛЬНО:
                                                </p>
                                                <p class="alert alert-warning">
                                                    Мы еще оказываем услуги детейлинга, что это такое Вам может рассказать и показать наш сотрудник…
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="timeline-inverted">
                                        <div class="timeline-badge info">
                                            <i class="material-icons">mood</i>
                                        </div>
                                        <div class="timeline-panel">
                                            <div class="timeline-heading">
                                                <span class="label label-info">Завершения контакта</span>
                                            </div>
                                            <div class="timeline-body">
                                                <p>
                                                    Поблагодарить клиента за обращение
                                                </p>
                                                <p>
                                                    Спасибо, что воспользовались&nbsp; нашими услугами!
                                                </p>
                                                <p>
                                                    Попрощаться и пригласить клиента в автомойку в дальнейшем.
                                                </p>
                                                <p>
                                                    Всего доброго! (пожелание добавить: хорошего дня, хорошего вечера, хороших выходных и т.п. по ситуации)
                                                </p>
                                            </div>
                                        </div>
                                    </li>

                                </ul>


                            </div>
                            <div class="tab-pane" id="pill2">
                                <h3>
                                    Требования к оператору
                                </h3>
                                <ol>
                                    <li>
                                        Уверенный голос
                                    </li>
                                    <li>
                                        Четкая дикция
                                    </li>
                                    <li>
                                        Спокойное дыхание
                                    </li>
                                    <li>
                                        Правильный темп речи
                                    </li>
                                    <li>
                                        Улыбка
                                    </li>
                                    <li>
                                        Концентрация на разговоре
                                    </li>
                                    <li>
                                        Отсутствие ненужных шумов
                                    </li>
                                </ol>
                                <h3>
                                    Недопустимо
                                </h3>
                                <ol>
                                    <li>
                                        Раздраженные интонации
                                    </li>
                                    <li>
                                        Повышение голоса
                                    </li>
                                    <li>
                                        Жевание во время разговора
                                    </li>
                                    <li>
                                        Разговор «свысока» с собеседником, трудно воспринимающим информацию
                                    </li>
                                    <li>
                                        Фразы, указывающие на некорректное поведение собеседника, высказанные в грубой форме.
                                    </li>
                                    <li>
                                        Переход на личный стиль общения: заигрывание, обсуждение посторонних вопросов.
                                    </li>
                                </ol>
                                <p>
                                    ВАЖНО!
                                </p>
                                <ol type="1">
                                    <li>ТРУБКА ДОЛЖНА БЫТЬ ПОДНЯТА АДМИНИСТРАТОРОМ В ТЕЧЕНИИ 5-30 СЕКУНД.</li>
                                    <li>После поднятия трубки Администратор ждет 2 секунды прежде чем начать общение с клиентом (требуется время на установление соединения).</li>
                                    <li>На любой вопрос клиента нельзя отвечать вопросом! </li>
                                    <li>Если клиент переспрашивает администратора один и тот же вопрос несколько раз, то строжайше запрещено использовать следующий набор фраз:</li>
                                </ol>
                                <blockquote>
                                    <p>
                                        - Я же Вам уже говорила!
                                    </p>
                                    <p>
                                        - Вы уже меня об этом спрашивали!
                                    </p>
                                    <p>
                                        - Я же сказала!
                                    </p>
                                </blockquote>
                                <p>
                                    И т.п.
                                </p>
                                <ol type="1">
                                    <li>Если Вы не знаете ответа на поставленный клиентом вопрос, то необходимо записать контактный телефон, уточнить ответ на вопрос и перезвонить клиенту.</li>
                                    <li>Если Вы разговариваете по одному телефону, а в этот момент идет вызов на вторую трубку, то прежде чем поднять вторую трубку, необходимо извиниться перед клиентом фразой: Прошу прощения, у нас вторая линия, я сейчас отвечу и вернусь к Вам! А клиенту на второй линии сообщить, что сейчас консультируете другого клиента и записать его контактный номер.</li>
                                </ol>
                                <p>
                                    ОБЯЗАТЕЛЬНО СТАРАТЬСЯ ВЗЯТЬ КОНТАКТЫ КЛИЕНТА!
                                </p>
                                <p>
                                    Телефонный разговор с первичным клиентом:
                                </p>
                                <p>
                                    Как правильно начать разговор по телефону (подъем трубки по 2 звонку, но не позднее 30 секунд):
                                </p>
                                <blockquote>
                                    <p>
                                        Администратор:- Детейлинг центр ДримАвто, администратор … (имя). Добрый день!
                                    </p>
                                    <p>
                                        Клиент: - Добрый день. А вы … делаете? (Вопрос относительно услуги).
                                    </p>
                                    <p>
                                        Администратор:- Да, рады вас слышать. Хорошо, что обратились к нам. (можно опустить).
                                    </p>
                                    <p>
                                        Администратор: - Вы у нас уже были или первый раз?
                                    </p>
                                    <p>
                                        Клиент: - Нет, я в первый раз.
                                    </p>
                                    <p>
                                        Очень важный момент: есть клиенты, которые у нас уже были (повторные), которые узнали о нас по рекламе (первичные), которые у нас были, но про нас забыли, которые пришли по рекомендации.
                                    </p>
                                    <p>
                                        Администратор: - Как я могу к вам обращаться?
                                    </p>
                                    <p>
                                        Клиент: - Василий, а сколько стоит … (название услуги)?
                                    </p>
                                    <p>
                                        Администратор: - Очень приятно, Василий. Итак, стоимость … в нашем центре варьируется от … до … руб. Цена зависит от категории вашего автомобиля.
                                    </p>
                                    <p>
                                        Эта технология называется “розетка”.
                                    </p>
                                    <p>
                                        Со 100% вероятностью клиент будет говорить - дорого. Т.к.единственный критерий, который клиент понимает - это цена. И какую бы вы цену не назвали - это всегда будет дорого.
                                    </p>
                                    <p>
                                        Клиент: - Что-то дороговато.
                                    </p>
                                </blockquote>

                                    <p>
                                        Варианты ответа администратора:
                                    </p>
                                <blockquote>
                                    <p>
                                        - Соглашусь, что на первый взгляд цена может показаться высокой, однако могу я Вас спросить, с чем вы сравниваете?
                                    </p>
                                    <p>
                                        - Соглашусь с Вами, что цена является важным аспектом …. (название услуги). Давайте я вам расскажу, из чего складывается цена. Хорошо?
                                    </p>
                                    <p>
                                        - Да цена - один из важных показателей при выборе. Я правильно поняла, что вы хотите просто помыть машину (сделать полировку и т.п.)?
                                    </p>
                                </blockquote>
                                <p>Продолжение разговора</p>
                                <blockquote>
                                    <p>
                                        Клиент: - Мне надо подумать.
                                    </p>
                                    <p>
                                        Администратор: - Вас что-то смущает?
                                    </p>
                                    <p>
                                        Администратор: - Все наши мастера профессионалы, я гарантирую, что качество вас порадует. Завтра в 12:00 запишемся?
                                    </p>
                                    <p>
                                        Клиент: Да (Нет, Мне надо подумать).
                                    </p>
                                    <p>
                                        Предлагаем то время, которое нам нужно заполнить. Сначала мы предлагаем время неудобное. Время, которое плохо заполняется.
                                    </p>
                                    <p>
                                        Если клиент согласился на запись, то:
                                    </p>
                                    <p>
                                        Администратор: - Итак, Василий, завтра в 12:00 я записала вас на … , оставьте ваш телефон, чтобы я могла вам напомнить.
                                    </p>
                                    <p>
                                        Клиент: Да, все верно, мой телефон 892хххххххх
                                    </p>
                                    <p>
                                        Администратор: - Ждем вас…. Всего доброго!
                                    </p>
                                </blockquote>
                                <p>
                                    Если клиент отказался от записи, но идет на контакт, колеблется, то:
                                </p>
                                <p>
                                    Вариант 1 (отказ от записи на предложенное время)
                                </p>
                                <blockquote>
                                    <p>
                                        Администратор: - Вас записать на другой день? Когда Вам будет удобно?
                                    </p>
                                    <p>
                                        Клиент: Да. Запишите меня …
                                    </p>
                                    <p>
                                        Администратор: - Ждем вас…. Всего доброго!
                                    </p>
                                </blockquote>
                                <p>
                                    Вариант 2 (Клиент отказывается записываться)
                                </p>
                                <blockquote>
                                    <p>
                                        Администратор: - Хорошо, будем ждать Вашего звонка, мы работаем круглосуточно, 7 дней в неделю. Звоните!
                                    </p>
                                    <p>
                                        Клиент: Да, Спасибо!
                                    </p>
                                    <p>
                                        Администратор: - Всего доброго!
                                    </p>
                                </blockquote>
                                <p>
                                    При работе с повторными клиентами применяем другой алгоритм:
                                </p>
                                <blockquote>
                                    <p>
                                        Администратор:- Детейлинг центр ДримАвто, администратор … (имя). Добрый день!
                                    </p>
                                    <p>
                                        Клиент: - Добрый день. А можно записаться на …? (Вопрос относительно услуги).
                                    </p>
                                    <p>
                                        Администратор:- Да, рады вас слышать. Хорошо, что обратились к нам. (можно опустить).
                                    </p>
                                    <p>
                                        Администратор: - Вы у нас уже были или первый раз?
                                    </p>
                                    <p>
                                        Клиент: - нет, я был раньше …
                                    </p>
                                    <p>
                                        Администратор: - Отлично! Подскажите Вашу фамилию (если ведется запись по программе)
                                    </p>
                                    <p>
                                        Если записи по программе нет то:
                                    </p>
                                    <p>
                                        Администратор: - Пожалуйста, напомните, как Вас зовут?
                                    </p>
                                    <p>
                                        Клиент: - Василий …
                                    </p>
                                    <p>
                                        Администратор: - Василий. Вы ведь у нас постоянный клиент…
                                    </p>
                                </blockquote>
                                <p>
                                    Далее уточняется, что хочет клиент и ему предлагается допуслуга:
                                </p>
                                <blockquote>
                                    <p>
                                        Администратор: - Хочу предложить Вам еще одну услугу …
                                    </p>
                                    <p>
                                        - У нас сейчас проводится акция…
                                    </p>
                                </blockquote>
                                <p>
                                    И т.п.
                                </p>
                                <p>
                                    Все звонки должны быть зафиксированы в таблице установленной формы, в соответствии с инструкцией. Администратор дает ежедневный отчет по выполненным звонкам и отвечает за точность приведенных данных.
                                </p>
                                <p>
                                    Кроме того, администратор обязан:
                                </p>
                                <ul>
                                    <li>
                                        держать личный МТ на рабочем месте всегда в беззвучном режиме.
                                    </li>
                                    <li>...</li>
                                </ul>
                                <p class="alert alert-danger">
                                    СТРОГО ЗАПРЕЩЕНО БЕСЕДОВАТЬ ПО ЛИЧНОМУ МТ НА ЛИЧНЫЕ ТЕМЫ НА РАБОЧЕМ МЕСТЕ (ЗА ИСКЛЮЧЕНИЕМ ФОРС-МАЖОРОВ), ОСОБО ЭТО НЕ ПРИЕМЛЕМО В ПРИСУТСТВИИ КЛИЕНТА!
                                </p>
                            </div>
                            <div class="tab-pane" id="pill4">
                                <div aria-multiselectable="true" class="panel-group" id="accordion" role="tablist">
                                    <div class="panel panel-default">
                                        <div class="panel-heading" id="headingOne" role="tab">
                                            <a aria-controls="collapseOne" aria-expanded="true" data-parent="#accordion" data-toggle="collapse" href="#collapseOne" role="button">
                                                <h4 class="panel-title">
                                                    <h3>
                                                        Администратору
                                                        <i class="material-icons">keyboard_arrow_down</i>
                                                    </h3>
                                                </h4>
                                            </a>
                                        </div>
                                        <div aria-labelledby="headingOne" class="panel-collapse collapse in" id="collapseOne" role="tabpanel">
                                            <div class="panel-body">
                                                <ul>
                                                    <li>
                                                        Рабочий график с 9.00 до 21.00 –дневной / С 21.00 до 9.00-ночной
                                                    </li>
                                                    <li>
                                                        Следить за тем чтобы автомойщики выходили на смену
                                                    </li>
                                                    <li>
                                                        СТРОГО по графику с 9.00 до 21.00
                                                    </li>
                                                    <li>
                                                        ТОЛЬКО в чистой рабочей форме
                                                    </li>
                                                    <li>
                                                        В трезвом виде (недопустим запах перегара)
                                                    </li>
                                                    <li>
                                                        Встречать Клиента который подъезжает
                                                    </li>
                                                    <li>
                                                        Предлагать загнать машину в бокс
                                                    </li>
                                                    <li>
                                                        Помимо интересующей услуги предложить сопутствующие дополнительные услуги и услуги по детейлингу
                                                    </li>
                                                    <li>
                                                        Вежливо попросить оставить номер телефона для звонка о готовности автомобиля
                                                    </li>
                                                    <li>
                                                        Знать детально все цены на услуги, названия автохимии их преимущества и отличия
                                                    </li>
                                                    <li>
                                                        Припарковать автомобиль по готовности, а в случае отсутствия клиента оставить ключи у кассира
                                                    </li>
                                                    <li>
                                                        Вести табель отъезда мойщиков и контролировать их приезд
                                                    </li>
                                                    <li>
                                                        Вести запись клиентов на дополнительные услуги, услуги детейлинга
                                                    </li>
                                                    <li>
                                                        Подписывать договора на хранение шин и «подшивать» их в специальную папку
                                                    </li>
                                                    <li>
                                                        Вести таблицу штрафов и премирования с детальным описанием причины наложения
                                                    </li>
                                                    <li>
                                                        Следить за соблюдением кассиром регламента
                                                    </li>
                                                    <li>
                                                        Следить за соблюдением автомойщиками регламента
                                                    </li>
                                                </ul>
                                                <p>
                                                    А именно за тем что мойщики:
                                                </p>
                                                <ul>
                                                <li>
                                                    Запрещено КУРИТЬ во время процесса мойки, разрешено строго в специально-отведенном месте
                                                </li>
                                                <li>
                                                    ЗАПРЕЩЕНО использовать нецензурную лексику в присутствии клиента и в комнате ожидания
                                                </li>
                                                <li>
                                                    Находиться в комнате ожидания при клиенте
                                                </li>
                                                <li>
                                                    Следить за порядком в БОКСЕ (чистые стены и пол, камера видеонаблюдения, чистые светильники, сложенные тряпки и бутылки с химией)
                                                </li>
                                                <li>
                                                    СТРОГО ЗАПРЕЩЕНО прибегать к критике, оценке, высказыванию своего негативного отношения к клиенту и его автомобилю!!!
                                                </li>
                                                <li>
                                                    Соблюдать порядок в комнате отдыха (личные вещи убирать в шкаф, продукты питания в холодильник, регулярно выбрасывать мусор, чистая микроволновая печь)
                                                </li>
                                                <li>
                                                    Мойщики помимо порядка и чистоты в боксах, убирают мусорные баки на всей территории мойки и следят за чистотой на территории в целом
                                                </li>
                                                <li>
                                                    В случае отсутствия администратора мойщик обязан встречать и провожать машину, помогать клиенту правильно заехать в бокс без повреждений автомобиля клиента и автомойки
                                                </li>
                                                <li>
                                                    НЕ ВСТАВАТЬ на пороги машин!!! (использовать стремянку)
                                                </li>
                                                <li>
                                                    НЕ ИСПОЛЬЗОВАТЬ одну тряпку для разных операций
                                                </li>
                                                <li>
                                                    Замшевые тряпки, одну для протирания кузова, другую для сушки
                                                </li>
                                                <li>
                                                    Лохматая тряпка (микрофибра) для салона
                                                </li>
                                                <li>
                                                    КОНТРОЛЬ автохимии (если что-то заканчивается,  ЗАРАНЕЕ ПРЕДУПРЕДИТЬ АДМИНИСТРАТОРА об этом)
                                                </li>
                                                <li>
                                                    Сотрудник должен знать весь ассортимент химии для мойки
                                                </li>
                                                <li>
                                                    Прокатывать ДИСКИ, чтобы были чистые
                                                </li>
                                                <li>
                                                    Рационально использовать автохимию и аксессуары (стирать  и промывать тряпки, губки после каждой смены, срок их службы должен составлять не менее двух недель)
                                                </li>
                                                <li>
                                                    Класть коврик под ноги клиенту
                                                </li>
                                                <li>
                                                    Время комплексной мойки  не более 40 минут
                                                </li>
                                                <li>
                                                    Администратор обязан следить за соблюдением мойщиками всех пунктов регламента, в противном случае За несоблюдение регламента автомойщика, администратор в праве наложить штраф на сотрудника (размер будет определяться в зависимости от степени и частоты нарушений по согласованию).
                                                </li>
                                                <li>
                                                    Принимать Машину и производить контроль качества оказания услуги
                                                </li>
                                                <li>
                                                    В случае аргументированного недовольства клиента оказанной услуги в доброжелательной форме предложить ему исправить допущенные недочеты
                                                </li>
                                                <li>
                                                    Осуществлять закупку товаров и автохимии по необходимости.
                                                </li>
                                                <li>
                                                    Знать приблизительный расход химии по конкретным услугам и вести таблицу-учет по затратам на месяц на основании товарных накладных и счетов
                                                </li>
                                                <li>
                                                    Вести дополнительный учет автохимии
                                                </li>
                                                </ul>
                                                <p class="alert alert-danger">
                                                    За несоблюдение регламента администратора, руководитель в праве наложить штраф на сотрудника (размер будет определяться в зависимости от степени и частоты нарушений).
                                                </p>
                                                <blockquote>
                                                    <p>
                                                        Бездельника хлебом не корми, а дай порассуждать, да и в умении очернить других ему не откажешь. Он всегда готов найти оправдание собственной никчемности.
                                                    </p>
                                                    <small>Леонардо да Винчи</small>
                                                </blockquote>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="panel panel-default">
                                        <div class="panel-heading" id="headingTwo" role="tab">
                                            <a aria-controls="collapseTwo" aria-expanded="false" class="collapsed" data-parent="#accordion" data-toggle="collapse" href="#collapseTwo" role="button">
                                                <!--h4 class="panel-title"-->
                                                <h3>
                                                    Кассиру
                                                    <i class="material-icons">keyboard_arrow_down</i>
                                                </h3>
                                            </a>
                                        </div>
                                        <div aria-labelledby="headingTwo" class="panel-collapse collapse" id="collapseTwo" role="tabpanel">
                                            <div class="panel-body">
                                                <ul>
                                                    <li>
                                                        Выход на работу только в рабочей форме
                                                    </li>
                                                    <li>
                                                        Выход на работу по графику с 9.00 до 21.30
                                                    </li>
                                                    <li>
                                                        Опрятный внешний вид
                                                    </li>
                                                    <li>
                                                        Следить за порядком в клиентской
                                                    </li>
                                                    <li>
                                                        Отвечать на звонки согласно инструкции (выждать пару секунд , сообщить о том куда клиент позвонил «ДРИМ АВТО» доброжелательно представиться «ИМЯ», предложить ему помочь «Чем я могу Вам помочь?»
                                                    </li>
                                                </ul>
                                                <p>
                                                    Обязательно предложить ему сопутствующую услугу, после ответа на его вопрос, в конце разговора поблагодарить его за звонок в ДРИМ АВТО и пожелать ВСЕГО ДОБРОГО, ДО СВИДАНИЯ.
                                                </p>
                                                <ul>
                                                    <li>
                                                        ЗАПРЕЩЕНО при клиенте разговаривать по личному телефону и брать его в руки
                                                    </li>
                                                    <li>
                                                        Если автомобиль подъезжает к боксу оповещать мойщиков, нажимая на дистанционный звонок
                                                    </li>
                                                    <li>
                                                        УСЛУЖЛИВО и ВЕЖЛИВО разговаривать с клиентом
                                                    </li>
                                                    <li>
                                                        Не разговаривать с администратором или кем-либо еще о внутренних вопросах автомойки при клиентах
                                                    </li>
                                                    <li>
                                                        Учет товара (Заранее предупреждать администраторов о необходимости произвести закупки)
                                                    </li>
                                                    <li>
                                                        Подробный отчет о проданной продукции и услугах
                                                    </li>
                                                    <li>
                                                        Заполнять таблицы посещения клиентов (откуда узнали и т.д)
                                                    </li>
                                                    <li>
                                                        Оповещать о действующих и предстоящих акциях и предложениях (бесплатная мойка, первое посещение и т.д)
                                                    </li>
                                                    <li>
                                                        Отправлять отчет в конце смены в WhatsApp
                                                    </li>
                                                    <li>
                                                        Знать весь список предоставляемых услуг, ассортимент химии для мойки, детейлинга, шиномонтажа
                                                    </li>
                                                    <li>
                                                        Пробивать чек за все товары и услуги, в противном случае эта сумма будет изыматься с заработной платы кассира
                                                    </li>
                                                    <li>
                                                        Считать и вести промежуточные отчеты по заработной плате сотрудников
                                                    </li>
                                                    <li>
                                                        ЗАПРЕЩЕНО выдавать авансы сотрудникам без согласования с руководством
                                                    </li>
                                                </ul>
                                                <p class="alert alert-danger">
                                                    За несоблюдение регламента кассира, компания в праве наложить штраф на сотрудника (размер будет определяться в зависимости от степени и частоты нарушений).
                                                </p>
                                                <blockquote>
                                                    <p>
                                                        Ничто не истощает и не разрушает человека, как продолжительное физическое бездействие.
                                                    </p>
                                                    <small>Аристотель</small>
                                                </blockquote>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="panel panel-default">
                                        <div class="panel-heading" id="headingThree" role="tab">
                                            <a aria-controls="collapseThree" aria-expanded="false" class="collapsed" data-parent="#accordion" data-toggle="collapse" href="#collapseThree" role="button">
                                                <h3>
                                                    Автомойщику
                                                    <i class="material-icons">keyboard_arrow_down</i>
                                                </h3>
                                            </a>
                                        </div>
                                        <div aria-labelledby="headingThree" class="panel-collapse collapse" id="collapseThree" role="tabpanel">
                                            <div class="panel-body">
                                                <ul>
                                                    <li>
                                                        Выход на смену
                                                    </li>
                                                    <li>
                                                        СТРОГО по графику с 9.00 до 21.00
                                                    </li>
                                                    <li>
                                                        ТОЛЬКО в чистой рабочей форме
                                                    </li>
                                                    <li>
                                                        В трезвом виде (недопустим запах перегара)
                                                    </li>
                                                    <li>
                                                        ЗАПРЕЩЕНО КУРИТЬ во время процесса мойки, разрешено строго в специально-отведенном месте
                                                    </li>
                                                    <li>
                                                        ЗАПРЕЩЕНО использовать нецензурную лексику в присутствии клиента и в комнате ожидания
                                                    </li>
                                                    <li>
                                                        Находиться в комнате ожидания при клиенте
                                                    </li>
                                                    <li>
                                                        Следить за порядком в БОКСЕ (чистые стены и пол, камера видеонаблюдения, чистые светильники, сложенные тряпки и бутылки с химией)
                                                    </li>
                                                    <li>
                                                        СТРОГО ЗАПРЕЩЕНО прибегать к критике, оценке, высказыванию своего негативного отношения к клиенту и его автомобилю!!!
                                                    </li>
                                                    <li>
                                                        Соблюдать порядок в комнате отдыха (личные вещи убирать в шкаф, продукты питания в холодильник, регулярно выбрасывать мусор, чистая микроволновая печь)
                                                    </li>
                                                    <li>
                                                        Мойщики помимо порядка и чистоты в боксах, убирают мусорные баки на всей территории мойки и следят за чистотой на территории в целом
                                                    </li>
                                                    <li>
                                                        В случае отсутствия администратора мойщик обязан встречать и провожать машину, помогать клиенту правильно заехать в бокс без повреждений автомобиля клиента и автомойки
                                                    </li>
                                                    <li>
                                                        НЕ ВСТАВАТЬ на пороги машин!!! (использовать стремянку)
                                                    </li>
                                                    <li>
                                                        НЕ ИСПОЛЬЗОВАТЬ одну тряпку для разных операций
                                                    </li>
                                                    <li>
                                                        Замшевые тряпки, одну для протирания кузова, другую для сушки
                                                    </li>
                                                    <li>
                                                        Лохматая тряпка (микрофибра) для салона
                                                    </li>
                                                    <li>
                                                        КОНТРОЛЬ автохимии (если что-то заканчивается,  ЗАРАНЕЕ ПРЕДУПРЕДИТЬ АДМИНИСТРАТОРА об этом)
                                                    </li>
                                                    <li>
                                                        Сотрудник должен знать весь ассортимент химии для мойки
                                                    </li>
                                                    <li>
                                                        Прокатывать ДИСКИ, чтобы были чистые
                                                    </li>
                                                    <li>
                                                        Рационально использовать автохимию и аксессуары (стирать  и промывать тряпки, губки после каждой смены, срок их службы должен составлять не менее двух недель)
                                                    </li>
                                                    <li>
                                                        Класть коврик под ноги клиенту
                                                    </li>
                                                    <li>
                                                        Время комплексной мойки не более 40 минут
                                                    </li>
                                                </ul>

                                                <p class="alert alert-danger">
                                                    За несоблюдение регламента автомойщика, администратор в праве наложить штраф на сотрудника (размер будет определяться в зависимости от степени и частоты нарушений).
                                                </p>
                                                <blockquote>
                                                    <p>
                                                        Работа избавляет нас от трех великих зол: скуки, порока, нужды.
                                                    </p>
                                                    <small>Вольтер</small>
                                                </blockquote>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
