import {Component, OnInit} from '@angular/core';
import {RecordsService} from '../../../../services/records.service';

@Component({
    selector: 'app-record-count',
    templateUrl: './record-count.component.html'
})
export class RecordCountComponent implements OnInit {
    public count: number = 0;

    constructor(private service: RecordsService) {
    }

    ngOnInit() {
        this.service.getCount().subscribe((response: any) => {
            this.count = response.data;
        });
    }

}
