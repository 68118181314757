import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

const paymentTypeList = [
    {name: 'Наличка', val: 'cash'},
    {name: 'Картой', val: 'card'},
];

@Component({
    selector: 'app-change-payment-type-modal',
    templateUrl: './change-payment-type-modal.component.html',
    styleUrls: ['./change-payment-type-modal.component.scss']
})
export class ChangePaymentTypeModalComponent implements OnInit {
    order: any;
    currentType: string;
    paymentTypeList: any[] = paymentTypeList;

    constructor(
        private dialogRef: MatDialogRef<ChangePaymentTypeModalComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any,
    ) {
    }

    ngOnInit() {
        if (this.data) {
            this.order = this.data.order || null;
            this.currentType = this.data.currentPaymentType || null;
        }
    }

    close(): void {
        this.dialogRef.close(false);
    }

    success(): void {
        this.dialogRef.close({newPaymentType: this.currentType});
    }

}
