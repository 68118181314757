<h2 class="text-center">{{blockName}}</h2>

<div class="alert alert-danger" *ngIf="showNumberInput">
    <span><b>В записи не указан номер машины - для перевода записи в заказ нужно указать номер машины!</b></span>
</div>

<div class="alert alert-info" *ngIf="showSuccessfulUpdateNumber">
    <span><b>Номер машины успешно добавлен</b></span>
</div>

<div class="row" *ngIf="showNumberInput">
    <form class="col-xs-12" [formGroup]="form" novalidate (submit)="addCarNumberToRecord()">
        <div class="row">
            <app-input-car-number
                [parentForm]="form"
                [inputType]="'carNumberType'"
                [inputNumber]="'carNumber'">
            </app-input-car-number>

            <div class="col-xs-12 col-sm-3">
                <button type="submit" mat-raised-button color="accent" [disabled]="!this.form.valid">
                    Добавить
                </button>
            </div>
        </div>
    </form>
</div>

<div class="d-flex justify-content-around">
    <button mat-raised-button color="warn" (click)="close()">Отменить</button>
    <button mat-raised-button color="accent"
            [disabled]="showNumberInput"
            (click)="success()">
        Подтвердить
    </button>
</div>
