import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AddedServicesComponent} from './added-services/added-services.component';
import {ServiceItemComponent} from './added-services/service-item/service-item.component';
import {ListOfServiceForSelectionComponent} from './list-of-service-for-selection/list-of-service-for-selection.component';
import {SelectGradeComponent} from './list-of-service-for-selection/select-grade/select-grade.component';
import {ServiceListComponent} from './service-list/service-list.component';
import {MaterialModule} from '@app/core/modules/material/material.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ModalListOfServiceForSelectionComponent} from '@shared/components/order/modal-list-of-service-for-selection/modal-list-of-service-for-selection.component';
import {AlreadyAddedServicesComponent} from '@shared/components/order/already-added-services/already-added-services.component';
import { OrderCompleteComponent } from './order-complete/order-complete.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
    ],
    declarations: [
        ListOfServiceForSelectionComponent,
        ModalListOfServiceForSelectionComponent,
        SelectGradeComponent,
        AddedServicesComponent,
        ServiceListComponent,
        ServiceItemComponent,
        AlreadyAddedServicesComponent,
        OrderCompleteComponent,
    ],
    exports: [
        ListOfServiceForSelectionComponent,
        ModalListOfServiceForSelectionComponent,
        SelectGradeComponent,
        AddedServicesComponent,
        ServiceListComponent,
        ServiceItemComponent,
        OrderCompleteComponent,
    ],
    entryComponents: [
        ModalListOfServiceForSelectionComponent,
        AddedServicesComponent,
        SelectGradeComponent,
        OrderCompleteComponent,
    ],
})
export class OrderModule {
}
