import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TextMaskModule} from 'angular2-text-mask';
import {TimePickerModule} from '../time-picker/time-picker.module';
import {BoxStatusComponent} from './box-status/box-status.component';
import {ConfirmComponent} from './confirm/confirm.component';
import {ModalEmployeesListComponent} from './modal-employees-list/modal-employees-list.component';
import {DateTimePickerComponent} from './date-time-picker/date-time-picker.component';
import {ConfirmAskReasonComponent} from './confirm-ask-reason/confirm-ask-reason.component';
import {ChangeBoxComponent} from './change-box/change-box.component';
import {InputCarNumberComponent} from './input-car-number/input-car-number.component';
import {AlertComponent} from './alert/alert.component';
import {CustomerSharesInfoComponent} from './customer-shares-info/customer-shares-info.component';
import {LoaderComponent} from './loader/loader.component';
import {LoaderService} from './loader/loader.service';
import {AutocompleteComponent} from './autocomplete/autocomplete.component';
import {OrderPaymentModalComponent} from './order-payment-modal/order-payment-modal.component';
import {RouterModule} from '@angular/router';
import {SimpleAutocompleteComponent} from './simple-autocomplete/simple-autocomplete.component';
import {MyHammerProvider} from '@app/core/providers/my-hammer.provider';
import {HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import {RevenuePerShiftComponent} from './revenue-per-shift/revenue-per-shift.component';
import {SessionOpenModalComponent} from './shifts/session-open-modal/session-open-modal.component';
import {SessionNeedCloseComponent} from './shifts/session-need-close/session-need-close.component';
import {NotificationsComponent} from './notifications/notifications.component';
import {OrderCreateComponent} from './order-create/order-create.component';
import {MaterialModule} from '@app/core/modules/material/material.module';
import {DirectivesModule} from '../directives/directives.module';
import {SessionEmployeeAssignmentsComponent} from './shifts/session-employee-assignments/session-employee-assignments.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {CustomerPhoneAutocompleteComponent} from './forms/customer-phone-autocomplete/customer-phone-autocomplete.component';
import {SelectableCustomerCarsListComponent} from './forms/selectable-customer-cars-list/selectable-customer-cars-list.component';
import {OrderModule} from '@shared/components/order/order.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        TimePickerModule,
        TextMaskModule,
        MaterialModule,
        DirectivesModule,
        DragDropModule,
        OrderModule,
    ],
    declarations: [
        BoxStatusComponent,
        ConfirmComponent,
        ModalEmployeesListComponent,
        DateTimePickerComponent,
        ConfirmAskReasonComponent,
        ChangeBoxComponent,
        InputCarNumberComponent,
        AlertComponent,
        CustomerSharesInfoComponent,
        LoaderComponent,
        AutocompleteComponent,
        OrderPaymentModalComponent,
        SimpleAutocompleteComponent,
        RevenuePerShiftComponent,
        SessionOpenModalComponent,
        SessionNeedCloseComponent,
        NotificationsComponent,
        OrderCreateComponent,
        SessionEmployeeAssignmentsComponent,
        CustomerPhoneAutocompleteComponent,
        SelectableCustomerCarsListComponent,
    ],
    exports: [
        OrderModule,
        BoxStatusComponent,
        ConfirmComponent,
        ModalEmployeesListComponent,
        DateTimePickerComponent,
        ConfirmAskReasonComponent,
        ChangeBoxComponent,
        InputCarNumberComponent,
        AlertComponent,
        CustomerSharesInfoComponent,
        LoaderComponent,
        AutocompleteComponent,
        OrderPaymentModalComponent,
        SimpleAutocompleteComponent,
        RevenuePerShiftComponent,
        SessionOpenModalComponent,
        SessionNeedCloseComponent,
        NotificationsComponent,
        OrderCreateComponent,
        CustomerPhoneAutocompleteComponent,
        SelectableCustomerCarsListComponent,
    ],
    entryComponents: [
        ConfirmComponent,
        ModalEmployeesListComponent,
        ConfirmAskReasonComponent,
        ChangeBoxComponent,
        InputCarNumberComponent,
        AlertComponent,
        CustomerSharesInfoComponent,
        LoaderComponent,
        AutocompleteComponent,
        OrderPaymentModalComponent,
        SimpleAutocompleteComponent,
        RevenuePerShiftComponent,
        SessionOpenModalComponent,
        SessionNeedCloseComponent,
        NotificationsComponent,
        OrderCreateComponent,
        SessionEmployeeAssignmentsComponent,
        CustomerPhoneAutocompleteComponent,
    ],
    providers: [
        LoaderService,
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: MyHammerProvider
        }
    ],
})
export class SharedComponentsModule {
}
