import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {MENU_CONFIG, MenuConfig, MenuService} from './menu.service';

@NgModule({
    providers: [MenuService]
})
export class MenuModule {
    public constructor(@Optional() @SkipSelf() parentModule: MenuModule) {
        if (parentModule) {
            throw new Error('MenuModule has already been imported.');
        }
    }

    public static forRoot(config: MenuConfig): ModuleWithProviders {
        return {
            ngModule: MenuModule,
            providers: [
                {
                    provide: MENU_CONFIG,
                    useValue: config,
                    multi: true
                }
            ]
        };
    }
}
