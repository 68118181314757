<ul class="nav navbar-nav">
    <li class="dropdown">
        <a href="#" class="dropdown-toggle" data-toggle="dropdown">
            <i class="material-icons">notifications</i>
            <span class="notification">{{notificationData.length}}</span>
            <p class="hidden-lg hidden-md">
                Notifications
                <b class="caret"></b>
            </p>
        </a>

        <ul class="dropdown-menu notification-dropdown" [class.notification-dropdown--show]="openList">
            <li *ngFor="let item of notificationData">
                <div class="notification-item">
                    <span [innerHTML]="item.message"></span>
                    <a (click)="markAsRead($event, item)" mat-mini-fab color="accent">
                        <i class="material-icons">check</i>
                    </a>
                </div>
            </li>
        </ul>
    </li>
</ul>
