import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {routes} from './orders.routes';
import {ListComponent} from './pages/list/list.component';
import {MenuModule} from '@services/menu/menu.module';
import {SharedModule} from '@shared/shared.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TextMaskModule} from 'angular2-text-mask';
import {HttpClientModule} from '@angular/common/http';
import {NewComponent} from './pages/new/new.component';
import {DataSharedResolver} from './services/data-shared-resolver.service';
import {OrderService} from './services/order.service';
import {LastOrdersComponent} from './components/last-orders/last-orders.component';
import {ShopOrderComponent} from './pages/shop-order/shop-order.component';
import {MaterialModule} from '@app/core/modules/material/material.module';
import {ChangePaymentTypeModalComponent} from './components/change-payment-type-modal/change-payment-type-modal.component';
import {CurrentOrderRowComponent} from './components/current-order-row/current-order-row.component';
import {MatPaginatorModule} from '@angular/material/paginator';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MenuModule.forRoot({routes, order: 3}),
        SharedModule,
        MaterialModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        TextMaskModule,
        MatPaginatorModule,
    ],
    declarations: [
        ListComponent,
        NewComponent,
        LastOrdersComponent,
        ShopOrderComponent,
        ChangePaymentTypeModalComponent,
        CurrentOrderRowComponent,
    ],
    entryComponents: [ChangePaymentTypeModalComponent],
    providers: [DataSharedResolver, OrderService]
})
export class OrdersModule {
}
