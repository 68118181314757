<div class="services">
    <h4 class="panel-title">
        Выбранные услуги<sup>*</sup>&nbsp;

        <span *ngIf="showError"
              class="error-msg">
                Нужно выбрать услуги для заказа!
        </span>
    </h4>

    <div class="services__list">
        <div *ngFor="let item of services" [attr.disabled]="!item.active"
             class="service__list-item">
            <app-service-item
                (changeBasePrice)="changeBasePrice($event)"
                (changeEmployee)="changeEmployeeForService($event)"
                (changeSale)="setSaleForServiceItem($event)"
                (remove)="onRemove.emit($event)"
                [item]="item"
            >
            </app-service-item>
        </div>
    </div>

    <div *ngIf="!services.length">
        Нет выбранных услуг
    </div>

    <div *ngIf="serviceTotalCost !== 0" class="services__total">
        <div class="services__total-name">
            <span><b>Итого:</b></span>
        </div>

        <div class=services__total-value>
            <div *ngIf="serviceSaleTotalCost">
                <b>{{serviceSaleTotalCost}} руб.</b>&nbsp;
            </div>
            &nbsp;
            <div [class.old-price]="serviceSaleTotalCost">
                <b>{{serviceTotalCost}} руб.</b>
            </div>
        </div>
    </div>
</div>
