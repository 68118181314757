import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {API_URL} from '../core/constants';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class EmployeesService {
    constructor(private http: HttpClient) {
    }

    public getAll(): Observable<any[]> {
        return this.http.get(`${API_URL}/employees`).pipe(
            map((result: any) => result.data)
        );
    }
}
