import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {routes} from './new-call.routes';
import {MenuModule} from '@services/menu/menu.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NewCallComponent} from './components/new-call/new-call.component';
import {HttpClientModule} from '@angular/common/http';
import {TextMaskModule} from 'angular2-text-mask';
import {SharedModule} from '@shared/shared.module';
import {CallService} from './services/call.service';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        RouterModule.forChild(routes),
        MenuModule.forRoot({routes, order: 9}),
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatCheckboxModule,
        MatIconModule,
        TextMaskModule,
        SharedModule
    ],
    declarations: [NewCallComponent],
    providers: [
        CallService
    ]

})
export class NewCallModule {
}
