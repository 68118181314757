import {Component, OnDestroy, OnInit} from '@angular/core';
import {SessionShiftService} from '@app/core/services/session-shift.service';
import {AuthBaseService} from '@app/core/services/auth-base.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BoxInfoService} from '@services/box-info.service';
import {forkJoin, Subscription} from 'rxjs';
import {LoaderService} from '../../loader/loader.service';
import {EmployeesService} from '@services/employees.service';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {PHOTO_URL} from '@app/core/constants';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-session-employee-assignments',
    templateUrl: './session-employee-assignments.component.html',
    styleUrls: ['./session-employee-assignments.component.scss']
})
export class SessionEmployeeAssignmentsComponent implements OnInit, OnDestroy {
    boxList: any[];
    employeesList: any[];
    form: FormGroup;
    photoUrl: string = PHOTO_URL;
    private subscriptions: Subscription;

    constructor(
        public dialogRef: MatDialogRef<SessionEmployeeAssignmentsComponent>,
        private service: SessionShiftService,
        private authUser: AuthBaseService,
        private fb: FormBuilder,
        private boxService: BoxInfoService,
        private employeeService: EmployeesService,
        private loader: LoaderService,
    ) {
        this.subscriptions = new Subscription();
        this.employeesList = [];
        this.boxList = [];
        this.form = this.fb.group({});

        this.loader.show();
    }

    ngOnInit(): void {
        const fetch$ = forkJoin(this.employeeService.getAll(), this.boxService.getAllBox()).subscribe(([employeesList, boxList]) => {
            this.employeesList = employeesList;
            this.boxList = boxList.map((box: any) => ({...box, newEmployees: [], requireEmployee: box.id !== 5}));
            this.boxList.forEach((box: any) => {
                if (box.id === 5) {
                    // TODO: логику нужно перенести на бек, и хранить данные в таблице, сделать флаг обязательного заполнения работников
                    this.form.addControl(box.id, this.fb.control([]));
                } else {
                    this.form.addControl(box.id, this.fb.control([], Validators.required));
                }
            });

            this.loader.hide();
        });
        this.subscriptions.add(fetch$);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    onSubmit(): void {
        this.updateForm();

        if (this.form.valid) {
            this.loader.show();
            this.boxService.fillTheBoxWithEmployees(this.form.value).subscribe((response: any) => {
                this.loader.hide();

                if (response.success) {
                    this.dialogRef.close({needOpenSession: true});
                }
            });
        }
    }

    boxRequiredToggleHandler(box: any): void {
        box.requireEmployee = !box.requireEmployee;

        if (box.requireEmployee) {
            this.form.controls[box.id].setValidators(Validators.required);
        } else {
            this.form.controls[box.id].clearValidators();
            this.form.controls[box.id].setErrors(null);
        }

        this.form.controls[box.id].updateValueAndValidity();
    }

    drop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(
                event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex
            );
            this.updateForm();
        }
    }

    logout(): void {
        this.authUser.logout();
        window.location.reload();
    }

    private updateForm(): void {
        this.boxList.forEach((box: any) => {
            this.form.controls[box.id].setValue(box.newEmployees);
        });
    }
}
