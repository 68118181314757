import {Injectable} from '@angular/core';
import {DataSharedService} from '../../../services/data-shared.service';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';

@Injectable()
export class DataSharedResolver implements Resolve<any>{

    constructor(private service: DataSharedService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        return this.service.getRouterData();
    }

}
