import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {routes} from './boxes.routes';
import {BoxesComponent} from './components/boxes/boxes.component';
import {BoxViewComponent} from './components/box-view/box-view.component';
import {TimeInfoComponent} from './components/box-view/components/time-info/time-info.component';
import {AutoNumberComponent} from './components/box-view/components/auto-number/auto-number.component';
import {SharedModule} from '@shared/shared.module';
import {MenuModule} from '@services/menu/menu.module';
import {ServiceSaleComponent} from './components/box-view/components/service-sale/service-sale.component';
import {OrderSaleComponent} from './components/box-view/components/order-sale/order-sale.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CustomerSharesComponent} from './components/box-view/components/customer-shares/customer-shares.component';
import {ShareGroupComponent} from './components/box-view/components/customer-shares/share-group/share-group.component';
import {ListEmployeeOfThisOrderComponent} from './components/box-view/components/list-employee-of-this-order/list-employee-of-this-order.component';
import {ChangePriceModalComponent} from '@app/states/boxes/components/box-view/components/change-price-modal/change-price-modal.component';
import {ServiceInfoComponent} from './components/box-view/components/service-info/service-info.component';
import {ServiceInfoDirective} from './components/box-view/components/service-info/service-info.directive';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatRadioModule} from '@angular/material/radio';
import {MatInputModule} from '@angular/material/input';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MenuModule.forRoot({routes, order: 4}),
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        MatListModule,
        MatIconModule,
        MatCardModule,
        MatButtonModule,
        MatRadioModule,
        MatInputModule,
        MatTooltipModule
    ],
    declarations: [
        BoxesComponent,
        BoxViewComponent,
        TimeInfoComponent,
        AutoNumberComponent,
        ServiceSaleComponent,
        OrderSaleComponent,
        CustomerSharesComponent,
        ShareGroupComponent,
        ListEmployeeOfThisOrderComponent,
        ChangePriceModalComponent,
        ServiceInfoComponent,
        ServiceInfoDirective,
    ],
    entryComponents: [
        ServiceSaleComponent,
        OrderSaleComponent,
        ChangePriceModalComponent,
        ServiceInfoComponent,
    ],
    providers: []
})
export class BoxesModule {
}
