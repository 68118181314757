import {LocalStorageService} from './services/local-storage.service';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {routes} from './todo.routes';
import {TodoComponent} from './components/todo/todo.component';
import {MenuModule} from '@services/menu/menu.module';
import {MatListModule} from '@angular/material/list';
import {MatInputModule} from '@angular/material/input';
import {StorageService} from './services/storage.service';
import { FilterPipe } from './components/todo/filter.pipe';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MenuModule.forRoot({routes, order: 10}),
        MatButtonModule,
        MatListModule,
        MatInputModule,
        MatIconModule
    ],
    declarations: [TodoComponent, FilterPipe],
    providers: [
        LocalStorageService,
        {provide: StorageService, useClass: LocalStorageService}
    ]
})
export class TodoModule {
}
