<div class="table-responsive">
    <table mat-table #table [dataSource]="dataSource" class="table table-price-list" matSort>
        <!-- Name Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Названия услуги</th>
            <td mat-cell *matCellDef="let element">
                {{element.name}}
            </td>
        </ng-container>

        <!-- CostAllGrades Column -->
        <div *ngIf="grades.length">
            <ng-container
                *ngFor="let grade of grades; let i = index;"
                [matColumnDef]="'grade-' + grade.id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{grade.name}}</th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.grades[i]">{{element.grades[i].price}} руб.</span>
                </td>
            </ng-container>
        </div>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
