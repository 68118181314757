import {Component, OnInit} from '@angular/core';
import {LoaderService} from '@shared/components/loader/loader.service';
import {BoxInfoService} from '@services/box-info.service';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-express-add',
    templateUrl: './express-add.component.html',
})
export class ExpressAddComponent implements OnInit {
    constructor(
        private snackBar: MatSnackBar,
        private loader: LoaderService,
        private boxInfoService: BoxInfoService,
        private router: Router,
    ) {
    }

    ngOnInit() {
    }

    public onSubmit(data: any) {
        this.loader.show();
        this.boxInfoService.addExpressOrder(data.formValue).subscribe((response: any) => {
            if (response.success) {
                this.loader.hide();
                this.snackBar.open(response.message, '', {
                    duration: 1000,
                    verticalPosition: 'top',
                    horizontalPosition: 'right'
                });

                if (response.success) {
                    this.router.navigate(['/']);
                }
            }
        });
    }
}
