import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';

@Component({
    selector: 'app-autocomplete',
    templateUrl: './autocomplete.component.html',
    styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteComponent implements OnInit, OnDestroy {
    // @ViewChild('input') input: ElementRef;
    @Input() parentForm: FormGroup;
    @Input() controlName: string;
    @Input() data: Observable<any[]>;
    @Input() objectKey: string;
    @Input() placeholder: string;
    @Output() search: EventEmitter<any> = new EventEmitter<any>();
    public showItems: boolean = false;
    private sub: Subscription;

    constructor() {
    }

    ngOnInit() {
        this.sub = this.data.subscribe((result: any) => {
            this.showItems = !!result.length;
        });

        // Observable.fromEvent(this.input.nativeElement, 'keyup')
        //     .debounceTime(300)
        //     .distinctUntilChanged()
        //     .subscribe((event: any) => {
        //         this.showItems = true;
        //         console.log(event.target.value);
        //     });
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    public selectItem(item: any) {
        this.showItems = false;
        this.parentForm.patchValue(
            {
                [this.controlName]: item[this.objectKey]
            },
            {emitEvent: false}
        );
    }

}
