<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xs-12">
                <div class="card">
                    <div class="card-header card-header-icon" data-background-color="rose">
                        <i class="material-icons">phone</i>
                    </div>

                    <div class="card-content">
                        <h4 class="card-title">Новый звонок</h4>

                        <form class="form-horizontal" [formGroup]="form" novalidate>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-xs-12">
                                        <mat-form-field class="full-width form__phone">
                                            <mat-label>Номер телефона</mat-label>
                                            <input type="tel" matInput placeholder="+7 ___ ___-__-__"
                                                   [textMask]="{mask: maskPhone}"
                                                   formControlName="phone"
                                                   appAutofocus
                                                   phonePrefix>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-xs-12 col-sm-6">
                                    <app-date-time-picker
                                        [label]="'Памятка о перезвоне'"
                                        [datePlaceholder]="'Дата'"
                                        [timePlaceholder]="'Время'"
                                        (change)="onChangeDate($event)">
                                    </app-date-time-picker>
                                </div>

                                <div class="col-xs-12 col-sm-6 text-right">
                                    <br>
                                    <button mat-raised-button color="accent" (click)="addComment()">
                                        <i class="material-icons">add</i>Добавить комментарий
                                    </button>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-xs-12 col-sm-6">
                                    <div class="form__info" formArrayName="info">
                                        <mat-checkbox class="d-block form__info-item"
                                                      *ngFor="let item of infoList; let i = index"
                                                      [value]="item"
                                                      [formControlName]="i">
                                            {{ item }}
                                        </mat-checkbox>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6">
                                    <div class="form__comments" formArrayName="comments">
                                        <div *ngFor="let comment of comments.controls; index as i">
                                            <mat-form-field class="full-width mat-input-mini" floatLabel="never">
                                                <input matInput placeholder="Комментарий" [formControlName]="i">
                                                <mat-icon matSuffix (click)="deleteComment(i)">delete</mat-icon>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="text-right">
                                <button mat-raised-button color="accent" [disabled]="!form.valid"
                                        (click)="onSubmit()">
                                    Добавить
                                </button>
                            </div>
                        </form>
                    </div>
                </div>

                <br>
                <br>

                <div class="alert">
                    <h4>Памятка</h4>
                    <ul>
                        <li>Поздороваться</li>
                        <li>Представиться</li>
                        <li>Спросить чем помочь?</li>
                        <li>Решить вопрос</li>
                        <li>Попрощаться</li>
                        <li>Сделать заметку о перезвоне если надо</li>
                    </ul>
                    <br>
                </div>
            </div>
        </div>
    </div>
</div>
