import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {routes} from './records.routes';
import {MenuModule} from '@services/menu/menu.module';
import {SharedModule} from '@shared/shared.module';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TextMaskModule} from 'angular2-text-mask';
import {ConfirmTransferComponent} from './components/current-records/components/confirm-transfer/confirm-transfer.component';
import {FormRecordComponent} from './components/form-record/form-record.component';
import {CurrentRecordsComponent} from './components/current-records/current-records.component';
import {RecordsService} from './services/records.service';
import {MatSelectModule} from '@angular/material/select';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MenuModule.forRoot({routes, order: 5}),
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatCardModule,
        MatSortModule,
        MatTableModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        SharedModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        TextMaskModule,
    ],
    declarations: [
        FormRecordComponent,
        CurrentRecordsComponent,
        ConfirmTransferComponent
    ],
    exports: [],
    entryComponents: [
        ConfirmTransferComponent
    ],
    providers: [
        RecordsService
    ]
})
export class RecordsModule {}
