import * as Hammer from 'hammerjs';
import {HammerGestureConfig} from '@angular/platform-browser';

export class MyHammerProvider extends HammerGestureConfig {
    overrides = <any>{
        'swipe': {
            threshold: 10,
            velocity: 0.3,
            direction: Hammer.DIRECTION_ALL
        },
        'pan': {
            threshold: 20,
            direction: Hammer.DIRECTION_ALL
        },
    };

    buildHammer(element: HTMLElement) {
        return new Hammer(element, {
            touchAction: 'pan-y'
        });
    }
}
