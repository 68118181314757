import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {DATE_TIME_FORMAT} from '../../../../../../core/constants';
// import * as moment from 'moment';

// TODO: хак что бы обмануть компилятор тайп скрипта, разобраться как исправить
declare var moment: any;

@Component({
    selector: 'app-time-info',
    templateUrl: './time-info.component.html',
    styleUrls: ['./time-info.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TimeInfoComponent implements OnInit, OnChanges, OnDestroy {
    @Input() start: any = '';
    @Input() end: any = '';
    @Input() avgTime: number = 0;
    public startTime: any = '';
    public endTime: any = '';
    public diff: any = '';
    public estimatedEndTime: any = '';
    public attention: boolean = false;
    private interval: any = null;

    constructor() {
    }

    ngOnInit() {
        if (this.start) {
            this.startTime = moment(this.start).format(DATE_TIME_FORMAT);
        }
        if (this.end) {
            this.endTime = moment(this.end).format(DATE_TIME_FORMAT);
        }
        this.calculateEstimateTime();
        this.calculateDiff();
        this.interval = setInterval(() => {
            this.calculateDiff();
        }, 2000);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.calculateEstimateTime();
        this.calculateDiff();
    }

    ngOnDestroy(): void {
        clearTimeout(this.interval);
    }

    private calculateDiff() {
        this.diff = moment.preciseDiff(moment(this.start), moment(), true);
        this.checkOnSuspiciousAction();
    }

    private calculateEstimateTime() {
        this.estimatedEndTime = moment(this.start).add(this.avgTime, 's').format(DATE_TIME_FORMAT);
    }

    private checkOnSuspiciousAction() {
        const end = moment(this.start).add(this.avgTime, 's');
        const now = moment();
        const result = end.diff(now);

        this.attention = result < 0;
    }
}
