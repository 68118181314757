import {Injectable} from '@angular/core';

@Injectable()
export class DataSharedService {
    private ROUTE_KEY: string = 'DATA_SHARED_ROUTER_KEY';
    private routerData: any = null;

    constructor() {
    }

    public resetRouterData(): void {
        window.localStorage.removeItem(this.ROUTE_KEY);
        this.routerData = null;
    }

    public getRouterData(): any {
        this.loadData();

        return this.routerData;
    }

    public setRouterData(data: any): void {
        window.localStorage.setItem(this.ROUTE_KEY, JSON.stringify(data));
        this.routerData = data;
    }

    private loadData(): void {
        this.routerData = JSON.parse(window.localStorage.getItem(this.ROUTE_KEY));
        // console.log(this.routerData);
    }
}
