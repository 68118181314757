<form [formGroup]="form" (submit)="openShift()">
    <h1 mat-dialog-title>Открытия смены</h1>
    <div mat-dialog-content class="session__content">
        <p><strong>Для работы приложения откройте смену</strong></p>
        <p>
            Смену открывает: {{user.name}}
        </p>
        <mat-checkbox formControlName="isNight">Это ночная смена!</mat-checkbox>

        <hr>
        <div><strong>Выбор персонала на смену</strong></div>

        <div>
            <mat-form-field>
                <select matNativeControl formControlName="cashier" title="Список кассиров">
                    <option value="">Выбрать кассира</option>
                    <option *ngFor="let item of cashiersList | async" [value]="item.id">{{item.fio}}</option>
                </select>
                <mat-error *ngIf="form.controls.cashier.hasError('required')">Нужно выбрать кассира</mat-error>
            </mat-form-field>
        </div>

        <div>
            <mat-form-field>
                <select matNativeControl formControlName="administrator" title="Список кассиров">
                    <option value="">Выбрать администратора</option>
                    <option *ngFor="let item of administratorsList | async" [value]="item.id">{{item.fio}}</option>
                </select>
                <mat-error *ngIf="form.controls.administrator.hasError('required')">
                    Нужно выбрать администратора
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <div mat-dialog-actions class="d-flex justify-content-between">
        <button type="submit" mat-raised-button color="accent" cdkFocusInitial>Открыть</button>
        <button type="button" mat-raised-button color="warn" (click)="logout()">
            Выход из программы
        </button>
    </div>
</form>
