import {DashboardMenuService} from './dashboard-menu.service';

const MenuServiceFactory = () => {
  return new DashboardMenuService();
};


export const DashboardMenuServiceProvider = {
  provide: DashboardMenuService,
  useFactory: MenuServiceFactory,
  deps: []
};
