<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xs-12">
                <div class="card">
                    <div class="card-header card-header-icon" data-background-color="purple">
                        <i class="material-icons">assignment</i>
                    </div>

                    <div class="card-content">
                        <h4 class="card-title">Добавления нового заказа</h4>

                        <app-order-create
                            [client]="customer"
                            (onSubmit)="onSubmit($event)">
                        </app-order-create>
                    </div>
                </div>

                <div *ngIf="customer">
                    <br>
                    <app-customer-shares-info [customerId]="customer.id"></app-customer-shares-info>
                </div>
            </div>
        </div>
    </div>
</div>
