import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {API_URL} from '../../../core/constants';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';


@Injectable()
export class RecordsService {
    constructor(private http: HttpClient) {
    }

    public getDataList(): Observable<any[]> {
        return this.http
            .get(`${API_URL}/records`)
            .pipe(
                map((result: any) => result.data)
            );
    }

    public getRecordById(id: number): Observable<any> {
        return this.http.get(`${API_URL}/records/${id}`)
            .pipe(
                map((response: any) => response.data)
            );
    }

    public create(data: any): Observable<any> {
        return this.http.post(`${API_URL}/records`, data);
    }

    public edit(id: number, data: any): Observable<any> {
        return this.http.put(`${API_URL}/records/${id}`, data);
    }

    public removeRecord(id: number): Observable<any> {
        return this.http.delete(`${API_URL}/records/${id}`);
    }

    public addCarNumber(recordId: number, data: any): Observable<any> {
        return this.http.patch(`${API_URL}/records/${recordId}/addCarNumber`, data);
    }

    public transferToOrder(recordId: number): Observable<any> {
        return this.http.post(`${API_URL}/orders/createBasedOnRecord`, {recordId});
    }
}
