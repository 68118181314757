<h4 mat-dialog-title class="text-center">Список сотрудников</h4>
<mat-dialog-content>
    <table mat-table #table [dataSource]="dataSource" class="table modal-employees-table">
        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()"
                              (change)="$event ? selection.toggle(row) : null"
                              [checked]="selection.isSelected(row)">
                </mat-checkbox>
            </td>
        </ng-container>

        <ng-container matColumnDef="fio">
            <th mat-header-cell *matHeaderCellDef>Имя Фамилия</th>
            <td mat-cell *matCellDef="let element"
                title="{{element.surname}} {{element.name}} {{element.patronymic}}">
                <!--img *ngIf="element.photo" [src]="photoUrl + '/' + element.photo"
                     class="img-show-on-hover img-circle img-cover img-position-top-center"-->
                <img *ngIf="element.photo" [src]="photoUrl + '/' + element.photo"
                     width="40"
                     height="40"
                     class="img-circle img-cover img-position-top-center">
                {{element.name}} {{element.surname}}
            </td>
        </ng-container>

        <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef>Должность</th>
            <td mat-cell *matCellDef="let element"> {{element.position.name}}</td>
        </ng-container>

        <ng-container matColumnDef="salary">
            <th mat-header-cell *matHeaderCellDef>ЗП за день</th>
            <td mat-cell *matCellDef="let element">
                <div>
                    <div *ngIf="element.current_day_salary">
                        Полная сумма: {{element.current_day_salary?.total_sum | number:'1.0-0'}}₽
                    </div>
                    <div *ngIf="element.current_day_salary">
                        С учетом скидки: {{element.current_day_salary?.total_sum_sale | number:'1.0-0'}}₽
                    </div>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            (click)="selection.toggle(row)">
        </tr>
    </table>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-between">
    <button mat-raised-button color="accent" (click)="close()">Отменить</button>
    <button mat-raised-button class="btn btn-fill btn-rose" (click)="successClose()">Применить</button>
</mat-dialog-actions>
