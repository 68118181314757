import {Injectable, Injector} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {API_URL} from '@app/core/constants';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AuthBaseService {
    readonly user$: BehaviorSubject<any>;
    private token: string;
    private user: any;

    constructor(private inj: Injector) {
        this.token = '';
        this.user = null;
        this.user$ = new BehaviorSubject(this.user);
    }

    public init() {
        this.loadToken();
        this.loadUser();
    }

    public authorize(token: string): void {
        this.setToken(token);
        //this.inj.get(Router).navigate(['/dashboard']);
    }

    public logout() {
        if (this.token) {
            this.inj.get(HttpClient).post(`${API_URL}/logout`, {}).subscribe();
        }

        this.clearToken();
        this.inj.get(Router).navigate(['/auth/login']);
    }

    public isAuth(): boolean {
        return !!this.token;
    }

    public getToken(): string {
        return this.token;
    }

    public getUser(): any {
        return this.user;
    }

    public getUserSubject(): BehaviorSubject<any> {
        return this.user$;
    }

    public setUser(value: any) {
        this.user = value;
        window.localStorage.setItem('user', JSON.stringify(value));
        this.user$.next(this.user);
    }

    private setToken(token: string): void {
        this.token = `Bearer ${token}`;
        window.localStorage.setItem('token', this.token);
    }

    private loadToken(): void {
        this.token = window.localStorage.getItem('token') || '';
    }

    private clearToken() {
        window.localStorage.removeItem('token');
        this.token = '';
    }

    private loadUser(): void {
        this.user = JSON.parse(window.localStorage.getItem('user') || null);
        this.user$.next(this.user);
    }
}
