<h2 mat-dialog-title class="text-center">Список предоставляемых услуг</h2>

<div>
    <app-already-added-services [listAdded]="alreadyAddedServices"></app-already-added-services>
    <br>
    <mat-form-field class="full-width" [floatLabel]="'always'">
        <mat-label>Быстрый поиск</mat-label>
        <input matInput placeholder="Введите названия услуги для поиска" [formControl]="searchTerm"
               autocomplete="off">
    </mat-form-field>
</div>

<mat-dialog-content>
    <div *ngIf="isLoading" class="spinner-wrap">
        <mat-spinner></mat-spinner>
    </div>

    <app-added-services
        [showError]="false"
        [services]="selectedServices"
        (onUpdate)="updateItemFromSelectedServices($event)"
        (onRemove)="removeItemFromSelectedServices($event)"></app-added-services>
    <br>

    <br>
    <div class="form-group">
        <div class="row">
            <div class="col-xs-12">
                <div *ngIf="servicesForSearch.length">
                    <app-service-list
                        [items]="servicesForSearch"
                        [showCategory]="true"
                        (onSelect)="selectServiceHandler($event)">
                    </app-service-list>
                </div>
            </div>

            <div class="col-xs-12" [hidden]="!!searchTerm.value">
                <mat-selection-list class="clearfix services-mat-selection-list">
                    <mat-tab-group class="services-mat-selection-list-group">
                        <mat-tab label="Популярные услуги">
                            <app-service-list
                                [items]="servicesPopular"
                                [showCategory]="true"
                                (onSelect)="selectServiceHandler($event)"></app-service-list>
                        </mat-tab>

                        <mat-tab
                            *ngFor="let parentCategory of servicesTabs"
                            [label]="parentCategory.name">
                            <div *ngFor="let category of parentCategory.children">
                                <div class="col-xs-12">
                                    <h5>{{category.name}}</h5>
                                    <hr>
                                </div>

                                <app-service-list
                                    [items]="category.services"
                                    (onSelect)="selectServiceHandler($event)"></app-service-list>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </mat-selection-list>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions class="justify-content-between">
    <button type="button" mat-raised-button color="accent" (click)="cancel()">Закрыть</button>
    <button type="button" mat-raised-button color="accent"
            [disabled]="!selectedServices.length"
            (click)="successClose()">
        Добавить
    </button>
</mat-dialog-actions>

