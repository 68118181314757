<h1 mat-dialog-title class="text-center">
    Выручка за смену ({{shiftState.isNightNow ? 'Ночь' : 'День'}})
</h1>

<div mat-dialog-content>
    <h4>Начало смены: {{shiftState.session.created_at}}</h4>

    <ng-container *ngIf="categories">
        <h4>Информация по категориям</h4>

        <div class="category__item" *ngFor="let category of categories">
            <div class="category_name">{{category.name}}</div>
            <div class="category__body">
                <div class="category__total-sum">Общая сумма: {{category.sum}} ₽</div>

                <div class="category__children" *ngIf="category.category_id === 83">
                    Подкатегории:

                    <div class="category__item category__item--sub" *ngFor="let subCategory of category.childrens">
                        <div class="category_name">{{subCategory.name}} - {{subCategory.sum}} ₽</div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="expenses && expenses.items.length">
        <h4>Расходы</h4>

        <div class="category__item" *ngFor="let expense of expenses.items">
            <div class="category_name">{{expense.payment_purpose}} - {{expense.sum}} ₽</div>
        </div>
    </ng-container>

    <br>
    <h4>Итого</h4>
    <table class="table" *ngIf="totals">
        <tr *ngIf="expenses">
            <td>Расходов</td>
            <td>{{expenses.sum || 0}} ₽</td>
        </tr>
        <tr>
            <td>Наличка</td>
            <td>{{totals.sum_cash || 0}} ₽</td>
        </tr>
        <tr>
            <td>Карта</td>
            <td>{{totals.sum_card || 0}} ₽</td>
        </tr>
        <tr>
            <td>Безнал</td>
            <td>{{totals.sum_legal || 0}} ₽</td>
        </tr>
        <tr>
            <td><strong>Всего</strong></td>
            <td>
                <strong>{{totals.sum || 0}} ₽</strong>
            </td>
        </tr>
    </table>
</div>

<div mat-dialog-actions>
    <button mat-raised-button (click)="dialogRef.close()" color="warn">Закрыть</button>
</div>
