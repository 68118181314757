import {ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Customer} from '@app/entities/Customer';
import {Subscription} from 'rxjs';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {API_URL} from '@app/core/constants';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {OrderInputData} from '@shared/components/order-payment-modal/order-input-data';
import {UIMangerService} from '@services/ui-manger.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface Cash {
    id: number;
    type: string;
    name: string;
    color: string;
    sort: number;
    can_split_payment: number;
    balance: number;
}

export interface FormCashItem {
    id: number;
    sum: number;
    alreadyPaid: boolean;
    canSplitPayment: boolean;
}

@Component({
    selector: 'app-order-payment-modal',
    templateUrl: './order-payment-modal.component.html',
    styleUrls: ['./order-payment-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderPaymentModalComponent implements OnInit, OnDestroy {
    form: FormGroup;
    cashesList: Cash[] = [];
    customerType: number;
    isCustomerTypeLegal: boolean;
    orderId: number;
    sum: number;
    n6carWash: boolean;
    showLoader = false;

    private subscriptions: Subscription = new Subscription();

    constructor(
        public dialogRef: MatDialogRef<OrderPaymentModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: OrderInputData,
        private fb: FormBuilder,
        private http: HttpClient,
        private ui: UIMangerService,
    ) {
        this.orderId = this.data.orderId;
        this.sum = (this.data && this.data.sum) ? this.data.sum : 0;
        this.customerType = (this.data && this.data.customerType) ? this.data.customerType : Customer.TYPE_PHYSICAL;
        this.isCustomerTypeLegal = this.customerType === Customer.TYPE_LEGAL;
        this.n6carWash = false;

        this.form = this.fb.group({
            sentTo1c: {value: false, disabled: true},
            cashes: this.fb.array([this.createCashItem()]),
        });
    }

    get formCashes(): FormArray {
        return <FormArray>this.form.get('cashes');
    }

    /**
     * Возвращает список касс, с учетом фильтрации
     */
    get cashes(): Cash[] {
        if (this.formCashes.controls.length <= 1) {
            return this.cashesList;
        }

        return this.cashesList.filter(cash => cash.type !== 'legal' || cash.can_split_payment === 1);
    }

    /**
     * Общая сумма в кассах для оплаты
     */
    get totalSum(): number {
        let totalSum = 0;
        const items = this.form.get('cashes') as FormArray;
        items.controls.forEach(control => {
            const val = control.get('sum').value;

            if (val) {
                totalSum += val;
            }
        });

        return totalSum;
    }

    async ngOnInit() {
        // Слушаем изменения формы, для блокоровки/разблокировки чекбокса "отправки в 1С"
        this.form.controls.cashes.valueChanges.subscribe(() => {
            if (this.totalSum === this.sum) {
                this.form.get('sentTo1c').patchValue(true, {emitEvent: false});
                this.form.get('sentTo1c').enable({emitEvent: false});
            } else {
                this.form.get('sentTo1c').patchValue(false, {emitEvent: false});
                this.form.get('sentTo1c').disable({emitEvent: false});
            }
        });

        const cashesResponse = await this.getCashes();

        if (cashesResponse.success) {
            this.cashesList = cashesResponse.data;
        }

        await this.checkExistPayments();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    getCashes(): Promise<any> {
        return this.http.get(`${API_URL}/cashes`).toPromise();
    }

    close(): void {
        this.dialogRef.close({success: false});
    }

    /**
     * Оплата заказа
     */
    success(): void {
        if (this.form.valid) {
            this.showLoader = true;
            this.http.post(`${API_URL}/cashes/addPay`, {
                order_id: this.orderId,
                cashes: this.form.value.cashes.filter(cash => cash.id !== '' && cash.alreadyPaid === false),
                sentTo1c: this.form.value.sentTo1c,
                cashOperationType: this.data.cashOperationType,
            }).subscribe(
                (response: any) => {
                    this.showLoader = false;

                    if (response.success) {
                        this.dialogRef.close({
                            success: true,
                            order: response.data.order,
                        });
                    }
                },
                (error: HttpErrorResponse) => {
                    this.ui.showSnackBar(error.error.message);
                    this.showLoader = false;
                }
            );
        }
    }

    /**
     * Обновляет/заполняет выбранную кассу данными
     */
    updateCashItem(item: AbstractControl): void {
        // После изменения значения в селекте устанавливаем по дефолту инпут цены включенным
        item.get('sum').enable();

        // Устнавливаем автоматом цену только в том случае если цена еще не была изменена
        if (item.get('sum').value === 0) {
            item.get('sum').patchValue(this.sum - this.totalSum, {emitEvent: true});
        }

        // TODO: Это плохое решение, нужно придумать лучше, возможно нужно использовать Map
        const cash = this.cashesList.find(cashItem => cashItem.id === item.get('id').value);

        // Если касса безнальная и нет возможности раздельной оплаты тогда запрещаем добавление новой кассы
        if (cash.type === 'legal' && cash.can_split_payment === 0) {
            item.get('sum').disable();
            item.get('sum').patchValue(this.sum, {emitEvent: true});
            return;
        }

        // Если сумма в кассе меньше ститоимость заказа тогда автоматически добавим новую кассу в UI
        if (this.sum > this.totalSum) {
            const items = this.form.get('cashes') as FormArray;
            items.push(this.createCashItem());
            this.setClearValidatorsFromCaches();
        }
    }

    /**
     * Проверяет или текущая сумма не привышает сумму которою нужно оплатить.
     * Показывает или удаляет поле для выбора кассы.
     */
    checkTotalSum() {
        const items = this.form.get('cashes') as FormArray;

        if (this.totalSum >= this.sum) {
            items.controls = items.controls.filter((control: AbstractControl) => {
                return control.get('id').value !== '';
            });
        } else {
            const emptyControl = items.controls.find((control: AbstractControl) => {
                return control.get('id').value === '';
            });

            if (emptyControl === undefined) {
                items.push(this.createCashItem());
            }
        }

        this.setClearValidatorsFromCaches();
    }

    /**
     * Удаление кассы из форм билдера
     */
    removeCashItem(itemIndex: number) {
        const cashes = this.form.get('cashes') as FormArray;
        cashes.controls = cashes.controls.filter((item, idx) => idx !== itemIndex);
        this.checkTotalSum();
    }

    /**
     * Создает объект кассы для форм билдера
     */
    private createCashItem(cashId: number | string = '', sum: number | string = 0, alreadyPaid: boolean = false): FormGroup {
        return this.fb.group({
            id: [cashId, [Validators.required]],
            sum: [{value: sum, disabled: true}, [Validators.required]],
            alreadyPaid: alreadyPaid,
            canSplitPayment: false,
        });
    }

    /**
     * Устанавливает валидоторы на импуты выбора касс по условию
     */
    private setClearValidatorsFromCaches() {
        setTimeout(() => {
            const cashes = this.form.get('cashes') as FormArray;

            cashes.controls.forEach((cash) => {
                cash.get('id').setValidators([Validators.required]);
                cash.get('id').updateValueAndValidity();
                cash.get('sum').setValidators([Validators.required]);
                cash.get('sum').updateValueAndValidity();
            });

            // Очистим валидоторы для послдней добавленной кассы
            if (cashes.controls.length > 1) {
                const lastCashIndex = cashes.controls.length - 1;
                cashes.controls[lastCashIndex].get('id').clearValidators();
                cashes.controls[lastCashIndex].get('id').updateValueAndValidity();
                cashes.controls[lastCashIndex].get('sum').clearValidators();
                cashes.controls[lastCashIndex].get('sum').updateValueAndValidity();
            }
        });
    }

    /**
     * Проверяет по API существующие платежи для заказа
     */
    private async checkExistPayments() {
        const response: any = await this.http.get(`${API_URL}/cashes/checkPay/${this.orderId}`).toPromise();

        if (!response.success) {
            return;
        }

        const cashes = this.form.get('cashes') as FormArray;
        cashes.controls = [];

        response.data.list.forEach((item) => cashes.push(this.createCashItem(item.cash_id, item.sum, true)));
        cashes.push(this.createCashItem());
    }
}
