<div class="row">
    <label class="col-xs-12">{{label}}</label>
</div>
<div class="row">
    <mat-form-field class="col-xs-6">
        <input
            matInput
            readonly
            [matDatepicker]="picker"
            (click)="picker.open()"
            (dateChange)="dateChange($event)"
            [value]="frontDate"
            [placeholder]="datePlaceholder"
            [title]="datePlaceholder"
            [min]="minDate"
            [max]="maxDate"
        >
        <mat-datepicker touchUi="true" #picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="col-xs-6">
        <input
            readonly
            matInput
            (click)="onTimeChange()"
            [value]="frontTime"
            [placeholder]="timePlaceholder"
            [title]="timePlaceholder">
    </mat-form-field>
</div>
