<div *ngIf="shares.length">
    <mat-card>
        <mat-card-content>
            <h5>Бонусы клиента</h5>

            <app-share-group *ngFor="let carGroup of shares; trackBy: tackFn"
                             [group]="carGroup"
                             [currentOrderServices]="currentOrderServices"
                             (addSale)="onAddSale($event)"
                             (addServiceSale)="onAddServiceSale($event)"
            ></app-share-group>
        </mat-card-content>
    </mat-card>
</div>
