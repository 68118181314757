import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {API_URL} from '../core/constants';
import {Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable()
export class CompanyServiceListService {
    private data: any[] = [];

    constructor(private http: HttpClient) {
    }

    public getDataList(): Observable<any[]> {
        if (this.data.length) {
            return of(this.data);
        } else {
            return this.loadData().pipe(
                tap((result: any[]) => {
                    this.data = result;
                })
            );
        }
    }

    public removeRecord(id: number) {
        this.data = this.data.filter((item: TableRowCompanyService) => item.id !== id);
    }

    private loadData(): Observable<any[]> {
        return this.http.get<any[]>(`${API_URL}/pricelist`);
    }
}

export interface TableRowCompanyService {
    id: number;
    name: string;
    cost: string;
    status: boolean;
    responsiblePeople: object[];
    isEditing: boolean;
}

