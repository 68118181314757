import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {StatesModule} from './states/states.module';
import {MenuModule} from '@services/menu/menu.module';
import {SidebarModule} from '@shared/sidebar/sidebar.module';
import {NavbarModule} from '@shared/navbar/navbar.module';
import {SharedModule} from '@shared/shared.module';
import {ServicesModule} from '@services/services.module';
import {routes} from './app.routes';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {AppComponent} from './components/app/app.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {BoxStatusBarComponent} from './components/dashboard/components/box-status-bar/box-status-bar.component';
import localeRu from '@angular/common/locales/ru';
import {registerLocaleData} from '@angular/common';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {GLOBAL_DATE_FORMATS} from './core/constants';
import {AuthLayoutComponent} from './layouts/auth-layout/auth-layout.component';
import {ManagerLayoutComponent} from './layouts/manager-layout/manager-layout.component';
import {PaginatorIntl} from './core/providers/map-paginator-intl';
import {UnprocessedOrdersComponent} from './components/dashboard/components/unprocessed-orders/unprocessed-orders.component';
import {RecordCountComponent} from './components/dashboard/components/record-count/record-count.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthInterceptorService} from './core/services/auth-interceptor.service';
import {AuthBaseService} from './core/services/auth-base.service';
import {ManagerGuard} from './core/guards/manager.guard';
import {TiresStatusBarComponent} from './components/dashboard/components/tires-status-bar/tires-status-bar.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {SessionShiftService} from './core/services/session-shift.service';
import {onInitProvider} from './core/providers/on-init.provider';
import {SaleActionCheckComponent} from './components/sale-action-check/sale-action-check.component';
import {MaterialModule} from './core/modules/material/material.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UIMangerService} from '@services/ui-manger.service';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';

registerLocaleData(localeRu);

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(routes, {useHash: false, enableTracing: false}),
        HttpClientModule,
        MenuModule,
        StatesModule,
        SidebarModule,
        NavbarModule,
        SharedModule,
        ServicesModule,
        MatProgressSpinnerModule,
        ServiceWorkerModule.register('/ngsw-worker.js', {enabled: environment.production}),
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ],
    declarations: [
        PageNotFoundComponent,
        AppComponent,
        AuthLayoutComponent,
        ManagerLayoutComponent,
        DashboardComponent,
        BoxStatusBarComponent,
        TiresStatusBarComponent,
        UnprocessedOrdersComponent,
        RecordCountComponent,
        SaleActionCheckComponent,
    ],
    exports: [
        AuthLayoutComponent,
        ManagerLayoutComponent
    ],
    providers: [
        {provide: APP_INITIALIZER, useFactory: onInitProvider, deps: [AuthBaseService, SessionShiftService, MatDialog], multi: true},
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            deps: [AuthBaseService, UIMangerService, SessionShiftService],
            multi: true
        },
        {provide: MAT_DATE_LOCALE, useValue: 'ru'},
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: GLOBAL_DATE_FORMATS},
        {provide: MatPaginatorIntl, useClass: PaginatorIntl},
        ManagerGuard,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
