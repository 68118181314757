import {Component, Inject, OnInit} from '@angular/core';
import {EmployeesService} from '@services/employees.service';
import {SelectionModel} from '@angular/cdk/collections';
import {pluck} from '@app/Helpers/Helper';
import {PHOTO_URL} from '@app/core/constants';
import {MatTableDataSource} from '@angular/material/table';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-modal-employees-list',
    templateUrl: './modal-employees-list.component.html',
    styleUrls: ['./modal-employees-list.component.scss']
})
export class ModalEmployeesListComponent implements OnInit {
    displayedColumns = ['select', 'fio', 'position', 'salary'];
    dataSource = new MatTableDataSource([]);
    selection = new SelectionModel(true, []);
    photoUrl: string = PHOTO_URL;

    constructor(
        public dialogRef: MatDialogRef<ModalEmployeesListComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private service: EmployeesService
    ) {
    }

    ngOnInit() {
        this.service.getAll().subscribe((items: any[]) => {
            this.dataSource.data = items;
            this.selection.select(...this.getSelectedRows(items));
        });
    }

    public close(): void {
        this.dialogRef.close();
    }

    public successClose(): void {
        this.dialogRef.close(this.selection.selected);
    }

    private getSelectedRows(items: any[]): any[] {
        const ids = pluck('id', this.data.employees);
        return items.filter((item) => ids.includes(item.id));
    }
}
