<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xs-12">
                <div class="card">
                    <div class="card-header card-header-icon" data-background-color="rose">
                        <i class="material-icons">phone</i>
                    </div>

                    <div class="card-content">
                        <h4 class="card-title">Детализация по позиции</h4>

                        <div class="order-table-wrap">
                            <div class="spinner-wrap" *ngIf="false">
                                <mat-spinner *ngIf="false"></mat-spinner>
                            </div>

                            <table class="table table-hover" *ngIf="employees.length">
                                <thead>
                                <tr>
                                    <th>Смена</th>
                                    <th>Дата</th>
                                    <th></th>
                                    <th>ФИО</th>
                                    <th>ЗП за день</th>
                                    <th title="ЗП с начала месяця по текущую смену">ЗП с начала месяця</th>
                                </tr>
                                </thead>
                                <tbody>
                                <ng-container *ngFor="let employee of employees">
                                    <tr [routerLink]="['/salaries/session', session.id, 'position', position.id, 'employees', employee.employer_id]">
                                        <td>{{session.id}}</td>
                                        <td>
                                            <div title="Дата открытия смены">{{session.created_at}}</div>
                                            <div *ngIf="session.closed_at" title="Дата закрытия смены">
                                                {{session.closed_at}}
                                            </div>
                                        </td>
                                        <td><img src="{{employee.photo}}" style="max-width: 200px;"></td>
                                        <td>{{employee.surname}} {{employee.name}}</td>
                                        <td>{{employee.sum}} руб</td>
                                        <td>
                                            <span *ngIf="employee.sum_start_of_month_to_current_shift">
                                                {{employee.sum_start_of_month_to_current_shift}} руб
                                            </span>
                                            <span *ngIf="!employee.sum_start_of_month_to_current_shift">-</span>
                                        </td>
                                    </tr>
                                </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
