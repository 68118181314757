<div class="wrapper wrapper-full-page">
    <nav class="navbar navbar-primary navbar-transparent navbar-fixed-top">
        <div class="container">
            <div class="navbar-header">
                <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#menu-example">
                    <span class="sr-only">Toggle navigation</span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </button>
                <a class="navbar-brand">DrmAuto</a>
            </div>

            <div class="collapse navbar-collapse" id="menu-example">
                <ul class="nav navbar-nav navbar-right">
                    <li class="">
                        <a href="/pages/register">
                            <i class="material-icons">person_add</i> Регистрация
                        </a>
                    </li>
                    <li class=" active ">
                        <a href="/pages/login">
                            <i class="material-icons">fingerprint</i> Авторизация
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
    <div class="full-page login-page" filter-color="black">

        <div class="content">
            <div class="container">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>



