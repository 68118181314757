import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {TextMaskModule} from 'angular2-text-mask';
import {TimePickerComponent} from './time-picker/time-picker.component';
import {HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import {ModalTimePickerComponent} from './modal-time-picker/modal-time-picker.component';
import {MyHammerProvider} from '@app/core/providers/my-hammer.provider';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TextMaskModule,
        MatButtonModule,
        MatFormFieldModule
    ],
    declarations: [
        TimePickerComponent,
        ModalTimePickerComponent
    ],
    exports: [
        TimePickerComponent,
        ModalTimePickerComponent
    ],
    entryComponents: [
        ModalTimePickerComponent
    ],
    providers: [{
        provide: HAMMER_GESTURE_CONFIG,
        useClass: MyHammerProvider
    }]
})
export class TimePickerModule {
}
