<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xs-12">
                <div class="card">
                    <div class="card-header card-header-icon" data-background-color="purple">
                        <i class="material-icons">assignment</i>
                    </div>
                    <div class="card-content">
                        <h4 class="card-title">
                            {{recordId ? 'Обновления' : 'Добавления новой'}} записи
                        </h4>

                        <form novalidate [formGroup]="form" (submit)="onSubmit()">
                            <div class="row">
                                <div class="col-xs-12 col-sm-4">
                                    <mat-form-field class="full-width">
                                        <input matInput placeholder="Фамилия" formControlName="surname"
                                               autocomplete="off" upperFirstLetter>
                                    </mat-form-field>
                                </div>
                                <div class="col-xs-12 col-sm-4">
                                    <mat-form-field class="full-width">
                                        <input matInput placeholder="Имя" formControlName="name"
                                               autocomplete="off" upperFirstLetter>
                                    </mat-form-field>
                                </div>
                                <div class="col-xs-12 col-sm-4">
                                    <mat-form-field class="full-width">
                                        <input matInput placeholder="Отчество" formControlName="patronymic"
                                               autocomplete="off" upperFirstLetter>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-xs-12 col-sm-6">
                                    <app-customer-phone-autocomplete
                                        formControlName="phone"
                                        (selectClient)="onSelectClientByPhone($event)"
                                        (fullMatch)="onSelectClientByPhone($event)"
                                    >
                                    </app-customer-phone-autocomplete>
                                </div>

                                <div class="col-xs-12 col-sm-6">
                                    <mat-form-field class="full-width">
                                        <mat-label>Дополнительный номер телефона</mat-label>
                                        <input matInput
                                               placeholder="+7 ___ ___-__-__"
                                               formControlName="phone2"
                                               [textMask]="{mask: maskPhone}"
                                               phonePrefix>
                                    </mat-form-field>
                                </div>
                            </div>

                            <app-selectable-customer-cars-list
                                *ngIf="client && client.cars.length > 1"
                                [client]="client"
                                (change)="carSelect($event)">
                            </app-selectable-customer-cars-list>

                            <div class="row">
                                <app-input-car-number
                                    [parentForm]="form"
                                    [inputType]="'carNumberType'"
                                    [inputNumber]="'carNumber'"
                                    [typeClasses]="'col-xs-12 col-sm-4'"
                                    [numberClasses]="'col-xs-12 col-sm-8'">
                                </app-input-car-number>
                            </div>

                            <div class="row">
                                <div class="col-xs-12 col-sm-6">
                                    <app-date-time-picker
                                        [label]="'Дата и время записи'"
                                        [datePlaceholder]="'Дата записи'"
                                        [timePlaceholder]="'Время записи'"
                                        [dateTime]="form.controls['whenDatetime'].value"
                                        (change)="onChangeDate($event)">
                                    </app-date-time-picker>
                                    <mat-error *ngIf="form.controls['whenDatetime'].hasError('required')">
                                        Дата записи <strong>обязательно поле</strong>
                                    </mat-error>
                                </div>

                                <div class="col-xs-12 col-sm-6">
                                    <app-date-time-picker
                                        [label]="'Дата напоминая о записи'"
                                        [datePlaceholder]="'Дата напоминания'"
                                        [timePlaceholder]="'Время напоминания'"
                                        [dateTime]="form.controls['whenRemember'].value"
                                        [maxDate]="maxDateForRemember"
                                        (change)="onChangeRememberDate($event)">
                                    </app-date-time-picker>
                                    <mat-error *ngIf="form.controls['whenRemember'].hasError('required')">
                                        Дата напоминания <strong>обязательно поле</strong>
                                    </mat-error>
                                </div>
                            </div>

                            <div class="btn-group">
                                <button type="button" mat-raised-button color="warn" (click)="openServiceListModal()">
                                    Добавить услуги к записи
                                </button>

                                <div *ngIf="services.length">
                                    <br>
                                    <ul>
                                        <li *ngFor="let item of services">{{item.name}}</li>
                                    </ul>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-xs-12 col-sm-6">
                                    <mat-form-field class="full-width">
                                        <input matInput placeholder="Марка" formControlName="make"
                                               autocomplete="off">
                                    </mat-form-field>
                                </div>
                                <div class="col-xs-12 col-sm-6">
                                    <mat-form-field class="full-width">
                                        <input matInput placeholder="Модель" formControlName="model"
                                               autocomplete="off">
                                    </mat-form-field>
                                </div>
                            </div>

                            <mat-form-field class="full-width">
                                <textarea matInput
                                          placeholder="Откуда узнали о нас?"
                                          formControlName="whereDidYouHearAboutUs"
                                          matTextareaAutosize
                                          matAutosizeMinRows="2"
                                          matAutosizeMaxRows="5"></textarea>
                            </mat-form-field>

                            <mat-form-field class="full-width">
                                <textarea matInput
                                          placeholder="Заметки о текущей записи"
                                          formControlName="note"
                                          matTextareaAutosize
                                          matAutosizeMinRows="2"
                                          matAutosizeMaxRows="5"></textarea>
                            </mat-form-field>

                            <div class="d-flex justify-content-end">
                                <button type="submit" mat-raised-button color="accent" *ngIf="!recordId">
                                    Создать
                                </button>

                                <button type="button" mat-raised-button color="accent" *ngIf="recordId"
                                        (click)="onUpdate()">
                                    Обновить
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
