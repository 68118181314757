<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xs-12">
                <div class="card">
                    <div class="card-content">
                        <h4 class="card-title">Список инкасаций</h4>

                        <mat-tab-group>
                            <mat-tab *ngFor="let cash of list; trackBy: tackFn" [label]="cash.name">
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Дата списания</th>
                                            <th class="text-center">Сумма списания</th>
                                            <th class="text-center">Баланс после списания</th>
                                            <th class="text-center">Кто делал списания</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr *ngFor="let transaction of cash.transactions; trackBy: tackFn">
                                            <td>{{transaction.id}}</td>
                                            <td>{{transaction.created_at}}</td>
                                            <td class="text-center">{{transaction.sum}} ₽</td>
                                            <td class="text-center">{{transaction.balance}} ₽</td>
                                            <td class="text-center">{{transaction.manager.name}}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
