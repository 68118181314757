import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {API_URL} from '../core/constants';

@Injectable()
export class CustomerService {

    constructor(private http: HttpClient) {
    }

    public getShares(customerId: number): Observable<any> {
        return this.http.get(`${API_URL}/customers/${customerId}/shares`);
    }
}
