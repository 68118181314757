import {ApplicationRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Observable, of, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, switchMap} from 'rxjs/operators';
import {InputCarNumberComponent} from '../input-car-number/input-car-number.component';
import {ListOfServiceForSelectionComponent} from '../order/list-of-service-for-selection/list-of-service-for-selection.component';
import {BoxInfoService} from '@services/box-info.service';
import {ClientsService} from '@app/states/clients/services/clients.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-order-create',
    templateUrl: './order-create.component.html',
    styleUrls: ['./order-create.component.scss']
})
export class OrderCreateComponent implements OnInit, OnDestroy {
    @ViewChild(InputCarNumberComponent) inputCarNumberComponent: InputCarNumberComponent;
    @ViewChild(ListOfServiceForSelectionComponent) listOfServiceForSelection: ListOfServiceForSelectionComponent;

    @Input() client: any;
    @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();

    form: FormGroup;
    boxList = [];
    selectedBox: number;
    filteredSurname$: Observable<any[]>;
    filteredName$: Observable<any[]>;
    clientGrades: any[] = [];
    selectedCar: any = null;

    private boxListSubscriber: Subscription;

    constructor(
        private app: ApplicationRef,
        private fb: FormBuilder,
        private dialog: MatDialog,
        private boxInfoService: BoxInfoService,
        private clientsService: ClientsService,
    ) {
        this.form = this.fb.group({
            boxId: null,
            clientId: null,
            clientName: null,
            clientSurname: null,
            clientPhone: null,
            carId: null,
            carNumberType: ['', [Validators.required]],
            carNumber: [null, [Validators.required, Validators.minLength(3)]],
            services: [[], [Validators.required]],
            note: null
        });
    }

    ngOnInit() {
        this.getBoxList();
        this.setClientInfo();

        this.filteredSurname$ = this.inputAutoCompleteGenerator('clientSurname', 2, this.clientsService.findClientsBySurnameForAutocomplete);
        this.filteredName$ = this.inputAutoCompleteGenerator('clientName', 2, this.clientsService.findClientsByNameForAutocomplete);
    }

    ngOnDestroy() {
        this.boxListSubscriber.unsubscribe();
        this.resetForm();
    }

    selectBox(boxId: number): void {
        if (this.selectedBox === boxId) {
            this.selectedBox = null;
            return;
        }

        this.selectedBox = boxId;
        this.form.patchValue({
            boxId: this.selectedBox
        });
    }

    selectCarNumber(car: any): void {
        this.client = car.owner;
        this.selectedCar = car;
        this.form.patchValue(
            {
                clientId: this.client.id,
                clientName: this.client.name,
                clientSurname: this.client.surname,
                clientPhone: this.client.phone,
                carId: car.id,
                carNumber: car.number,
                carNumberType: car.number_type,
            },
            {emitEvent: false}
        );

        if (car.model.grades) {
            this.clientGrades = car.model.grades;
        }
    }

    onSelectClientByPhone(client: any) {
        this.form.patchValue(
            {
                clientSurname: client.surname,
                clientName: client.name,
                clientPhone: client.phone,
            },
            {emitEvent: false}
        );

        this.clientsService.getClientById(client.id).subscribe((response: any) => {
            this.client = response;
            this.setClientInfo();
        });
    }

    selectCarNumberInUI(car: any) {
        this.selectedCar = car;
        this.form.patchValue({
            carId: car.id,
            carNumber: car.number,
            carNumberType: car.numberType,
        });

        this.setClientGrades(car);
    }

    submitHandler() {
        if (this.form.valid) {
            this.onSubmit.emit({
                formValue: {...this.form.value}
            });

            this.selectedBox && (this.getBoxList());
            this.resetForm();
        } else {
            console.info(this.form);

            for (let key in this.form.controls) {
                if (this.form.controls.hasOwnProperty(key)) {
                    this.form.controls[key].markAsTouched();
                }
            }
        }
    }

    resetForm(): void {
        this.form.reset();
        this.form.controls.clientPhone.markAsUntouched();

        if (this.listOfServiceForSelection) {
            /**
             * TODO: через дочерний компонент очищаем выбранные элемены.
             */
            this.listOfServiceForSelection.clearSelectedServices();
        }

        this.selectedBox = null;
        this.client = null;

        if (this.inputCarNumberComponent) {
            this.inputCarNumberComponent.setDefaultValueToFormsField();
        }
    }

    onUpdateSelectionItem(data: any): void {
        this.form.patchValue({
            services: data
        });
    }

    private getBoxList(): void {
        this.boxListSubscriber = this.boxInfoService.getAllBoxStream().subscribe((res) => {
            this.boxList = res;
        });
    }

    private setClientInfo(): void {
        if (this.client) {
            this.form.patchValue({
                clientId: this.client.id,
                clientName: this.client.name,
                clientSurname: this.client.surname,
                clientPhone: this.client.phone
            }, {emitEvent: false});

            if (this.client.cars.length === 1) {
                this.selectCarNumberInUI(this.client.cars[0]);
            }
        }
    }

    private inputAutoCompleteGenerator(controlName: string, minLenForSearch: number, cb: any): Observable<any> {
        return this.form.controls[controlName].valueChanges.pipe(
            filter((result: string) => !!result),
            debounceTime(300),
            distinctUntilChanged(),
            switchMap((result: string) => result.length >= minLenForSearch ? cb(result) : of([]))
        );
    }

    private setClientGrades(car: any) {
        if (car.model && car.model.grades) {
            this.clientGrades = car.model.grades;
        }
    }
}
