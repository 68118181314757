import {Component, Input, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';

@Component({
    selector: 'app-price-table',
    templateUrl: './price-table.component.html',
    styleUrls: ['./price-table.component.scss']
})
export class PriceTableComponent implements OnInit {
    @Input() tableData: any[] = [];
    public displayedColumns: string[] = ['name'];
    public dataSource: MatTableDataSource<any>;
    public grades: any[];

    constructor() {
        this.grades = [];
    }

    ngOnInit() {
        this.getGrades();
        this.dataSource = new MatTableDataSource<any>(this.tableData);
    }

    private getGrades(): void {
        const map = new Map();

        this.tableData.forEach((category: any) => {
            category.grades.forEach((grade: any) => map.set(grade.id, grade));
        });

        const sort = (a, b) => {
            if (a.sort > b.sort) {
                return 1;
            }

            if (a.sort < b.sort) {
                return -1;
            }

            return 0;
        };

        this.grades = Array.from(map.values()).sort(sort);
        this.grades.forEach(grade => this.displayedColumns.push(`grade-${grade.id}`));
        map.clear();
    }
}
