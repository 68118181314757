import {Injectable} from '@angular/core';

@Injectable()
export class LoaderService {
    public progress: boolean = false;

    constructor() {
    }

    public show() {
        this.progress = true;
        document.body.classList.add('no-events');
    }

    public hide() {
        this.progress = false;
        document.body.classList.remove('no-events');
    }
}
