import {ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';

interface Employee {
    id: number;
    photo: string;
    name: string;
    salary: number;
}

@Component({
    selector: 'app-service-info',
    templateUrl: './service-info.component.html',
    styleUrls: ['./service-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.Emulated,
    animations: [
        trigger('toggleAnimation', [
            transition(':enter', [
                style({opacity: 0}),
                animate(300, style({opacity: 1})),
            ]),
            transition(':leave', [
                animate(300, style({opacity: 0})),
            ]),
        ]),
    ],
})
export class ServiceInfoComponent implements OnInit {
    @Input() service: any = null;

    hasEmployees: boolean = false;
    employees: Employee[];

    constructor() {
    }

    ngOnInit() {
        this.hasEmployees = this.service.responsibles && Array.isArray(this.service.responsibles);

        if (this.hasEmployees) {
            const employeeCount = this.service.responsibles.length;
            this.employees = this.service.responsibles.map(employee => {
                return {
                    id: employee.id,
                    photo: employee.photo,
                    name: employee.fioAndPosition,
                    salary: (this.service.price / employeeCount) * employee.sum_percent / 100,
                };
            });
        }
    }

    trackById(index: number, el: Employee) {
        return el.id;
    }
}
