import {Component, Input, OnInit} from '@angular/core';
import {FormClientInfoService} from '../../../new-client/form-client-info.service';
import {MatTableDataSource} from '@angular/material/table';

@Component({
    selector: 'app-garage',
    templateUrl: './garage.component.html',
    styleUrls: ['./garage.component.scss']
})
export class GarageComponent implements OnInit {
    @Input() cars: any[] = [];
    @Input() clientId: number;

    public displayedColumns = ['number', 'make', 'model', 'year', 'bonus', 'actions'];
    public dataSource = new MatTableDataSource<any>([]);

    constructor(private formService: FormClientInfoService) {
    }

    ngOnInit() {
        this.dataSource.data = this.cars;
    }

    public removeCar(car: any): void {
        this.formService.removeCar(this.clientId, car.id).subscribe((response) => {
            if (response.success) {
                this.dataSource.data = this.dataSource.data.filter((item: any) => item.id !== car.id);
            }
        });
    }
}
