import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface ConfirmData {
    name?: string;
    text?: string;
    successButtonText?: string;
}

@Component({
    selector: 'app-confirm',
    templateUrl: './confirm.component.html',
})
export class ConfirmComponent implements OnInit {
    blockName = 'Вы подтверждаете действие?';
    text = '';
    successButtonText = 'Подтвердить';

    constructor(
        public dialogRef: MatDialogRef<ConfirmComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmData
    ) {
    }

    ngOnInit() {
        if (this.data) {
            if (this.data.name) {
                this.blockName = this.data.name;
            }

            if (this.data.text) {
                this.text = this.data.text;
            }

            if (this.data.successButtonText) {
                this.successButtonText = this.data.successButtonText;
            }
        }
    }

    close(): void {
        this.dialogRef.close(false);
    }

    success(): void {
        this.dialogRef.close(true);
    }
}
