export const servicesCostCalculation = (data: any[]): number => {
    let cost = 0;

    data.forEach((item: any) => {
        cost += item.price;
    });

    return cost;
};

export const servicesSaleCostCalculation = (data: any[]): number => {
    let cost = 0;

    data.forEach((item: any) => {
        if (item.salePrice !== null && item.salePrice !== undefined) {
            cost += item.salePrice;
        } else {
            cost += item.price;
        }
    });

    return cost;
};
