import {Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {MatTableDataSource} from '@angular/material/table';

@Component({
    selector: 'app-sent-sms',
    templateUrl: './sent-sms.component.html',
    styleUrls: ['./sent-sms.component.scss']
})
export class SentSmsComponent implements OnInit {
    displayedColumns = ['date', 'text'];
    dataSource = new MatTableDataSource(ELEMENT_DATA);

    constructor() {
    }

    ngOnInit() {
    }

}

const ELEMENT_DATA = [
    {date: '01-01-2018', text: 'Химчистка сидений'},
    {date: '02-01-2018', text: 'Химчистка педали'},
    {date: '03-01-2018', text: 'Химчистка ковры'},
    {date: '04-01-2018', text: 'Химчистка мотор'},
];
