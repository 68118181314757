<!-- id Column -->
<td>
    <div>{{order.id}}</div>
</td>

<!-- customer Column -->
<td>
    <ng-container *ngIf="order?.customer">
        <div *ngIf="order.customer.name || order.customer.surname"
             [title]="'Клиент: ' + order.customer.surname + ' ' + order.customer.name"
             class="text-has-icon">
            <i class="material-icons">person</i>
            {{order.customer.name || order.customer.surname}}
        </div>

        <div *ngIf="order.customer.phone" class="text-has-icon"
             title="Телефон клиента">
            <i class="material-icons">phone</i>
            {{order.customer.phone}}
        </div>
    </ng-container>

    <div *ngIf="order?.car" class="text-has-icon" title="Данные о машине клиента">
        <i class="material-icons">directions_car</i>
        <a [routerLink]="['/clients/profile', order.customer.id]">
            {{order.car.number}}
        </a>
    </div>

    <div class="text-has-icon" title="дата создания заказа">
        <i class="material-icons">date_range</i> {{order.createdAtFormat}}
    </div>
</td>

<!-- serviceList Column -->
<td>
    <div *ngFor="let serviceItem of order.services_list">
        <div>
            <span [innerHTML]="serviceItem.service.name" style="color: #000;"></span>
            <div>
                <span *ngFor="let serviceEmployee of serviceItem.employees_list; let i = index" style="color: #333;">
                    <span *ngIf="i > 0">,&nbsp;</span>
                    {{serviceEmployee.surname}} {{serviceEmployee.name}} ({{serviceEmployee?.position?.name}})
                </span>
            </div>
        </div>

        <div *ngIf="serviceItem.cause">/ <strong>Причина скидки: {{serviceItem.cause}}</strong></div>
    </div>

    <br>
    <div *ngIf="order?.note">
        <strong>Заметки по заказу: <i>{{order.note}}</i></strong>
    </div>

    <div *ngIf="order?.customer?.note">
        <strong>Заметки по клиенту: <i>{{order.customer.note}}</i></strong>
    </div>
</td>

<!-- totalCost Column -->
<td>
    <div>
        Полная: {{order.servicesCost}} руб.
    </div>

    <div *ngIf="order.servicesCost !== order.servicesSaleCost">
        {{order.servicesCost > order.servicesSaleCost ? 'Co скидкой' : 'С наценкой'}}:
        {{order.servicesSaleCost}} руб.
    </div>
</td>

<!-- status Column -->
<td>
    <div>
        <span *ngIf="order.status === 1" class="alert alert--mini alert-info"><b>Новый</b></span>
        <span *ngIf="order.status === 2" class="alert alert--mini alert-warning"><b>В боксе</b></span>
        <span *ngIf="order.status === 3" class="alert alert--mini alert-success"><b>Завершен</b></span>
        <span *ngIf="order.status === 4" class="alert alert--mini alert-danger"><b>Отменен</b></span>
    </div>

    <div>
        <span *ngIf="order.payment_status === 1" class="alert alert--mini alert-info"><b>Оплачен</b></span>
        <span *ngIf="order.payment_status === 0" class="alert alert--mini alert-danger"><b>Не оплачен</b></span>
    </div>
</td>

<!-- box Column -->
<td>
    <mat-form-field>
        <mat-label>Бокс</mat-label>
        <select
            (change)="onBoxChange()"
            [(ngModel)]="selectedBoxId"
            matNativeControl
            required>
            <option value="0">Вне бокса</option>
            <option *ngFor="let itemBox of boxList" [value]="itemBox.id">{{itemBox.name}}</option>
        </select>
    </mat-form-field>
</td>

<!-- actions Column -->
<td class="td-actions">
    <div>
        <button
            (click)="completeOrder.emit(order)"
            [disabled]="order.status === 3"
            class="btn btn-success"
            title="Завершить заказ"
            type="button">
            <i class="material-icons">done</i>
        </button>

        <button
            *ngIf="order.status === ORDER_STATUS_IN_PROGRESS && order.box_id > 0"
            [routerLink]="['/boxes', order.box_id]"
            class="btn btn-danger"
            title="Посмотреть заказ в боксе"
            type="button">
            <i class="material-icons">home</i>
        </button>

        <button
            (click)="pay.emit(order)"
            [disabled]="order.payment_status == 1 || order.status == 4"
            class="btn btn-warning"
            title="Оплатить заказ"
            type="button">
            <i class="material-icons">payment</i>
        </button>

        <button
            (click)="cancelOrder.emit(order)"
            *ngIf="order.status !== 4"
            [disabled]="order.payment_status == 1"
            class="btn btn-danger"
            title="Удалить заказ"
            type="button">
            <i class="material-icons">close</i>
        </button>
    </div>
    <div style="margin-top: 5px;">
        <button
            (click)="cancelOrder.emit(order)"
            *ngIf="order.status !== 4"
            [disabled]="order.payment_status == 1"
            class="btn btn-info full-width"
            type="button">
            Печать чека
        </button>
    </div>
</td>
