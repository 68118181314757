import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-auto-number',
    templateUrl: './auto-number.component.html',
    styleUrls: ['./auto-number.component.scss']
})
export class AutoNumberComponent implements OnInit {
    @Input() number: string = '';
    public numberForRender: string[] = [];

    constructor() {
    }

    ngOnInit(): void {
        this.parseNumber();
    }

    private parseNumber(): void {
        const originalSrt = String(this.number);
        const result = originalSrt.match(/\d{2,3}$/);
        const str = originalSrt.slice(0, -result[0].length);

        this.numberForRender[0] = str;
        this.numberForRender[1] = result[0];
    }
}
