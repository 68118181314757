import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {API_URL} from '@app/core/constants';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-session-positions',
    templateUrl: './session-positions.component.html',
    styleUrls: ['./session-positions.component.scss']
})
export class SessionPositionsComponent implements OnInit, OnDestroy {
    session: any;
    positions: any;
    private subscriptions: Subscription;

    constructor(
        private http: HttpClient,
        private route: ActivatedRoute,
    ) {
        this.session = null;
        this.positions = null;
        this.subscriptions = new Subscription();
    }

    ngOnInit(): void {
        const response$ = this.getPositions().subscribe((response: any) => {
            this.session = response.session;
            this.positions = response.positions;
        });
        this.subscriptions.add(response$);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private getPositions(): Observable<any> {
        return this.http.get(`${API_URL}/salaries/sessions/${this.route.snapshot.params.sessionId}`);
    }
}
