/**
 * @class Order
 */
export default class Order {
    static readonly STATUS_CREATED = 1;
    static readonly STATUS_IN_PROGRESS = 2;
    static readonly STATUS_COMPLETE = 3;
    static readonly STATUS_CANCELED = 4;
    static readonly PAYMENT_NOT_PAID = 0;
    static readonly PAYMENT_PAID = 1;
}
