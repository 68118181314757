import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'modal-time-picker',
    templateUrl: './modal-time-picker.component.html',
    styleUrls: ['./modal-time-picker.component.scss']
})
export class ModalTimePickerComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<ModalTimePickerComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    ngOnInit() {
    }

    onSubmit(data) {
        this.data.time = data;
        this.successClose();
    }

    successClose(): void {
        this.dialogRef.close(this.data);
    }
}
