import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {OrderSaleResult} from '@app/states/boxes/components/box-view/components/order-sale/order-sale-config';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-order-sale',
    templateUrl: './order-sale.component.html',
    styleUrls: ['./order-sale.component.scss']
})
export class OrderSaleComponent implements OnInit {
    price: any = null;
    form: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<OrderSaleComponent, OrderSaleResult | boolean>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder
    ) {
    }

    ngOnInit() {
        this.form = this.fb.group({
            percent: [null, [Validators.max(100)]],
            fixed: null,
            cause: '',
        });

        this.form.controls.percent.valueChanges.subscribe(() => {
            if (this.form.controls.fixed.value !== null && this.form.controls.fixed.value > 0) {
                this.form.patchValue({fixed: null}, {emitEvent: false});
            }
        });

        this.form.controls.fixed.valueChanges.subscribe(() => {
            if (this.form.controls.percent.value !== null && this.form.controls.percent.value > 0) {
                this.form.patchValue({percent: null}, {emitEvent: false});
            }
        });
    }

    close(): void {
        this.dialogRef.close(false);
    }

    success(): void {
        if (this.form.valid) {
            this.dialogRef.close(this.form.value);
        }
    }
}
