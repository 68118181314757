import {Component, ElementRef, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../auth.service';
import {AuthBaseService} from '@app/core/services/auth-base.service';
import {HttpErrorResponse} from '@angular/common/http';

declare var $: any;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    private toggleButton: any;
    private sidebarVisible: boolean;
    private nativeElement: Node;
    public form: FormGroup;
    public showErrorMessage: boolean;

    constructor(
        private element: ElementRef,
        private fb: FormBuilder,
        private authService: AuthService,
        private authBaseService: AuthBaseService,
    ) {
        this.nativeElement = element.nativeElement;
        this.sidebarVisible = false;
        this.showErrorMessage = false;
    }

    ngOnInit() {
        let navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];

        setTimeout(function () {
            $('.card').removeClass('card-hidden');
        }, 700);

        this.form = this.fb.group({
            email: ['', [Validators.required]],
            password: ['', [Validators.required]]
        });
    }

    public onSubmit(): void {
        if (this.form.valid) {
            this.showErrorMessage = false;
            this.authService.login(this.form.value).subscribe(
                (response: any) => {
                    if (response.success) {
                        this.authBaseService.setUser(response.data.user);
                        this.authBaseService.authorize(response.data.accessToken);
                        window.location.href = '/';
                    }
                },
                (error: HttpErrorResponse) => {
                    this.showErrorMessage = error.status === 401;
                }
            );
        }
    }
}
