<div class="check-page">

    <form class="form check-form" [formGroup]="form" novalidate (submit)="submitHandler()">
        <div>
            <mat-form-field class="full-width">
                <mat-label>Введите номер вашего автомобиля</mat-label>
                <input type="text" matInput formControlName="carNumber" pattern="[А-Яа-я0-9]+">
                <mat-error>Ввод номера только русскими буквами</mat-error>
            </mat-form-field>

            <button type="submit" class="btn btn-fill btn-rose">
                Проверить
            </button>
        </div>
    </form>
    <br>
    <br>
    <div class="result alert alert-info" *ngIf="result.length">
        <div *ngFor="let item of result">
            <div class="alert alert--mini {{item.result.can_use ? 'alert-success' : 'alert-danger'}}">
                Клиент может помыться по акции: {{item.result.can_use ? 'ДА' : 'НЕТ'}}
            </div>

            <div>Акция: {{item.action.name}}</div>
            <div>Машина: {{item.car.number}} / {{item.car.make?.name}} / {{item.car.model?.name}}</div>
            <div>Нужно моек для получения акции: {{item.action.n_count}}</div>
            <div *ngIf="(item.action.n_count - item.result.total_count) >= 0">
                Клиенту еще моек: {{item.action.n_count - item.result.total_count}}
            </div>
            <div>У клиента есть моек: {{item.result.total_count}}</div>
            <br>
            <div>
                Список заказываемых услуг

                <ul>
                    <li *ngFor="let service of item.services">
                        <div>{{service.service_name}} / Заказов - {{service.current_count}}</div>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="error alert alert-danger" *ngIf="error">
        {{error}}
    </div>
</div>
