import {Component, OnInit} from '@angular/core';
import {API_URL} from '@app/core/constants';
import {HttpClient} from '@angular/common/http';
import {UIMangerService} from '@services/ui-manger.service';
import {CashCollectionComponent} from '@app/states/cashbox/components/cash-collection/cash-collection.component';

@Component({
    selector: 'app-cash-list',
    templateUrl: './cash-list.component.html',
    styleUrls: ['./cash-list.component.scss']
})
export class CashListComponent implements OnInit {
    cashList: any[] = [];

    constructor(
        private http: HttpClient,
        private ui: UIMangerService,
    ) {
    }

    ngOnInit() {
        this.getCashList();
    }

    tackFn(index, item) {
        return item.id;
    }

    cashCollection(cash: any) {
        this.ui.open<CashCollectionComponent>(CashCollectionComponent, {cash}).subscribe(async (result) => {
            if (result && result.success) {
                this.ui.showSnackBar('Инкасация прошла успешно');
                this.getCashList();
            }
        });
    }

    private getCashList() {
        return this.http.get(`${API_URL}/cashes`).subscribe((response: any) => {
            if (response.success) {
                this.cashList = response.data;
                /*this.cashList.map(item => {
                    if (!item.balance) {
                        item.balance = 0;
                    }
                    return item;
                });*/
                console.log(this.cashList);
            }
        });
    }
}
