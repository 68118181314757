<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xs-12">
                <div class="card">
                    <div class="card-header card-header-icon" data-background-color="rose">
                        <i class="material-icons">contacts</i>
                    </div>

                    <div class="card-content">
                        <h4 class="card-title">Поиск клиента</h4>

                        <form novalidate>
                            <div class="row">
                                <div class="col-xs-12">
                                    <mat-form-field class="full-width">
                                        <mat-label>По номеру телефона</mat-label>
                                        <input type="tel" matInput placeholder="+7 ___ ___-__-__"
                                               [formControl]="phone" autocomplete="off"
                                               #inputPhone
                                               phonePrefix>
                                    </mat-form-field>
                                </div>

                                <div class="col-xs-12">
                                    <mat-form-field class="full-width">
                                        <mat-label>По номеру авто</mat-label>
                                        <input type="text" matInput placeholder="А00АА000"
                                               [formControl]="carNumber" autocomplete="off">
                                    </mat-form-field>
                                </div>
                            </div>
                        </form>

                        <p>
                            Номера для проверки работы:
                            <b>79161178320</b>, <b>380987310382</b>, <b>380681245124</b>
                        </p>


                        <div class="full-width" *ngIf="isFetch">
                            <mat-spinner class="center-block"></mat-spinner>
                        </div>

                        <mat-nav-list *ngIf="!isFetch">
                            <!--<mat-list-item *ngFor="let client of filteredClients$ | async">-->
                            <mat-list-item *ngFor="let client of filteredClients">
                                <a matLine [routerLink]="['/clients/profile', client.id]" class="client-link">
                                    <div class="client-info">
                                        <ng-container *ngIf="client.name">{{client.name}},</ng-container>
                                        {{client.phone}}
                                    </div>

                                    <mat-chip-list *ngIf="client.cars.length">
                                        <mat-chip *ngFor="let car of client.cars">
                                            {{car.number}}
                                            <span *ngIf="car.make_id">, {{car.make.name}}</span>
                                            <span *ngIf="car.model_id">, {{car.model.name}}</span>
                                        </mat-chip>
                                    </mat-chip-list>
                                </a>
                            </mat-list-item>
                        </mat-nav-list>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
