import {Injectable} from '@angular/core';
import {Observable, Observer} from 'rxjs';
import {PusherService} from './pusher.service';
import {API_URL} from '../core/constants';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class NotificationService {
    public notification$: Observable<any>;

    constructor(
        private pusher: PusherService,
        private http: HttpClient,
    ) {
        this.notification$ = Observable.create((observer: Observer<any>) => {
            this.pusher.notification.bind('Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', (response: any) => {
                observer.next(response);
            });
        });
    }

    public getDbList(): Observable<any> {
        return this.http.get(`${API_URL}/notifications`);
    }

    public connect() {
        return this.notification$;
    }

    markAsRead(id: string): Observable<any> {
        return this.http.put(`${API_URL}/notifications/${id}/markAsReadNotification`, {});
    }
}
