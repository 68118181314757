<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xs-12">
                <div class="card">
                    <div class="card-header card-header-tabs" data-background-color="red">
                        <div class="nav-tabs-navigation">
                            <div class="nav-tabs-wrapper">
                                <span class="nav-tabs-title">Категории:</span>
                                <ul class="nav nav-tabs" data-tabs="tabs">
                                    <li class="active">
                                        <a data-toggle="tab" (click)="changeCurrentCategory(0)">
                                            <i class="material-icons">bug_report</i> Автомойка
                                            <div class="ripple-container"></div>
                                        </a>
                                    </li>
                                    <li>
                                        <a data-toggle="tab" (click)="changeCurrentCategory(1)">
                                            <i class="material-icons">code</i> Детейлинг
                                            <div class="ripple-container"></div>
                                        </a>
                                    </li>
                                    <li>
                                        <a data-toggle="tab" (click)="changeCurrentCategory(2)">
                                            <i class="material-icons">cloud</i> Шиномонтаж
                                            <div class="ripple-container"></div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="card-content">

                        <form class="example-form" (submit)="add(fInput.value); $event.preventDefault(); fInput.value = ''">
                            <mat-form-field class="full-width">
                                <input matInput [placeholder]="'Введите задачу ' + categories[currentCategory]" #fInput
                                       class="form-control-lg input-lg">
                                <button type="button" mat-button matSuffix mat-icon-button
                                        (click)="add(fInput.value); fInput.value = ''">
                                    <mat-icon>add</mat-icon>
                                </button>
                            </mat-form-field>
                        </form>

                        <mat-list>
                            <mat-list-item *ngFor="let todo of this.todos | async | filterCategory:currentCategory"
                                           class="todo">
                                <div matLine class="todo__text" [class.done]="todo.isDone">{{todo.text}}</div>
                                <button type="button" mat-icon-button color="primary" (click)="doneToggle(todo)">
                                    <mat-icon>{{ todo.isDone ? 'close' : 'check' }}</mat-icon>
                                </button>
                                <button type="button" mat-icon-button color="warn" (click)="remove(todo)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </mat-list-item>
                        </mat-list>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
