import {Routes} from '@angular/router';
import {StorageOfTiresComponent} from './components/storage-of-tires/storage-of-tires.component';
import {ManagerLayoutComponent} from '../../layouts/manager-layout/manager-layout.component';
import {ManagerGuard} from '../../core/guards/manager.guard';

export const routes: Routes = [
    {
        path: 'storage-of-tires',
        component: ManagerLayoutComponent,
        data: {
            menu: {
                name: 'Хранения резины',
                type: 'link',
                showInDashboard: true,
                showInSideBar: true,
                fontIcon: 'hdr_weak',
                imageIcon: '../assets/img/dashboard-menu/tire.svg'
            }
        },
        children: [
            {
                path: '',
                component: StorageOfTiresComponent,
                canActivate: [ManagerGuard]
            }
        ]
    }
];
