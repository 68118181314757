import {Routes} from '@angular/router';
import {FormRecordComponent} from './components/form-record/form-record.component';
import {CurrentRecordsComponent} from './components/current-records/current-records.component';
import {ManagerLayoutComponent} from '../../layouts/manager-layout/manager-layout.component';
import {ManagerGuard} from '../../core/guards/manager.guard';

export const routes: Routes = [
    {
        path: 'records',
        component: ManagerLayoutComponent,
        data: {
            menu: {
                name: 'Записи',
                type: 'sub',
                showInDashboard: true,
                showInSideBar: true,
                fontIcon: 'description',
                imageIcon: '../assets/img/dashboard-menu/file.svg'
            }
        },
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'current'
            },
            {
                path: 'current',
                component: CurrentRecordsComponent,
                canActivate: [ManagerGuard],
                data: {
                    menu: {
                        name: 'Список',
                        type: 'link',
                        showInDashboard: false,
                        showInSideBar: true,
                        fontIcon: 'dashboard',
                        imageIcon: ''
                    }
                },
            },
            {
                path: 'new',
                component: FormRecordComponent,
                canActivate: [ManagerGuard],
                data: {
                    menu: {
                        name: 'Новая запись',
                        type: 'link',
                        showInDashboard: false,
                        showInSideBar: true,
                        fontIcon: 'dashboard',
                        imageIcon: ''
                    }
                },
            },
            {
                path: 'edit/:id',
                component: FormRecordComponent,
                canActivate: [ManagerGuard]
            }
        ]
    },
];
