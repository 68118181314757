<form [formGroup]="form">
    <app-loader *ngIf="showLoader"></app-loader>

    <h2 class="text-center" mat-dialog-title>Инкасация</h2>
    <div class="text-center">
        Касса: {{cash.name}} <br>
        Доступная сумма: {{cash.balance}}₽
    </div>

    <div mat-dialog-content>
        <mat-form-field class="full-width">
            <input
                formControlName="sum"
                matInput
                placeholder="Сумма"
                title="Сумма"
                type="number">
            <mat-error *ngIf="form.controls.sum.hasError('required')">Обязательно для заполнения</mat-error>
            <mat-error *ngIf="form.controls.sum.hasError('max')">
                Некорректная сумма списания. В кассе доступно только {{cash.balance}}₽
            </mat-error>
        </mat-form-field>

        <br>
    </div>

    <mat-dialog-actions
        [ngClass]="{'no-events': showLoader}"
        class="d-flex justify-content-between">
        <div>
            <button (click)="close()" color="warn" mat-raised-button>Отменить</button>
        </div>

        <div>
            <button
                (click)="success()"
                [disabled]="form.invalid"
                color="accent"
                mat-raised-button>
                Инкасировать
            </button>
        </div>
    </mat-dialog-actions>
</form>
