<mat-form-field class="field">
    <mat-label>Номер телефона</mat-label>
    <input type="tel"
           placeholder="+7 ___ ___-__-__"
           autocomplete="off"
           [formControl]="inputValue"
           matInput
           [matAutocomplete]="auto"
           #inputPhone
           appAutofocus
           phonePrefix>
</mat-form-field>
<mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let client of filteredClients$ | async"
                [value]="client.phone"
                (click)="selectClient.emit(client)">
                <span>
                    <ng-container *ngIf="client.name">{{client.name}}, </ng-container>
                    {{client.phone}}
                </span>
    </mat-option>
</mat-autocomplete>
