import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {API_URL} from '../core/constants';

@Injectable()
export class RecordsService {

    constructor(private http: HttpClient) {
    }

    public getCount(): Observable<any> {
        return this.http.get(`${API_URL}/records/count`);
    }
}
