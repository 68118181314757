<h3 class="text-center">
    Изменения базовой цены для услуги
    <span *ngIf="serviceName"> - {{serviceName}}</span>
</h3>
<p class="text-center text-danger">Функционал использовать для услуг с плавающей ценой от - до</p>

<form novalidate [formGroup]="form" (submit)="success()">
    <mat-form-field class="full-width">
        <mat-label>Установить базовую цену</mat-label>
        <input type="number" matInput placeholder="Привести к цене" formControlName="price">
    </mat-form-field>

    <div class="d-flex justify-content-around">
        <button type="button" mat-raised-button color="warn" (click)="close()">Отменить</button>
        <button type="button" mat-raised-button color="primary" (click)="resetToBasePrice()">
            Сбросить цену до - {{serviceBasePrice}}
        </button>
        <button type="submit" mat-raised-button color="accent">Применить</button>
    </div>
</form>
