import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {API_URL} from '../../core/constants';

@Component({
    selector: 'app-sale-action-check',
    templateUrl: './sale-action-check.component.html',
    styleUrls: ['./sale-action-check.component.scss']
})
export class SaleActionCheckComponent implements OnInit {
    public form: FormGroup;
    public result = [];
    public error = null;

    constructor(
        private fb: FormBuilder,
        private http: HttpClient
    ) {
    }

    ngOnInit() {
        this.form = this.fb.group({
            carNumber: [null],
        });
    }

    submitHandler() {
        this.http.post(`${API_URL}/sale-action-check/6check`, this.form.value).subscribe((response: any) => {
            console.log(response);

            if (response.success) {
                this.error = null;
                this.result = response.data;
            } else {
                this.result = [];
                this.error = response.error;
            }
        });
    }
}
