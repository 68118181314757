<app-added-services
    [showError]="parentForm.controls['services'].invalid && parentForm.controls['services'].touched"
    [services]="selectedServices"
    (onUpdate)="updateItemFromSelectedServices($event)"
    (onRemove)="removeFromSelected($event)"
>
</app-added-services>
<br>

<div class="d-flex justify-content-end">
    <button type="submit" class="btn btn-fill btn-rose">
        Добавить новый заказ
    </button>
</div>

<br>
<div class="form-group">
    <div class="row">
        <div class="col-xs-12">
            <div>
                <mat-form-field class="full-width" [floatLabel]="'always'">
                    <mat-label>Быстрый поиск</mat-label>
                    <input matInput placeholder="Введите названия услуги для поиска" [formControl]="searchTerm"
                           autocomplete="off">
                </mat-form-field>

                <div *ngIf="servicesForSearch.length">
                    <app-service-list [items]="servicesForSearch"
                                      [showCategory]="true"
                                      (onSelect)="selectHandler($event)">
                    </app-service-list>
                </div>
            </div>
        </div>

        <div class="col-xs-12" [hidden]="!!searchTerm.value">
            <mat-selection-list class="clearfix services-mat-selection-list" #serviceList>
                <mat-tab-group class="services-mat-selection-list-group">
                    <mat-tab label="Популярные услуги">
                        <app-service-list [items]="servicesPopular"
                                          [showCategory]="true"
                                          (onSelect)="selectHandler($event)">
                        </app-service-list>
                    </mat-tab>

                    <mat-tab
                        *ngFor="let parentCategory of servicesTabs"
                        [label]="parentCategory.name">
                        <div *ngFor="let category of parentCategory.children">
                            <div class="col-xs-12">
                                <h5>{{category.name}}</h5>
                                <hr>
                            </div>
                            <app-service-list [items]="category.services"
                                              (onSelect)="selectHandler($event)"></app-service-list>
                        </div>
                    </mat-tab>
                </mat-tab-group>
                <div class="clearfix"></div>

                <div class="panel-group" id="accordion">
                    <div class="panel panel-default">
                        <div class="panel-heading" id="headingOne">
                            <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne">
                                <h4 class="panel-title">
                                    Все услуги
                                    <i class="material-icons">keyboard_arrow_down</i>
                                </h4>
                            </a>
                        </div>

                        <div id="collapseOne" class="panel-collapse collapse">
                            <div class="panel-body">
                                <app-service-list [items]="servicesOther"
                                                  (onSelect)="selectHandler($event)"></app-service-list>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-selection-list>
        </div>
    </div>
</div>
