import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

@Component({
    selector: 'app-simple-autocomplete',
    templateUrl: './simple-autocomplete.component.html',
    styleUrls: ['./simple-autocomplete.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class SimpleAutocompleteComponent {
    @Input() data: any[];
    @Input() objectKey: string;
    @Output() selected: EventEmitter<any> = new EventEmitter<any>();

    public selectItem(item: any) {
        this.selected.emit(item);
    }
}
