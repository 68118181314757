import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-ask-reason',
    templateUrl: './confirm-ask-reason.component.html',
    styleUrls: ['./confirm-ask-reason.component.scss']
})
export class ConfirmAskReasonComponent implements OnInit {
    public rejectionReason = new FormControl('', [Validators.required, Validators.minLength(5)]);

    constructor(
        public dialogRef: MatDialogRef<ConfirmAskReasonComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    ngOnInit() {
    }

    close(): void {
        this.dialogRef.close({success: false});
    }

    success(): void {
        this.dialogRef.close({success: true, data: this.rejectionReason.value});
    }

}

