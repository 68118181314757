<div class="row" *ngIf="cars.length">
    <div class="col-xs-12">
        <h4 class="panel-title">Выберите машину</h4>

        <mat-radio-group>
            <mat-radio-button class="car-item"
                              *ngFor="let car of cars"
                              [value]="car.id"
                              (change)="onChange(car)">
                {{car.make?.name}} {{car.model?.name}} {{car.year}}({{car.number}})

                <ng-container *ngIf="!car.number_type">
                    <a type="button" class="btn btn-warning btn-xs"
                       target="_blank"
                       [routerLink]="['/clients/profile/'+ client.id +'/car/'+ car.id +'/update']">
                        <i class="material-icons">edit</i>
                        Тип номера не установлен
                    </a>
                </ng-container>
            </mat-radio-button>
        </mat-radio-group>
    </div>
</div>
