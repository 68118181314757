<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xs-12">
                <div class="card">
                    <div class="card-header card-header-icon" data-background-color="rose">
                        <i class="material-icons">contacts</i>
                    </div>

                    <div class="card-content">
                        <h4 class="card-title">Новый клиент</h4>
                        <form class="client-form form-horizontal" novalidate
                              [formGroup]="form"
                              (submit)="submit($event)">

                            <div class="row">
                                <div class="col-xs-12">
                                    <app-customer-phone-autocomplete
                                        formControlName="phone"
                                        (fullMatch)="onSelectClient($event)"
                                        (selectClient)="onSelectClient($event)"
                                    >
                                    </app-customer-phone-autocomplete>
                                </div>
                            </div>

                            <div class="row">
                                <app-input-car-number
                                    #inputCarNumber
                                    [parentForm]="form"
                                    [inputType]="'carNumberType'"
                                    [inputNumber]="'carNumber'"
                                    [typeClasses]="'col-xs-12 col-sm-4'"
                                    [numberClasses]="'col-xs-12 col-sm-4'"
                                    [showAutoComplete]="true"
                                    (onSelect)="selectCarNumber($event)">
                                </app-input-car-number>

                                <div class="col-xs-12 col-sm-4">
                                    <mat-form-field>
                                        <mat-select placeholder="Выбирите тип клиента"
                                                    formControlName="clientType">
                                            <mat-option *ngFor="let type of typeClients" [value]="type.id">
                                                {{ type.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="row">
                                    <div class="col-xs-12 col-sm-4">
                                        <app-autocomplete
                                            [parentForm]="form"
                                            [controlName]="'family'"
                                            [objectKey]="'Surname'"
                                            [data]="filteredSurname$"
                                            [placeholder]="'Фамилия'">
                                        </app-autocomplete>
                                    </div>

                                    <div class="col-xs-12 col-sm-4">
                                        <app-autocomplete
                                            [parentForm]="form"
                                            [controlName]="'name'"
                                            [objectKey]="'Name'"
                                            [data]="filteredName$"
                                            [placeholder]="'Имя'">
                                        </app-autocomplete>
                                    </div>

                                    <div class="col-xs-12 col-sm-4">
                                        <mat-form-field class="client-form__field">
                                            <input matInput placeholder="Отчество" formControlName="patronymic">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="client-form__photo-block">
                                    <button type="button" class="btn btn-fill btn-info btn-lg client-form__render-web"
                                            (click)="openWebCam()">
                                        <i class="material-icons">add</i> Добавить фото
                                    </button>
                                    <div #videoContainer class="client-form__video-container"></div>
                                    <div class="client-form__images-container" *ngIf="image">
                                        <img [src]="image">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-xs-12 text-right">
                                    <div class="form-group form-button">
                                        <button type="submit" class="btn btn-fill btn-rose">
                                            Добавить
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
