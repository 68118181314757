import {Component, OnDestroy, OnInit} from '@angular/core';
import {OrderService} from '../../services/order.service';
import {Subscription} from 'rxjs';
import {ChangePaymentTypeModalComponent} from '@app/states/orders/components/change-payment-type-modal/change-payment-type-modal.component';
import {UIMangerService} from '@services/ui-manger.service';
import {MatTableDataSource} from '@angular/material/table';

@Component({
    selector: 'app-last-orders',
    templateUrl: './last-orders.component.html',
    styleUrls: ['./last-orders.component.scss']
})
export class LastOrdersComponent implements OnInit, OnDestroy {
    displayedColumns = ['id', 'customer', 'serviceList', 'totalCost', 'paymentType', 'statuses', 'actions'];
    dataSource: MatTableDataSource<any[]>;

    private subscriptions: Subscription = new Subscription();

    constructor(
        private ordersService: OrderService,
        private ui: UIMangerService
    ) {
    }

    ngOnInit() {
        const lastOrders$ = this.ordersService.getLastOrders().subscribe((response) => {
            this.dataSource = new MatTableDataSource(response);
        });
        this.subscriptions.add(lastOrders$);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    deleteOrder(order: any) {
        const dialog$ = this.ui.showConfirm().subscribe(result => {
            if (result.success) {
                this.ui.loaderShow();
                this.ordersService.createRequestToDeleteOrder(order.id, result.data).subscribe((response: any) => {
                    this.ui.loaderHide();

                    if (response.success) {
                        this.ui.showAlert('ВНИМАНИЕ', 'Отправлен запрос на удаления');
                        order.is_removal = {
                            cause: result.data,
                        };
                    } else {
                        this.ui.showAlert('ВНИМАНИЕ', response.error);
                    }
                });
            }
        });
        this.subscriptions.add(dialog$);
    }

    changePaymentType(order: any): void {
        let currentPaymentType = 'cash';

        if (order.sum_card > 0) {
            currentPaymentType = 'card';
        }

        if (order.sum_legal > 0) {
            currentPaymentType = 'legal';
        }

        const dialog$ = this.ui.open(ChangePaymentTypeModalComponent, {
            order: order,
            currentPaymentType
        })
            .subscribe(result => {
                if (!result) {
                    return;
                }

                if (currentPaymentType === result.newPaymentType) {
                    return;
                }

                let orderToUpdate = {sumCash: 0, sumCard: 0,};

                if (result.newPaymentType === 'cash') {
                    orderToUpdate.sumCash = order.sum;
                }

                if (result.newPaymentType === 'card') {
                    orderToUpdate.sumCard = order.sum;
                }

                this.ui.loaderShow();
                this.ordersService.changePaymentType(order.id, orderToUpdate).subscribe(
                    (response: any) => {
                        this.ui.loaderHide();
                        order.sum_cash = orderToUpdate.sumCash;
                        order.sum_card = orderToUpdate.sumCard;
                    },
                    (error: any) => {
                        console.error(error);
                        this.ui.loaderHide();
                    }
                );
            });
        this.subscriptions.add(dialog$);
    }
}
