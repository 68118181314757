import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {RecordsService} from '../../services/records.service';
import {ConfirmComponent} from '@shared/components/confirm/confirm.component';
import {ConfirmTransferComponent} from './components/confirm-transfer/confirm-transfer.component';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import * as moment from 'moment';
import {AlertComponent} from '@shared/components/alert/alert.component';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-current-records',
    templateUrl: './current-records.component.html',
    styleUrls: ['./current-records.component.scss']
})
export class CurrentRecordsComponent implements OnInit, AfterViewInit, OnDestroy {
    public displayedColumns = ['fio', 'phone', 'car_number', 'when_datetime', 'when_remember', 'actions'];
    public dataSource: MatTableDataSource<any> = new MatTableDataSource();
    public isLoadingResults = true;
    public activeRow = 0;
    private subscription: Subscription;

    @ViewChild(MatSort) sort: MatSort;

    constructor(
        private service: RecordsService,
        private dialog: MatDialog,
        private route: ActivatedRoute,
    ) {
        this.subscription = new Subscription();
    }

    ngOnInit(): void {
        const route$ = this.route.queryParams.subscribe(params => {
            this.activeRow = +params['id'] || 0;
        });
        this.subscription.add(route$);

        const recordList$ = this.service.getDataList().subscribe((response) => {
            this.dataSource.data = response;
            this.isLoadingResults = false;
        });
        this.subscription.add(recordList$);
    }

    ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim().toLowerCase();
        this.dataSource.filter = filterValue;
    }

    public removeWithConfirm(itemRecord: any): void {
        const dialog$ = this.dialog
            .open(ConfirmComponent, {
                width: '60%',
                minHeight: '80%'
            })
            .afterClosed()
            .subscribe(result => {
                result && this.removeItemRecord(itemRecord);
            });
        this.subscription.add(dialog$);
    }

    public transferToOrder(record) {
        if (!moment(record.whenDatetime).isBetween(moment().startOf('day'), moment().endOf('day'))) {
            this.dialog.open(AlertComponent, {
                width: '60%',
                minHeight: '80%',
                data: {
                    name: 'ВНИМАНИЕ',
                    text: '<strong>Операция не может быть завершена! <br> Текущая дата не совпадает с датой записи</strong>',
                }
            });
            return;
        }

        const dialog$ = this.dialog
            .open(ConfirmTransferComponent, {
                width: '60%',
                minHeight: '80%',
                data: {record: record}
            })
            .afterClosed().subscribe(result => {
                if (result) {
                    this.service.transferToOrder(record.id).subscribe((response) => {
                        if (response.success) {
                            this.dataSource.data = this.dataSource.data.filter((item: any) => item.id !== record.id);
                        }
                    });
                }
            });
        this.subscription.add(dialog$);
    }

    private removeItemRecord(itemRecord: any) {
        this.subscription.add(
            this.service.removeRecord(itemRecord.id).subscribe((response) => {
                if (response.status) {
                    this.dataSource.data = this.dataSource.data.filter((item: any) => item.id !== itemRecord.id);
                }
            })
        );
    }
}

