<form [formGroup]="form" novalidate #domForm>
    <div class="row">
        <div class="col-xs-12 col-sm-4">
            <mat-form-field class="full-width">
                <input matInput placeholder="Фамилия" formControlName="surname" upperFirstLetter>
            </mat-form-field>
        </div>

        <div class="col-xs-12 col-sm-4">
            <mat-form-field class="full-width">
                <input matInput placeholder="Имя" formControlName="name" upperFirstLetter>
            </mat-form-field>
        </div>

        <div class="col-xs-12 col-sm-4">
            <mat-form-field class="full-width">
                <input matInput placeholder="Отчество" formControlName="patronymic" upperFirstLetter>
            </mat-form-field>
        </div>
    </div>

    <mat-form-field class="full-width">
        <input type="tel" matInput placeholder="Телефон" formControlName="phone" [textMask]="{mask: maskPhone}">
    </mat-form-field>

    <div>
        <mat-radio-group labelPosition="after" formControlName="sex" class="full-width">
            <mat-radio-button class="radio-item" value="0">Женщина</mat-radio-button>
            <mat-radio-button class="radio-item" value="1">Мужчина</mat-radio-button>
        </mat-radio-group>
    </div>
    <br>
    <div>
        <mat-radio-group labelPosition="after" formControlName="typeId" class="full-width">
            <mat-radio-button class="radio-item" value="1">Физ. лицо</mat-radio-button>
            <mat-radio-button class="radio-item" value="2">Юр. лицо</mat-radio-button>
            <mat-radio-button class="radio-item" value="3">Сотрудник</mat-radio-button>
        </mat-radio-group>
    </div>

    <div class="panel" *ngIf="form.controls.typeId.value == 2">
        <div class="panel-heading"><h4>Данные Юр. Лица</h4></div>
        <div class="panel-body" formGroupName="legalPerson">

            <mat-form-field class="full-width">
                <input type="text" matInput placeholder="ИНН" formControlName="inn">
            </mat-form-field>

            <mat-form-field class="full-width">
                <input type="text" matInput placeholder="ОГРН" formControlName="ogrn">
            </mat-form-field>

            <mat-form-field class="full-width">
                <input type="text" matInput placeholder="Названия компании" formControlName="companyName">
            </mat-form-field>

            <mat-form-field class="full-width">
                <input type="text" matInput placeholder="КПП" formControlName="kpp">
            </mat-form-field>

            <mat-form-field class="full-width">
                <input type="text" matInput placeholder="Банковский счет" formControlName="bankAccount">
            </mat-form-field>
        </div>
    </div>

    <mat-form-field class="full-width">
        <input matInput [matDatepicker]="picker" placeholder="День рождения" formControlName="dateOfBirthday">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
        <mat-select placeholder="Любимая услуга" formControlName="loveServiceId">
            <mat-option>Нет любимых услуг</mat-option>
            <mat-option *ngFor="let service of loveServicesList" [value]="service.id">
                {{service.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width">
        <textarea matInput placeholder="Заметки"
                  formControlName="note"
                  matTextareaAutosize matAutosizeMinRows="2" matAutosizeMaxRows="5"></textarea>
    </mat-form-field>

    <div class="clearfix"></div>
</form>

<ng-container *ngIf="numberOfVisitsInTheLastThreeMonths">
    <h4>Количество посещений за последние 3 месяца - <strong>{{numberOfVisitsInTheLastThreeMonths}}</strong></h4>
    <br>
</ng-container>

<div *ngIf="client">
    <app-customer-shares-info [customerId]="client.id"></app-customer-shares-info>
</div>
