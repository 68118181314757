// @ts-ignore
// @ts-nocheck

/**
 * @param {number} min
 * @param {number} max
 * @return {number}
 */
export const randomIntegerInRange = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

/**
 * @return {string}
 */
export const uuid = () =>
    ([1e7] as any + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
    );

/**
 * @param {string} fieldName
 * @param {Array} data
 * @return Array
 */
export const pluck = (fieldName, data = []) => data.map(item => item[fieldName]);

export const phoneFixAndPrepare = phone => {
    if (phone && phone.length > 16) {
        phone = phone.slice(0, 16);
    }

    return phone.replace(/\D+/g, '');
};

export const clearCarNumber = (number: string) => {
    return number.replace(/_/g, '').replace(/\s/g, '');
};

/**
 * @param {string} str
 * @return {string}
 */
export const ucFirst = str => {
    if (!str) return str;

    return str[0].toUpperCase() + str.slice(1);
};
