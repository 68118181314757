import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ModalTimePickerComponent} from '../../time-picker/modal-time-picker/modal-time-picker.component';
import {Moment} from 'moment';
import {Observable} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';

@Component({
    selector: 'app-date-time-picker',
    templateUrl: './date-time-picker.component.html',
    styleUrls: ['./date-time-picker.component.scss'],
})
export class DateTimePickerComponent implements OnInit, OnChanges {
    @Input() label: string;
    @Input() datePlaceholder: string;
    @Input() timePlaceholder: string;
    @Input() date: any;
    @Input() time: any;
    @Input() dateTime: any;
    @Input() minDate: Date;
    @Input() maxDate: Date;
    @Output() change: EventEmitter<any> = new EventEmitter<any>();

    frontDate = '';
    frontTime = '';

    constructor(private dialog: MatDialog) {
    }

    ngOnInit() {
        if (this.date) {
            this.frontDate = this.date || '';
        }

        if (this.time) {
            this.frontTime = this.time || '';
        }

        if (this.dateTime) {
            this.parseDateTime(this.dateTime);
        }

        if (!this.minDate) {
            this.minDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0 ,0);
        }

        if (!this.maxDate) {
            this.maxDate = new Date(new Date().getFullYear() + 1, 11, 31);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.dateTime) {
            this.parseDateTime(changes.dateTime.currentValue);
        }
    }

    public dateChange(event: MatDatepickerInputEvent<Moment>) {
        this.frontDate = event.value.format('YYYY-MM-DD');
        this.onChange();

        this.openDialog().subscribe(result => {
            if (result !== undefined && result.time !== undefined) {
                this.frontTime = `${result.time.hour}:${result.time.minute}:00`;
                this.onChange();
            }
        });
    }

    public onTimeChange() {
        this.openDialog().subscribe(result => {
            if (result !== undefined && result.time !== undefined) {
                this.frontTime = `${result.time.hour}:${result.time.minute}:00`;
                this.onChange();
            }
        });
    }

    private openDialog(): Observable<any> {
        return this.dialog
            .open(ModalTimePickerComponent, {data: {}})
            .afterClosed();
    }

    private onChange() {
        this.change.emit({date: this.frontDate, time: this.frontTime});
    }

    private parseDateTime(dateTime: string): void {
        if (dateTime) {
            const [date, time] = dateTime.split(' ');
            this.frontDate = date;
            this.frontTime = time;
        } else {
            this.frontDate = null;
            this.frontTime = null;
        }
    }
}
