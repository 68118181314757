import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {API_URL} from '@app/core/constants';
import {Observable, Subscription} from 'rxjs';

@Component({
    selector: 'app-sessions',
    templateUrl: './sessions.component.html',
    styleUrls: ['./sessions.component.scss']
})
export class SessionsComponent implements OnInit, OnDestroy {
    sessions: any[];
    private subscriptions: Subscription;

    constructor(private http: HttpClient) {
        this.sessions = [];
        this.subscriptions = new Subscription();
    }

    ngOnInit(): void {
        const sessions$ = this.getSession().subscribe((response: any) => {
            this.sessions = response.data;
        });
        this.subscriptions.add(sessions$);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private getSession(): Observable<any> {
        return this.http.get(`${API_URL}/salaries/sessions`);
    }
}
