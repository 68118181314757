import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {API_URL} from '../core/constants';
import {Observable} from 'rxjs';

@Injectable()
export class StorageService {

    constructor(private http: HttpClient) {
    }

    public getItemList(): Observable<any> {
        return this.http.get(`${API_URL}/storage`);
    }

    public getStatusForBar(): Observable<any> {
        return this.http.get(`${API_URL}/storage/statuses`);
    }

    public getDataForTheCreatingNewItem(): Observable<any> {
        return this.http.get(`${API_URL}/storage/listOfWarehousesAndProductTypes`);
    }

    public saveNewItemInStorage(data: any): Observable<any> {
        return this.http.post(`${API_URL}/storage`, data);
    }
}
