<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xs-12">
                <div class="card">
                    <div class="card-content">

                        <div class="table-responsive">
                            <table mat-table [dataSource]="dataSource" class="table">
                                <ng-container matColumnDef="user">
                                    <th *matHeaderCellDef>Добавил(а)</th>
                                    <td *matCellDef="let element">
                                        {{element.user.name}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="sum">
                                    <th *matHeaderCellDef>Сумма</th>
                                    <td *matCellDef="let element">
                                        {{element.sum}} руб.
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="payment_purpose">
                                    <th *matHeaderCellDef>Назначение платежа</th>
                                    <td *matCellDef="let element">
                                        {{element.payment_purpose || '-'}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="money_taken_from">
                                    <th *matHeaderCellDef>Откуда взяли деньги</th>
                                    <td *matCellDef="let element">
                                        {{element.money_taken_from || '-'}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="payment_type">
                                    <th *matHeaderCellDef>Тип оплаты</th>
                                    <td *matCellDef="let element">
                                        {{element.paymentTypeStatus || '-'}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="created_at">
                                    <th *matHeaderCellDef>Дата</th>
                                    <td *matCellDef="let element">
                                        {{element.created_at}}
                                    </td>
                                </ng-container>

                                <!-- actions Column -->
                                <!--<ng-container matColumnDef="actions">-->
                                    <!--<th *matHeaderCellDef>Действия</th>-->
                                    <!--<td *matCellDef="let element" class="nowrap td-actions text-right">-->
                                        <!--<button type="button" class="btn btn-success">-->
                                            <!--<i class="material-icons">edit</i>-->
                                        <!--</button>-->
                                    <!--</td>-->
                                <!--</ng-container>-->

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>
                        </div>

                        <mat-paginator [length]="resultsLength"
                                       [pageSize]="15">
                        </mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
