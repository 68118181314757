<div class="service">
    <div class="service__name">
        <button
            (click)="changeSale.emit(item)"
            *ngIf="item.active"
            mat-icon-button
            title="Добавления скидки"
            type="button">
            <i class="fa fa-percent service__icon-button"></i>
        </button>

        <button
            (click)="changeBasePrice.emit(item)"
            *ngIf="item.active"
            mat-icon-button
            title="Редактирования базовой цены"
            type="button">
            <mat-icon class="icon-min">money</mat-icon>
        </button>

        <button
            (click)="changeEmployee.emit(item)"
            *ngIf="item.without_employees !== 1 && item.active"
            mat-icon-button
            title="Изменения сотрудников для услуги" type="button">
            <mat-icon class="icon-min">group_add</mat-icon>
        </button>

        <span>
            {{ item.name }}:
            <span *ngIf="item.gradeName" class="service__grade">
                (Грейд - {{item.gradeName}})
            </span>
        </span>
    </div>

    <div class="service__value">
        <div>
            <span *ngIf="item.salePrice">{{ item.salePrice }} руб.</span>
            &nbsp;
            <span *ngIf="item.price !== item.salePrice" [class.old-price]="item.salePrice">{{item.price}} руб.</span>
        </div>

        <button (click)="remove.emit(item)"
                [title]="item.active ? 'Удаление услуги': 'Возврат услуги'"
                mat-icon-button>
            <mat-icon class="icon-min">
                {{ item.active ? 'delete' : 'settings_backup_restore' }}
            </mat-icon>
        </button>
    </div>
</div>
