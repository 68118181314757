import {ApplicationRef, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {API_URL} from '../../../../../../../../core/constants';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-grade-info',
    templateUrl: './grade-info.component.html',
    styleUrls: ['./grade-info.component.scss']
})
export class GradeInfoComponent implements OnInit, OnChanges {
    @Input('modelName') modelName: string;
    public carModel: any;
    public gradesGroupsList: any[];

    constructor(
        private app: ApplicationRef,
        private http: HttpClient
    ) {
        this.init = this.init.bind(this);
    }

    ngOnInit(): void {

    }

    ngOnChanges(changes: SimpleChanges): void {
        this.getModel(changes.modelName.currentValue).subscribe(this.init);
    }

    public updateGrade(grade: any): void {
        if (grade.activeInUI) {
            this.http.delete(`${API_URL}/cars/model-list/${this.carModel.id}/grade/${grade.id}`)
                .subscribe(
                    (response: any) => {
                        if (response.success) {
                            grade.activeInUI = false;
                        }
                    },
                    (error: any) => {
                        grade.activeInUI = true;
                    }
                );
        } else {
            this.http.put(`${API_URL}/cars/model-list/${this.carModel.id}`, {gradeId: grade.id, gradeCategoryId: grade.service_category_id})
                .subscribe(
                    (response: any) => {
                        if (response.success) {
                            grade.activeInUI = true;
                        }
                    },
                    (error: any) => {
                        grade.activeInUI = false;
                    }
                );
        }
    }

    private getModel(modelName: string): Observable<any> {
        return this.http.get(`${API_URL}/cars/models/${modelName}/grades`);
    }

    private init(response: any): void {
        if (response.success) {
            this.carModel = response.carModel;
            this.gradesGroupsList = response.gradesGroupsList;
        }
    }
}
