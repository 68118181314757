<div class="table-responsive">
    <table mat-table #table [dataSource]="dataSource" class="table">
        <ng-container matColumnDef="id">
            <th *matHeaderCellDef>ID</th>
            <td *matCellDef="let element"> {{element.id}} </td>
        </ng-container>

        <ng-container matColumnDef="date">
            <th *matHeaderCellDef>Дата</th>
            <td *matCellDef="let element"> {{element.createdAtFormat}} </td>
        </ng-container>

        <ng-container matColumnDef="serviceList">
            <th *matHeaderCellDef>Услуги</th>
            <td *matCellDef="let element">
                <div *ngFor="let service of element.services">
                    {{service.name}}
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="6nAction">
            <th *matHeaderCellDef>6я мойка</th>
            <td *matCellDef="let element">
                {{element.used_action ? 'Да' : 'Нет'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="fullSum">
            <th *matHeaderCellDef>Сумма</th>
            <td *matCellDef="let element">
                {{element.sum_without_discount}} руб
            </td>
        </ng-container>

        <ng-container matColumnDef="sale">
            <th *matHeaderCellDef>Скидка</th>
            <td *matCellDef="let element">
                {{element.sum_without_discount - element.sum}} руб
            </td>
        </ng-container>

        <ng-container matColumnDef="cost">
            <th *matHeaderCellDef>Итог</th>
            <td *matCellDef="let element">
                {{element.sum}} руб
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
