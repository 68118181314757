import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Subscription} from 'rxjs';
import {SelectGradeComponent} from './select-grade/select-grade.component';
import {API_URL} from '@app/core/constants';
import {HttpClient} from '@angular/common/http';
import {FormControl, FormGroup} from '@angular/forms';
import {uuid} from '@app/Helpers/Helper';
import {calculateFixedPrice} from '@app/Helpers/service-helper';
import {ConfirmComponent} from '../../confirm/confirm.component';
import {UIMangerService} from '@services/ui-manger.service';

@Component({
    selector: 'app-list-of-service-for-selection',
    templateUrl: './list-of-service-for-selection.component.html',
    styleUrls: ['./list-of-service-for-selection.component.scss']
})
export class ListOfServiceForSelectionComponent implements OnInit, OnDestroy, OnChanges {
    @Input() parentForm: FormGroup;
    @Input() client: any;
    @Input() clientGrades: any[];
    @Input() selectedCar: any;
    @Output() selectedItem: EventEmitter<any> = new EventEmitter<any>();

    servicesPopular = [];
    servicesOther = [];
    servicesTabs = [];
    servicesForSearch = [];
    selectedServices = [];
    searchTerm = new FormControl();
    private subscriptions: Subscription;

    constructor(
        private ui: UIMangerService,
        private http: HttpClient,
    ) {
        this.subscriptions = new Subscription();
    }

    ngOnInit(): void {
        // Получения данных из API
        this.http.get(`${API_URL}/serviceList`).subscribe((response: any) => {
            this.servicesPopular = response.data.popular;
            this.servicesOther = response.data.all;
            this.servicesTabs = response.data.tabs;
        });

        this.searchTerm.valueChanges.subscribe((term: string) => {
            if (!term.length) {
                this.servicesForSearch = [];
                return;
            }

            this.servicesForSearch = this.servicesOther
                .filter((item: any) => {
                    return item.name.toLowerCase().includes(term.toLowerCase());
                })
                .sort((a, b) => {
                    const nameA = a.category.name.toLowerCase();
                    const nameB = b.category.name.toLowerCase();

                    if (nameA < nameB) {
                        return -1;
                    }

                    if (nameA > nameB) {
                        return 1;
                    }

                    return 0;
                });
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.client) {
            this.client = changes.client.currentValue;

            // TODO: исправить этот момент и унифицировать переменные к одному виду, а то это пиздец
            if (this.client && this.client.loveServiceId) {
                this.client.love_service_id = this.client.loveServiceId;
            }
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    async selectHandler(service: any) {
        const exist = this.selectedServices.find((item: any) => item.id === service.id);

        if (!!exist) {
            const result = await this.ui.open(ConfirmComponent, {name: 'Добавить услугу еще 1 раз?'}).toPromise();

            if (!result) {
                return;
            }
        }

        this.setGradeToTheService({...service});
    }

    updateItemFromSelectedServices(service: any): void {
        this.selectedServices = this.selectedServices.map((item: any) => {
            if (service._id === item._id) {
                return service;
            }

            return item;
        });
        this.updateFormServices();
    }

    removeFromSelected(service: any) {
        this.selectedServices = this.selectedServices.filter((item: any) => {
            return item._id !== service._id;
        });
        this.updateFormServices();
    }

    clearSelectedServices(): void {
        this.selectedServices = [];
    }

    private selectedGrade(service: any): void {
        const dialog$ = this.ui.open(SelectGradeComponent, {serviceId: service.id})
            .subscribe((grade) => {
                if (grade) {
                    this._setGrade(service, grade);

                    if (this.selectedCar && this.selectedCar.model && this.selectedCar.model.id) {
                        this._setGradeToCarModel(this.selectedCar.model.id, service.master_service_category_id, grade.id);
                    }
                }
            });
        this.subscriptions.add(dialog$);
    }

    private updateFormServices() {
        /**
         * TODO: спредом мы убираем только ссылку на массив, так как в массиве у нас объекты ссылки на них будут просто скопированны,
         *       тоесть при изменении объекта дочерними компонентами он будет изменен во всех друшгих компонентах.
         */
        this.selectedItem.emit([...this.selectedServices]);
    }

    private setGradeToTheService(service: any) {
        const clientGrade = this._getGradeByCategoryId(service.master_service_category_id);

        if (clientGrade !== undefined) {
            this.http.get(`${API_URL}/serviceList/${service.id}/grades/${clientGrade.gradeId}`)
                .subscribe((response: any) => {
                    if (response.success) {
                        this._setGrade(service, {
                            id: response.data.grade.id,
                            name: response.data.grade.name,
                            price: response.data.price,
                        });
                    } else {
                        this.selectedGrade(service);
                    }
                });
        } else {
            this.selectedGrade(service);
        }
    }

    private updateAllUIServicesLists() {
        const selectedIds = this.selectedServices.map((service: any) => service.id);
        this.updateUIServicesLists(this.servicesPopular, selectedIds);
        this.updateUIServicesLists(this.servicesOther, selectedIds);

        this.servicesTabs.forEach((parent: any) => {
            parent.children.forEach((child: any) => {
                this.updateUIServicesLists(child.services, selectedIds);
            });
        });
    }

    private updateUIServicesLists(list: any[], selectedIds: number[]) {
        list.forEach((service: any) => {
            if (selectedIds.includes(service.id)) {
                service.isAdded = true;
                return;
            }

            service.isAdded = false;
        });
    }

    private _setGrade(service, grade) {
        if (grade) {
            service.price = grade.price;
            service.gradeName = grade.name;
            service.gradeId = grade.id;
            service._id = uuid();

            if (this.client && service.can_be_loved && service.id === this.client.love_service_id) {
                const fixed = calculateFixedPrice(service.price, service.love_percent);
                service.salePrice = service.price - fixed;
                service.discountFixed = fixed;
                service.discountPercent = service.love_percent;
            }


            this.selectedServices = [...this.selectedServices, service];
            this.updateFormServices();
            this.updateAllUIServicesLists();
        }
    }

    private _getGradeByCategoryId($masterCategoryId: number): any | undefined {
        return this.clientGrades.find((grade: any) => grade.categoryId === $masterCategoryId);
    }

    private _setGradeToCarModel(modelId: number, gradeCategoryId: number, gradeId: number) {
        this.http.put(`${API_URL}/cars/model-list/${modelId}`, {gradeCategoryId, gradeId}).subscribe((response: any) => {
            // TODO: так делать нельзя это нужно переделить, нужно пушить
            this.clientGrades.push({
                categoryId: gradeCategoryId,
                gradeId: gradeId,
            });
        });
    }
}
