import {ComponentRef, Directive, ElementRef, HostListener, Input} from '@angular/core';
import {Overlay, OverlayPositionBuilder, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {ServiceInfoComponent} from '@app/states/boxes/components/box-view/components/service-info/service-info.component';

@Directive({
    selector: '[serviceInfo]'
})
export class ServiceInfoDirective {
    @Input('serviceInfo') service = null;
    private overlayRef: OverlayRef;
    private canShow: boolean = false;

    constructor(
        private overlay: Overlay,
        private overlayPositionBuilder: OverlayPositionBuilder,
        private elementRef: ElementRef
    ) {
    }

    ngOnInit(): void {
        const service = this.service;
        this.canShow = service.responsibles && Array.isArray(service.responsibles) && service.responsibles.length && service.active;

        if (this.canShow) {
            const positionStrategy = this.overlayPositionBuilder
                .flexibleConnectedTo(this.elementRef)
                .withPositions([{
                    originX: 'center',
                    originY: 'top',
                    overlayX: 'center',
                    overlayY: 'bottom',
                    offsetY: -8,
                }]);

            this.overlayRef = this.overlay.create({positionStrategy});
        }
    }

    @HostListener('mouseenter')
    show() {
        if (this.canShow) {
            const tooltipRef: ComponentRef<ServiceInfoComponent> = this.overlayRef.attach(new ComponentPortal(ServiceInfoComponent));
            tooltipRef.instance.service = this.service;
        }
    }

    @HostListener('mouseout')
    hide() {
        if (this.canShow) {
            this.overlayRef.detach();
        }
    }
}
