import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-x-report',
  templateUrl: './x-report.component.html',
  styleUrls: ['./x-report.component.scss']
})
export class XReportComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
