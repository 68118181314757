<mat-table #table [dataSource]="dataSource" matSort>
    <!-- Date Column -->
    <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Дата </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.date}} </mat-cell>
    </ng-container>

    <!-- Text Column -->
    <ng-container matColumnDef="text">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Текст сообщения </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.text}} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
