<div>
    <div>
        <mat-radio-group *ngIf="boxes.length; else notEmptyBox">
            <div *ngFor="let box of boxes">
                <mat-radio-button class="example-radio-button" [value]="box.name" (change)="selected(box)">
                    {{box.name}} - {{box.description}}
                </mat-radio-button>
            </div>
        </mat-radio-group>

        <ng-template #notEmptyBox>
            Нет свободных боксов
        </ng-template>
    </div>

    <br>
    <div class="button-row d-flex justify-content-between">
        <button mat-raised-button color="warn" (click)="close()">Закрыть</button>
        <button mat-raised-button color="accent" (click)="successClose()" [disabled]="!boxes.length">
            Переместить
        </button>
    </div>
</div>
