import {BoxesComponent} from './components/boxes/boxes.component';
import {BoxViewComponent} from './components/box-view/box-view.component';
import {Routes} from '@angular/router';
import {ManagerLayoutComponent} from '../../layouts/manager-layout/manager-layout.component';
import {ManagerGuard} from '../../core/guards/manager.guard';

export const routes: Routes = [
    {
        path: 'boxes',
        component: ManagerLayoutComponent,
        data: {
            menu: {
                name: 'Боксы',
                type: 'link',
                showInDashboard: true,
                showInSideBar: true,
                fontIcon: 'apps',
                imageIcon: '../assets/img/dashboard-menu/boxes.svg'
            }
        },
        children: [
            {
                path: '',
                component: BoxesComponent,
                canActivate: [ManagerGuard]
            },
            {
                path: ':id',
                component: BoxViewComponent,
                canActivate: [ManagerGuard]
            }
        ]
    }
];
