<!-- это класс с тега nav - navbar-absolute-->
<nav #navbar class="navbar navbar-transparent">
    <div class="container-fluid">
        <div class="navbar-minimize">
            <button id="minimizeSidebar"
                    class="btn btn-round btn-white btn-fill btn-just-icon"
                    title="Переключатель меню">
                <i class="material-icons visible-on-sidebar-regular">more_vert</i>
                <i class="material-icons visible-on-sidebar-mini">view_list</i>
            </button>
        </div>

        <div class="navbar-header">
            <div class="d-flex align-items-center">
                <button class="btn btn-sm btn-white" (click)="backUrl()" [disabled]="isRoot"
                        title="Возвращает на предыдущую страницу">
                    <i class="fa fa-arrow-circle-left"></i>
                </button>

                <button class="btn btn-sm btn-white" routerLink="/" [disabled]="isRoot"
                        title="Переход на главную страницу">
                    <i class="fa fa-home"></i>
                </button>

                <a class="btn btn-sm btn-info" routerLink="/clients/new" title="Добавления нового клиента">
                    <i class="fa fa-user-plus"></i>
                </a>

                <a class="btn btn-sm btn-info" routerLink="/orders/shop" title="Создать заказ из холодильника">
                    <i class="fa fa-shopping-cart"></i>
                </a>

                <a class="btn btn-sm btn-info" routerLink="/clients/express-add" title="Создания экспресс заказа">
                    <i class="fa fa-rocket"></i>
                </a>


                <button type="button" class="navbar-toggle" (click)="sidebarToggle()">
                    <span class="sr-only">Toggle navigation</span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </button>
            </div>
        </div>

        <div class="collapse navbar-collapse">
            <div *ngIf="isMobileMenu()">
                <ul class="nav navbar-nav navbar-right">
                    <li title="Оповещения">
                        <app-notifications></app-notifications>
                    </li>

                    <li>
                        <a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown"
                           title="Персональное меню">
                            <i class="material-icons">person</i>
                            <p class="hidden-lg hidden-md">Profile</p>
                        </a>
                        <ul class="dropdown-menu" *ngIf="sessionState">
                            <li><a>{{user.name}}</a></li>
                            <li>
                                <hr>
                            </li>
                            <li (click)="getRevenue()"><a>Выручка за смену</a></li>
                            <li>
                                <hr>
                            </li>
                            <li *ngIf="!sessionState.shiftIsOpen"><a (click)="startShift()">Открыть смену</a></li>
                            <li *ngIf="sessionState.shiftIsOpen"><a (click)="closeShift()">Закрыть смену</a></li>
                            <li>
                                <hr>
                            </li>
                            <li><a (click)="restartBackendServer()">Перезагрузить сервер</a></li>
                            <li>
                                <hr>
                            </li>
                            <li><a (click)="logout()">Выйти из учетной записи</a></li>
                        </ul>
                    </li>

                    <li class="separator hidden-lg hidden-md"></li>
                </ul>

                <form class="navbar-form navbar-right" role="search">
                    <div class="form-group form-search is-empty">
                        <input type="text" class="form-control" placeholder="Поиск">
                        <span class="material-input"></span>
                    </div>

                    <button type="submit" class="btn btn-white btn-round btn-just-icon">
                        <i class="material-icons">search</i>
                        <div class="ripple-container"></div>
                    </button>
                </form>
            </div>
        </div>
    </div>
</nav>
