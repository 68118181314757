import {Routes} from '@angular/router';
import {TodoComponent} from './components/todo/todo.component';
import {ManagerLayoutComponent} from '../../layouts/manager-layout/manager-layout.component';
import {ManagerGuard} from '../../core/guards/manager.guard';

export const routes: Routes = [
    {
        path: 'todo',
        component: ManagerLayoutComponent,
        data: {
            menu: {
                name: 'TODO',
                type: 'link',
                showInDashboard: false,
                showInSideBar: true,
                fontIcon: 'perm_phone_msg',
                imageIcon: '../assets/img/dashboard-menu/file.svg'
            }
        },
        children: [
            {
                path: '',
                component: TodoComponent,
                canActivate: [ManagerGuard]
            }
        ]
    }
];
