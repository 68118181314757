import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {BoxInfoService} from '../../../../services/box-info.service';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-boxes',
    templateUrl: './boxes.component.html',
    styleUrls: ['./boxes.component.scss'],
})
export class BoxesComponent implements OnInit, OnDestroy {
    public boxList: Observable<any[]>;

    constructor(
        private sanitizer: DomSanitizer,
        private boxService: BoxInfoService,
    ) {}

    ngOnInit() {
        this.boxList = this.boxService.getAllBox();
    }

    ngOnDestroy() {
    }

}
