import {NgModule} from '@angular/core';
import {PhonePrefixDirective} from './phone-prefix/phone-prefix.directive';
import {AppAutofocusDirective} from './app-autofocus/app-autofocus.directive';
import {UpperFirstLetterDirective} from './upper-first-letter/upper-first-letter.directive';
import {CommonModule} from '@angular/common';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        PhonePrefixDirective,
        AppAutofocusDirective,
        UpperFirstLetterDirective,
    ],
    exports: [
        PhonePrefixDirective,
        AppAutofocusDirective,
        UpperFirstLetterDirective,
    ],
})
export class DirectivesModule {
}
