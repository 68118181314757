<div *ngIf="this.renderTable">
    <div class="material-datatables table-responsive">
        <mat-table #table [dataSource]="dataSource">
            <!-- Columns -->
            <ng-container matColumnDef="dateEnd">
                <mat-header-cell *matHeaderCellDef>Дата окончания</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.dateEnd}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="storage">
                <mat-header-cell *matHeaderCellDef>Склад</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.storage.name}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="type">
                <mat-header-cell *matHeaderCellDef>Тип товара</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.type.name}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="customer">
                <mat-header-cell *matHeaderCellDef>Клиент</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.customer.name}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="car">
                <mat-header-cell *matHeaderCellDef>Машина</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <div *ngIf="element.car">
                        <div>{{element.car.make}} {{element.car.model}}</div>
                        <div>{{element.car.number}}</div>
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef>Статус</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.status.name}}</mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
    </div>
</div>
