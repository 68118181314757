import {Pipe, PipeTransform} from '@angular/core';
import {Todo} from './todo';

@Pipe({
    name: 'filterCategory',
    pure: false
})
export class FilterPipe implements PipeTransform {

    transform(data: Todo[], value: number): any {
        return data.filter((item: Todo) => item.category === value);
    }

}
