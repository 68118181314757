<mat-card>
    <mat-card-header>
        <mat-card-title>Сотрудники прикрепленные к заказу №{{order.id}}</mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <div class="table-responsive">
            <table class="table table-striped" *ngIf="employeeList.length; else noEmployees">
                <thead>
                <tr>
                    <th>Имя Фамилия</th>
                    <th>Должность</th>
                    <th>ЗП за заказ</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let responsible of employeeList"
                    title="{{responsible.surname}} {{responsible.name}} {{responsible.patronymic}}">
                    <td>
                        <img *ngIf="responsible.photo" [src]="photoUrl + '/' + responsible.photo"
                             width="25px"
                             height="25px"
                             class="img-circle img-cover img-position-top-center">
                        {{responsible.name}} {{responsible.surname}}
                    </td>

                    <td>{{responsible.position?.name}}</td>

                    <td>
                        <div *ngIf="responsible.salary_of_order">{{responsible.salary_of_order}}₽</div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <ng-template #noEmployees><b>В услагх заказа не назначены исполнители!</b></ng-template>
    </mat-card-content>
</mat-card>
