import {SessionNeedCloseComponent} from '@shared/components/shifts/session-need-close/session-need-close.component';
import {SessionOpenModalComponent} from '@shared/components/shifts/session-open-modal/session-open-modal.component';
import {AuthBaseService} from '../services/auth-base.service';
import {SessionShiftService} from '../services/session-shift.service';
import {SessionEmployeeAssignmentsComponent} from '@shared/components/shifts/session-employee-assignments/session-employee-assignments.component';
import {MatDialog} from '@angular/material/dialog';

export function onInitProvider(authService: AuthBaseService, sessionShiftService: SessionShiftService, dialog: MatDialog) {
    return async () => {
        await authService.init();

        const result = await authService.getUserSubject().value;

        if (result) {
            try {
                const checkResponse = await sessionShiftService.checkSession().toPromise();

                if (checkResponse.needClosePreviousShift) {
                    const sessionNeedClose = await dialog.open(SessionNeedCloseComponent, {disableClose: true}).afterClosed().toPromise();

                    if (sessionNeedClose.needOpenSession) {
                        await dialog.open(SessionOpenModalComponent, {disableClose: true}).afterClosed().toPromise();
                    }
                }

                if (!checkResponse.success) {
                    await dialog.open(SessionOpenModalComponent, {disableClose: true}).afterClosed().toPromise();
                }

                if (checkResponse.success && !checkResponse.session.is_employee_installed) {
                    await dialog
                        .open(SessionEmployeeAssignmentsComponent, {
                            disableClose: true,
                            minWidth: '80%',
                            minHeight: '90%'
                        })
                        .afterClosed()
                        .toPromise();
                }
            } catch (e) {
            }
        }
    };
}
