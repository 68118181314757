import {Routes} from '@angular/router';
import {ListComponent} from './pages/list/list.component';
import {NewComponent} from './pages/new/new.component';
import {DataSharedResolver} from './services/data-shared-resolver.service';
import {ManagerLayoutComponent} from '../../layouts/manager-layout/manager-layout.component';
import {ManagerGuard} from '../../core/guards/manager.guard';
import {ShopOrderComponent} from './pages/shop-order/shop-order.component';

export const routes: Routes = [
    {
        path: 'orders',
        component: ManagerLayoutComponent,
        data: {
            menu: {
                name: 'Заказы',
                type: 'link',
                showInDashboard: true,
                showInSideBar: true,
                fontIcon: 'description',
                imageIcon: '../assets/img/dashboard-menu/file.svg'
            }
        },
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'list'
            },
            {
                path: 'list',
                component: ListComponent,
                canActivate: [ManagerGuard],
                // data: {
                //     menu: {
                //         name: 'Список заказов',
                //         type: 'link',
                //         showInDashboard: false,
                //         showInSideBar: true,
                //         fontIcon: 'dashboard',
                //         imageIcon: ''
                //     }
                // },
            },
            {
                path: 'new',
                component: NewComponent,
                canActivate: [ManagerGuard],
                resolve: {
                    customer: DataSharedResolver
                },
            },
            {
                path: 'edit',
                component: NewComponent,
                canActivate: [ManagerGuard]
            },
            {
                path: 'shop',
                component: ShopOrderComponent,
            },
        ]
    }
];
