import {Component, OnInit} from '@angular/core';
import {DashboardMenuServiceProvider} from './dashboard.service.provider';
import {MenuConfig, MenuService} from '../../services/menu/menu.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    providers: [DashboardMenuServiceProvider]
})
export class DashboardComponent implements OnInit {
    public menuItems: MenuConfig[] = [];

    constructor(
        private menuService: MenuService
    ) {
    }

    ngOnInit() {
        this.menuItems = this.menuService.getConfig();
    }

}
