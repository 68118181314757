<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xs-12">
                <p>
                    Запись должна учитывать рейтинг пользователя, если он записывался ранее и не опаздывал, то ему можно
                    на 3 часа запись дипазон открывать.
                    Если он раздолбай - то на час, иначе вообще нельзя и запись должна быть синхронизирована с сайтом.
                    Надо учитывать время оказания услуги при записи. Шиномонта одно, КМ - другое.
                    А если он записан в 2 бокса. Как вести учет?
                </p>
            </div>

            <div class="col-xs-12">
                <div class="card">
                    <div class="card-header card-header-icon" data-background-color="purple">
                        <i class="material-icons">assignment</i>
                    </div>
                    <div class="card-content">
                        <h4 class="card-title">Список записей</h4>
                        <div class="toolbar">
                            <div class="row">
                                <div class="col-xs-9 col-sm-10">
                                    <mat-form-field>
                                        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Фильтр">
                                    </mat-form-field>
                                </div>
                                <div class="col-xs-3 col-sm-2 text-right">
                                    <a class="btn btn-just-icon btn-round btn-youtube" [routerLink]="['/records/new']">
                                        <i class="material-icons">add</i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="material-datatables table-responsive">
                            <div *ngIf="isLoadingResults" class="spinner-wrap">
                                <mat-spinner></mat-spinner>
                            </div>

                            <table mat-table #table [dataSource]="dataSource" class="table" matSort>
                                <!-- Name Column -->
                                <ng-container matColumnDef="fio">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>ФИО</th>
                                    <td mat-cell *matCellDef="let element">
                                        <div>{{element.surname}} {{element.name}} {{element.patronymic}}</div>
                                        <div *ngIf="element.opComment" class="white-space-normal">
                                            <strong>Заметка: <i>{{element.opComment}}</i></strong>
                                        </div>
                                    </td>
                                </ng-container>

                                <!-- Phone Column -->
                                <ng-container matColumnDef="phone">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Номер телефона</th>
                                    <td mat-cell *matCellDef="let element">
                                        <div>{{element.phone}}</div>
                                        <div>{{element.phone2}}</div>
                                    </td>
                                </ng-container>

                                <!-- car_number Column -->
                                <ng-container matColumnDef="car_number">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Номер машины</th>
                                    <td mat-cell *matCellDef="let element">
                                        <span *ngIf="element.car && element.car.model && element.car.model.make">
                                            {{element.car.model.make.name}}
                                        </span>
                                        <span *ngIf="element.car && element.car.model">
                                            {{element.car.model.name}}
                                        </span>
                                        <div>
                                            {{element.carNumber}}
                                        </div>
                                    </td>
                                </ng-container>

                                <!-- when_datetime Column -->
                                <ng-container matColumnDef="when_datetime">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Время записи</th>
                                    <td mat-cell *matCellDef="let element">{{element.whenDatetime}}</td>
                                </ng-container>

                                <!-- when_remember Column -->
                                <ng-container matColumnDef="when_remember">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Напомнить</th>
                                    <td mat-cell *matCellDef="let element">{{element.whenRemember}}</td>
                                </ng-container>

                                <!-- actions Column -->
                                <ng-container matColumnDef="actions">
                                    <th mat-header-cell *matHeaderCellDef>
                                        Действия
                                    </th>
                                    <td mat-cell *matCellDef="let element" class="nowrap td-actions text-right">
                                        <button type="button"
                                                class="btn btn-info"
                                                title="Перевести запись в заказ"
                                                (click)="transferToOrder(element)">
                                            <i class="material-icons">check</i>
                                        </button>

                                        <button type="button"
                                                class="btn btn-success"
                                                title="Редактирования записи"
                                                [routerLink]="['/records/edit', element.id]">
                                            <i class="material-icons">edit</i>
                                        </button>

                                        <button type="button"
                                                class="btn btn-danger"
                                                title="Удалить запись"
                                                (click)="removeWithConfirm(element)">
                                            <i class="material-icons">close</i>
                                        </button>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                                    [id]="row.id" [class.active]="activeRow === row.id"></tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
