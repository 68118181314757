import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-time-picker',
    templateUrl: './time-picker.component.html',
    styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent implements OnInit {
    @Output() submit: EventEmitter<any> = new EventEmitter();
    private PAN_ACTION = {UP: 'panup', DOWN: 'pandown'};
    public hour: any = '00';
    public minute: any = '00';

    constructor() {
    }

    ngOnInit() {
    }

    hourUp(): void {
        if (+this.hour === 0) {
            this.hour = 23;
            return;
        }
        this.hour--;
        if (String(this.hour).length === 1) {
            this.hour = String('0' + this.hour);
        }
    }

    hourDown(): void {
        if (+this.hour % 23 === 0 && +this.hour !== 0) {
            this.hour = '00';
            return;
        }
        this.hour++;
        if (String(this.hour).length === 1) {
            this.hour = String('0' + this.hour);
        }
    }

    swipeHour($event: any): void {
        if ($event.additionalEvent === this.PAN_ACTION.UP && $event.timeStamp % 2 === 0) {
            this.hourUp();
        }

        if ($event.additionalEvent === this.PAN_ACTION.DOWN && $event.timeStamp % 2 === 0) {
            this.hourDown();
        }
    }

    minuteUp(): void {
        if (+this.minute === 0) {
            this.minute = 59;
            return;
        }
        this.minute--;
        if (String(this.minute).length === 1) {
            this.minute = String('0' + this.minute);
        }
    }

    minuteDown(): void {
        if (+this.minute % 59 === 0 && +this.minute !== 0) {
            this.minute = '00';
            return;
        }
        this.minute++;
        if (String(this.minute).length === 1) {
            this.minute = String('0' + this.minute);
        }
    }

    swipeMinute($event: any): void {
        if ($event.additionalEvent === this.PAN_ACTION.UP && $event.timeStamp % 2 === 0) {
            this.minuteUp();
        }

        if ($event.additionalEvent === this.PAN_ACTION.DOWN && $event.timeStamp % 2 === 0) {
            this.minuteDown();
        }
    }

    onSubmit(): void {
        this.submit.emit({
            hour: this.hour,
            minute: this.minute
        });
    }
}
