import {Routes} from '@angular/router';
import {StandardsComponent} from './components/standards/standards.component';
import {ManagerLayoutComponent} from '../../layouts/manager-layout/manager-layout.component';
import {ManagerGuard} from '../../core/guards/manager.guard';

export const routes: Routes = [
    {
        path: 'standards',
        component: ManagerLayoutComponent,
        data: {
            menu: {
                name: 'Стандарты',
                type: 'link',
                showInDashboard: true,
                showInSideBar: true,
                fontIcon: 'format_list_bulleted',
                imageIcon: '../assets/img/dashboard-menu/base.svg'
            }
        },
        children: [
            {
                path: '',
                component: StandardsComponent,
                canActivate: [ManagerGuard]
            }
        ]
    }
];
