<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <ng-container *ngFor="let item of menuItems">
                <div class="col-xs-12 col-sm-4 grid-item" *ngIf="item.showInDashboard">
                    <div class="card menu-item" [routerLink]="['/', item.path]" role="button" *ngIf="item.showInDashboard">
                        <div class="card-content text-center">
                            <picture>
                                <img [src]="item.imageIcon" class="menu-item__icon">
                            </picture>
                            <br>
                            <br>
                            <h4 class="card-title">{{ item.name }}</h4>
                        </div>

                        <ng-container *ngIf="item.path === 'orders'">
                            <app-unprocessed-orders class="card__info-block"></app-unprocessed-orders>
                        </ng-container>

                        <ng-container *ngIf="item.path === 'boxes'">
                            <app-box-status-bar class="card__info-block"></app-box-status-bar>
                        </ng-container>

                        <ng-container *ngIf="item.path === 'records'">
                            <app-record-count class="card__info-block"></app-record-count>
                        </ng-container>

                        <ng-container *ngIf="item.path === 'storage-of-tires'">
                            <app-tires-status-bar class="card__info-block"></app-tires-status-bar>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
