import {AfterViewInit, Component, EventEmitter, forwardRef, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef} from '@angular/core';
import {MIN_NUMBER_FOR_AUTOCOMPLETE, PHONE_MASK, PHONE_STANDART_LENGTH} from '@app/core/constants';
import maskInput from 'vanilla-text-mask/dist/vanillaTextMask.js';
import {debounceTime, distinctUntilChanged, map, switchMap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {phoneFixAndPrepare} from '@app/Helpers/Helper';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ClientsService} from '@app/states/clients/services/clients.service';

@Component({
    selector: 'app-customer-phone-autocomplete',
    templateUrl: './customer-phone-autocomplete.component.html',
    styleUrls: ['./customer-phone-autocomplete.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => CustomerPhoneAutocompleteComponent),
        multi: true
    }]
})
export class CustomerPhoneAutocompleteComponent implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor {
    @Output() selectClient: EventEmitter<any> = new EventEmitter<any>();
    @Output() fullMatch: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('inputPhone', {read: ViewContainerRef}) inputPhone: ViewContainerRef;

    inputValue: FormControl = new FormControl('');
    filteredClients$: Observable<any>;
    private maskedInputController;

    constructor(
        private clientsService: ClientsService,
    ) {
    }

    ngOnInit() {
        this.inputValue.valueChanges.subscribe((result) => {
            this.writeValue(result);
            this.onTouched();
        });

        this.filteredClients$ = this.inputValue.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            map(phoneFixAndPrepare),
            switchMap(phone => phone.length >= MIN_NUMBER_FOR_AUTOCOMPLETE
                ? this.clientsService.findClientsByPhone(phone)
                : of([])
            )
        );

        this.filteredClients$.subscribe((response: any[]) => {
            const phone = phoneFixAndPrepare(this.inputValue.value);

            if (phone.length === PHONE_STANDART_LENGTH && response.length === 1) {
                this.fullMatch.emit(response[0]);
                this.filteredClients$ = of([]);
            }
        });
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.maskedInputController = maskInput({
                inputElement: this.inputPhone.element.nativeElement,
                mask: PHONE_MASK
            });
        });
    }

    ngOnDestroy() {
        this.maskedInputController.destroy();
    }

    onChange: any = () => {
    };

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    onTouched: any = () => {
    };

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(val: any): void {
        if (!val || typeof val !== 'string') {
            return;
        }

        this.inputValue.setValue(val, {emitEvent: false});
        this.onChange(val);
    }
}
