import {Routes} from '@angular/router';
import {SessionsComponent} from './pages/sessions/sessions.component';
import {ManagerLayoutComponent} from '@app/layouts/manager-layout/manager-layout.component';
import {ManagerGuard} from '@app/core/guards/manager.guard';
import {SessionPositionsComponent} from '@app/states/salaries/pages/session-positions/session-positions.component';
import {SessionPositionEmployeesComponent} from '@app/states/salaries/pages/session-position-employees/session-position-employees.component';
import {EmployeeDetailComponent} from '@app/states/salaries/pages/employee-detail/employee-detail.component';

export const routes: Routes = [
    {
        path: 'salaries',
        component: ManagerLayoutComponent,
        data: {
            menu: {
                name: 'Зарплаты',
                type: 'link',
                showInDashboard: false,
                showInSideBar: true,
                fontIcon: 'list_alt',
                imageIcon: '../assets/img/dashboard-menu/file.svg'
            },
        },
        children: [
            {
                path: '',
                component: SessionsComponent,
                canActivate: [ManagerGuard],
                data: {
                    breadcrumb: [
                        {
                            label: 'Главная',
                            url: '/'
                        },
                        {
                            label: 'Смены',
                            url: ''
                        }
                    ]
                },
            },
            {
                path: 'session/:sessionId',
                component: SessionPositionsComponent,
                canActivate: [ManagerGuard],
                data: {
                    breadcrumb: [
                        {
                            label: 'Главная',
                            url: '/'
                        },
                        {
                            label: 'Смены',
                            url: '/salaries'
                        },
                        {
                            label: 'Смена №{{sessionId}}',
                            url: ''
                        }
                    ]
                },
            },
            {
                path: 'session/:sessionId/position/:positionId',
                component: SessionPositionEmployeesComponent,
                canActivate: [ManagerGuard],
                data: {
                    breadcrumb: [
                        {
                            label: 'Главная',
                            url: '/'
                        },
                        {
                            label: 'Смены',
                            url: '/salaries'
                        },
                        {
                            label: 'Смена №{{sessionId}}',
                            url: '/salaries/session/:sessionId'
                        },
                        {
                            label: 'Позиция - {{positionName}}',
                            url: ''
                        }
                    ]
                },
            },
            {
                path: 'session/:sessionId/position/:positionId/employees/:employeeId',
                component: EmployeeDetailComponent,
                canActivate: [ManagerGuard],
                data: {
                    breadcrumb: [
                        {
                            label: 'Главная',
                            url: '/'
                        },
                        {
                            label: 'Смены',
                            url: '/salaries'
                        },
                        {
                            label: 'Смена №{{sessionId}}',
                            url: '/salaries/session/:sessionId'
                        },
                        {
                            label: 'Позиция - {{positionName}}',
                            url: '/salaries/session/:sessionId/position/:positionId'
                        },
                        {
                            label: 'Сотрудник - {{employeeName}}',
                            url: ''
                        },
                    ]
                },
            },
        ]
    }
];
