<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xs-12">
                <div class="card">
                    <div class="card-header card-header-icon" data-background-color="purple">
                        <i class="material-icons">assignment</i>
                    </div>
                    <div class="card-content">
                        <h4 class="card-title">Карточка клиента</h4>

                        <div *ngIf="client">
                            <div class="row">
                                <div class="col-xs-12">
                                    <button mat-raised-button color="warn" class="pull-right" (click)="createNewOrder()">
                                        Создать заказ для клиента
                                    </button>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-xs-12 col-sm-3">
                                    <ul class="nav nav-pills nav-pills-thin nav-pills-rose nav-stacked">
                                        <li class="active">
                                            <a href="#tab1" data-toggle="tab">Карточка клиента</a>
                                        </li>
                                        <li>
                                            <a href="#tab-garage" data-toggle="tab">Гараж</a>
                                        </li>
                                        <li>
                                            <a href="#tab-visits" data-toggle="tab">Посещения</a>
                                        </li>
                                        <li>
                                            <a href="#tab3" data-toggle="tab">Статистика</a>
                                        </li>
                                        <li>
                                            <a href="#tab-sent-sms" data-toggle="tab">Отправленные SMS</a>
                                        </li>
                                        <li>
                                            <a href="#tab-send-sms" data-toggle="tab">Отправить SMS</a>
                                        </li>
                                        <li>
                                            <a href="#tab7" data-toggle="tab">Карты лояльности</a>
                                        </li>
                                        <li>
                                            <a href="#tab-calls" data-toggle="tab">Звонки</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-xs-12 col-sm-9">
                                    <div class="tab-content">
                                        <div class="tab-pane active" id="tab1">
                                            <app-card
                                                [client]="client"
                                                (update)="updateCustomerInfo($event)">
                                            </app-card>
                                        </div>

                                        <div class="tab-pane" id="tab-garage">
                                            <app-garage [cars]="client.cars" [clientId]="client.id"></app-garage>
                                        </div>

                                        <div class="tab-pane" id="tab-visits">
                                            <app-history-of-visits [client]="client"></app-history-of-visits>
                                        </div>

                                        <div class="tab-pane" id="tab3">
                                            Efficiently3
                                        </div>

                                        <div class="tab-pane" id="tab-sent-sms">
                                            <app-sent-sms></app-sent-sms>
                                        </div>

                                        <div class="tab-pane" id="tab-send-sms">
                                            <app-send-sms></app-send-sms>
                                        </div>

                                        <div class="tab-pane" id="tab7">
                                            Efficiently7
                                        </div>

                                        <div class="tab-pane" id="tab-calls">
                                            <app-history-of-calls></app-history-of-calls>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
