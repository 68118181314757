import {Injectable} from '@angular/core';
import {StorageInterface} from './storage.interface';
import {Todo} from '../components/todo/todo';
import {Observable, BehaviorSubject} from 'rxjs';
import {uuid} from '../../../Helpers/Helper';

@Injectable()
export class LocalStorageService implements StorageInterface {
    private key: string = 'todo-data';
    private storage: Storage = window.localStorage;
    private data: Todo[] = [];
    private connect$: BehaviorSubject<Todo[]> = new BehaviorSubject<Todo[]>([]);

    public constructor() {
        this.loadItems()
            .then((result: Todo[]) => {
                this.data = result;
                this.updateData();
            });
    }

    private loadItems(): Promise<Todo[]> {
        return new Promise((resolve) => {
            const storageResult = this.storage.getItem(this.key);
            const items = storageResult ? JSON.parse(storageResult) : [];

            return resolve(items.map(item => new Todo(item)));
        });
    }

    public getItem(): Observable<Todo[]> {
        return this.connect$.asObservable();
    }

    public addItem(data: Todo): void {
        data.setId(uuid());
        this.data.push(data);
        this.saveStorage();
        this.updateData();
    }

    public updateItem(data: Todo): void {
        this.data = this.data.map((item: Todo): Todo => {
            if (item.id === data.id) {
                item.isDone = !item.isDone;
            }

            return item;
        });
        this.saveStorage();
        this.updateData();
    }

    public removeItem(data: Todo): void {
        this.data = this.data.filter((item: Todo) => item.id !== data.id);
        this.saveStorage();
        this.updateData();
    }

    public clear(): void {
        this.storage.removeItem(this.key);
        this.data = [];
        this.updateData();
    }

    private saveStorage(): void {
        this.storage.setItem(this.key, JSON.stringify(this.data));
    }

    private updateData(): void {
        this.connect$.next(this.data)
    }
}
