import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {API_URL} from '@app/core/constants';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConfirmComponent} from '@shared/components/confirm/confirm.component';
import {uuid} from '@app/Helpers/Helper';
import {UIMangerService} from '@services/ui-manger.service';
import {OrderPaymentModalComponent} from '@shared/components/order-payment-modal/order-payment-modal.component';
import {Customer} from '@app/entities/Customer';

@Component({
    selector: 'app-shop-order',
    templateUrl: './shop-order.component.html',
    styleUrls: ['./shop-order.component.scss']
})
export class ShopOrderComponent implements OnInit, OnDestroy {
    selectedServices: any[] = [];
    form: FormGroup;
    services: any[] = [];
    cost: number = 0;

    constructor(
        private fb: FormBuilder,
        private http: HttpClient,
        private ui: UIMangerService,
    ) {
        this.form = this.fb.group({
            services: [[], [Validators.required]],
        });
    }

    ngOnInit(): void {
        this.http.get(`${API_URL}/orders/shopServices`).subscribe((response: any) => {
            this.services = response.data;
        });
    }

    ngOnDestroy(): void {
    }

    async selectHandler(service: any) {
        const _service: any = {...service, _id: uuid()};
        const exist = this.selectedServices.find((item: any) => item.id === _service.id);

        if (!!exist) {
            const result = await this.ui.open(ConfirmComponent, {name: 'Добавить услугу еще раз?'}).toPromise();

            if (!result) {
                return;
            }
        }

        this.selectedServices = [...this.selectedServices, _service];
        this.update();
    }

    /**
     * Обработчик изменений элементов которые находятся в добавленных
     * @param {Object} service
     */
    updateItemFromSelectedServices(service: any): void {
        this.selectedServices = this.selectedServices.map((item: any) => {
            if (service._id === item._id) {
                return service;
            }

            return item;
        });
        this.update();
    }

    removeFromSelected(service: any) {
        this.selectedServices = this.selectedServices.filter((item: any) => {
            return item._id !== service._id;
        });
        this.update();
    }

    resetForm(): void {
        this.selectedServices = [];
        this.update();
    }

    submit(): void {
        this.ui.getLoader().show();
        this.http.post(`${API_URL}/orders/shopStore`, this.form.value).subscribe((response: any) => {
            if (response.success) {
                this.resetForm();
                this.ui.getLoader().hide();
                // this.ui.showSnackBar(response.message);
                this.pay(response.order);
            }
        });
    }

    pay(order: any) {
        this.ui.open(OrderPaymentModalComponent, {
            orderId: order.id,
            customerType: Customer.TYPE_PHYSICAL,
            sum: order.sum,
            cashOperationType: 2
        })
            .subscribe(result => {
                if (result && result.success && result.order !== null) {
                    this.ui.showSnackBar(`Создан новый заказ в магазине №${result.order.id}`);
                    return;
                }

                this.pay(order);
            });
    }

    private update(): void {
        this.calculateCost();
        this.form.patchValue({services: this.selectedServices});
        this.updateUIServicesLists(this.services, this.selectedServices.map((service: any) => service.id));
    }

    private calculateCost(): void {
        this.cost = 0;
        this.selectedServices.forEach((item: any) => {
            this.cost += item.price;
        });
    }

    private updateUIServicesLists(list: any[], selectedIds: number[]) {
        list.forEach((service: any) => {
            if (selectedIds.includes(service.id)) {
                service.isAdded = true;
                return;
            }

            service.isAdded = false;
        });
    }
}
