<div class="card-content">
    <h4 class="card-title">Последние заказы</h4>

    <div class="order-table-wrap">
        <div class="table-responsive">
            <table #table [dataSource]="dataSource" class="table" mat-table>
                <ng-container matColumnDef="id">
                    <th *matHeaderCellDef mat-header-cell>№</th>
                    <td *matCellDef="let row" mat-cell>
                        <div>{{row.id}}</div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="customer">
                    <th *matHeaderCellDef mat-header-cell>Клиент</th>
                    <td *matCellDef="let row" mat-cell>
                        <ng-container *ngIf="row?.customer">
                            <div *ngIf="row.customer.name || row.customer.surname"
                                 [title]="'Клиент: ' + row.customer.surname + ' ' + row.customer.name"
                                 class="text-has-icon">
                                <i class="material-icons">person</i>
                                {{row.customer.name || row.customer.surname}}
                            </div>

                            <div *ngIf="row.customer.phone" class="text-has-icon"
                                 title="Телефон клиента">
                                <i class="material-icons">phone</i>
                                {{row.customer.phone}}
                            </div>
                        </ng-container>

                        <div *ngIf="row?.car" class="text-has-icon" title="Данные о машине клиента">
                            <i class="material-icons">directions_car</i>
                            <a [routerLink]="['/clients/profile', row.customer.id]">
                                {{row.car.number}}
                            </a>
                        </div>

                        <div class="text-has-icon" title="дата создания заказа">
                            <i class="material-icons">date_range</i> {{row.createdAtFormat}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="serviceList">
                    <th *matHeaderCellDef mat-header-cell>Список услуг</th>
                    <td *matCellDef="let row" mat-cell>
                        <div *ngFor="let serviceItem of row.services_list">
                            <div>
                                <span [innerHTML]="serviceItem.service.name" style="color: #000;"></span>
                                <div>
                                    <span *ngFor="let serviceEmployee of serviceItem.employees_list; let i = index" style="color: #333;">
                                    <span *ngIf="i > 0">,&nbsp;</span>
                                        {{serviceEmployee.surname}} {{serviceEmployee.name}} ({{serviceEmployee?.position?.name}})
                                    </span>
                                </div>
                            </div>

                            <div *ngIf="serviceItem.cause"><strong>Причина скидки: {{serviceItem.cause}}</strong></div>
                        </div>

                        <br>
                        <div *ngIf="row.note">
                            <strong>Заметки по заказу: <i>{{row.note}}</i></strong>
                        </div>
                        <div *ngIf="row.customer?.note">
                            <strong>Заметки по клиенту: <i>{{row.customer.note}}</i></strong>
                        </div>

                        <div *ngIf="row.is_removal?.cause">
                            <strong class="alert alert--mini alert-danger">
                                Причина удаления: {{row.is_removal.cause}}
                            </strong>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="totalCost">
                    <th *matHeaderCellDef mat-header-cell>Цена</th>
                    <td *matCellDef="let row" mat-cell>
                        <div>
                            Полная: {{row.sum_without_discount}} руб.
                        </div>
                        <div *ngIf="row.sum_without_discount !== row.sum">
                            {{row.sum_without_discount > row.sum ? 'Co скидкой' : 'С наценкой'}}:
                            {{row.sum}} руб.
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="paymentType">
                    <th *matHeaderCellDef mat-header-cell>Тип оплаты</th>
                    <td *matCellDef="let row" mat-cell>
                        <div>
                            <span *ngIf="row.sum_card > 0" class="alert alert--mini alert-rose">
                                Карта
                            </span>

                            <span *ngIf="row.sum_legal > 0" class="alert alert--mini alert-rose">
                                Безнал
                            </span>

                            <span *ngIf="row.sum_card === 0 && row.sum_legal === 0"
                                  class="alert alert--mini alert-rose">
                                Наличные
                            </span>
                        </div>

                        <div *ngIf="row.sum > 0 && row.sum_legal === 0">
                            <button (click)="changePaymentType(row)" class="btn-like-link"
                                    title="Вызывает окно для изменения типа оплаты для заказа"
                                    type="button">
                                Изменить тип оплаты
                            </button>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="statuses">
                    <th *matHeaderCellDef mat-header-cell>Статусы</th>
                    <td *matCellDef="let row" mat-cell>
                        <div>
                            <span *ngIf="row.status === 1" class="alert alert--mini alert-info">
                                <b>Новый</b>
                            </span>

                            <span *ngIf="row.status === 2"
                                  class="alert alert--mini alert-warning">
                                <b>В боксе</b>
                            </span>

                            <span *ngIf="row.status === 3"
                                  class="alert alert--mini alert-success">
                                <b>Завершен</b>
                            </span>

                            <span *ngIf="row.status === 4"
                                  class="alert alert--mini alert-danger">
                                <b>Отменен</b>
                            </span>
                        </div>

                        <div>
                            <span *ngIf="row.payment_status === 1"
                                  class="alert alert--mini alert-info">
                                <b>Оплачен</b>
                            </span>

                            <span *ngIf="row.payment_status === 0"
                                  class="alert alert--mini alert-danger">
                                <b>Не оплачен</b>
                            </span>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th *matHeaderCellDef mat-header-cell>Действия</th>
                    <td *matCellDef="let row" class="nowrap td-actions text-right" mat-cell>
                        <button
                            class="btn btn-warning"
                            type="button">
                            Чек возврата
                        </button>

                        <button
                            (click)="deleteOrder(row)"
                            *ngIf="!row.is_removal"
                            class="btn btn-danger"
                            title="Удалить заказ"
                            type="button"
                        >
                            <i class="material-icons">close</i>
                        </button>

                        <i *ngIf="row.is_removal"
                           class="material-icons"
                           style="color: red; padding: 1px 4px;"
                           title="Заказ отправлен на удаления">
                            error_outline
                        </i>
                    </td>
                </ng-container>

                <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
            </table>
        </div>
    </div>
</div>
