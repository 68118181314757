import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {routes} from './cashbox.routes';
import {CashboxComponent} from './components/cashbox/cashbox.component';
import {SwitchComponent} from './components/switch/switch.component';
import {MenuModule} from '@services/menu/menu.module';
import {AddComponent} from './components/add/add.component';
import {XReportComponent} from './components/x-report/x-report.component';
import {ZReportComponent} from './components/z-report/z-report.component';
import {ControlComponent} from './components/control/control.component';
import {AddExpenseComponent} from './pages/add-expense/add-expense.component';
import {SharedModule} from '@shared/shared.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '@app/core/modules/material/material.module';
import {ListOfExpensesComponent} from './components/list-of-expenses/list-of-expenses.component';
import {CashListComponent} from './pages/cash-list/cash-list.component';
import {CashCollectionComponent} from './components/cash-collection/cash-collection.component';
import {CollectionListComponent} from './pages/collection-list/collection-list.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MenuModule.forRoot({routes, order: 7}),
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
    ],
    declarations: [
        CashboxComponent,
        SwitchComponent,
        AddComponent,
        XReportComponent,
        ZReportComponent,
        ControlComponent,
        AddExpenseComponent,
        ListOfExpensesComponent,
        CashListComponent,
        CashCollectionComponent,
        CollectionListComponent,
    ],
    entryComponents: [
        CashCollectionComponent,
    ],
})
export class CashboxModule {
}
