import {
    AfterViewInit,
    ApplicationRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ClientFormService} from '@services/client-form.service';
import {clearCarNumber} from '@app/Helpers/Helper';
import {debounceTime, distinctUntilChanged, filter, map, switchMap} from 'rxjs/operators';
import {MIN_NUMBER_FOR_CAR_NUMBER_AUTOCOMPLETE} from '@app/core/constants';
import {of, Subscription} from 'rxjs';
import {ClientsService} from '@app/states/clients/services/clients.service';
import maskInput from 'vanilla-text-mask/dist/vanillaTextMask.js';
import {MatSelectChange} from '@angular/material/select';

@Component({
    selector: 'app-input-car-number',
    templateUrl: './input-car-number.component.html',
    // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputCarNumberComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('inputCarNumber') inputCarNumber: ElementRef;
    @Input() parentForm: FormGroup;
    @Input() inputType: string;
    @Input() inputNumber: string;
    @Input() typeClasses: string;
    @Input() numberClasses: string;
    @Input() showAutoComplete: boolean;
    @Input() needSetDefaults: boolean;
    @Output() onSelect: EventEmitter<any>;

    public carNumberPattern: string[];
    public carNumberPlaceholder;
    public typeCarNumbers: any[];
    public carNumberList: any[];
    private maskedInputController: any;
    private subscriptions: Subscription;

    constructor(
        private app: ApplicationRef,
        private clientFormService: ClientFormService,
        private clientsService: ClientsService,
    ) {
        this.showAutoComplete = this.showAutoComplete || false;
        this.needSetDefaults = this.needSetDefaults || true;
        this.carNumberPlaceholder = '';
        this.typeCarNumbers = [];
        this.onSelect = new EventEmitter<any>();
        this.maskedInputController = null;
        this.carNumberPattern = [];
        this.subscriptions = new Subscription();
    }

    ngOnInit() {
        this.inputType = this.inputType || 'carNumberType';
        this.inputNumber = this.inputNumber || 'carNumber';
        this.typeClasses = this.typeClasses || 'col-xs-5 col-sm-4';
        this.numberClasses = this.numberClasses || 'col-xs-7 col-sm-5';

        const clientFormService$ = this.clientFormService.getInputData().subscribe((result) => {
            this.typeCarNumbers = result.carNumberTypes;

            // TODO: это хак, срочно исправить, нужен для коректной работы формы редактирования машины.
            if (this.parentForm.controls[this.inputType].value) {
                this.parentForm.patchValue({carNumberType: this.parentForm.controls[this.inputType].value}, {emitEvent: false});
                this.updateCarNumberPlaceholderAndPattern(this.parentForm.controls[this.inputType].value);
            } else {
                if (this.needSetDefaults) {
                    this.setDefaultValueToFormsField();
                }
            }
        });
        this.subscriptions.add(clientFormService$);

        const filteredClients$ = this.parentForm.controls[this.inputNumber].valueChanges
            .pipe(
                filter(number => !!number),
                debounceTime(300),
                distinctUntilChanged(),
                map(clearCarNumber),
                switchMap(number => number.length >= MIN_NUMBER_FOR_CAR_NUMBER_AUTOCOMPLETE
                    ? this.clientsService.findClientsByCarNumber(number)
                    : of([])
                )
            )
            .subscribe((response: any[]) => {
                this.carNumberList = response;
            });
        this.subscriptions.add(filteredClients$);
    }

    ngAfterViewInit(): void {
    }

    ngOnDestroy(): void {
        this.destroyMask();
        this.subscriptions.unsubscribe();
    }

    public onChangeCarTypeNumber(data: MatSelectChange): void {
        this.updateCarNumberPlaceholderAndPattern(+data.value);

        if (this.inputCarNumber) {
            setTimeout(() => {
                this.inputCarNumber.nativeElement.focus();
                this.inputCarNumber.nativeElement.dispatchEvent(new Event('input'));
            });
        }
    }

    public onSelectedCarNumber(data: any) {
        this.carNumberList = [];
        this.onSelect.emit(data);
    }

    public setDefaultValueToFormsField(): void {
        this.parentForm.patchValue({
            carNumberType: 1, // DEFAULT VALUE ID 1
        });

        this.updateCarNumberPlaceholderAndPattern(1);
    }

    public clearDataOnBlur() {
        setTimeout(() => {
            this.carNumberList = [];
        }, 200);
    }

    private updateCarNumberPlaceholderAndPattern(id: number): void {
        if (this.typeCarNumbers.length) {
            const {placeholder, pattern} = this.typeCarNumbers.find((item) => item.id === id);
            this.carNumberPlaceholder = placeholder;
            this.carNumberPattern = pattern;
            this.updateMask();
        }
    }

    private updateMask(): void {
        this.destroyMask();
        this.maskedInputController = maskInput({
            inputElement: this.inputCarNumber.nativeElement,
            mask: this.carNumberPattern,
            keepCharPositions: true
        });
    }

    private destroyMask(): void {
        if (this.maskedInputController) {
            this.maskedInputController.destroy();
        }
    }
}
