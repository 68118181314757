<div class="main-content">
    <div class="container-fluid">
        <div class="box-card">
            <mat-card class="b-mat-cart">
                <div class="row">
                    <div class="col-xs-12 col-sm-6 box-card__header-block">
                        <div class="embed-responsive embed-responsive-16by9 video-item__overlay">
                            <video height="220" muted autoplay style="max-width: 100%;" controls
                                   *ngIf="box">
                                <source [src]="box.video_url" type="video/mp4"/>
                                Сожалею; ваш браузер не поддерживает HTML5 видео в WebM с VP8 или MP4 с H.264.
                            </video>
                        </div>

                        <div class="d-flex align-items-center">
                            <h6 *ngIf="box">{{box.description}}</h6>
                            <div *ngIf="box && box.order" class="b-left-margin">Заказ №{{box.order.id}}</div>
                        </div>

                        <app-box-status
                            *ngIf="box"
                            [status]="box.currentStatus.status"
                            [label]="box.currentStatus.label">
                        </app-box-status>
                    </div>

                    <div class="col-xs-12 col-sm-6 box-card__header-block">
                        <div>
                            <div *ngIf="box && box.order && box.order.car !== null">
                                <div class="box-card__list box-card__info">
                                    <div class="box-card__list-field info__field">
                                        <mat-icon class="box-card__list-field-icon">info_outline</mat-icon>
                                        <span class="box-card__list-field-value">
                                            {{box.order.car.make?.name}} {{box.order.car.model?.name}}
                                        </span>
                                        <span class="box-card__list-field-divider">/</span>
                                        <span class="box-card__list-field-value">
                                            <app-auto-number [number]="box.order.car.number"></app-auto-number>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="box && box.order">
                                <div class="box-card__service">
                                    <div class="box-card__list box-card__service-list">
                                        <h3 class="box-card__service-title">Услуги:</h3>

                                        <div class="box-card__list-field service__field"
                                             *ngFor="let item of box.order.services; trackBy: trackByServices"
                                             [attr.disabled]="!item.active">

                                            <div class="box-card__list-field-name">
                                                <i class="box-card__list-field-icon fa fa-percent"
                                                   *ngIf="item.active"
                                                   (click)="setSaleForServiceItem(item)"
                                                   title="Добавления скидки">
                                                </i>

                                                <span [serviceInfo]="item">{{item.name}}</span>
                                            </div>

                                            <div class="box-card__list-field-value">
                                                <div class="box-service-prices">
                                                    <span *ngIf="item.salePrice !== item.price">
                                                        {{ item.salePrice }} руб.
                                                    </span>
                                                    <span [class.old-price]="item.salePrice !== item.price">
                                                        {{ item.price }} руб.
                                                    </span>
                                                </div>

                                                <button mat-icon-button
                                                        *ngIf="item.active"
                                                        (click)="changeBasePrice(item)"
                                                        title="Редактирования базовой цены">
                                                    <mat-icon class="icon-min">money</mat-icon>
                                                </button>

                                                <button mat-icon-button
                                                        *ngIf="item.without_employees !== 1 && item.active"
                                                        (click)="changeEmployeeForServiceRow(item)"
                                                        title="Изменения сотрудников для услуги">
                                                    <mat-icon class="icon-min">group_add</mat-icon>
                                                </button>

                                                <button mat-icon-button
                                                        (click)="toggleServiceItemStatus(item)"
                                                        title="Активация/Деактивация услуги">
                                                    <mat-icon class="icon-min">
                                                        {{ item.active ? 'delete' : 'settings_backup_restore' }}
                                                    </mat-icon>
                                                </button>
                                            </div>
                                        </div>

                                        <div class="box-card__service-buttons">
                                            <div>
                                                <button class="btn btn-just-icon btn-round btn-youtube"
                                                        title="Установки скидки на ввесь заказ!"
                                                        [disabled]="box.order.payment_status == 1"
                                                        (click)="setSaleToOrder()">
                                                    <i class="fa fa-percent"></i>
                                                </button>
                                            </div>
                                            <div>
                                                <button class="btn btn-just-icon btn-round btn-youtube"
                                                        [disabled]="box.order.payment_status == 1"
                                                        (click)="openAddServiceModal()">
                                                    <i class="material-icons">add</i>
                                                </button>
                                            </div>
                                            <div></div>
                                        </div>

                                        <div class="box-card__list-field service__field service__field_total"
                                             *ngIf="serviceTotalCost !== 0">
                                            <div class="box-card__list-field-name service__field-name">
                                                <span><b>Итого:</b></span>
                                            </div>

                                            <div class="box-card__list-field-value">
                                                <div *ngIf="serviceSaleTotalCost !== serviceTotalCost">
                                                    <b>{{serviceSaleTotalCost}} руб</b>&nbsp;
                                                </div>

                                                <div [class.old-price]="serviceSaleTotalCost !== serviceTotalCost">
                                                    <b>{{serviceTotalCost}} руб</b>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="alert alert-warning" *ngIf="box && box.order && box.order.note">
                            <strong>Заметки о заказе: {{box.order.note}}</strong>
                        </div>
                    </div>
                </div>
            </mat-card>

            <app-time-info *ngIf="box && box.order"
                           [start]="box.order.when_started"
                           [end]="box.order.when_out"
                           [avgTime]="averageServiceExecutionTime">
            </app-time-info>

            <div *ngIf="box && box.order">
                <app-customer-shares
                    [customerId]="box.order.customer.id"
                    [currentOrderServices]="box.order.services"
                    (addSale)="setSaleByShares($event)"
                    (addServiceSale)="onAddServiceSale($event)"
                ></app-customer-shares>
                <br>
            </div>

            <div class="d-flex justify-content-between flex-xs-column b-bottom-margin" *ngIf="box">
                <div class="d-flex justify-content-between flex-xs-column full-width" *ngIf="box.order">
                    <mat-form-field style="min-width: 200px;">
                        <mat-label>Перевести клиента в другой бокс</mat-label>
                        <select
                            title="Управление боксами"
                            (change)="openChangeBoxModal()"
                            [(ngModel)]="selectedBoxId"
                            matNativeControl
                            required>
                            <option value="0">Вывод из бокса</option>
                            <option *ngFor="let itemBox of boxList" [value]="itemBox.id">{{itemBox.name}}</option>
                        </select>
                    </mat-form-field>

                    <button
                        class="btn btn-info"
                        mat-raised-button
                        (click)="payOrder()"
                        [disabled]="!!box.order.payment_status">
                        <i class="material-icons">credit_card</i>
                        {{box.order.payment_status ? 'Оплачен' : 'Оплатить заказ'}}
                    </button>

                    <button mat-raised-button color="warn" (click)="confirmOfCompleteOrder(tooltip)">
                        <span
                            [matTooltip]="'Для завершения заказа нужно прикрепить рабочих к боксу!'"
                            #tooltip="matTooltip"
                            matTooltipPosition="above"></span>
                        <i class="material-icons">done</i>
                        Завершить оказания услуг
                    </button>
                </div>

                <button mat-raised-button color="accent" [routerLink]="['/orders/list']" *ngIf="!box.order">
                    <i class="material-icons">add_circle_outline</i>
                    Привязять заказ к боксу
                </button>
            </div>

            <mat-card class="b-mat-cart" *ngIf="box && box.order && box.order.customer">
                <div class="row">
                    <div class="col-xs-12">
                        <h3 matSubheader>Информация о клиенте</h3>

                        <table class="table table-striped table-hover">
                            <tr>
                                <td>Телефон</td>
                                <td><b>{{box.order.customer.phone}}</b></td>
                            </tr>

                            <tr>
                                <td>Имя</td>
                                <td><b>{{box.order.customer.name}}</b></td>
                            </tr>

                            <tr>
                                <td>Всего заказов</td>
                                <td><b>{{box.order.customer.cnt_of_orders}}</b></td>
                            </tr>

                            <tr>
                                <td>Сумма балов</td>
                                <td><b>{{box.order.customer.bonuses}}</b></td>
                            </tr>

                            <tr>
                                <td>Пос. визит</td>
                                <td><b>{{box.order.customer.last_order_date}}</b></td>
                            </tr>

                            <tr>
                                <td>Заметка</td>
                                <td><b>{{box.order.customer.note}}</b></td>
                            </tr>
                        </table>

                        <div class="d-flex justify-content-end">
                            <button mat-raised-button color="basic"
                                    [routerLink]="['/clients/profile', box.order.customer.id]">
                                <i class="material-icons">edit</i>
                                Редактировать данныне клиента
                            </button>
                        </div>
                    </div>
                </div>
            </mat-card>


            <ng-container *ngIf="box && box.order && (employeeOfThisOrder | async) as employeeOfThisOrderList">
                <app-list-employee-of-this-order
                    [order]="box.order"
                    [employeeList]="employeeOfThisOrderList"
                >
                </app-list-employee-of-this-order>
            </ng-container>

            <div *ngIf="box">
                <br>
                <mat-card>
                    <mat-card-header>
                        <mat-card-title>Сотрудники прикрепленные к боксу {{box.shortName}}</mat-card-title>
                    </mat-card-header>

                    <mat-card-content>
                        <div class="table-responsive">
                            <table class="table table-striped"
                                   *ngIf="box.employeeList && box.employeeList.length; else noEmployees">
                                <thead>
                                <tr>
                                    <th>Имя Фамилия</th>
                                    <th>Должность</th>
                                    <th>ЗП за день</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let responsible of box.employeeList"
                                    title="{{responsible.surname}} {{responsible.name}} {{responsible.patronymic}}">
                                    <td>
                                        <img *ngIf="responsible.photo" [src]="photoUrl + '/' + responsible.photo"
                                             width="25px"
                                             height="25px"
                                             class="img-circle img-cover img-position-top-center">
                                        {{responsible.name}} {{responsible.surname}}
                                    </td>
                                    <td>{{responsible.position.name}}</td>
                                    <td>
                                        <div *ngIf="responsible.current_day_salary">
                                            Полная сумма: {{responsible.current_day_salary?.total_sum}}₽
                                        </div>
                                        <div *ngIf="responsible.current_day_salary">
                                            С учетом скидки: {{responsible.current_day_salary?.total_sum_sale}}₽
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <ng-template #noEmployees><b>В боксе нет рабочих</b></ng-template>
                    </mat-card-content>

                    <mat-card-actions>
                        <button mat-raised-button color="accent" (click)="changeEmployeesInBox()">
                            <i class="material-icons">person_add</i>
                            {{box.employeeList.length ? 'Сменить исполнителей' : 'Добавить исполнителей'}}
                        </button>
                    </mat-card-actions>
                </mat-card>
            </div>
        </div>
    </div>
</div>
