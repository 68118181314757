import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
    selector: '[upperFirstLetter]'
})
export class UpperFirstLetterDirective {

    constructor(
        private el: ElementRef,
    ) {
    }

    @HostListener('input', ['$event'])
    onInput($event) {
        const val = this.el.nativeElement.value;

        if (val !== null) {
            this.el.nativeElement.value = val.charAt(0).toUpperCase() + val.slice(1).toLowerCase();
        }
    }
}
