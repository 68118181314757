import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LastSelectedGradeService {
    private gradeId: number[];

    constructor() {
        this.gradeId = [];
    }

    getGradeId(): number[] {
        return this.gradeId;
    }

    setGradeId(value: number[]) {
        this.gradeId = [...value];
    }
}
