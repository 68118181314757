<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xs-12">
                <div class="card">
                    <div class="card-header card-header-icon" data-background-color="rose">
                        <i class="material-icons">phone</i>
                    </div>

                    <div class="card-content">
                        <h4 class="card-title">
                            Детализация по смене
                            <span *ngIf="session">&nbsp;({{session.type_value}})</span>
                            <span *ngIf="session" title="Открыта">&nbsp;{{session.created_at}}</span>
                            <span *ngIf="session && session.closed_at" title="Закрыта"> - {{session.closed_at}}</span>
                        </h4>

                        <div class="order-table-wrap">
                            <div class="spinner-wrap" *ngIf="false">
                                <mat-spinner *ngIf="false"></mat-spinner>
                            </div>

                            <table class="table table-hover" *ngIf="positions && positions.items.length > 0">
                                <thead>
                                <tr>
                                    <th>Позиция</th>
                                    <th>Итого ЗП</th>
                                    <th>Количество заказов</th>
                                </tr>
                                </thead>
                                <tbody>
                                <ng-container *ngFor="let position of positions.items">
                                    <tr [routerLink]="['/salaries/session', session.id, 'position', position.position_id]">
                                        <td>{{position.position_name}}</td>
                                        <td>{{position.prepare_sum || position.sum}} руб</td>
                                        <td>-</td>
                                    </tr>
                                </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
