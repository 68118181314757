import {Routes} from '@angular/router';
import {CashboxComponent} from './components/cashbox/cashbox.component';
import {SwitchComponent} from './components/switch/switch.component';
import {AddComponent} from './components/add/add.component';
import {XReportComponent} from './components/x-report/x-report.component';
import {ZReportComponent} from './components/z-report/z-report.component';
import {ControlComponent} from './components/control/control.component';
import {ManagerLayoutComponent} from '@app/layouts/manager-layout/manager-layout.component';
import {ManagerGuard} from '@app/core/guards/manager.guard';
import {AddExpenseComponent} from './pages/add-expense/add-expense.component';
import {ListOfExpensesComponent} from './components/list-of-expenses/list-of-expenses.component';
import {CashListComponent} from '@app/states/cashbox/pages/cash-list/cash-list.component';
import {CollectionListComponent} from '@app/states/cashbox/pages/collection-list/collection-list.component';

export const routes: Routes = [
    {
        path: 'cashbox',
        component: ManagerLayoutComponent,
        data: {
            menu: {
                name: 'Касса',
                type: 'sub',
                showInDashboard: true,
                showInSideBar: true,
                fontIcon: 'timeline',
                imageIcon: '../assets/img/dashboard-menu/cashbox.svg'
            }
        },
        children: [
            {
                path: '',
                component: CashboxComponent,
                canActivate: [ManagerGuard]
            },
            {
                path: 'cash-list',
                component: CashListComponent,
                canActivate: [ManagerGuard],
                data: {
                    menu: {
                        name: 'Список касс',
                        type: 'link',
                        showInDashboard: false,
                        showInSideBar: true,
                        fontIcon: 'dashboard',
                        imageIcon: ''
                    }
                },
            },
            {
                path: 'add-expense',
                component: AddExpenseComponent,
                canActivate: [ManagerGuard],
                data: {
                    menu: {
                        name: 'Добавить расход',
                        type: 'link',
                        showInDashboard: false,
                        showInSideBar: true,
                        fontIcon: 'dashboard',
                        imageIcon: ''
                    }
                },
            },
            {
                path: 'list-of-expense',
                component: ListOfExpensesComponent,
                canActivate: [ManagerGuard],
                data: {
                    menu: {
                        name: 'Список расходов',
                        type: 'link',
                        showInDashboard: false,
                        showInSideBar: true,
                        fontIcon: 'dashboard',
                        imageIcon: ''
                    }
                },
            },
            {
                path: 'collection-list',
                component: CollectionListComponent,
                canActivate: [ManagerGuard],
                data: {
                    menu: {
                        name: 'Список инкасаций',
                        type: 'link',
                        showInDashboard: false,
                        showInSideBar: true,
                        fontIcon: 'dashboard',
                        imageIcon: ''
                    }
                },
            },
            {
                path: 'switch',
                component: SwitchComponent,
                canActivate: [ManagerGuard],
                data: {
                    menu: {
                        name: 'Смены',
                        type: 'link',
                        showInDashboard: false,
                        showInSideBar: true,
                        fontIcon: 'dashboard',
                        imageIcon: ''
                    }
                },
            },
            {
                path: 'add',
                component: AddComponent,
                canActivate: [ManagerGuard],
                data: {
                    menu: {
                        name: 'Внесение наличных',
                        type: 'link',
                        showInDashboard: false,
                        showInSideBar: true,
                        fontIcon: 'dashboard',
                        imageIcon: ''
                    }
                },
            },
            {
                path: 'x-report',
                component: XReportComponent,
                canActivate: [ManagerGuard],
                data: {
                    menu: {
                        name: 'X-отчет',
                        type: 'link',
                        showInDashboard: false,
                        showInSideBar: true,
                        fontIcon: 'dashboard',
                        imageIcon: ''
                    }
                },
            },
            {
                path: 'z-report',
                component: ZReportComponent,
                canActivate: [ManagerGuard],
                data: {
                    menu: {
                        name: 'Z-отчет',
                        type: 'link',
                        showInDashboard: false,
                        showInSideBar: true,
                        fontIcon: 'dashboard',
                        imageIcon: ''
                    }
                },
            },
            {
                path: 'control',
                component: ControlComponent,
                canActivate: [ManagerGuard],
                data: {
                    menu: {
                        name: 'Сверка итогов',
                        type: 'link',
                        showInDashboard: false,
                        showInSideBar: true,
                        fontIcon: 'dashboard',
                        imageIcon: ''
                    }
                },
            }
        ]
    }
];
