import {Component, OnInit, AfterViewInit} from '@angular/core';
import {CompanyServiceListService} from '@services/company-service-list.service';

@Component({
    selector: 'app-price-list',
    templateUrl: './price-list.component.html',
    styleUrls: ['./price-list.component.scss']
})
export class PriceListComponent implements OnInit, AfterViewInit {
    public serviceCategoryList: any;

    constructor(private service: CompanyServiceListService) {
    }

    ngOnInit() {
        this.service.getDataList().subscribe((result: any) => {
            this.serviceCategoryList = result;
        });
    }

    ngAfterViewInit() {

    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim();
        filterValue = filterValue.toLowerCase();
        // this.dataSource.filter = filterValue;
    }
}
