import {Component, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {API_URL} from '@app/core/constants';
import {tap} from 'rxjs/operators';

@Component({
    selector: 'app-employee-detail',
    templateUrl: './employee-detail.component.html',
    styleUrls: ['./employee-detail.component.scss']
})
export class EmployeeDetailComponent implements OnInit {
    session: any;
    position: any;
    employee: any;
    managerInfo: any;
    ordersInfo: any;
    isLoading: boolean = false;
    private subscriptions: Subscription;

    constructor(
        private http: HttpClient,
        private route: ActivatedRoute,
    ) {
        this.session = null;
        this.position = null;
        this.employee = null;
        this.subscriptions = new Subscription();
    }

    ngOnInit(): void {
        const response$ = this.getEmployee().subscribe((response: any) => {
            this.session = response.session;
            this.position = response.position;
            this.employee = response.employee;
            this.managerInfo = response.manager_info;
            this.ordersInfo = response.orders_info;
            console.log(response);
            //this.breadcrumb.updateBreadcrumbLabels({positionName: this.position.name, employeeName: this.employee.fio});
        });
        this.subscriptions.add(response$);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private getEmployee(): Observable<any> {
        const {sessionId, positionId, employeeId} = this.route.snapshot.params;
        this.isLoading = true;
        return this.http.get(`${API_URL}/salaries/sessions/${sessionId}/positions/${positionId}/employees/${employeeId}`).pipe(
            tap(() => {
                this.isLoading = false;
            })
        );
    }
}
