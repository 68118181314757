import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {API_URL} from '@app/core/constants';

@Component({
    selector: 'app-collection-list',
    templateUrl: './collection-list.component.html',
    styleUrls: ['./collection-list.component.scss']
})
export class CollectionListComponent implements OnInit {
    list: any[] = [];

    constructor(
        private http: HttpClient,
    ) {
    }

    ngOnInit() {
        this.getList();
    }

    tackFn(index, item) {
        return item.id;
    }

    private getList() {
        return this.http.get(`${API_URL}/cashes/collections`).subscribe((response: any) => {
            if (response.success) {
                this.list = response.data;
                console.log(this.list);
            }
        });
    }
}
