import {Component, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';

@Component({
    selector: 'app-history-of-calls',
    templateUrl: './history-of-calls.component.html',
    styleUrls: ['./history-of-calls.component.scss']
})
export class HistoryOfCallsComponent implements OnInit {
    displayedColumns = ['date', 'whoCalled', 'talkTime', 'commentOnTheConversation', 'resultOfConversation'];
    dataSource = new MatTableDataSource(ELEMENT_DATA);

    constructor() {
    }

    ngOnInit() {
    }

}

const ELEMENT_DATA = [
    {date: '01-01-2018', whoCalled: 'Жека', talkTime: '4:30', commentOnTheConversation: 'нет коментов', resultOfConversation: 'все гуд'},
    {date: '02-01-2018', whoCalled: 'Саша', talkTime: '5:30', commentOnTheConversation: 'нет коментов', resultOfConversation: 'все гуд'},
    {date: '03-01-2018', whoCalled: 'Дима', talkTime: '6:30', commentOnTheConversation: 'нет коментов', resultOfConversation: 'все гуд'},
    {date: '04-01-2018', whoCalled: 'Андрей', talkTime: '0:30', commentOnTheConversation: 'нет коментов', resultOfConversation: 'все гуд'},
];
