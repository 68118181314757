import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface AlertData {
    name: string | null;
    text: string | null;
}

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
    blockName = 'Информация об операции';
    text = 'Операция выполнена успешно';

    constructor(
        public dialogRef: MatDialogRef<AlertComponent>,
        @Inject(MAT_DIALOG_DATA) public data: AlertData
    ) {
    }

    ngOnInit(): void {
        if (this.data) {
            if (this.data.name) {
                this.blockName = this.data.name;
            }

            if (this.data.text) {
                this.text = this.data.text;
            }
        }
    }

    close(): void {
        this.dialogRef.close(false);
    }
}
