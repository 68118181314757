<h3 class="text-center">
    Установка скидки на заказ!
</h3>

<div class="text-center">
    <p class="error-msg">Использовать только для установки скидки на ввесь заказ!</p>
</div>

<form novalidate [formGroup]="form" (submit)="success()">
    <mat-form-field class="full-width">
        <mat-label>Скидка в процентах</mat-label>
        <input type="number" matInput placeholder="%" formControlName="percent">
        <mat-error *ngIf="form.controls.percent.hasError('max')">
            Максимальное значение поля {{form.controls.percent.errors.max.max}},
            текущее значение {{form.controls.percent.errors.max.actual}}
        </mat-error>
    </mat-form-field>

    <mat-form-field class="full-width">
        <mat-label>Фиксированная скидка</mat-label>
        <input type="number" matInput placeholder="руб" formControlName="fixed">
    </mat-form-field>

    <mat-form-field class="full-width">
        <mat-label>Причина скидки</mat-label>
        <input type="text" matInput placeholder="Укажите причину установки скидки" formControlName="cause">
    </mat-form-field>

    <div class="d-flex justify-content-around">
        <button type="button" mat-raised-button color="warn" (click)="close()">Отменить</button>
        <button type="submit" mat-raised-button color="accent">Применить</button>
    </div>
</form>
