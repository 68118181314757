import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {CardComponent} from './components/profile/components/card/card.component';
import {ProfileComponent} from './components/profile/profile.component';
import {WebCamModule} from 'ack-angular-webcam';
import {TextMaskModule} from 'angular2-text-mask';
import {SharedModule} from '@shared/shared.module';
import {NewClientComponent} from './components/new-client/new-client.component';
import {WebCameraComponent} from './components/new-client/components/web-camera/web-camera.component';
import {FormClientInfoService} from './components/new-client/form-client-info.service';
import {routes} from './clients.routes';
import {MenuModule} from '@services/menu/menu.module';
import {HistoryOfVisitsComponent} from './components/profile/components/history-of-visits/history-of-visits.component';
import {HistoryOfCallsComponent} from './components/profile/components/history-of-calls/history-of-calls.component';
import {SentSmsComponent} from './components/profile/components/sent-sms/sent-sms.component';
import {SendSmsComponent} from './components/profile/components/send-sms/send-sms.component';
import {ClientListComponent} from './components/client-list/client-list.component';
import {ClientsService} from './services/clients.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {GarageComponent} from './components/profile/components/garage/garage.component';
import {CarFormComponent} from './components/profile/components/car-form/car-form.component';
import {ExpressAddComponent} from './components/express-add/express-add.component';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {GradeInfoComponent} from './components/profile/components/car-form/components/grade-info/grade-info.component';
import {MatCardModule} from '@angular/material/card';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatRadioModule} from '@angular/material/radio';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatListModule} from '@angular/material/list';
import {MatSnackBarModule} from '@angular/material/snack-bar';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MenuModule.forRoot({routes, order: 2}),
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        MatCardModule,
        MatSortModule,
        MatTableModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatRadioModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatChipsModule,
        MatIconModule,
        TextMaskModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        WebCamModule,
        SharedModule,
        MatAutocompleteModule,
        MatTooltipModule,
        MatCheckboxModule,
        MatListModule,
        MatSnackBarModule,
        NgxMatSelectSearchModule
    ],
    declarations: [
        ProfileComponent,
        CardComponent,
        NewClientComponent,
        WebCameraComponent,
        HistoryOfVisitsComponent,
        HistoryOfCallsComponent,
        SentSmsComponent,
        SendSmsComponent,
        ClientListComponent,
        GarageComponent,
        CarFormComponent,
        ExpressAddComponent,
        GradeInfoComponent,
    ],
    entryComponents: [
        WebCameraComponent,
    ],
    providers: [
        FormClientInfoService,
        ClientsService
    ]
})
export class ClientsModule {
}
