import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-service-item',
    templateUrl: './service-item.component.html',
    styleUrls: ['./service-item.component.scss']
})
export class ServiceItemComponent implements OnInit {
    @Input() item: any;

    @Output() remove: EventEmitter<any> = new EventEmitter<any>();
    @Output() changeSale: EventEmitter<any> = new EventEmitter<any>();
    @Output() changeBasePrice: EventEmitter<any> = new EventEmitter<any>();
    @Output() changeEmployee: EventEmitter<any> = new EventEmitter<any>();

    constructor() {
    }

    ngOnInit(): void {
        // console.log('ServiceItemComponent', this.item);
    }
}
