<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xs-12">
                <div class="card">
                    <div class="card-header card-header-icon" data-background-color="rose">
                        <i class="material-icons">contacts</i>
                    </div>

                    <div class="card-content">
                        <h4 class="card-title">Новый клиент</h4>
                        <app-order-create (onSubmit)="onSubmit($event)"></app-order-create>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
