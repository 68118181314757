<h2 mat-dialog-title class="text-center">Выбирите грейд</h2>

<mat-dialog-content>
    <div *ngIf="isFetchingResults" class="spinner-wrap">
        <mat-spinner></mat-spinner>
    </div>

    <div *ngIf="!isFetchingResults" class="grade--wrap">
        <mat-radio-group *ngIf="list.length; else notEmptyBox">
            <mat-radio-button
                *ngFor="let item of list"
                class="grade__radio-button"
                [value]="item.grade.id"
                (change)="selected(item)"
                [checked]="item.checkCarGrade"
            >
                {{item.grade.name}} - {{item.price}}
            </mat-radio-button>
        </mat-radio-group>

        <ng-template #notEmptyBox>
            Нет доступных грейдов
        </ng-template>
    </div>
</mat-dialog-content>

<mat-dialog-actions class="justify-content-between">
    <button mat-raised-button color="warn" (click)="close()">Отменить</button>

    <button mat-raised-button color="accent"
            (click)="successClose()"
            [disabled]="!list.length || !isSelected">
        Выбрать
    </button>
</mat-dialog-actions>
