import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {API_URL} from '@app/core/constants';
import {LastSelectedGradeService} from '@services/last-selected-grade.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-select-grade',
    templateUrl: './select-grade.component.html',
    styleUrls: ['./select-grade.component.scss']
})
export class SelectGradeComponent implements OnInit, OnDestroy {
    public list: any[] = [];
    private sub: Subscription;
    private selectedGrade: any;
    public isSelected: boolean;
    public isFetchingResults: boolean;
    public carGrades: number[];

    constructor(
        public dialogRef: MatDialogRef<SelectGradeComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private http: HttpClient,
        private lastGradeService: LastSelectedGradeService
    ) {
        this.isSelected = false;
        this.isFetchingResults = true;
        this.carGrades = this.data.defaultGrade ? this.data.defaultGrade : this.lastGradeService.getGradeId();
    }

    ngOnInit() {
        this.sub = this.http.get(`${API_URL}/serviceList/${this.data.serviceId}/grades`)
            .subscribe((response: any) => {
                this.list = this.checkCarGrade(response.data);
                this.isFetchingResults = false;
            });
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    public close(): void {
        this.dialogRef.close(false);
    }

    public successClose(): void {
        this.lastGradeService.setGradeId([this.selectedGrade.id]);
        this.dialogRef.close(this.selectedGrade);
    }

    public selected(item: any) {
        this.isSelected = true;
        this.selectedGrade = {
            id: item.grade.id,
            name: item.grade.name,
            price: item.price
        };
    }

    public checkCarGrade(data: any[]): any[] {
        return data.map((item) => {
            item.checkCarGrade = this.carGrades.includes(item.grade.id);

            if (item.checkCarGrade) {
                setTimeout(() => this.selected(item));
            }

            return item;
        });
    }
}
