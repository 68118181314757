import {Injectable} from '@angular/core';
import {Location} from '@angular/common';
import {NavigationEnd, Router} from '@angular/router';

@Injectable()
export class LocalHistoryService {
    public isRoot: boolean;

    constructor(
        private location: Location,
        private router: Router
    ) {
        this.isRoot = this.location.path() === '/dashboard';


        this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationEnd) {
                // this.isRoot = event.url === '/';
                this.isRoot = this.location.path() === '/dashboard';
            }
        });
    }
}
