import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RecordsService} from '../../../../services/records.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-transfer',
  templateUrl: './confirm-transfer.component.html',
  styleUrls: ['./confirm-transfer.component.scss']
})
export class ConfirmTransferComponent implements OnInit {
    public form: FormGroup;
    public blockName = 'Перевести запись в заказ';
    public record: any;
    public showNumberInput = false;
    public showSuccessfulUpdateNumber = false;

    constructor(
        public dialogRef: MatDialogRef<ConfirmTransferComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private service: RecordsService,
        private fb: FormBuilder
    ) {}

    ngOnInit() {
        this.record = this.data.record;

        this.form = this.fb.group({
            carNumberType: null,
            carNumber: ['', [Validators.required, Validators.minLength(5)]],
        });

        if (!this.record.carNumber) {
            this.showNumberInput = true;
        }
    }

    public addCarNumberToRecord(): void {
        if (this.form.valid) {
            this.service.addCarNumber(this.record.id, this.form.value).subscribe((response) => {
                if (response.success) {
                    this.record.carNumber = this.form.controls.carNumber.value;
                    this.showNumberInput = false;
                    this.showSuccessfulUpdateNumber = true;
                }
            });
        }
    }

    public close(): void {
        this.dialogRef.close(false);
    }

    public success(): void {
        this.dialogRef.close(true);
    }
}
