import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PHONE_MASK} from '@app/core/constants';
import {RecordsService} from '../../services/records.service';
import {AlertComponent} from '@shared/components/alert/alert.component';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {markAsTouchedAllFields} from '@app/Helpers/ReactiveFormHelper';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-form-record',
    templateUrl: './form-record.component.html',
    styleUrls: ['./form-record.component.scss']
})
export class FormRecordComponent implements OnInit, OnDestroy {
    form: FormGroup;
    maskPhone: string[] = PHONE_MASK;
    services: any[] = [];
    recordId: any = null;
    maxDateForRemember: Date;
    client: any;
    private subscription: Subscription;

    constructor(
        private fb: FormBuilder,
        private dialog: MatDialog,
        private service: RecordsService,
        private activeRoute: ActivatedRoute,
    ) {
        this.subscription = new Subscription();
        this.maxDateForRemember = this.getDefaultMaxDate();
    }

    ngOnInit() {
        const router$ = this.activeRoute.paramMap.subscribe((params) => {
            if (params.has('id')) {
                this.service.getRecordById(+params.get('id')).subscribe((response) => {
                    this.recordId = response.id;
                    this.fillFormWhenEditRecord(response);
                });
            }
        });
        this.subscription.add(router$);

        this.form = this.fb.group({
            customerId: null,
            surname: null,
            name: null,
            patronymic: null,
            phone: ['', [Validators.required]],
            phone2: null,
            whenDatetime: ['', [Validators.required]],
            whenRemember: ['', [Validators.required]],
            carNumberType: '',
            carNumber: '',
            services: [[]],
            note: null,
            whereDidYouHearAboutUs: null,
            make: null,
            model: null,
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    onChangeDate({date, time}): void {
        this.form.patchValue({
            whenDatetime: `${date} ${time}`
        });

        const d = new Date(date);
        d.setDate(d.getDate());
        this.maxDateForRemember = d;
    }

    onChangeRememberDate({date, time}): void {
        this.form.patchValue({
            whenRemember: `${date} ${time}`
        });
    }

    openServiceListModal(): void {
        alert('Реализовать компонент списка услуг / перенести из создания заказа');
    }

    onSubmit() {
        if (this.form.valid) {
            const create$ = this.service.create(this.form.value).subscribe((response) => {
                if (response.success) {
                    this.dialog.open(AlertComponent, {
                        width: '80%',
                        minHeight: '80%',
                        data: {name: '', text: ''}
                    });
                    this.resetForm();
                }
            });
            this.subscription.add(create$);
        } else {
            markAsTouchedAllFields(this.form.controls);
        }
    }

    onUpdate() {
        if (this.form.valid) {
            const update$ = this.service.edit(this.recordId, this.form.value).subscribe((response) => {
                if (response.success) {
                    this.dialog.open(AlertComponent, {
                        width: '90%',
                        minHeight: '80%',
                        data: {name: '', text: 'Запись успешно обновленна'}
                    });
                }
            });
            this.subscription.add(update$);
        }
    }

    onSelectClientByPhone(client: any): void {
        this.client = client;
        this.form.patchValue({
            customerId: client.id,
            surname: client.surname,
            name: client.name,
            patronymic: client.patronymic,
            phone: client.phone,
            note: client.note,
        });

        if (client.cars.length === 1) {
            this.carSelect(client.cars[0]);
        }
    }

    carSelect(car: any): void {
        const make = car.hasOwnProperty('make') && car.make ? car.make.name : null;
        const model = car.hasOwnProperty('model') && car.model ? car.model.name : null;

        this.form.patchValue({
            carNumberType: car.number_type,
            carNumber: car.number,
            make: make,
            model: model,
        });
    }

    private fillFormWhenEditRecord(data: any): void {
        this.services = data.servicesArr;
        this.form.patchValue({
            customerId: null,
            surname: data.surname,
            name: data.name,
            patronymic: data.patronymic,
            phone: data.phone,
            phone2: data.phone2,
            whenDatetime: data.whenDatetime,
            whenRemember: data.whenRemember,
            // carNumberType: data.name,
            carNumber: data.carNumber,
            services: data.servicesArr,
            note: data.opComment,
            whereDidYouHearAboutUs: null,
            make: null,
            model: null,
        });
    }

    private getDefaultMaxDate(): Date {
        return new Date(new Date().getFullYear() + 1, 11, 31);
    }

    private resetForm(): void {
        this.form.reset();
        this.services = [];
        this.maxDateForRemember = this.getDefaultMaxDate();
    }
}
