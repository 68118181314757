import {Inject, Injectable, InjectionToken} from '@angular/core';

export const MENU_CONFIG = new InjectionToken('menuConfig');

export interface MenuConfig {
    order: number;
    routes: any;
    path?: any;
    children?: MenuConfig[]
}

@Injectable()
export class MenuService {
    private menuConfig: MenuConfig[] = [];

    constructor(@Inject(MENU_CONFIG) menuConfig) {
        this.menuConfig = [...this.menuConfig, ...menuConfig]
            .sort(this.sortHandler)
            .map(this.preparedHandler);
    }

    public getConfig() {
        return this.menuConfig;
    }

    private sortHandler(a: MenuConfig, b: MenuConfig): number {
        return a.order > b.order ? 1 : -1;
    }

    private preparedHandler(item: MenuConfig): MenuConfig {
        if (item.routes[0].data !== undefined && item.routes[0].data.menu !== undefined) {
            item = {...item, ...item.routes[0].data.menu, path: item.routes[0].path};
        }

        if (item.routes[0].children !== undefined) {
            item.children = item.routes[0].children
                .map((item: any) => {
                    if (item.data !== undefined && item.data.menu !== undefined) {
                        item = {...item, ...item.data.menu, path: item.path, isSub: true};
                    }

                    return item;
                })
                .filter(item => item.isSub);
        }

        return item;
    }
}
