import {Injectable, OnDestroy, TemplateRef} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {LoaderService} from '@shared/components/loader/loader.service';
import {ConfirmAskReasonComponent} from '@shared/components/confirm-ask-reason/confirm-ask-reason.component';
import {AlertComponent} from '@shared/components/alert/alert.component';
import {ComponentType} from '@angular/cdk/portal';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class UIMangerService implements OnDestroy {
    private subscriptions: Subscription = new Subscription();

    constructor(
        private dialog: MatDialog,
        private loader: LoaderService,
        private snackBar: MatSnackBar
    ) {
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    /**
     * Открытие модально окна
     *
     * @param componentOrTemplateRef
     * @param data
     */
    open<T, D = any, R = any | boolean>(componentOrTemplateRef: ComponentType<T> | TemplateRef<T>, data: D | any = {}): Observable<R> {
        return this.dialog
            .open(componentOrTemplateRef, {
                width: '60%',
                minHeight: '80%',
                disableClose: true,
                data
            })
            .afterClosed();
    }

    /**
     * Расширяет метод open, по умолчанию возвращает promise
     *
     * @see open
     * @param componentOrTemplateRef
     * @param data
     */
    openAsPromise<D = any, R = any>(componentOrTemplateRef, data: D | any = {}): Promise<R> {
        return this.open(componentOrTemplateRef, data).toPromise();
    }

    showAlert(name: string, message: string, width: string = '60%', minHeight: string = '80%'): void {
        this.dialog.open(AlertComponent, {
            width: '60%',
            minHeight: '80%',
            data: {name, text: message}
        });
    }

    showConfirm(width: string = '60%', minHeight: string = '80%'): Observable<any> {
        return this.dialog
            .open(ConfirmAskReasonComponent, {
                width: '60%',
                minHeight: '80%'
            })
            .afterClosed();
    }

    getLoader(): LoaderService {
        return this.loader;
    }

    loaderShow(): void {
        this.loader.show();
    }

    loaderHide(): void {
        this.loader.hide();
    }

    getSnackBar(): MatSnackBar {
        return this.snackBar;
    }

    showSnackBar(text: string) {
        this.snackBar.open(text, null, {
            duration: 3 * 1000,
            verticalPosition: 'top',
            horizontalPosition: 'right'
        });
    }
}
