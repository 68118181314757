<div class="blocks-list">
    <div class="block" (panup)="swipeHour($event)" (pandown)="swipeHour($event)">
        <button class="button" (click)="hourUp()"><i class="material-icons icon">keyboard_arrow_up</i></button>
        <input type="number" placeholder="H" class="input" min="0" max="23" tabindex="1"
               #inputHour
               [(ngModel)]="hour">
        <button class="button" (click)="hourDown()"><i class="material-icons icon">keyboard_arrow_down</i></button>
    </div>
    <div><b>:</b></div>
    <div class="block" (panup)="swipeMinute($event)" (pandown)="swipeMinute($event)">
        <button class="button" (click)="minuteUp()"><i class="material-icons icon">keyboard_arrow_up</i></button>
        <input type="number" placeholder="M" class="input" min="0" max="59" tabindex="2"
               #inputMinute
               [(ngModel)]="minute">
        <button class="button" (click)="minuteDown()"><i class="material-icons icon">keyboard_arrow_down</i></button>

    </div>
    <div>
        <button mat-raised-button
                color="accent"
                (click)="onSubmit()"
                [disabled]="(inputHour.value.length > 2 || inputMinute.value.length > 2 ) || (inputHour.value.length === 0 || inputMinute.value.length === 0)">
            ОК
        </button>
    </div>
</div>

<mat-error *ngIf="inputHour.value.length > 2">Не верный формат часов</mat-error>
<mat-error *ngIf="inputMinute.value.length > 2">Не верный формат минут</mat-error>
