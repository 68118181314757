import {Component, OnDestroy, OnInit} from '@angular/core';
import {StorageService} from '../../../../services/storage.service';

@Component({
    selector: 'app-tires-status-bar',
    templateUrl: './tires-status-bar.component.html',
    styleUrls: ['./tires-status-bar.component.scss']
})
export class TiresStatusBarComponent implements OnInit, OnDestroy {
    public list: any[] = [];
    private subscriber;

    constructor(private storageService: StorageService) {
    }

    ngOnInit() {
        this.subscriber = this.storageService.getStatusForBar().subscribe((response: any) => {
            this.list = response.data;
        });
    }

    ngOnDestroy() {
        this.subscriber.unsubscribe();
    }
}
