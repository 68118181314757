import {Injectable} from '@angular/core';

@Injectable()
export class DashboardMenuService {
    private menuItem: Array<Object> = [];

    constructor() {
        this.menuItem = [
            {name: 'Новый клиент', link: '/clients/new', icon: '../assets/img/dashboard-menu/new-client.png'},
            {name: 'Новый звонок', link: '/new-call', icon: '../assets/img/dashboard-menu/phone-call.png'},
            {name: 'Боксы', link: '/boxes', icon: '../assets/img/dashboard-menu/boxes.png'},
            {name: 'Прайс-лист', link: '/price-list', icon: '../assets/img/dashboard-menu/invoice.png'},
            {name: 'Касса', link: '/cashbox', icon: '../assets/img/dashboard-menu/cashbox.png'},
            {name: 'Отчет', link: '/report', icon: '../assets/img/dashboard-menu/report.png'},
            {name: 'Стандарты', link: '/standards', icon: '../assets/img/dashboard-menu/base.png'},
            {name: 'Текущие записи', link: '/records/current', icon: '../assets/img/dashboard-menu/file.png'},
            {name: 'Хранения резины', link: '/storage-of-tires', icon: '../assets/img/dashboard-menu/tire.png'},
        ];
    }

    getMenuItem(): Array<Object> {
        return this.menuItem;
    }
}
