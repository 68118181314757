<mat-card class="b-mat-cart">
    <div *ngIf="attention"><b>Внимания время работы превысило ожидаемое время!!!</b></div>
    <mat-list class="time-info">
        <mat-list-item class="time-info__field">
            <span class="time-info__label">Начало:</span>
            <span class="time-info__value"><mat-icon>access_time</mat-icon> {{startTime}}</span>
        </mat-list-item>
        <mat-list-item class="time-info__field">
            <span class="time-info__label">В работе:</span>

            <span class="time-info__value">
                <mat-icon>timer</mat-icon>
                <ng-container *ngIf="diff.hours < 10">0{{diff.hours}}</ng-container>
                <ng-container *ngIf="diff.hours >= 10">{{diff.hours}}</ng-container>
                :
                <ng-container *ngIf="diff.minutes < 10">0{{diff.minutes}}</ng-container>
                <ng-container *ngIf="diff.minutes >= 10">{{diff.minutes}}</ng-container>
            </span>
        </mat-list-item>
        <mat-list-item class="time-info__field" *ngIf="endTime">
            <span class="time-info__label">Время окончания:</span>
            <span class="time-info__value"><mat-icon>timer_off</mat-icon>{{endTime}}</span>
        </mat-list-item>
        <mat-list-item class="time-info__field" *ngIf="!endTime">
            <span class="time-info__label">Приблизительное время окончания:</span>
            <span class="time-info__value"><mat-icon>timer_off</mat-icon>-</span>
            <!--<span class="time-info__value"><mat-icon>timer_off</mat-icon>{{estimatedEndTime}}</span>-->
        </mat-list-item>
    </mat-list>
</mat-card>
