<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xs-12">
        <div class="card">
          <div class="card-content">
            <div class="row">
              <div class="col-md-7">
                <br>
                <h3>Тут список позиций, которые заказал клиент</h3>

                <h3>Возможность добавить из базы услуг</h3>

                <h3>Возможность добавить из базы товаров</h3>

                <h3>Возможность добавить произвольную позицию</h3>

                <h3>Если перешли из бокса, тот тут заголовок</h3>

                <h2>Оплата заказа #135</h2>
              </div>
              <div class="col-md-5 pull-right btn-calc-block">
                <div class="row">
                  <div class="col-md-12">
                    <input type="text" class="form-control input-lg form-control-lg" value="850">
                  </div>
                </div>
                <!--mat-form-field class="example-form-field">
                  <input matInput type="text" placeholder="Clearable input" [(ngModel)]="value"/>
                  <button mat-button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="value=''">

                  </button>
                </mat-form-field-->
                <table class="table table-default">
                  <tr>
                    <td colspan="2">
                      <a class="btn btn-2w btn-behance">
                        <small>Наличные</small> <span>1000.00</span>
                      </a>
                    </td>
                    <td colspan="2">
                      <a class="btn btn-2w btn-dark">
                        <small>Сдача</small> <span>150.00</span>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td><a class="btn btn-lg btn-info">7</a></td>
                    <td><a class="btn btn-lg btn-info">8</a></td>
                    <td><a class="btn btn-lg btn-info">9</a></td>
                    <td rowspan="2">
                      <a href="#" class="btn btn-2h btn-lg btn-success">OK</a>
                    </td>
                  </tr>
                  <tr>
                    <td><a class="btn btn-lg btn-info">4</a></td>
                    <td><a class="btn btn-lg btn-info">5</a></td>
                    <td><a class="btn btn-lg btn-info">6</a></td>
                  </tr>
                  <tr>
                    <td><a class="btn btn-lg btn-info">1</a></td>
                    <td><a class="btn btn-lg btn-info">2</a></td>
                    <td><a class="btn btn-lg btn-info">3</a></td>
                  </tr>
                  <tr>
                    <td><a class="btn btn-lg btn-info">0</a></td>
                    <td><a class="btn btn-lg btn-warning">.</a></td>
                    <td><a class="btn btn-lg btn-danger">%</a></td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>