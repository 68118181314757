<div class="web-camera__loader" *ngIf="!loading">
    <mat-spinner></mat-spinner>
</div>

<ack-webcam
    #webcam
    [options]="cameraOption"
    (success)="onCamSuccess($event)"
    (catch)="onCamError($event)"
></ack-webcam>

<div class="web-camera__button-group" *ngIf="loading">
    <button type="button" class="btn btn-success" (click)="genBase64(webcam)">
        <i class="material-icons">check</i> Захват изображения
    </button>

    <button type="button" class="btn btn-danger" (click)="close()">
        <i class="material-icons">close</i> Закрыть
    </button>
</div>
