<div>
    <div *ngFor="let item of listAdded"
         class="section-tag section-tag--is-added">
        {{item.name}}
    </div>
</div>

<div class="total-cost">
    Сумма: <b>{{addedTotalCost}}</b> руб.
</div>
