import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-service-list',
    templateUrl: './service-list.component.html',
    styleUrls: ['./service-list.component.scss']
})
export class ServiceListComponent implements OnInit {
    @Input() items: any;
    @Input() showCategory: boolean;
    @Output() onSelect: EventEmitter<any>;

    constructor() {
        this.items = [];
        this.showCategory = false;
        this.onSelect = new EventEmitter<any>();
    }

    ngOnInit() {
        
    }

    public select(service): void {
        this.onSelect.emit(service);
    }
}
