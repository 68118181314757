<form [formGroup]="form">
    <app-loader *ngIf="showLoader"></app-loader>

    <h2 class="text-center" mat-dialog-title>Оплата заказа</h2>

    <div mat-dialog-content>
        <div class="d-flex align-items-center" style="font-size: 18px;">
            <div>Оплата в кассу</div>
            <div class="d-flex" style="margin-left: 10px">
                <strong>{{totalSum}} из {{sum}}</strong>

                <mat-error *ngIf="totalSum > sum" style="margin-left: 10px">Некорректная сумма списания</mat-error>
            </div>
        </div>
        <div>Еще нужно внести: <strong>{{sum - totalSum}}</strong></div>
        <br>

        <div *ngFor="let item of formCashes.controls; let i = index" formArrayName="cashes">
            <div [formGroupName]="i" class="row">
                <div class="col-sm-8">
                    <mat-form-field class="full-width">
                        <mat-label>Выбрать кассу</mat-label>
                        <mat-select (selectionChange)="updateCashItem(item)" formControlName="id">
                            <mat-option *ngFor="let cash of cashes" [value]="cash.id">
                                {{cash.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-sm-2">
                    <mat-form-field>
                        <input
                            (input)="checkTotalSum()"
                            formControlName="sum"
                            matInput
                            placeholder="Сумма"
                            title="Сумма"
                            type="number">
                    </mat-form-field>
                </div>

                <div class="col-sm-2">
                    <button (click)="removeCashItem(i)"
                            *ngIf="item.get('id').value && item.get('alreadyPaid').value === false"
                            color="warn"
                            mat-raised-button>
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </div>
        </div>

        <br>
        <br>
    </div>

    <mat-dialog-actions
        [ngClass]="{'no-events': showLoader}"
        class="d-flex justify-content-between">
        <div>
            <button (click)="close()" color="warn" mat-raised-button>Отменить</button>
        </div>

        <div>
            <mat-checkbox formControlName="sentTo1c" style="margin-right: 10px;">
                отправить в 1С
            </mat-checkbox>

            <button
                (click)="success()"
                [disabled]="totalSum > sum"
                color="accent"
                mat-raised-button>
                Подтвердить оплату
            </button>
        </div>
    </mat-dialog-actions>
</form>
