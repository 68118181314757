<div class="logo">
    <div class="logo-normal">
        <a class="simple-text">Drmauto</a>
    </div>

    <div class="logo-img">
        <img src="/assets/icons/icon-72x72.png"/>
    </div>
</div>


<div class="sidebar-wrapper">
    <div class="user">
        <div class="photo">
            <img [src]="avatar"/>
        </div>
        <div class="info">
            <a data-toggle="collapse" href="#collapseExample" class="collapsed">
                        <span style="text-overflow: ellipsis; overflow: hidden; display: block; padding-right: 25px;">
                            {{user.name}}
                            <b class="caret"></b>
                        </span>
            </a>

            <div class="collapse" id="collapseExample">
                <ul class="nav">
                    <li>
                        <a (click)="logout()">
                            <span class="sidebar-mini">В</span>
                            <span class="sidebar-normal">Выход</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div *ngIf="isNotMobileMenu()">
        <ul class="nav nav-mobile-menu">
            <li class="dropdown">
                <a class="dropdown-toggle" data-toggle="dropdown" href="#" aria-expanded="false">
                    <i class="material-icons">notifications</i>
                    <span class="notification">5</span>
                    <p class="hidden-lg hidden-md">
                        Notifications
                        <b class="caret"></b>
                    </p>
                </a>
                <ul class="dropdown-menu">
                    <li>
                        <a href="#">Mike John responded to your email</a>
                    </li>
                </ul>
            </li>

            <li class="">
                <a class="dropdown-toggle" data-toggle="dropdown" href="#pablo" aria-expanded="false">
                    <i class="material-icons">person</i>
                    <p class="hidden-lg hidden-md">Profile</p>
                    <div class="ripple-container"></div>
                </a>
            </li>
            <li class="separator hidden-lg hidden-md"></li>
        </ul>
    </div>

    <div class="nav-container">
        <ul class="nav">
            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                <a [routerLink]="['/dashboard']">
                    <i class="material-icons">dashboard</i>
                    <p>Главная</p>
                </a>
            </li>

            <li *ngFor="let menuItem of menuItems; let i = index;"
                routerLinkActive="active">

                <!--If is a single link-->
                <a [routerLink]="['/', menuItem.path]" *ngIf="menuItem.type === 'link'">
                    <i class="material-icons">{{menuItem.fontIcon}}</i>
                    <p>{{menuItem.name}}</p>
                </a>
                <!--If it have a submenu-->
                <a data-toggle="collapse" href="#{{menuItem.name}}" *ngIf="menuItem.type === 'sub'">
                    <i class="material-icons">{{menuItem.fontIcon}}</i>
                    <p>{{menuItem.name}}<b class="caret"></b></p>
                </a>

                <!--Display the submenu items-->
                <div id="{{menuItem.name}}"
                     class="collapse"
                     routerLinkActive="in"
                     *ngIf="menuItem.type === 'sub'">
                    <ul class="nav">
                        <li routerLinkActive="active" *ngFor="let childItem of menuItem.children">
                            <a [routerLink]="['/', menuItem.path, childItem.path]">
                                <span class="sidebar-mini">{{childItem.ab}}</span>
                                <span class="sidebar-normal">{{childItem.name}}</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </li>
        </ul>

        <br>
        <br>
        <ul class="nav">
            <li>
                <a href="http://md-pro-angular.creative-tim.com/documentation/tutorial?ref=md-pro-archive">
                    <i class="material-icons">school</i>
                    <p>Documentation</p>
                </a>
            </li>
        </ul>

    </div>
</div>
