import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-already-added-services',
    templateUrl: './already-added-services.component.html',
    styleUrls: ['./already-added-services.component.scss']
})
export class AlreadyAddedServicesComponent implements OnInit {
    @Input() listAdded: any[] = [];
    addedTotalCost: number = 0;

    ngOnInit(): void {
        this.calculateAddedListCost();
    }

    private calculateAddedListCost(): void {
        this.addedTotalCost = this.listAdded.reduce((previousValue, currentItem) => previousValue + currentItem.salePrice, 0);
    }
}
