import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {API_URL} from '../../core/constants';
import {Observable} from 'rxjs';

@Injectable()
export class AuthService {

    constructor(private http: HttpClient) {
    }

    public login(data): Observable<any> {
        return this.http.post(`${API_URL}/login`, data);
    }
}
