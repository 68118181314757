import {Component, OnInit} from '@angular/core';
import {BoxInfoService} from '../../../../services/box-info.service';

@Component({
    selector: 'app-unprocessed-orders',
    templateUrl: './unprocessed-orders.component.html'
})
export class UnprocessedOrdersComponent implements OnInit {
    public count: number = 0;

    constructor(private service: BoxInfoService) {
    }

    ngOnInit() {
        this.service.getNewOrdersCount().subscribe((response: any) => {
            this.count = response.data;
        })
    }

}
