<form (submit)="submitHandler()" [formGroup]="form" class="client-form form-horizontal" novalidate>
    <div *ngIf="!client?.cars" class="row">
        <app-input-car-number
            (onSelect)="selectCarNumber($event)"
            [inputNumber]="'carNumber'"
            [inputType]="'carNumberType'"
            [numberClasses]="'col-xs-6'"
            [parentForm]="form"
            [showAutoComplete]="true"
            [typeClasses]="'col-xs-6'">
        </app-input-car-number>
    </div>

    <div class="row">
        <div class="col-xs-12 col-sm-4">
            <app-customer-phone-autocomplete
                (selectClient)="onSelectClientByPhone($event)"
                formControlName="clientPhone"
            >
            </app-customer-phone-autocomplete>
        </div>

        <div class="col-xs-6 col-sm-4">
            <app-autocomplete
                [controlName]="'clientSurname'"
                [data]="filteredSurname$"
                [objectKey]="'Surname'"
                [parentForm]="form"
                [placeholder]="'Фамилия'">
            </app-autocomplete>
        </div>

        <div class="col-xs-6 col-sm-4">
            <app-autocomplete
                [controlName]="'clientName'"
                [data]="filteredName$"
                [objectKey]="'Name'"
                [parentForm]="form"
                [placeholder]="'Имя'">
            </app-autocomplete>
        </div>
    </div>

    <div *ngIf="client?.cars">
        <div *ngIf="client.cars.length" class="row">
            <div class="col-xs-12">
                <h4 class="panel-title">Выберите машину</h4>
                <div class="service-list">
                    <mat-radio-group formControlName="carId">
                        <mat-radio-button (change)="selectCarNumberInUI(car)"
                                          *ngFor="let car of client.cars"
                                          [value]="car.id"
                                          class="cars__item">
                            {{car.make?.name}} {{car.model?.name}} {{car.year}}({{car.number}})
                            <ng-container *ngIf="!car.numberType">
                                <button [routerLink]="['/clients/profile/'+ client.id +'/car/'+ car.id +'/update']" class="btn btn-warning btn-xs"
                                        type="button">
                                    <i class="material-icons">edit</i>
                                    Тип номера не установлен
                                </button>
                            </ng-container>
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
        </div>

        <div *ngIf="!client.cars.length && client.id">
            <button [routerLink]="['/clients/profile/'+ client.id +'/car-add']" color="accent" mat-raised-button
                    type="button">
                Добавить машину клиенту
            </button>
            <br>
            <hr>
        </div>
    </div>

    <div *ngIf="this.selectedCar !== null">
        <div class="d-flex justify-content-between flex-wrap">
            <button
                (click)="selectBox(box.id)"
                *ngFor="let box of boxList"
                [disabled]="box.order_id"
                [ngClass]="{'btn-success': !box.order_id, 'btn-danger': box.order_id, 'active': selectedBox === box.id}"
                class="box-item btn"
                type="button">
                {{box.name}}
            </button>

            <!--<span class="box-item__info">-->
            <!--<span>-->
            <!--<i class="fa fa-clock-o"></i><span>14:45</span>-->
            <!--</span>-->
            <!--</span>-->
        </div>
        <br>

        <app-list-of-service-for-selection
            (selectedItem)="onUpdateSelectionItem($event)"
            [clientGrades]="clientGrades"
            [client]="client"
            [parentForm]="form"
            [selectedCar]="selectedCar">
        </app-list-of-service-for-selection>

        <mat-form-field class="full-width">
        <textarea
            formControlName="note"
            matAutosizeMaxRows="5"
            matAutosizeMinRows="2"
            matInput
            matTextareaAutosize
            placeholder="Заметки о текущем заказе"></textarea>
        </mat-form-field>

        <div class="row">
            <div class="col-xs-12">
                <div class="d-flex justify-content-between">
                    <button (click)="resetForm()" class="btn btn-fill btn-default" type="button">
                        Сбросить
                    </button>

                    <button class="btn btn-fill btn-rose" type="submit">
                        Добавить новый заказ
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="this.selectedCar === null">
        <br>
        <span class="error-msg alert--mini">Нужно выбрать машину!</span>
    </div>
</form>
