import {Component, Input, OnInit} from '@angular/core';
import {PHOTO_URL} from '@app/core/constants';

@Component({
    selector: 'app-list-employee-of-this-order',
    templateUrl: './list-employee-of-this-order.component.html',
    styleUrls: ['./list-employee-of-this-order.component.scss']
})
export class ListEmployeeOfThisOrderComponent implements OnInit {
    @Input() order: any;
    @Input() employeeList: any[];

    photoUrl: string = PHOTO_URL;

    ngOnInit() {
        if (this.order.services && Array.isArray(this.order.services)) {
            this.order.services.forEach(service => {
                if (service.responsibles && Array.isArray(service.responsibles)) {
                    const employeeCount = service.responsibles.length;

                    this.employeeList.forEach((employee: any, idx: number) => {
                        const check = service.responsibles.find(r => r.id === employee.id);

                        if (check !== undefined) {
                            if (this.employeeList[idx].salary_of_order === undefined || this.employeeList[idx].salary_of_order === null) {
                                this.employeeList[idx].salary_of_order = 0;
                            }

                            const salary = (service.price / employeeCount) * employee.sum_percent / 100;
                            this.employeeList[idx].salary_of_order += salary;
                        }
                    });
                }
            });
        }
    }
}
