import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {Routes} from '@angular/router';
import {ManagerLayoutComponent} from './layouts/manager-layout/manager-layout.component';
import {ManagerGuard} from './core/guards/manager.guard';
import {SaleActionCheckComponent} from './components/sale-action-check/sale-action-check.component';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
    },
    {
        path: '',
        component: ManagerLayoutComponent,
        children: [
            {
                path: 'dashboard',
                component: DashboardComponent,
                canActivate: [ManagerGuard],
            }
        ]
    },
    {
        path: '6check',
        component: SaleActionCheckComponent,
    },
    {
        path: '**',
        component: PageNotFoundComponent
    }
];

/*
        data: {
            menu: {
                name: 'Главная',
                type: 'link',
                showInDashboard: false,
                showInSideBar: true,
                fontIcon: 'dashboard',
                imageIcon: ''
            }
        }
 */
