<h1 mat-dialog-title>Закрытия смены ({{session.type === 'NIGHT' ? 'Ночь' : 'День'}})</h1>

<div mat-dialog-content>
    <p><strong>Нужно закрыть смену</strong></p>
    <p>
        Дата открытия: {{session.created_at}}
    </p>
    <p>
        Смену открыл: {{session.user_open.name}}
    </p>
    <p>
        <strong>Смену закрывает: {{user.name}}</strong>
    </p>
</div>

<div mat-dialog-actions>
    <button mat-raised-button color="accent" (click)="closeShift()" cdkFocusInitial>Закрыть смену</button>
    <button mat-raised-button color="accent" (click)="continueCurrentSession()" cdkFocusInitial>
        Продолжить текущую смену
    </button>
</div>
