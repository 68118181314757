<h2 class="text-center" mat-dialog-title>Завершение заказа</h2>

<div mat-dialog-content>
    <!--    <mat-checkbox [(ngModel)]="n6carWash">-->
    <!--        Была предъявлена 6 мойка-->
    <!--    </mat-checkbox>-->
</div>

<mat-dialog-actions class="d-flex justify-content-between">
    <div>
        <button (click)="close()" color="warn" mat-raised-button>Отменить</button>
    </div>

    <div>
        <button
            (click)="success()"
            color="accent"
            mat-raised-button>
            Подтвердить
        </button>
    </div>
</mat-dialog-actions>
