import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {API_URL} from '@app/core/constants';
import {UIMangerService} from '@services/ui-manger.service';
import {forkJoin} from 'rxjs';
import {AuthBaseService} from '@app/core/services/auth-base.service';
import {CASH_OPERATION_TYPES} from '@app/states/cashbox/pages/add-expense/constants';

@Component({
    selector: 'app-add-expense',
    templateUrl: './add-expense.component.html',
    styleUrls: ['./add-expense.component.scss']
})
export class AddExpenseComponent implements OnInit, OnDestroy {
    form: FormGroup;
    cashes: any[] = [];
    expensesTypes: any[] = [];
    employeeList: any[] = [];
    showDateTimePicker: FormControl = new FormControl(false);

    constructor(
        private fb: FormBuilder,
        private http: HttpClient,
        private ui: UIMangerService,
        private auth: AuthBaseService
    ) {
        this.loadFormData();
        this.loadSecondFormData();

        this.form = this.fb.group({
            cash: [null, [Validators.required]],
            cash_type: [null, [Validators.required]],
            sum: [{value: null, disabled: true}, [Validators.required, Validators.pattern(/^\d+$/)]],
            payment_purpose: [null],
            employee: [{value: null, disabled: true}],
            datetime: null,
        });

        this.form.get('cash').valueChanges.subscribe((cashId: number | null) => {
            if (cashId === null) {
                return;
            }

            this.updateSumControl(cashId);
        });

        this.form.get('cash_type').valueChanges.subscribe((cashTypeId: number | null) => {
            if (cashTypeId === null) {
                return;
            }

            this.updateEmployeeControl(cashTypeId);
        });
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }

    get user(): any {
        return this.auth.getUser();
    }

    submit(): void {
        if (this.form.valid) {
            this.ui.getLoader().show();
            this.http.post(`${API_URL}/company-expenses`, this.form.value)
                .subscribe(
                    (response: any) => {
                        this.ui.getLoader().hide();

                        if (response.success) {
                            this.ui.showSnackBar('Расход успешно добавлен');
                            this.form.reset();
                            this.form.markAsUntouched();
                            this.form.markAsPristine();
                            this.loadFormData();
                        }
                    },
                    (error: HttpErrorResponse) => {
                        this.ui.showSnackBar(error.error.message);
                        this.ui.getLoader().hide();
                    }
                );
        }
    }

    /**
     * Загрузка данных для работы формы
     */
    private loadFormData(): void {
        this.ui.getLoader().show();

        forkJoin({
            cashes: this.http.get(`${API_URL}/company-expenses/cashbox-list`),
            expenseTypes: this.http.get(`${API_URL}/company-expenses/expenses-types`),
        }).subscribe((response: { cashes: any, expenseTypes: any }) => {
            if (response.cashes.success) {
                this.cashes = response.cashes.data;
            }

            if (response.expenseTypes.success) {
                this.expensesTypes = response.expenseTypes.data;
            }

            this.ui.getLoader().hide();
        });
    }

    /**
     * Загрузка второстепенных данных для работы формы
     */
    private loadSecondFormData(): void {
        this.http.get(`${API_URL}/employees`).subscribe((response: any) => {
            if (Array.isArray(response.data)) {
                this.employeeList = response.data;
            }
        });
    }

    /**
     * Обновляет поле sum, изменяет валидацию в зависимости от кассы
     * @param {number} cashId
     */
    private updateSumControl(cashId: number) {
        const sumControl = this.form.get('sum') as FormControl;
        const cash = this.cashes.find(cash => cash.id === cashId);
        sumControl.setValidators([Validators.max(cash.balance), Validators.required, Validators.pattern(/^\d+$/)]);
        sumControl.enable();
    }

    /**
     * Обновляет поле sum, изменяет валидацию в зависимости от кассы
     * @param {number} cashTypeId
     */
    private updateEmployeeControl(cashTypeId: number) {
        const employeeControl = this.form.get('employee') as FormControl;
        console.log(employeeControl);

        if (CASH_OPERATION_TYPES.PREPAYMENT === cashTypeId || CASH_OPERATION_TYPES.SALARY === cashTypeId) {
            employeeControl.setValidators([Validators.required]);
            employeeControl.enable();
        } else {
            employeeControl.clearValidators();
            employeeControl.disable();
        }

        employeeControl.updateValueAndValidity();
    }
}
