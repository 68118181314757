// TODO: Компонент надо отрефаткорить и превести к нормальному виду.


import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {FormClientInfoService} from '../../../new-client/form-client-info.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ClientFormService} from '@services/client-form.service';
import {HttpClient} from '@angular/common/http';
import {API_URL} from '@app/core/constants';
import {LoaderService} from '@shared/components/loader/loader.service';
import {takeUntil} from 'rxjs/operators';
import {ReplaySubject, Subject, Subscription} from 'rxjs';
import {FormAutoUpdate} from '@app/core/services/FormAutoUpdate';
import {MatSelect} from '@angular/material/select';

@Component({
    selector: 'app-car-form',
    templateUrl: './car-form.component.html',
    styleUrls: ['./car-form.component.scss']
})
export class CarFormComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('modelSelect') modelSelect: MatSelect;
    @ViewChild('domForm') $domForm: ElementRef;

    public isNew = true;
    public form: FormGroup;
    public makesList: any[];
    public makesListOptions: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    public makesFilterCtrl: FormControl = new FormControl();
    public modelsList: any[];
    public modelsListOptions: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    public modelsFilterCtrl: FormControl = new FormControl();
    private _onDestroy = new Subject<void>();

    private clientId: number;
    private carId: number;
    private formAutoUpdate: FormAutoUpdate;
    private formAutoSaveSubscription: Subscription;
    private subscriptions: Subscription;

    constructor(
        private fb: FormBuilder,
        private ref: ChangeDetectorRef,
        private formService: FormClientInfoService,
        private clientFormService: ClientFormService,
        private activeRoute: ActivatedRoute,
        private router: Router,
        private httpClient: HttpClient,
        private loader: LoaderService
    ) {
        this.makesList = [];
        this.modelsList = [];
        this.formAutoSaveSubscription = new Subscription();
        this.subscriptions = new Subscription();
    }

    ngOnInit() {
        // this.formAutoUpdate = new FormAutoUpdate(this.$domForm.nativeElement.elements, this.autoSaveHandler.bind(this));
        this.clientId = this.activeRoute.snapshot.params.id;
        this.carId = this.activeRoute.snapshot.params.carId || null;
        this.getMakesList();

        this.form = this.fb.group({
            number: ['', [Validators.required]],
            make: null,
            model: null,
            year: null,
            carNumberType: [null, [Validators.required]],
        });
        // this.form = new FormGroup(this.form.controls, {updateOn: 'blur'});

        if (this.carId) {
            this.isNew = false;

            this.subscriptions.add(
                this.formService.getCarById(this.clientId, this.carId).subscribe((response) => {
                    // TODO: вместо этого нужно получить ввесь список моделей для выбранной марки авто.
                    this.modelsListOptions.next([{name: response.data.model}]);

                    this.form.patchValue(response.data, {emitEvent: false});
                    this.form.patchValue({carNumberType: response.data.number_type}, {emitEvent: true});
                })
            );
        }

        this.subscriptions.add(
            // listen for search field value changes
            this.makesFilterCtrl.valueChanges
                .pipe(takeUntil(this._onDestroy))
                .subscribe(() => {
                    this._filterMakes();
                })
        );

        this.subscriptions.add(
            // listen for search field value changes
            this.modelsFilterCtrl.valueChanges
                .pipe(takeUntil(this._onDestroy))
                .subscribe(() => {
                    this._filterModels();
                })
        );

        // this.initFormAutoSave();
    }

    ngAfterViewInit(): void {
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
        // this.destroyFormAutoSave();
    }

    public submit(): void {
        if (this.form.valid) {
            if (this.isNew) {
                this.subscriptions.add(
                    this.formService.addCar(this.clientId, this.form.value).subscribe((response) => {
                        if (response.success) {
                            this.backToProfile();
                        }
                    })
                );
            } else {
                this.updateForm();
                this.backToProfile();
            }
        } else {
            for (let key in this.form.controls) {
                if (this.form.controls.hasOwnProperty(key)) {
                    this.form.controls[key].markAsTouched();
                }
            }
        }
    }

    private updateForm() {
        if (!this.isNew) {
            this.loader.show();
            this.subscriptions.add(
                this.formService.editCar(this.clientId, this.carId, this.form.value).subscribe((response) => {
                    this.loader.hide();
                })
            );
        }
    }

    public backToProfile(): void {
        this.router.navigate(['/clients/profile', this.clientId]);
    }

    public getModelsList(makeName: string) {
        const make = this.makesList.find((m) => m.name === makeName);

        if (!make) {
            return;
        }

        this.loader.show();
        this.subscriptions.add(
            this.httpClient.get(`${API_URL}/cars/model-list/${make.id}`).subscribe(
                (response: any) => {
                    this.modelsList = response;
                    this.loader.hide();
                    this.modelsListOptions.next([...this.modelsList]);
                    setTimeout(() => this.modelSelect.open());
                },
                (error: any) => {
                    this.loader.hide();
                }
            )
        );
    }

    // хак, потому чо не смог придумать как сделать норм с методом getModelsList
    public loadModelsList(makeName: string) {
        if (this.modelsList.length) {
        	return;
        }

        const make = this.makesList.find((m) => m.name === makeName);

        if (!make) {
            return;
        }

        this.subscriptions.add(
            this.httpClient.get(`${API_URL}/cars/model-list/${make.id}`).subscribe(
                (response: any) => {
                    this.modelsList = response;
                    this.modelsListOptions.next([...this.modelsList]);
                }
            )
        );
    }

    private getMakesList() {
        this.subscriptions.add(
            this.httpClient.get(`${API_URL}/cars/make-list`).subscribe((response: any) => {
                this.makesList = response;
                this.makesListOptions.next([...response]);

                if (this.form.controls.make.value) {
                	this.loadModelsList(this.form.controls.make.value);
                }
            })
        );
    }

    private _filterMakes() {
        if (!this.makesList) {
            return;
        }

        // get the search keyword
        let search = this.makesFilterCtrl.value;

        if (!search) {
            this.makesListOptions.next([...this.makesList]);
            return;
        } else {
            search = search.toLowerCase();
        }

        // filter the banks
        this.makesListOptions.next(
            this.makesList.filter(option => option.name.toLowerCase().includes(search))
        );
    }

    private _filterModels() {
        if (!this.modelsList) {
            return;
        }

        // get the search keyword
        let search = this.modelsFilterCtrl.value;

        if (!search) {
            this.modelsListOptions.next([...this.modelsList]);
            return;
        } else {
            search = search.toLowerCase();
        }

        // filter the banks
        this.modelsListOptions.next(
            this.modelsList.filter(option => option.name.toLowerCase().includes(search))
        );
    }

    private initFormAutoSave(): void {
        this.formAutoUpdate.addListeners();

        ['make', 'model', 'carNumberType'].forEach((fieldName: string) => {
            this.formAutoSaveSubscription.add(
                this.form.controls[fieldName].valueChanges.subscribe(() => {
                    this.updateForm();
                })
            );
        });
    }

    private destroyFormAutoSave(): void {
        this.formAutoUpdate.removeListeners();
        this.formAutoSaveSubscription.unsubscribe();
    }

    private autoSaveHandler(): void {
        setTimeout(() => {
            this.updateForm();
        });
    }
}
