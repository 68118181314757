<h2 class="text-center">Вы подтверждаете действие?</h2>

<div>
    <form>
        <mat-form-field class="full-width">
          <textarea [formControl]="rejectionReason"
                    class="full-width"
                    matAutosizeMaxRows="5"
                    matAutosizeMinRows="2"
                    matInput
                    matTextareaAutosize
                    placeholder="Введите причину"
          ></textarea>
        </mat-form-field>
    </form>
</div>

<div class="d-flex justify-content-around">
    <button (click)="close()" color="warn" mat-raised-button>Отменить</button>
    <button (click)="success()" [disabled]="rejectionReason.invalid" color="accent" mat-raised-button>Подтвердить
    </button>
</div>
