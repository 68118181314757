import {Component, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {PHONE_MASK} from '@app/core/constants';
import {CallService} from '../../services/call.service';
import {AlertComponent} from '@shared/components/alert/alert.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-new-call',
    templateUrl: './new-call.component.html',
    styleUrls: ['./new-call.component.scss']
})
export class NewCallComponent implements OnInit {
    public maskPhone = PHONE_MASK;
    public form: FormGroup;
    public comments: FormArray = this.fb.array([]);
    public infoList: string[] = SELECTION_INFO;

    constructor(
        private fb: FormBuilder,
        private service: CallService,
        private dialog: MatDialog
    ) {
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            phone: ['', [Validators.required]],
            date: null,
            info: this.fb.array(this.fillInfoFiled()),
            comments: this.comments
        });
    }

    public fillInfoFiled(): FormControl[] {
        return this.infoList.map(() => new FormControl(false));
    }

    public addComment(): void {
        this.comments.push(this.fb.control(''));
    }

    public deleteComment(idx: number): void {
        this.comments.controls.splice(idx, 1);
    }

    public onChangeDate(event: any) {
        this.form.patchValue({
            date: `${event.date} ${event.time}`
        });
    }

    public onSubmit(): void {
        this.form.patchValue({
            info: this.form.value.info.map((item, i) => item && this.infoList[i])
        });

        if (this.form.valid) {
            this.service.create(this.form.value).subscribe((response) => {
                if (response.success) {
                    this.form.reset();
                    this.dialog.open(AlertComponent, {
                        width: '90%',
                        minHeight: '80%',
                        data: {name: '', text: 'Информация о звонке успешно добавлена'}
                    });
                }
            });
        }
    }

}

const SELECTION_INFO = [
    'Уточнить от куда узнали о наc',
    'Предложить первичную диагностику автомобиля',
    'Уведомить о скидки с рекомендации дурга',
    'Уточнить от куда узнали о нас'
];
