import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {BoxInfoService} from '@services/box-info.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-change-box',
    templateUrl: './change-box.component.html',
    styleUrls: ['./change-box.component.scss']
})
export class ChangeBoxComponent implements OnInit, OnDestroy {
    public boxes: any[] = [];
    public selectedBox: any;
    private sub: Subscription;

    constructor(public dialogRef: MatDialogRef<ChangeBoxComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private boxService: BoxInfoService) {
    }

    ngOnInit() {
        this.sub = this.boxService.getFreeBoxes().subscribe((result) => {
            if (this.data.box !== undefined && this.data.box !== null) {
                this.boxes = result.filter((item: any) => item.id !== this.data.box.id);
            } else {
                this.boxes = result;
            }

            // this.boxes.push(this.fillData()); // TODO данный функционал пока не нужен, будем делать отдельную таблицу для этого
        });
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    public close(): void {
        this.dialogRef.close();
    }

    public successClose(): void {
        this.dialogRef.close(this.selectedBox);
    }

    public selected(item: any): void {
        this.selectedBox = item;
    }

    private fillData() {
        return {
            id: 0,
            name: 'На улицу',
            description: 'Вывод клиента на улицу'
        };
    }
}
