import {Component, OnDestroy, OnInit} from '@angular/core';
import {BoxInfoService} from '@services/box-info.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-box-status-bar',
    templateUrl: './box-status-bar.component.html',
    styleUrls: ['./box-status-bar.component.scss']
})
export class BoxStatusBarComponent implements OnInit, OnDestroy {
    list: any[] = [];
    private subscription: Subscription;

    constructor(private boxInfoService: BoxInfoService) {
        this.subscription = new Subscription();
    }

    ngOnInit() {
        const boxInfoSubscriber = this.boxInfoService.getAllBoxStream().subscribe((res) => {
            this.list = res;
        });
        this.subscription.add(boxInfoSubscriber);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
