import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {API_URL} from '@app/core/constants';
import {CurrentState, SessionShiftService} from '@app/core/services/session-shift.service';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-revenue-per-shift',
    templateUrl: './revenue-per-shift.component.html',
    styleUrls: ['./revenue-per-shift.component.scss']
})
export class RevenuePerShiftComponent implements OnInit {
    public categories: any[];
    public totals: any;
    public shiftState: CurrentState;
    public expenses: any;

    constructor(
        public dialogRef: MatDialogRef<RevenuePerShiftComponent>,
        private http: HttpClient,
        private shift: SessionShiftService
    ) {
        this.shiftState = this.shift.getCurrentState();
    }

    ngOnInit() {
        this.getInfo();
    }

    getInfo() {
        this.http.get(`${API_URL}/statistics/revenuePerShift`).subscribe((response: any) => {
            this.categories = response.categories;
            this.totals = response.totals;
            console.log(this.categories);
        });

        this.http.get(`${API_URL}/company-expenses/per-shift`).subscribe((response: any) => {
            this.expenses = response.data;
        });
    }
}
