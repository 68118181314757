import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import Client from '../../../../../../entities/client';
import {FormBuilder, FormGroup} from '@angular/forms';
import {PHONE_MASK_WITH_OUT_CODE} from '@app/core/constants';
import {ClientsService} from '../../../../services/clients.service';
import {Subscription} from 'rxjs';
import {MatChipInputEvent} from '@angular/material/chips';

@Component({
    selector: 'app-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit, OnDestroy {
    @Input() client: Client;
    @Output() update: EventEmitter<any> = new EventEmitter();
    @ViewChild('domForm') $domForm: ElementRef;

    form: FormGroup;
    loveServicesList: any[] = [];
    visible = true;
    // Enter, comma
    separatorKeysCodes = [ENTER, COMMA];
    maskPhone = PHONE_MASK_WITH_OUT_CODE;

    fruits = [
        {name: 'Lemon'},
        {name: 'Lime'},
        {name: 'Apple'}
    ];
    numberOfVisitsInTheLastThreeMonths: string;
    private formAutoSaveSubscription: Subscription = new Subscription();

    constructor(
        private fb: FormBuilder,
        private clientsService: ClientsService
    ) {
    }

    ngOnInit(): void {
        this.clientsService.getLovesServices().subscribe((response: any) => {
            this.loveServicesList = response;
        });

        this.clientsService.getCustomerInfo(this.client.id).subscribe((response: any) => {
            this.numberOfVisitsInTheLastThreeMonths = response.number_of_visits_in_the_last_three_months;
        });

        this.form = this.fb.group({
            phone: [null, {updateOn: 'blur'}],
            typeId: [null, {updateOn: 'blur'}],
            surname: [null, {updateOn: 'blur'}],
            name: [null, {updateOn: 'blur'}],
            patronymic: [null, {updateOn: 'blur'}],
            dateOfBirthday: [null, {updateOn: 'blur'}],
            sex: [null, {updateOn: 'blur'}],
            loveServiceId: [null, {updateOn: 'blur'}],
            note: [null, {updateOn: 'blur'}],
            legalPerson: this.fb.group({
                inn: [null, {updateOn: 'blur'}],
                ogrn: [null, {updateOn: 'blur'}],
                companyName: [null, {updateOn: 'blur'}],
                kpp: [null, {updateOn: 'blur'}],
                bankAccount: [null, {updateOn: 'blur'}],
            })
        });

        this.fillForm();

        this.form.valueChanges.subscribe((res) => {
            this.formAutoSaveSubscription.add(this.update.emit(res));
        });
    }

    ngOnDestroy(): void {
        this.destroyFormAutoSave();
    }

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        // Add our fruit
        if ((value || '').trim()) {
            this.fruits.push({name: value.trim()});
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }
    }

    remove(fruit: any): void {
        const index = this.fruits.indexOf(fruit);

        if (index >= 0) {
            this.fruits.splice(index, 1);
        }
    }

    private fillForm(): void {
        this.form.patchValue({
            phone: this.client.phone,
            typeId: this.client.typeId,
            surname: this.client.surname,
            name: this.client.name,
            patronymic: this.client.patronymic,
            dateOfBirthday: this.client.dateOfBirthday,
            sex: this.client.sex,
            loveServiceId: this.client.loveServiceId,
            note: this.client.note,
            legalPerson: {
                inn: this.client.inn,
                ogrn: this.client.ogrn,
                companyName: this.client.companyName,
                kpp: this.client.kpp,
                bankAccount: this.client.bankAccount,
            }
        });
    }

    private destroyFormAutoSave(): void {
        this.formAutoSaveSubscription.unsubscribe();
    }
}
