<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-content">
                        <mat-tab-group>
                            <mat-tab label="Сводка">
                                <ng-container *ngIf="dataList">
                                    <h4>Должны забрать на этой неделе</h4>
                                    <app-report-table [tableData]="dataList.currentWeek"></app-report-table>

                                    <h4>Должны забрать на следующей неделе</h4>
                                    <app-report-table [tableData]="dataList.nextWeek"></app-report-table>

                                    <h4>Не востребованные позиции</h4>
                                    <app-report-table [tableData]="dataList.previousWeek"></app-report-table>
                                </ng-container>
                            </mat-tab>

                            <mat-tab label="Положить на склад">
                                <div>
                                    <h2>Добавление позиций</h2>

                                    <form novalidate (submit)="submit()" [formGroup]="form">
                                        <div class="clearfix">
                                            <div class="col-xs-6">
                                                <mat-form-field class="full-width">
                                                    <mat-select placeholder="Выбирите cклад"
                                                                formControlName="storage">
                                                        <mat-option *ngFor="let storage of storageList"
                                                                    [value]="storage.id">
                                                            {{ storage.name }}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>

                                            <div class="col-xs-6">
                                                <mat-form-field class="full-width">
                                                    <mat-select placeholder="Выбирите тип хранимого продукта"
                                                                formControlName="itemType">
                                                        <mat-option *ngFor="let type of typeItems" [value]="type.id">
                                                            {{ type.name }}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>

                                        <div class="clearfix">
                                            <div class="col-xs-12 col-sm-6">
                                                <mat-form-field class="full-width">
                                                    <mat-label>Номер телефона</mat-label>
                                                    <input type="text"
                                                           matInput
                                                           formControlName="phone"
                                                           autocomplete="off"
                                                           phonePrefix
                                                           #inputPhone
                                                           placeholder="+7 ___ ___-__-__">
                                                </mat-form-field>
                                            </div>

                                            <div class="col-xs-12 col-sm-6">
                                                <div class="row">
                                                    <app-input-car-number
                                                        [parentForm]="form"
                                                        [inputType]="'carNumberType'"
                                                        [inputNumber]="'carNumber'"
                                                        [typeClasses]="'col-xs-12 col-sm-4'"
                                                        [numberClasses]="'col-xs-12 col-sm-4'">
                                                    </app-input-car-number>

                                                    <div class="col-xs-12 col-sm-4">
                                                        <mat-form-field>
                                                            <mat-select placeholder="Выбирите тип клиента"
                                                                        formControlName="clientType">
                                                                <mat-option *ngFor="let type of typeClients" [value]="type.id">
                                                                    {{ type.name }}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-xs-12">
                                            <mat-form-field>
                                                <input matInput
                                                       [matDatepicker]="picker"
                                                       formControlName="dateEnd"
                                                       autocomplete="off"
                                                       placeholder="Дата окончания">
                                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker touchUi="true" #picker></mat-datepicker>
                                            </mat-form-field>
                                        </div>

                                        <div class="text-right">
                                            <button class="btn btn-fill btn-rose">Добавить</button>
                                        </div>
                                    </form>
                                </div>
                            </mat-tab>

                            <mat-tab label="Забрать со склада">
                                <br>
                                <mat-form-field class="example-full-width">
                                    <input matInput placeholder="номер авто или телефона" class="input-lg">
                                </mat-form-field>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
