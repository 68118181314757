import {Routes} from '@angular/router';
import {PriceListComponent} from './components/price-list/price-list.component';
import {ManagerLayoutComponent} from '../../layouts/manager-layout/manager-layout.component';
import {ManagerGuard} from '../../core/guards/manager.guard';

export const routes: Routes = [
    {
        path: 'price-list',
        component: ManagerLayoutComponent,
        data: {
            menu: {
                name: 'Прайс-лист',
                type: 'link',
                showInDashboard: true,
                showInSideBar: true,
                fontIcon: 'content_paste',
                imageIcon: '../assets/img/dashboard-menu/invoice.svg'
            }
        },
        children: [
            {
                path: '',
                component: PriceListComponent,
                canActivate: [ManagerGuard]
            }
        ]
    }
];
