<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xs-12">
                <div class="card">
                    <div class="card-header card-header-icon" data-background-color="rose">
                        <i class="material-icons">phone</i>
                    </div>

                    <div class="card-content">
                        <h4 class="card-title">Список заказов</h4>

                        <div class="order-table-wrap">
                            <div *ngIf="isLoadingResults" class="spinner-wrap">
                                <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
                            </div>

                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                    <tr>
                                        <th class="mat-column-id">№</th>
                                        <th class="mat-column-customer">Клиент</th>
                                        <th class="mat-column-serviceList">Список услуг</th>
                                        <th class="mat-column-totalCost">Цена</th>
                                        <th class="mat-column-statuses">Статусы</th>
                                        <th class="mat-column-box">Бокс</th>
                                        <th class="mat-column-actions">Действия</th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    <tr
                                        current-order-row
                                        *ngFor="let item of dataSource.data"
                                        [boxList]="boxList"
                                        [order]="item"
                                        (pay)="onPay($event)"
                                        (cancelOrder)="onCancelOrder($event)"
                                        (completeOrder)="onCompleteOrder($event)"
                                        (boxChange)="onBoxChange($event)"
                                    ></tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>

                        <app-last-orders></app-last-orders>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
