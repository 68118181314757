import {environment} from '../../environments/environment';

export const MIN_NUMBER_FOR_AUTOCOMPLETE: number = 8;
export const MIN_NUMBER_FOR_CAR_NUMBER_AUTOCOMPLETE: number = 3;
export const PHONE_STANDART_LENGTH: number = 11;
export const API_URL: string = environment.apiUrl;
export const PHOTO_URL: string = environment.photoUrl;
export const BROADCAST_URL: string = environment.broadcastUrl;
export const DATE_FORMAT: any = 'DD-MM-YY h:m';
// export const DATE_TIME_FORMAT: any = 'DD-MM-YY HH:mm';
export const DATE_TIME_FORMAT: any = 'DD-MM HH:mm';
export const PHONE_MASK: any[] = ['+', '7', ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];
export const PHONE_MASK_WITH_OUT_CODE: any[] = ['+', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];

export const GLOBAL_DATE_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'LL',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};
