import {Component, OnInit} from '@angular/core';
import Client from '../../../../entities/client';
import {ActivatedRoute, Router} from '@angular/router';
import {ClientsService} from '../../services/clients.service';
import {DataSharedService} from '@services/data-shared.service';
import {LoaderService} from '@shared/components/loader/loader.service';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
    public client: Client;

    constructor(
        private router: ActivatedRoute,
        private clientsService: ClientsService,
        private r: Router,
        private dataService: DataSharedService,
        private loader: LoaderService
    ) {
    }

    ngOnInit() {
        this.router.params.subscribe((res: any) => {
            this.clientsService.getClientById(res.id).subscribe((result: any) => {
                this.client = result;
            });
        });
    }

    public createNewOrder(): void {
        this.dataService.setRouterData(this.client);
        this.r.navigate(['/orders/new']);
    }

    public updateCustomerInfo(data: any) {
        this.loader.show();
        this.clientsService.updateCustomerInfo(this.client.id, data).subscribe((response: any) => {
            this.loader.hide();
        });
    }
}
