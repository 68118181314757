import {Component, Input, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {API_URL} from '@app/core/constants';
import Client from '../../../../../../entities/client';
import {MatTableDataSource} from '@angular/material/table';

@Component({
    selector: 'app-history-of-visits',
    templateUrl: './history-of-visits.component.html',
    styleUrls: ['./history-of-visits.component.scss']
})
export class HistoryOfVisitsComponent implements OnInit {
    @Input('client') client: Client;

    displayedColumns = ['id', 'date', 'serviceList', '6nAction', 'fullSum', 'sale', 'cost'];
    dataSource: MatTableDataSource<any[]>;

    constructor(private http: HttpClient) {
        this.dataSource = new MatTableDataSource([]);
    }

    ngOnInit() {
        this.http.get(`${API_URL}/customers/${this.client.id}/order-history`).subscribe((response: any) => {
            this.dataSource.data = response.data;
        });
    }
}
