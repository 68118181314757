import {Injectable} from '@angular/core';
import Client from '../../../entities/client';
import {HttpClient} from '@angular/common/http';
import {API_URL} from '@app/core/constants';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class ClientsService {
    constructor(private http: HttpClient) {
        this.findClientsBySurnameForAutocomplete = this.findClientsBySurnameForAutocomplete.bind(this);
        this.findClientsByNameForAutocomplete = this.findClientsByNameForAutocomplete.bind(this);
    }

    findClientsByPhone(phone: string): Observable<any> {
        return this.http
            .get(`${API_URL}/customers/findByPhoneLike?phone=${phone}`)
            .pipe(
                map((result: any) => result.data)
            );
    }

    findClientsByCarNumber(number: string): Observable<any> {
        return this.http
            .get(`${API_URL}/customers/findByCarNumber?number=${number}`)
            .pipe(
                map((result: any) => result.data)
            );
    }

    findClientsByCarNumberLike(number: string): Observable<any> {
        return this.http
            .get(`${API_URL}/customers/findByCarNumberLike?number=${number}`)
            .pipe(
                map((result: any) => result.data)
            );
    }

    getClientById(id: number): Observable<Client> {
        return this.http
            .get(`${API_URL}/customers/${id}`)
            .pipe(
                map((result: any) => result.data)
            );
    }

    updateCustomerInfo(clientId: number, data: any): Observable<any> {
        return this.http.put(`${API_URL}/customers/${clientId}`, data);
    }

    findClientsBySurnameForAutocomplete(surname: string): Observable<any> {
        return this.http
            .get(`${API_URL}/fio/getBySurname?surname=${surname}`)
            .pipe(
                map((result: any) => result.data)
            );
    }

    findClientsByNameForAutocomplete(name: string): Observable<any> {
        return this.http
            .get(`${API_URL}/fio/getByName?name=${name}`)
            .pipe(
                map((result: any) => result.data)
            );
    }

    getLovesServices(): Observable<any> {
        return this.http
            .get(`${API_URL}/serviceList/lovesServices`)
            .pipe(
                map((result: any) => result.data)
            );
    }

    getCustomerInfo(customerId: number): Observable<any> {
        return this.http
            .get(`${API_URL}/customers/${customerId}/info`)
            .pipe(
                map((result: any) => result.data)
            );
    }
}
