import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BoxInfoService} from './box-info.service';
import {CompanyServiceListService} from './company-service-list.service';
import {NotificationService} from './notification.service';
import {HttpClientModule} from '@angular/common/http';
import {EmployeesService} from './employees.service';
import {DataSharedService} from './data-shared.service';
import {ClientFormService} from './client-form.service';
import {RecordsService} from './records.service';
import {PusherService} from './pusher.service';
import {CustomerService} from './customer.service';
import {LocalHistoryService} from './local-history.service';
import {StorageService} from './storage.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
    ],
    providers: [
        BoxInfoService,
        CompanyServiceListService,
        NotificationService,
        EmployeesService,
        DataSharedService,
        ClientFormService,
        RecordsService,
        PusherService,
        CustomerService,
        LocalHistoryService,
        StorageService
    ]
})
export class ServicesModule {
}
