import {phoneFixAndPrepare} from '../Helpers/Helper';

export default class Client {
    id: number;
    family: string;
    surname: string;
    name: string;
    patronymic: string;
    phone: string;
    carPhoto: string;
    clientType: string;
    carNumberType: string;
    carNumber: string;
    lastIp: string;
    lastDevice: string;
    lastOs: string;
    lastBrowser: string;
    managerId: string;
    dateOfBirthday: string;
    note: string;
    sex: string;
    typeId: string;
    loveServiceId: number;
    inn: string;
    ogrn: string;
    companyName: string;
    kpp: string;
    bankAccount: string;

    constructor(data: any) {
        for (let key in data) {
            if (data.hasOwnProperty(key)) {
                this[key] = data[key];
            }
        }
        this.typeId = String(data.typeId);
        this.preparePhoneNumber();
    }

    public setTehInfo() {
        this.setBrowser();
        this.setOsAndDevise();
    }

    private setBrowser() {
        let ua = navigator.userAgent, tem,
            M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

        if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return 'IE ' + (tem[1] || '');
        }

        if (M[1] === 'Chrome') {
            tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
            if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
        }

        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);

        this.lastBrowser = M.join(' ');
    }

    private setOsAndDevise() {
        const _s = navigator.userAgent.match(/\([A-Za-z.;\d\w\s]+\)/i);
        if (_s && _s[0]) {
            const result = _s[0].slice(1, -1).split(';');

            this.lastDevice = result[0];
            this.lastOs = result[1];
        }
    }

    /**
     * TODO: Внимания!
     *  Это хак, нужен что бы удалить лишную цыфру номера телефона, textMask работает странно и разрешает добавлять лишний символ
     *  хоть этого и не видно, в input поле
     */
    private preparePhoneNumber() {
        this.phone = phoneFixAndPrepare(this.phone);
    }
}
