import {
    Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import {WebCamComponent} from 'ack-angular-webcam';

@Component({
    selector: 'app-web-camera',
    templateUrl: './web-camera.component.html',
    styleUrls: ['./web-camera.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class WebCameraComponent implements OnInit, OnDestroy {
    @Input() widthContainer: number;
    @Input() heightContainer: number;
    @Output() base64Result: EventEmitter<any> = new EventEmitter();
    @Output() closeEvent: EventEmitter<any> = new EventEmitter();

    cameraOption: any;
    loading: boolean;

    constructor() {
    }

    ngOnInit(): void {
        this.loading = false;
        this.cameraOption = {
            audio: false,
            video: true,
            width: 400,
            height: 400,
            cameraType: 'back' || 'front'
        };

    }

    ngOnDestroy(): void {
        console.log('destroy WebCameraComponent');
    }

    genBase64(webcam: WebCamComponent) {
        webcam.getBase64()
            .then(base => {
                this.base64Result.emit({data: base});
            })
            .catch(e => console.error(e));
    }

    close() {
        this.closeEvent.emit();
    }

    onCamError(err) {
        // console.log('onCamError', err);
    }

    onCamSuccess(event) {
        this.loading = event.active;
        console.log('onCamSuccess', event);
    }
}
