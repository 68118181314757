/**
 * @param controls
 */
export const markAsTouchedAllFields = (controls: any): void => {
    for (let key in controls) {
        if (controls.hasOwnProperty(key)) {
            controls[key].markAsTouched();
        }
    }
};
