import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {of as observableOf, Subscription} from 'rxjs';
import {OrderService} from '../../services/order.service';
import {ConfirmAskReasonComponent} from '@shared/components/confirm-ask-reason/confirm-ask-reason.component';
import {Router} from '@angular/router';
import {OrderPaymentModalComponent} from '@shared/components/order-payment-modal/order-payment-modal.component';
import {LoaderService} from '@shared/components/loader/loader.service';
import {BoxInfoService} from '@services/box-info.service';
import {servicesCostCalculation, servicesSaleCostCalculation} from './helper';
import {OrderCompleteComponent} from '@shared/components/order/order-complete/order-complete.component';
import {BoxSimple} from '@app/states/boxes/box-interfaces';
import {UIMangerService} from '@services/ui-manger.service';
import {BoxChangeEmit} from '@app/states/orders/components/current-order-row/current-order-row.component';
import {catchError} from 'rxjs/operators';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';


@Component({
    selector: 'app-price-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(MatSort) sort: MatSort;

    dataSource = new MatTableDataSource();
    isLoadingResults = false;
    lastOrders: any[];
    boxList: BoxSimple[];
    private subscriptions: Subscription;

    constructor(
        private service: OrderService,
        private dialog: MatDialog,
        private loader: LoaderService,
        private router: Router,
        private boxService: BoxInfoService,
        private ui: UIMangerService,
    ) {
        this.lastOrders = [];
        this.subscriptions = new Subscription();
    }

    ngOnInit(): void {
        this.getData();
    }

    ngAfterViewInit(): void {
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    /**
     * Закгрузка данных заказов
     */
    private getData() {
        this.service.getBoxList().subscribe(response => {
            this.boxList = response;
        });

        this.isLoadingResults = true;
        this.service.getList()
            .pipe(
                catchError(() => {
                    this.isLoadingResults = false;
                    return observableOf([]);
                })
            )
            .subscribe(response => {
                this.isLoadingResults = false;
                this.dataSource.data = this.prepareDataForRender(response.data);
            });
    }

    /**
     * Удаление заказа
     */
    onCancelOrder(order: any) {
        const dialog$ = this.dialog
            .open(ConfirmAskReasonComponent, {
                width: '60%',
                minHeight: '80%'
            })
            .afterClosed()
            .subscribe(result => {
                if (result.success) {
                    this.loader.show();
                    this.service.cancelOrder(order.id, result.data).subscribe((response: any) => {
                        this.removeOrderFromUIlist(response.data.orderId);
                        this.loader.hide();
                    });
                }
            });
        this.subscriptions.add(dialog$);
    }

    /**
     * Оплата заказа
     */
    onPay(order: any): void {
        const dialog$ = this.dialog
            .open(OrderPaymentModalComponent, {
                width: '60%',
                minHeight: '80%',
                disableClose: true,
                data: {
                    orderId: order.id,
                    customerType: order.customer.type_id,
                    sum: order.servicesSaleCost,
                    cashOperationType: 1, // Нужно сделать константу
                }
            })
            .afterClosed()
            .subscribe(result => {
                if (result && result.success && result.order !== null) {
                    this.dataSource.data = this.prepareDataForRender(this.dataSource.data.map((orderItem: any) => {
                        return orderItem.id === result.order.id ? result.order : orderItem;
                    }));

                    if (result.order.status === 1 || result.order.status === 2) {
                        this.onCompleteOrder(result.order);
                    }
                }
            });
        this.subscriptions.add(dialog$);
    }

    /**
     * Завершение заказа
     */
    onCompleteOrder(order: any): void {
        this.dialog
            .open(OrderCompleteComponent, {
                width: '60%',
                minHeight: '80%',
                disableClose: true,
            })
            .afterClosed()
            .subscribe(result => {
                if (result && result.success) {
                    this.loader.show();
                    this.boxService.completeOrderFromOrderList(order.id, order.box_id).subscribe((response) => {
                        this.loader.hide();

                        if (response.success) {
                            this.dataSource.data = this.prepareDataForRender(this.dataSource.data.map((orderItem: any) => {
                                return orderItem.id === response.order.id ? response.order : orderItem;
                            }));

                            if (result.n6carWash) {
                                this.boxService.resetN6carWash(order.customer_id)
                                    .subscribe(response => console.info('resetN6carWash', response));
                            }

                            if (response.order.status === 3 && response.order.payment_status === 1) {
                                this.removeOrderFromUIlist(response.order.id);
                            }
                        }
                    });
                }
            });
    }

    /**
     * Изменяет бокс для заказа
     */
    async onBoxChange(data: BoxChangeEmit) {
        this.service.changeBox(data.order.box_id, data.newBox.id, data.order.id).subscribe(response => {
            if (response.success) {
                this.ui.showSnackBar(`Заказу №${data.order.id} назначен бокс "${data.newBox.name}"`);
                this.getData();
            }
        });
    }

    /**
     * Удаляет заказ из UI
     *
     * @param orderId
     */
    private removeOrderFromUIlist(orderId: number) {
        this.dataSource.data = this.dataSource.data.filter((order: any) => {
            return order.id !== orderId;
        });
    }

    /**
     * Преподготиавливает данные для рендеринга, суммирует услуги по базоввой цене и по скидке
     *
     * @param data
     */
    private prepareDataForRender(data: any[]): any[] {
        return data.map((item: any) => {
            item.servicesCost = servicesCostCalculation(item.services);
            item.servicesSaleCost = servicesSaleCostCalculation(item.services);

            return item;
        });
    }
}
