import {Component, OnInit} from '@angular/core';
import {StorageService} from '../../services/storage.service';
import {Todo} from './todo';
import {Observable} from 'rxjs';
import {FilterPipe} from './filter.pipe';

@Component({
    selector: 'app-todo',
    templateUrl: './todo.component.html',
    styleUrls: ['./todo.component.scss'],
    providers: [FilterPipe]
})
export class TodoComponent implements OnInit {
    public todos: Observable<Todo[]>;
    public currentCategory: number = 0;
    public categories = CATEGORY_LIST;

    constructor(private storage: StorageService) {
    }

    ngOnInit() {
        this.todos = this.storage.getItem();
    }

    add(text) {
        if (text.length) {
            this.storage.addItem(new Todo({
                text: text,
                category: this.currentCategory
            }));
        }
    }

    doneToggle(item: Todo): void {
        this.storage.updateItem(item);
    }

    remove(item: Todo): void {
        this.storage.removeItem(item);
    }

    changeCurrentCategory(data: number) {
        this.currentCategory = data;
    }
}

const CATEGORY_LIST = ['Автомойка', 'Детейлинг', 'Шиномонтаж'];
