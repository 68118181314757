import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {routes} from './price-list.routes';
import {PriceListComponent} from './components/price-list/price-list.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MenuModule} from '@services/menu/menu.module';
import {SharedModule} from '@shared/shared.module';
import {PriceTableComponent} from './components/price-list/components/price-table/price-table.component';
import {MatInputModule} from '@angular/material/input';


@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MenuModule.forRoot({routes, order: 8}),
        MatFormFieldModule,
        MatInputModule,
        MatTableModule,
        MatSortModule,
        SharedModule
    ],
    declarations: [PriceListComponent, PriceTableComponent]
})
export class PriceListModule {
}
