<h1 mat-dialog-title>Назначения сотрудников для текущей смены</h1>

<div mat-dialog-content>
    <div class="row" cdkDropListGroup>
        <div class="col-xs-6 col-sm-8">
            <p><strong>Список боксов</strong></p>

            <form id="employee-form" class="panel-list" [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="panel panel-default col-xs-12 col-md-6"
                     *ngFor="let box of boxList">
                    <div>
                        <h3 class="panel-title">
                            <strong>{{box.name}}</strong>
                        </h3>
                        <div class="d-flex">
                            <mat-checkbox (change)="boxRequiredToggleHandler(box)" [checked]="box.requireEmployee">
                                <span *ngIf="!box.requireEmployee">Не обязательно заполнять</span>
                                <mat-error *ngIf="box.requireEmployee && form.controls[box.id].hasError('required')">
                                    *Обязательно запонить
                                </mat-error>
                            </mat-checkbox>
                        </div>
                    </div>

                    <div class="panel-body list-group"
                         cdkDropList
                         [cdkDropListData]="box.newEmployees"
                         (cdkDropListDropped)="drop($event)">
                        <div class="employee-empty-list text-center" *ngIf="!box.newEmployees.length">
                            Перетяните сотрудников в эту область
                        </div>

                        <div class="list-group-item employee" *ngFor="let employee of box.newEmployees" cdkDrag>
                            <img *ngIf="employee.photo" [src]="photoUrl + '/' + employee.photo"
                                 width="25px"
                                 height="25px"
                                 class="img-circle img-cover img-position-top-center">
                            {{employee?.surname}} {{employee?.name}}
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div class="col-xs-6 col-sm-4 employee-list-wrap">
            <p><strong>Сотрудники для боксов</strong></p>

            <div class="employee-list">
                <div
                    class="list-group"
                    cdkDropList
                    [cdkDropListData]="employeesList"
                    (cdkDropListDropped)="drop($event)">
                    <div class="list-group-item employee" cdkDrag *ngFor="let employee of employeesList">
                        <img *ngIf="employee.photo" [src]="photoUrl + '/' + employee.photo"
                             width="25px"
                             height="25px"
                             class="img-circle img-cover img-position-top-center">
                        {{employee?.surname}} {{employee?.name}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div mat-dialog-actions class="justify-content-between">
    <button type="button" mat-raised-button color="accent" (click)="logout()">
        Сменить пользователя
    </button>

    <button type="submit" mat-raised-button color="accent" form="employee-form" [disabled]="form.invalid">
        Подтвердить
    </button>
</div>
