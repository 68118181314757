import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {routes} from './standards.routes';
import {StandardsComponent} from './components/standards/standards.component';
import {MenuModule} from '../../services/menu/menu.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MenuModule.forRoot({routes, order: 10})
    ],
    declarations: [StandardsComponent]
})
export class StandardsModule {
}
