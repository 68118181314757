<div class="wrapper">
    <app-loader *ngIf="loader.progress"></app-loader>

    <div class="sidebar" data-active-color="white" data-background-color="black"
         data-image="../assets/img/sidebar-1.jpg">
        <app-sidebar-cmp></app-sidebar-cmp>
        <div class="sidebar-background" style="background-image: url('../../../assets/img/sidebar-1.jpg')"></div>
    </div>

    <div class="main-panel">
        <app-navbar-cmp></app-navbar-cmp>
        <!--<app-ng7-mat-breadcrumb></app-ng7-mat-breadcrumb>-->
        <router-outlet></router-outlet>
    </div>
</div>
