import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {calculateFixedPrice} from '@app/Helpers/service-helper';
import {ServiceSaleConfig, ServiceSaleResult} from '@app/states/boxes/components/box-view/components/service-sale/service-sale-config';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-service-sale',
    templateUrl: './service-sale.component.html',
    styleUrls: ['./service-sale.component.scss']
})
export class ServiceSaleComponent implements OnInit {
    serviceName = '';
    servicePrice: any = null;
    form: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<ServiceSaleComponent, ServiceSaleResult | boolean>,
        @Inject(MAT_DIALOG_DATA) public data: ServiceSaleConfig,
        private fb: FormBuilder
    ) {
    }

    ngOnInit() {
        this.form = this.fb.group({
            percent: [null, [Validators.max(100)]],
            fixed: null,
            toPrice: null,
            cause: '',
        });

        this.serviceName = this.data.serviceName || '';
        this.servicePrice = this.data.servicePrice || 0;

        if (this.data.serviceDiscountPercent && this.data.servicePrice > 0) {
            const fixed = calculateFixedPrice(this.servicePrice, this.data.serviceDiscountPercent);
            this.form.patchValue({
                percent: this.data.serviceDiscountPercent,
                fixed: fixed,
                toPrice: this.servicePrice - fixed
            }, {emitEvent: false});
        }

        this.form.controls.percent.valueChanges.subscribe((sale: number) => {
            if (this.servicePrice) {
                const fixed = calculateFixedPrice(this.servicePrice, sale);
                this.form.patchValue({fixed: fixed, toPrice: this.servicePrice - fixed}, {emitEvent: false});
            }
        });

        this.form.controls.fixed.valueChanges.subscribe((sale: number) => {
            if (this.servicePrice) {
                this.form.patchValue(
                    {
                        percent: Math.ceil(sale * 100 / this.servicePrice),
                        toPrice: this.servicePrice - sale
                    },
                    {emitEvent: false}
                );
            }
        });

        // Установка
        this.form.controls.toPrice.valueChanges.subscribe((price: number) => {
            price = price === null ? this.servicePrice : price;

            if (this.servicePrice) {
                this.form.patchValue(
                    {
                        percent: 100 - Math.round(100 / (this.servicePrice / price)),
                        fixed: this.servicePrice - price
                    },
                    {emitEvent: false}
                );
            }
        });
    }

    close(): void {
        this.dialogRef.close(false);
    }

    success(): void {
        if (this.form.valid) {
            this.dialogRef.close(this.form.value);
        }
    }
}
