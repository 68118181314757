import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {OrderService} from '../../services/order.service';
import {LoaderService} from '@shared/components/loader/loader.service';

@Component({
    selector: 'app-new-record',
    templateUrl: './new.component.html',
    styleUrls: ['./new.component.scss']
})
export class NewComponent implements OnInit {
    public customer: any;

    constructor(
        private route: ActivatedRoute,
        private service: OrderService,
        private router: Router,
        private loader: LoaderService,
    ) {
        this.customer = null;
    }

    ngOnInit() {
        this.parseRouteData();
    }

    /**
     * Создает новый заказ
     *
     * @param data
     */
    onSubmit(data: any) {
        this.loader.show();
        this.service.create(data.formValue).subscribe(response => {
            this.loader.hide();

            if (response.success) {
                if (response.data.box_id > 0) {
                    this.router.navigate([`/boxes/${response.data.box_id}`]);
                } else {
                    this.router.navigate(['/']);
                }
            }
        });
    }

    private parseRouteData(): void {
        if (this.route.snapshot.data.customer) {
            this.customer = this.route.snapshot.data.customer;
        }
    }
}
