import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-report-table',
  templateUrl: './report-table.component.html',
  styleUrls: ['./report-table.component.scss']
})
export class ReportTableComponent implements OnInit, AfterViewInit {
    @Input() tableData: any;
    public renderTable: boolean = false;
    public displayedColumns: string[] = ['dateEnd', 'storage', 'type', 'customer', 'car', 'status'];
    public dataSource: any;

    constructor() {
    }

    ngOnInit() {
        this.dataSource = new MatTableDataSource(this.tableData);
        this.renderTable = true;
    }

    ngAfterViewInit(): void {
    }
}
