import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NewCallModule} from './new-call/new-call.module';
import {TodoModule} from './todo/todo.module';
import {BoxesModule} from './boxes/boxes.module';
import {PriceListModule} from './price-list/price-list.module';
import {CashboxModule} from './cashbox/cashbox.module';
import {StandardsModule} from './standards/standards.module';
import {StorageOfTiresModule} from './storage-of-tires/storage-of-tires.module';
import {RecordsModule} from './records/records.module';
import {ClientsModule} from './clients/clients.module';
import {OrdersModule} from './orders/orders.module';
import {AuthModule} from './auth/auth.module';
import {SalariesModule} from '@app/states/salaries/salaries.module';

@NgModule({
    imports: [
        CommonModule,
        ClientsModule,
        NewCallModule,
        TodoModule,
        BoxesModule,
        PriceListModule,
        CashboxModule,
        StandardsModule,
        RecordsModule,
        StorageOfTiresModule,
        OrdersModule,
        AuthModule,
        SalariesModule,
    ],
    declarations: []
})
export class StatesModule {
}
