import {Component, OnInit} from '@angular/core';
import {SessionShiftService} from '@app/core/services/session-shift.service';
import {AuthBaseService} from '@app/core/services/auth-base.service';
import {Observable} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SessionEmployeeAssignmentsComponent} from '../session-employee-assignments/session-employee-assignments.component';
import {UIMangerService} from '@services/ui-manger.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-session-shift-modal',
    templateUrl: './session-open-modal.component.html',
    styleUrls: ['./session-open-modal.component.scss']
})
export class SessionOpenModalComponent implements OnInit {
    form: FormGroup;
    user: any;
    cashiersList: Observable<any[]>;
    administratorsList: Observable<any[]>;

    constructor(
        private sessionOpenModalRef: MatDialogRef<SessionOpenModalComponent>,
        private service: SessionShiftService,
        private authUser: AuthBaseService,
        private fb: FormBuilder,
        private dialog: MatDialog,
        private ui: UIMangerService
    ) {
        this.form = this.fb.group({
            isNight: false,
            cashier: ['', [Validators.required]],
            administrator: ['', [Validators.required]],
        });
    }

    ngOnInit(): void {
        this.user = this.authUser.getUser();
        this.cashiersList = this.service.getListOfCashiers();
        this.administratorsList = this.service.getListOfAdministrators();
    }

    public openShift(): void {
        if (this.form.invalid) {
            return;
        }

        this.service.startSession(this.form.value).subscribe((response: any) => {

            if (response.success) {
                this.dialog.open(SessionEmployeeAssignmentsComponent, {disableClose: true, minWidth: '80%', minHeight: '90%'});
                this.sessionOpenModalRef.close();
                return;
            }

            console.error('Ошибка открытия смены', response);
        });
    }

    public logout(): void {
        this.authUser.logout();
        this.sessionOpenModalRef.close(false);
    }
}
