import {
    Component, ComponentFactoryResolver, OnInit, ViewContainerRef, ComponentRef, ViewChild, AfterViewInit, OnDestroy, ApplicationRef
} from '@angular/core';
import {WebCameraComponent} from './components/web-camera/web-camera.component';
import {FormClientInfoService} from './form-client-info.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import Client from '../../../../entities/client';
import {Observable, of} from 'rxjs';
import {debounceTime, switchMap, distinctUntilChanged} from 'rxjs/operators';
import {ClientsService} from '../../services/clients.service';
import {Router} from '@angular/router';
import {ClientFormService} from '@services/client-form.service';
import {LoaderService} from '@shared/components/loader/loader.service';
import {InputCarNumberComponent} from '@shared/components/input-car-number/input-car-number.component';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-new-client',
    templateUrl: './new-client.component.html',
    styleUrls: ['./new-client.component.scss']
})
export class NewClientComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('videoContainer', {read: ViewContainerRef}) videoContainer: ViewContainerRef;
    @ViewChild('inputCarNumber') inputCarNumber: InputCarNumberComponent;

    private webComponent: ComponentRef<WebCameraComponent>;
    public image: string;
    public typeClients: any = [];
    public form: FormGroup;
    public filteredSurname$: Observable<any[]>;
    public filteredName$: Observable<any[]>;

    constructor(
        private app: ApplicationRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private viewContainerRef: ViewContainerRef,
        private formService: FormClientInfoService,
        private clientFormService: ClientFormService,
        private fb: FormBuilder,
        private clientsService: ClientsService,
        private router: Router,
        private loader: LoaderService,
        public snackBar: MatSnackBar
    ) {
        this.getImage = this.getImage.bind(this);
        this.closeWebCam = this.closeWebCam.bind(this);
        this.clearImageWhenCloseWebCam = this.clearImageWhenCloseWebCam.bind(this);
    }

    ngOnInit() {
        this.clientFormService.getInputData().subscribe((result) => {
            this.typeClients = result.customerTypes;
            this.setDefaultValueToFormsField();
        });

        this.form = this.fb.group({
            phone: [''],
            carNumberType: ['', [Validators.required]],
            carNumber: [null, [Validators.required, Validators.minLength(3)]],
            clientType: ['', [Validators.required]],
            family: null,
            name: null,
            patronymic: null,
            carPhoto: null
        });

        this.filteredSurname$ = this.form.controls['family'].valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            switchMap((surname: string) => surname.length >= 2
                ? this.clientsService.findClientsBySurnameForAutocomplete(surname)
                : of([])
            )
        );

        this.filteredName$ = this.form.controls['name'].valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            switchMap((name: string) => name.length >= 2
                ? this.clientsService.findClientsByNameForAutocomplete(name)
                : of([])
            )
        );
    }

    ngAfterViewInit(): void {
    }

    ngOnDestroy() {

    }

    onSelectClient($event: any) {
        this.router.navigate(['/clients/profile', $event.id]);
    }

    submit($event) {
        $event.preventDefault();

        if (this.form.valid) {
            this.loader.show();
            this.formService.addClient(new Client(this.form.value))
                .subscribe((result: any) => {
                    this.loader.hide();
                    this.router.navigate(['/clients/profile', result.data.id]);
                });
        } else {
            for (let key in this.form.controls) {
                if (this.form.controls.hasOwnProperty(key)) {
                    this.form.controls[key].markAsTouched();
                }
            }
        }
    }

    openWebCam() {
        this.image = ''; // Сбрасываем изображения

        const factory = this.componentFactoryResolver.resolveComponentFactory(WebCameraComponent);
        this.webComponent = this.viewContainerRef.createComponent(factory);
        // this.webComponent.changeDetectorRef.detectChanges();
        this.videoContainer.insert(this.webComponent.hostView);

        this.webComponent.instance.base64Result.subscribe(this.getImage);
        this.webComponent.instance.closeEvent.subscribe(this.closeWebCam);
        this.webComponent.instance.closeEvent.subscribe(this.clearImageWhenCloseWebCam);
    }

    getImage(event: any) {
        this.image = event.data;
        this.closeWebCam();
        this.updateImageFormField();
    }

    selectCarNumber(data: any): void {
        this.router.navigate(['/clients/profile', data.owner.id]);
    }

    private updateImageFormField() {
        let image = this.image.replace(/^data:image\/(png|jpg|jpeg);base64,/, '');
        this.form.patchValue({
            carPhoto: this.image
        });

        const imageBlob = this.dataURItoBlob(image);
        const imageFile = new File([imageBlob], 'temp.jpeg', {type: 'image/jpeg'});
        const formData: FormData = new FormData();
        formData.append('upload', imageFile, 'temp.jpeg');

        this.loader.show();
        fetch('https://recognizer.drmauto.ru/uploadimage', {
            method: 'POST',
            body: formData
        })
            .then(r => r.text())
            .then((response: string) => {
                this.loader.hide();
                const data: string[] = response.split('\n');

                if (data[1] !== null && data[1] !== undefined && data[1] !== '') {
                    // TODO: на данный момент вставляем значнеия без форматирования, так как API присылает номер с английскими буквами,
                    // TODO: а у нас только русские по шаблону проходят.
                    // let conformedPhoneNumber = conformToMask(
                    //     'УУ008УУ77', //data[1],
                    //     this.inputCarNumber.carNumberPattern,
                    //     {guide: false}
                    // );

                    this.form.controls['carNumber'].setValue(data[1]);
                } else {
                    this.snackBar.open('НОМЕР НЕ РАСПОЗНАН', 'Закрыть', {
                        duration: 2000,
                        verticalPosition: 'top'
                    });
                }
            })
            .catch(() => {
                this.loader.hide();
                this.snackBar.open('Ошибка сервера', 'Закрыть', {
                    duration: 2000,
                    verticalPosition: 'top'
                });
            });
    }

    private closeWebCam() {
        this.webComponent.destroy();
    }

    private clearImageWhenCloseWebCam() {
        this.image = '';
        this.updateImageFormField();
    }

    private setDefaultValueToFormsField() {
        this.form.patchValue({
            clientType: 1, // DEFAULT VALUE ID 1,
        });
    }

    private dataURItoBlob(dataURI) {
        const byteString = atob(dataURI);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);

        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }

        return new Blob([arrayBuffer], {type: 'image/jpeg'});
    }
}
