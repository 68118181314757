/**
 * @class Todo
 */
export class Todo {
    public id: string;
    public text: string;
    public isDone: boolean;
    public category: number;

    public constructor({id = null, text, isDone = false, category}) {
        this.id = id;
        this.text = text;
        this.isDone = isDone;
        this.category = category;
    }

    public setId(value: string): this {
        this.id = value;

        return this;
    }
}
