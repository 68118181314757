import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {API_URL} from '../core/constants';
import {PusherService} from './pusher.service';
import {Observable, Observer} from 'rxjs';
import {map} from 'rxjs/operators';
import {OrderSaleResult} from '@app/states/boxes/components/box-view/components/order-sale/order-sale-config';

@Injectable()
export class BoxInfoService {
    private boxList$: Observable<any[]>;

    constructor(
        private http: HttpClient,
        private pusher: PusherService
    ) {
        this.boxList$ = Observable.create((observer: Observer<any[]>) => {
            this.getBoxesStatuses().subscribe((response: any) => {
                observer.next(response);

                this.pusher.boxChannel.bind('box-status-event', (response: any) => {
                    observer.next(response.statuses);
                });

                this.pusher.tiresChannel.bind('end-storage-time', (response: any) => {
                    //console.log('tiresChannel', response);
                });
            });
        });
    }

    getAllBoxStream() {
        return this.boxList$;
    }

    getAllBox(): Observable<any> {
        return this.http
            .get(`${API_URL}/boxes`).pipe(
                map((result: any) => result.data)
            );
    }

    getBoxesStatuses(): Observable<any> {
        return this.http.get(`${API_URL}/boxes/statuses`).pipe(
            map((result: any) => result.data)
        );
    }

    getFreeBoxes(): Observable<any> {
        return this.http
            .get(`${API_URL}/boxes/free`).pipe(
                map((result: any) => result.data)
            );
    }

    getBox(id: number): any {
        return this.http.get(`${API_URL}/boxes/${id}`);
    }

    changeEmployees(boxId: number, employeesId: number[]): Observable<any> {
        return this.http.post(`${API_URL}/boxes/${boxId}/changeEmployees`, {
            employeesId
        });
    }

    completeOrder(boxId: number, orderId: number): Observable<any> {
        return this.http.post(`${API_URL}/orders/${orderId}/complete`, {boxId});
    }

    completeOrderFromOrderList(orderId: number, boxId?: number): Observable<any> {
        return this.http.post(`${API_URL}/orders/${orderId}/complete`, {boxId});
    }

    payOrder(orderId: number, sentTo1c: boolean, paymentType: string, separatedSum: any): Observable<any> {
        return this.http.post(`${API_URL}/orders/${orderId}/pay`, {sentTo1c, paymentType, separatedSum});
    }

    /**/
    addNewServiceToOrder(boxId: number, orderId: number, serviceRows: any): Observable<any> {
        return this.http.post(`${API_URL}/orders/${orderId}/services`, {boxId, serviceRows});
    }

    disableServiceItem(orderId: number, orderServiceId: number): Observable<any> {
        return this.http.patch(`${API_URL}/orders/${orderId}/services/${orderServiceId}/disable`, {});
    }

    enableServiceItem(orderId: number, orderServiceId: number): Observable<any> {
        return this.http.patch(`${API_URL}/orders/${orderId}/services/${orderServiceId}/enable`, {});
    }

    setSaleForService(orderId: number, orderServiceId: number, data: any): Observable<any> {
        return this.http.patch(`${API_URL}/orders/${orderId}/services/${orderServiceId}/add-sale`, data);
    }

    changePrice(orderId: number, orderServiceId: number, data: any): Observable<any> {
        return this.http.patch(`${API_URL}/orders/${orderId}/services/${orderServiceId}/price`, data);
    }

    /**/

    getNewOrdersCount(): Observable<any> {
        return this.http.get(`${API_URL}/orders/newOrdersCount`);
    }

    updateServiceResponsibilities(serviceId: number, boxId: number, responsibles: any): Observable<any> {
        return this.http.put(`${API_URL}/order-service/${serviceId}`, {
            responsibles,
            boxId
        });
    }

    addExpressOrder(data: any): Observable<any> {
        return this.http.post(`${API_URL}/orders/expressAdd`, data);
    }

    deductPointsByShares(orderId: number, customerId: number, shareId: number, serviceId: number): Observable<any> {
        return this.http.put(`${API_URL}/customers/${customerId}/shares/${shareId}/deductPoints`, {
            serviceId,
            orderId,
        });
    }

    fillTheBoxWithEmployees(data: any): Observable<any> {
        return this.http.put(`${API_URL}/boxes/fillInStaff`, {boxes: data});
    }

    resetN6carWash(customerId: number): Observable<any> {
        return this.http.put(`${API_URL}/customers/${customerId}/shares/resetN6carWash`, {reset: true});
    }

    /**
     * Получает список сотрудников по массиву ИД
     *
     * @param employeeIds
     */
    getEmployeeListById(employeeIds: string[]): Observable<any[]> {
        return this.http
            .get<any[]>(`${API_URL}/employees`, {
                params: {
                    'ids[]': employeeIds.length ? employeeIds : [''], // Иначе HttpClient не отправляет пустой массив
                }
            })
            .pipe(
                map((response: any) => {
                    return response.data;
                })
            );
    }

    setOrderDiscount(orderId: number, data: OrderSaleResult): Observable<any> {
        return this.http.patch(`${API_URL}/orders/${orderId}/set-discount`, data);
    }
}
