import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subscription} from 'rxjs';
import {CustomerService} from '@services/customer.service';

export interface ICustomerSharesChange {
    serviceId: number;
    nFreeId: number;
}

export interface IAddServiceSale {
    nFreeId: number;
    serviceId: number;
    price: number;
    gradeId: number;
}

@Component({
    selector: 'app-customer-shares',
    templateUrl: './customer-shares.component.html',
    styleUrls: ['./customer-shares.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerSharesComponent implements OnInit, OnDestroy {
    @Input() customerId: number;
    @Input() currentOrderServices: any[];
    @Output() addSale: EventEmitter<ICustomerSharesChange>;
    @Output() addServiceSale: EventEmitter<IAddServiceSale>;

    public shares: any[];
    private subscription: Subscription;

    constructor(
        private cd: ChangeDetectorRef,
        private customerService: CustomerService
    ) {
        this.addSale = new EventEmitter<ICustomerSharesChange>();
        this.addServiceSale = new EventEmitter<IAddServiceSale>();
        this.currentOrderServices = this.currentOrderServices || [];
        this.shares = [];
        this.subscription = new Subscription();
    }

    ngOnInit(): void {
        this.loadData();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onAddSale(event: ICustomerSharesChange): void {
        this.addSale.emit(event);
    }

    public onAddServiceSale(event: IAddServiceSale): void {
        this.addServiceSale.emit(event);
    }

    public tackFn(index, item) {
        return item.car.id;
    }

    private loadData(): void {
        const data = this.customerService.getShares(this.customerId).subscribe((response: any) => {
            this.shares = response.data;
            this.cd.markForCheck();
        });
        this.subscription.add(data);
    }
}
