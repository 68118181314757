import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthBaseService} from '../services/auth-base.service';

@Injectable()
export class ManagerGuard implements CanActivate {
    constructor(private authService: AuthBaseService) {
    }

    canActivate(next: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (!this.authService.isAuth()) {
            this.authService.logout();
        }

        return this.authService.isAuth();
    }
}
