import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {StorageService} from '../../../../services/storage.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ClientFormService} from '../../../../services/client-form.service';
import {PHONE_MASK} from '../../../../core/constants';
import maskInput from 'vanilla-text-mask/dist/vanillaTextMask.js';
import {LoaderService} from '../../../../shared/components/loader/loader.service';



@Component({
    selector: 'app-storage-of-tires',
    templateUrl: './storage-of-tires.component.html',
    styleUrls: ['./storage-of-tires.component.scss']
})
export class StorageOfTiresComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('inputPhone', {read: ViewContainerRef}) inputPhone: ViewContainerRef;
    private maskedInputController;
    public form: FormGroup;
    public dataList: any;
    public storageList: any[];
    public typeItems: any[];
    public typeClients: any = [];

    constructor(
        private service: StorageService,
        private fb: FormBuilder,
        private clientFormService: ClientFormService,
        private loader: LoaderService
    ) {
    }

    ngOnInit() {
        this.form = this.fb.group({
            storage: [null, [Validators.required]],
            itemType: [null, [Validators.required]],
            phone: [null, [Validators.required]],
            dateEnd: [null, [Validators.required]],
            carNumber: null,
            carNumberType: null,
            clientType: null,
        });

        this.service.getItemList().subscribe((response: any) => {
            this.dataList = response.data;
        });

        this.clientFormService.getInputData().subscribe((result) => {
            this.typeClients = result.customerTypes;
            this.setDefaultValueToFormsField();
        });

        this.service.getDataForTheCreatingNewItem().subscribe((response: any) => {
            if (response.success) {
                this.storageList = response.data.warehouses;
                this.typeItems = response.data.itemTypes;
            }
        });
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.maskedInputController = maskInput({
                inputElement: this.inputPhone.element.nativeElement,
                mask: PHONE_MASK
            });
        });
    }

    ngOnDestroy() {
        this.maskedInputController.destroy();
    }

    public submit() {
        if (this.form.valid) {
            this.loader.show();
            this.service
                .saveNewItemInStorage(this.form.value)
                .subscribe(
                    (response: any) => {
                        this.loader.hide();
                        this.form.reset();
                    },
                    (error: any) => {
                        this.loader.hide();
                    }
                );
        }
    }

    private setDefaultValueToFormsField() {
        this.form.patchValue({
            clientType: 1, // DEFAULT VALUE ID 1,
        });
    }
}

