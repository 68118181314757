import {throwError, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {AuthBaseService} from './auth-base.service';
import {UIMangerService} from '@services/ui-manger.service';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
    constructor(
        private authService: AuthBaseService,
        private ui: UIMangerService
    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const cloneRequest = req.clone({
            headers: req.headers.set('Authorization', this.authService.getToken())
        });

        return next.handle(cloneRequest).pipe(
            catchError((err: HttpErrorResponse) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.error instanceof ProgressEvent && err.status === 0) {
                        this.ui.showSnackBar(`Ошибка подключение. Проверьте соединение с сервером или соединение сети!`);

                        return throwError(err);
                    }

                    // Unauthorized
                    if (err.status === 401) {
                        this.authService.logout();
                    }

                    if (err.status === 500) {
                        this.ui.showSnackBar('Ошибка на сервере, сообщите в поддрежку!');
                    }
                }

                return throwError(err);
            })
        );
    }
}
