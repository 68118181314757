import {Component, OnInit} from '@angular/core';
import {MenuService} from '@services/menu/menu.service';
import {AuthBaseService} from '@app/core/services/auth-base.service';

declare const $: any;

@Component({
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})
export class SidebarComponent implements OnInit {
    public menuItems: any[];
    public user: any;
    public avatar: string;

    constructor(
        private menuService: MenuService,
        private authBase: AuthBaseService
    ) {
        this.user = this.authBase.getUser();
        this.avatar = this.user.imagePath || '../assets/img/faces/avatar.jpg';
    }

    isNotMobileMenu(): boolean {
        return $(window).width() <= 991;
    }

    ngOnInit(): void {
        // TODO: без этого не работает скрол
        $('html').addClass('perfect-scrollbar-off');

        this.menuItems = this.menuService.getConfig();
    }

    logout() {
        this.authBase.logout();
    }
}
